// User Interface ------------------------------------------------------------
// This file can be expanded to handle all the user interface properties as
// they become available in browsers:
// http://www.w3.org/TR/2000/WD-css3-userint-20000216
@import "shared";


// This property controls the selection model and granularity of an element.
//
// @param $select
//   [ none | text | toggle | element | elements | all | inherit ]
@mixin user-select($select) {
  $select: unquote($select);
  @include experimental(user-select, $select,
    -moz, -webkit, not(-o), not(-ms), -khtml, official
  );
}
