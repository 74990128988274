.d-error{
	border-top:1px solid $color19;
	border-bottom:1px solid $color19;
	margin-bottom:15px;
	padding-top:40px;
	padding-bottom:60px;
	.h-error{
		margin:0;
		color: $color21;
		text-align:center;
		font-weight:bold;
	}
	.p-error{
		color: $color21;
		margin-bottom:34px;
		text-align:center;
		font-weight:bold;
	}
}

.subscription-form, .subscription-form-modern {
	.conditions-block {
		label {
			&.invalid {
				color: $color21;
                position: relative !important;
			}

		}
	}
}

.checkbox-invalid {
    color: $color21 !important;
}

.err-block {
    display: block;
    color: $color21 !important;
    font-size: 12px !important;
    line-height: 20px !important;
}

input[type="checkbox"] {
    &.invalid {
        outline: 2px $color21 solid !important;
        background-color: #e8c3c5 !important;
    }
}
