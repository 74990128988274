.page-login-container {
  display: table;
  width: 100%;
  height: 100vh;
}
main.page-login {
  width: 100%;
  display: table-cell;
  vertical-align: middle;

    section .section-header {
        padding: 13px 15px;
    }
}

.page-login{
  .vacationscms-hero{
    margin-top: 10%;
    text-align: center;

    h1{
      font-size: 20px;
      margin-bottom: 5px;
    }

    h2{
      font-size: 27px;
      margin: 0 0 15px;
    }
  }

  .errors-container{
    .alert{
      padding: 6px 15px;
      min-height: 34px;
    }
  }
  form{

    .control-label{
      overflow: hidden;
      height: 34px;
      -webkit-border-top-left-radius: 6px;
      -webkit-border-bottom-left-radius: 6px;
      -moz-border-radius-topleft: 6px;
      -moz-border-radius-bottomleft: 6px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-width: 1px;
      border-style: solid;
      padding: 7px 0;
      text-align: center;
    }

    .area-login{
      padding-left: 0;
      padding-right: 0;
      webkit-border-top-right-radius: 6px;
      -webkit-border-bottom-right-radius: 6px;
      -moz-border-radius-topright: 6px;
      -moz-border-radius-bottomright: 6px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-width: 1px;
      border-style: solid;
      border-left: 0px solid;
      input{
        margin: 0;
        border:0;
        webkit-border-top-right-radius: 6px;
        -webkit-border-bottom-right-radius: 6px;
        -moz-border-radius-topright: 6px;
        -moz-border-radius-bottomright: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    .page-submit{
      border-radius: 6px;
      input{
        color: inherit;
        width: 100%;
        background-color: inherit;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 6px;
        cursor: pointer;
        -webkit-appearance: button;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        margin-bottom: 0;
        text-align: center;
        text-decoration: none !important;
        vertical-align: middle;
        touch-action: manipulation;
        border: none;
        white-space: nowrap;
        padding: 7px 10px;
        font-size: 14px;
        line-height: 1.42857;
        border-radius: 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }
}

