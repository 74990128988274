.expert-advice
{

	div.section-content{
		padding-bottom:0;
	}
	section.search-box{
		margin-bottom:0;
		position:relative;
	}
	.expert-list{
		margin-left: -15px;
		margin-right: -16px;
		#expert-listColumn-add-expert{
			display:none;
			&.expert-listColumn{
				h4{
					height:34px;
				}
			}

		}
	}
	.expert-listColumn{
		padding-top:15px;
		padding-bottom:15px;
		margin-bottom: 15px;
		width:31.33%;
		@media  (max-width: $screen-xs-max) {
			margin-bottom: 0;
			border:0px;
			border-bottom:1px solid;
			&:nth-child(2n+2)::before {
				display: block;
				background-color:$darkerBackground;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				position: absolute;
				content: '';
			}
			min-height:190px;
		}
		margin-right:35px;
		min-height:190px;
		opacity:1;
		border: 1px solid inherit;
		@media (min-width: 980px) {
			&:nth-child(3n+3) {
				margin-right:0;
			}
		}

		&.ui-sortable-handle{
			cursor:move;
		}
		h3{
			margin:6px 0 4px 0;
			@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
				margin-bottom:0;
			}
			font-size:16px;
			color:$color1;
		}
		h4{
			font-size:14px;
			color:$color9;
			height:59px;
			@media (min-width: 980px) and (max-width: 1279px) {
				height:auto;

			}
			@media  (max-width: $screen-xs-max) {
				height:33px;
			}
			font-weight:bold;
			margin-top:0;
		}
		a{
			&:hover h3,&:hover h4 {
				color:$color18;
			}
		}
		.imageContainer{
			border: 1px solid;
			height:160px;
			table{
				height:100%;
				width:100%;
				font-size:12px;
				td{
					text-align: center;
				}
			}
		}
		p{
			line-height:19px;
			font-size:13px;
			overflow: hidden;
			text-overflow: ellipsis;
		  	&:hover{
			  overflow:visible;
			}
		}

		.col-xs-8{
			width:64%;
			padding-left: 149px;
			position: absolute;
			width: 92%;
			z-index:0;
		}

		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
			margin-right:25px;
			backgroud-color:red;
			width:48.3%;
			min-height:181px;
			margin-right: 25px;
			&:nth-child(2n+2) {
				margin-right:0;
			}
		}



		@media (min-width: 800px) and (max-width: 827px) {
			width:48.35%;
		}
		@media (min-width: 828px) and (max-width:  979px) {
			width:47.89%;
		}
		@media (min-width: 980px) and (max-width: 1279px) {
			margin-right: 23px;
			width:31.72%;
		}

		@media (max-width: $screen-xs-max) {
			margin-right: 23px;
			width:100%;
		}

        #confirmPersonalDataUsageAllowance {
            position: relative;
            color: black;
            text-align: left;
            min-height: 161px;
            border: 1px solid $color4;
            border-top: none;

			@media (max-width: $screen-xs-max) {
				border: none;
			}

            p {
                text-align: left;
            }

            input[type=checkbox]{
                height: auto;
                width: auto;
                text-align: left;
                display: inline-block;
            }
            label {
                min-height: 20px;
                padding-left: 10px;
                margin-bottom: 0;
                font-weight: normal;
                display: inline-block;
                max-width: 90%;
                text-align: left;

				&.invalid {
					color: $color21;
				}
            }
            button {
                margin: 20px auto;
                display: block;
            }
        }


	}

	.show-admin-panel {
		top: 2px;
		right: 14px !important;
	}

} // expert advice

.expert-advice,.expert
{
	.row{
		position:relative;
	}
	.show-admin-panel-expert-advice{
		height:auto;
		width:auto;
		position:absolute;
		top:0;
		right:-1px;
		left:-1px;
		text-align:right;
		padding-right: 5px;
		height:35px;
		display:none;
		.pencilEdit{
			display:block;
			text-align:left;
			margin-left:10px;
			margin-top: 8px;
			cursor: pointer;
			width:50%;
			&.pencilEditCenter{
				text-align:center;
			}
		}

		.minus, .addExpert{
			text-align:center;
			position:absolute;
			top:0;
			right:0;
			width:41px;
			height:35px;
			border-left:1px solid;
			cursor:pointer;
			i{
				line-height:35px;
			}
		}
		.minus{
		  i{
			line-height:36px;
		  }
		}
		.addExpert{
			border:0;
			font-size: 21px;
		}

		.undo{
			text-align:center;
			position:absolute;
			right:0;
			top:0;
			cursor:pointer;
			width:50%;
			display:none;
			border-left:1px solid #fff;
			height:35px;
			line-height:34px;
		}

	}

	// show admin button fix
	&:hover {
		.show-admin-panel {
			display: block;
		}
	}

}
.expert {

	.show-admin-panel {
		top: 2px;
		right: 14px
	}

	.show-admin-panel-expert-advice{
		background-color:red;
		display:block;
		top:9px;
	}
  section.guide-box {
	.section-content {
	  height: auto;
	}
  }
}
.imageContainer {
	overflow:hidden;
	width:135px;
	z-index:2;
	position:relative;
	@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
		height:152px;
	}
	@media  (max-width: $screen-xs-max) {
		height:127px;
	}

	@media (min-width: 361px) and (max-width: $screen-xs-max) {
		width:135px;
	}
	.addressExpert {
		display: block;
		min-width: 100%;
		min-height: 100%;
		background-size: cover;
		background-position: center;
	}
	.imageExpert {
		max-width: 100%;
		max-height: 100%;
	}

}



.description{
	.guide-box-expert, .guide-box{
		.tab-content{
			.row{
				display:block;
				padding-bottom:17px;
				padding-top:17px;
				margin-top:0;
				@media (max-width: $screen-sm-max) {
					padding-top:15px;
				}

				border-bottom:1px solid;
				.info{
					padding-left:15px;
					margin-bottom:0;
				  @media (max-width: $screen-xs-max) {
					  padding-left:0;
					  margin-bottom:0;
					  h3{
						margin-top: 9px;
						margin-bottom:4px;
					  }
					}
					div.text *{
						height:85px;
						overflow:hidden;
						text-overflow:ellipsis;
						line-height: 17px;
					}
				}
				.image-row-expert{
					height:145px;
					overflow:hidden;
					text-align:center;
				  	padding-left:30px;
					@media (min-width: $screen-xxs-min) and (max-width: $screen-xs-max) {
					  padding-left:0;
					}

				  }
				h3{margin-bottom: 5px;}
				a{
					position:relative;
				}
				a.more{
					float:right;
					padding-bottom:0;
				  	margin-top: 10px;
				}
			}
		}
	}

}

section.search-box{
	.description{
		.tab-content{
			padding-top:0;
			height:auto;
			padding-bottom:0;
		}
	}
}

.wiseWordsExpert {
	margin-top: 1em;
}

.expert{
	@media (max-width: $screen-xs-max) {
		.section-header:first-child{
			position:relative;
			overflow: visible;
			.dash{
				display:none;
			}
			.specialist{
				position:absolute;
				top:48px;
				left:14px;
				color:$color1;
				font-size:12px;
				font-weight:bold;
			}

		}
		.rowDesc{
			margin-top:41px;
			.descriptionImage{
				text-align:center;
				margin-right: 15px;
				overflow: hidden;
				img{
					display:inline;
					@media (max-width: 359px) {
						display:inline;
					}
				}
			}
		}
	  .guide-box-expert{
		padding-left:15px;
		padding-right:15px;
	  }
	}
	h3{width:100%;}
}

.showListExperts{
	margin-bottom:11px;
	display: block;
	.prev{
		margin-left:15px;
	}
}


#rightColumn{
	margin-bottom:15px;

	.input-container{
		label{
			font-weight:normal;
			margin-top:4px;
			margin-bottom:0px;
		  	height:31px;
			&.contact-captcha{
				margin-bottom:6px;
			}
		}
	  &.input-container-textarea{
		.input{
		  height:168px;
		  textarea{
			height:166px;
			margin-top:0;
		  }
		}
	  }

	}
  #contact-expert .input-row .input-container {
	margin-bottom:15px;
  }
	.contact-expert{
		.captchaField {
			.codeCaptcha {
				padding:0 0  15px 0;
				img {
				  border-right:0;
				}
			}
		}

		.conditions-block {
			margin-top: 10px;

			input {
				margin:4px 0 0
			}
		}
	}
	.input-row{
		&:first-child{
			label{
				margin-top:0;
			}
		}
	}
	.checkbox{
		label{
			font-size:11px;
			color:$color10;
		}
	}
	.input-row-before-code{
		margin-top:-3px;
	}
	.checkbox{
	  margin-top:0;
		input{
			width:auto;
		  	height:auto;
		}
	}
	fieldset{
		border:0;
	}
	.image-row-expert{
		overflow:visible;
		height:51px;
		width:63px;
		clear:both;
	}
}


.section-content-experts-right-column
{
	li{
		position:relative;
		height:83px;
		padding-left:70px;
		border-bottom:1px solid #fff;
		border-top:1px solid $color19;
		&:first-child{
			border-top:0;
		}
		&:hover .person div {color:$color18;}
		img{
			position:absolute;
			top:12px;
			left:12px;
			max-width: none;
			min-width: 0;
			width:45px;
		}
		.person{
			text-decoration:none;
			padding-top:14px;
			display:block;
			.personName{
				color:$color1;
				font-weight:bold;
			}
			.personKnow{
				color:$color9;
			}
		}

	}
	.list-unstyled{
		margin-bottom:0;
		border-bottom:1px solid $color19;
	}

}


.recommended{

	.show-admin-panel {
		top: 2px;
		right: 14px;
	}

	&:hover {
		.show-admin-panel {
			display: block;
		}
	}

	.text-justify{
		img{
			float:left;
			margin-right:15px;
			max-width:352px;
			min-width: 352px;
			@media (min-width: $screen-sm-min) and (max-width: 1279px) {
				max-width:345px;
			}
			@media (min-width: $screen-xxs-min) and (max-width: $screen-xs-max) {
				max-width:290px;
				float: none;
				margin:0 auto;
				/*content:"";*/
				margin-bottom:15px;
				display:block;
			  	min-width: 0;
/*
				&:after{
					background-color: brown;
					display: block;
					height: 8px;
					width: 13px;
					content: " ";
				}*/
			}
			@media (max-width: 319px) {
				width:auto;
				max-width:100%;
				min-width:100%;
			}


		}
	}
	#rightColumn{
		.tab-content{
			padding-top:0;
			padding-bottom:0;
			margin-bottom:-6px;
			height:auto;
			width:100%;
			.row{
				padding-top:0;
				padding-bottom:0;
				position:relative;
				height:49px;
				.text{
					display:none;
				}
				.more{
					display:none;
				}
				.col-lg-4{
					a{
						position:static;
					}
				}
				img{
					width:45px;
					position:absolute;
					top:5px;
					left:30px;
					max-width: none;
					min-width:0;
					max-height:28px;
				}
				h3{
					position:absolute;
					top:11px;
					left:89px;
					width:275px;
				}
				.col-lg-8{
					position:static;
					h3{
							a{
								text-overflow:ellipsis;
								white-space: nowrap;
								overflow:hidden;
								display: block;
								width:100%;
							}
					}

				}
				&:last-of-type{
				  border:0;
				}
			}
		}

		.expert-advice{
			.expert-listColumn{
				width:100%;
				margin-bottom:0;
				border:0;
				.col-xs-8{
					width:336px;

				}
			}
		}

	}

}

@media print {
  .printRecommended{
	background-color: transparent;
	.recommended{
	  background-color: transparent;
		  .scalable-x{
				  width:352px !important;
			      height:235px !important;
				  max-width: none;
				  min-width: 0;
				  display: inline-block !important;
				  float:none;
				  max-width: none;
				  min-width: 0;
			      float:left;
			      margin-right:15px;
			      margin-bottom:-4px;
			  }
	}
  }
  a[href]:after {
	content: none;
  }
}







