.flightbooking {

    #notifySend {
        margin-top: 15vh;
        margin-bottom: 25vh;
        margin-right: auto;
        margin-left: auto;
        border: 1px solid $color3;
        width: 35vw;
        min-width: 300px;
    }

    &.choose-seats {

        .t-cell {
            padding-bottom: 0;
            &.cell-01 {
                width: 32%;

                @include respond-to($screen-xs-max) {
                    display: block;
                    width: 100% !important;
                }
            }
            &.cell-02 {
                width: 50%;

                @include respond-to($screen-xs-max) {
                    margin-top: 10px;
                    display: block;
                    width: 100% !important;
                    padding-left: 43px;
                }
            }
            &.cell-03 {
                width: 18%;

                @include respond-to($screen-xs-max) {
                    display: block;
                    width: 100% !important;
                    padding-left: 43px;
                    margin-top: 10px;
                }
            }
            &.cell-passenger {

                @include respond-to($screen-xs-max) {
                    padding-right: 10px !important;
                    padding-left: 0px !important;
                }

                @include respond-to($screen-xxs-max) {
                    display: block;
                    width: 100% !important;
                    margin-top: 3px;
                    padding: 0 !important;
                }
            }
        }

        .section-header.section-header-with-icon  {

            .section-header-icon-small {
                display: inline-block;
                width: 34px;
                height: 34px;
                padding-top: 5px;
                text-align: center;
                background: white;
                border-radius: 100%;
                font-size: 24px;

                /*&.fa-sitting{
                    font-size: .9em;
                    padding-top: 1px;
                }*/
            }

            h2 {
                line-height: 34px;
                padding-right: 15px;
            }
        }

        .passengers-seats-row {
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $color19;

            .segment-passenger {
                padding: 2px 4px;
            }

            &:last-child {
                border-bottom: none;
            }

            .seat-price {
                color: $color7;
                font-weight: bold;
                white-space: pre;
            }

            .seat-code {
                font-weight: bold;
            }

            .segment-dep-des {
                font-size: 18px;
                padding: 2px 0px;
            }
            .seats-plane-icon {
                margin-right: 15px;
            }

            .segment-clear-container {
                text-align: right;

                .link {
                    padding-top: 0;

                    i {
                        margin-left:5px;
                        margin-right: 0;
                    }
                }

                @include respond-to($screen-xs-max) {
                    text-align: left;
                }
            }
        }

        .seats-header {
            border-bottom: 1px solid $color19;
            display: table;
            padding-top: 10px;
            padding-bottom: 10px;

            .select-seats-desc {
                display: table-cell;
                vertical-align: middle;
                width: 80%;
                padding-right: 15px;

                @include respond-to($screen-xxs-max) {
                    display: block;
                    width: 100% !important;
                    padding: 0 !important;
                }
            }

            .seats-btn-container {
                display: table-cell;
                vertical-align: middle;
                width: 20%;

                @include respond-to($screen-xxs-max) {
                    display: block;
                    width: 100% !important;

                    button {
                        float:none !important;
                        display: block;
                        width: 100%;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    &.section-from-organizer {
        .section-content {
            .input-title-label {
                display: none;
            }
        }
    }

    .section-header {

        .firefox-bug {
            // https://bugzilla.mozilla.org/show_bug.cgi?id=488725
            white-space: normal;

            .h2-main-txt {
                display: inline-block;
            }
        }
        .p-text {
            font-size: 14px;
        }
        .p-price {
            font-size: 24px;
        }
        .header-right-container {
            text-align: right;
            line-height: 32px;
        }
        .header-right-container2 {
            display: inline-block;
            float: right;
            text-align: right;
            font-size: 14px;
        }

        .header-seats-avail .description-xs{
            padding-right: $margin-padding-md;
            white-space: nowrap;
            @include respond-to($screen-xs-max) {
                display: inline-block;
            }
        }
    }

    .icon-direction-size{
        font-size: 39px;
    }

    .flight-restrictions {
        margin: 0 (-$half-gutter);
        padding: $margin-padding-md $half-gutter;

        /*.restriction-heading{
        }*/
        .restrictions-icon {
            margin-right: $margin-padding-md;
        }
        .restrictions-txt {
            position: relative;
            top: -2px;
        }

        .restrictions-list {
            margin-top: $margin-padding-sm;
            padding-left: $half-gutter+7px;
            //-webkit-appearance: none;

            b {
                font-weight: 500;
                color: $color21;
            }
        }
    }

    .f-list-elem-details {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: none;
    }

    .flight-details-500{
        @include respond-to(500px){
            .col-500-12{
                width: 100%;
            }
            .icon-pos-offset-500 {
                margin-top: $margin-padding-xs;
                margin-left: 25px;
            }
            .text-left-500{
                text-align: left;
                margin-top: $margin-padding-xs;
            }
        }
    }

    .flightlist-bottom-links {
        margin: 0 (-$half-gutter);
        padding: 0 $half-gutter 10px;

        .b-l-wrapper{
            display: inline-block;
            /*@include respond-from($screen-sm-min){
                margin-top: 10px;
            }*/
        }

        .btn-show-f-details{
            float: right;
            /*float: right;
            margin-top: $margin-padding-sm;
            margin-left: $margin-padding-md;
            font-size: 18px;
            @include respond-to($screen-xs-max){
                display: block;
                float: none;
                margin-left: 0;
            }*/
        }
    }

    .link {
        display: inline-block;
        padding-top: 11px;
        text-decoration: underline;
        color: $color16;
        cursor: pointer;

        &.link-mobile-m-b {
            @include respond-to($screen-xs-max) {
                margin-bottom: 15px;
            }
        }

        &:not(:last-child) {
            margin-right: $margin-padding-md;
        }

        &:hover {
            color: $color18;

            i:before {
                color: $color18;
            }
        }

        &.collapse-link {
            margin-right: 10px;
            padding-top: 0;
        }

        i {
            position: relative;
            top: 2px;
            left: 0;
            margin-right: 5px;
        }
    }

    .separator-dotted {
        border-top: 2px dotted $color19;
    }

    .participant-section-name {
        display: inline-block;
        padding: 10px 0 5px;
        color: $color1;
    }
    .participant-section-description {
        display: inline-block;
        padding-bottom: 10px;
        color: $color10;
    }
    .form-panel-elem {
        position: relative;
        min-height: 42px;
        padding: 2px $half-gutter;
        border-bottom: 1px solid $color19;

        &.error {
            border: 1px solid $color21;
            //color: $color21;

            .last-plan:before {
                color: $color21;
            }
        }

        &.choose-seats-panel-elem {
            padding: 10px $half-gutter;
        }
        .choose-seats-main-info {
            display: inline-block;
            min-height: 34px;
            vertical-align: middle;
        }
        /*
        &.active{
            background: $color4;
        }
        */

        .payment-subheading {
            padding: 8px 0;
            font-size: 18px;
            color: $color1;
        }
        .form-panel-elem-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin-bottom: 0;
            z-index: 5;
            cursor: pointer;
        }

        .radio-stack {
            top: 4px;
            right: -22px;
            margin-right: 27px;
        }

        .checkbox-stack {
            top: -14px;
            margin-right: 9px;
        }

        .credit-card-txt {
            position: relative;
            top: -1px;
        }
        .credit-card-price {
            display: inline-block;
            float: right;
            margin-top: 6px;
            font-size: 18px;
        }

        .link {
            text-decoration: underline;
            color: $color16;
            cursor: pointer;
            &:hover {
                color: $color18;
            }
        }

        .payment-online-txt {
            display: inline-block;
            margin-top: 6px;
        }
        .payment-online-ticketing-time{
            display: inline-block;
            position: relative;
            height: 14px;
            width: 37px;

            .fa{
                position: absolute;
                top: -8px;
                left: -2px;
                display: inline-block;
                padding: 10px;
                z-index: 10;
            }
        }
        .prices-sumup-txt {
            @extend .payment-online-txt;
        }
        .payment-online-charge {
            display: inline-block;
            margin-top: 6px;
            font-size: 18px;
        }
        .prices-sumup-charge {
            @extend .payment-online-charge;
        }
    }

    .toggle-hidden-fields {
        display: inline-block;
        position: relative;
        padding-bottom: 5px;
        padding-top: 8px;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        span {
            margin-right: 3px;
            text-decoration: underline;
        }

        .toggle-hidden-fields-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            cursor: pointer;
        }
        .label-txt {
            position: relative;
            top: -4px;
            left: 27px;
            text-decoration: none;
        }
    }

    .clear-inputs {
        display: inline-block;
        position: relative;
        padding: 6px 3px 3px;
        margin-top: 23px;
        text-decoration: underline;
        cursor: pointer;
        @include respond-to($screen-xs-max) {
            margin-top: 0;
        }

        .clear-inputs-close {
            display: inline-block;
            position: relative;
            top: 1px;
            left: 5px;
            color: $color1;
        }
    }

    .header-double-right {
        line-height: 25px;

        .header-name {
            position: relative;
            top: 2px;
        }

        .btn-collapse {
            font-size: 14px;
            top: -3px;
            height: 27px;
            position: relative;
        }
    }

    /* additional-services*/
    .additional-services{
        table-layout: fixed;

        > tbody > tr > td{
            padding: 8px $half-gutter;
        }

        .price{
            float: right;
            font-size: 18px;
        }
    }
}

.elem-price-container-footer {
    padding: 10px $half-gutter;

    .total-charge {
        font-size: 18px;

        &.agreements-total-charge {
            display: inline-block;
            position: relative;
            top: -2px;
            //margin-bottom: 3px;
        }

        .total-price {
            font-size: 36px;
            white-space: nowrap;
        }
    }

    .btn {
        font-size: 18px;
        padding: 5px $margin-padding-sm 6px;
        @include respond-from($screen-sm-min) {
            margin-left: $margin-padding-sm;
        }

        &.btn-formback {
            float: left;
            margin: 5px 0 0;

            @include respond-to($screen-xs-max) {
                display: none;
            }

        }
    }

    .send-buttons-container {
        display: inline-block;
        //float: right;
        margin-top: 15px;

        @include respond-to($screen-xs-max) {
            display: block;
            margin-top: 5px;

            .btn {
                margin-bottom: 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.section-payer {

    .payer-tab {
        display: inline-block;
        position: relative;
        padding: 3px $margin-padding-sm $margin-padding-sm $margin-padding-md;
    }

    .radio-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
        cursor: pointer;
        z-index: 5;
    }

    .radio-stack.radio-payer {
        display: inline-block;
        top: 6px;
        right: -20px;
        padding-right: 23px;
    }
}

.t-table {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.t-row {
    display: table-row;
    min-height: 41px;
/*
    &.full-width-row{
        column-span: all; !* W3C *!
        -webkit-column-span: all; !* Safari & Chrome *!
        -moz-column-span: all; !* Firefox *!
        -ms-column-span: all; !* Internet Explorer *!
        -o-column-span: all; !* Opera *!
    }*/
}

.t-cell {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    padding-bottom: 5px;

    &.cell-1 {
        width: 41px;

        &.cell-agreements {
            width: 36px;
        }
    }
    &.cell-2 {
        &.cell-agreements {
            padding-top: 7px;
            padding-bottom: 5px;
        }
    }
    &.cell-3 {
        width: 110px;

        &.cell-sum-up {
            width: 120px;
        }
    }
}

.booking {
    .input-title-label {
        height: 25px;
        display: flex;
        align-items: center;
        font-weight: 500;

        .input-label-hint {
            margin-left: 4px;
            font-size: 14px;
        }
    }
    input,
    select,
    .icon-date {
        & + .input-err-msg {
            display: none;
            position: absolute;
            bottom: -3px;
            left: $half-gutter;
            right: $half-gutter;
            color: $color21;
        }

        &.error + .input-err-msg {
            display: block;
        }
    }
}

.steps-wizard {
    margin-top: 15px;
    @include respond-to($screen-xs-max) {
        margin-bottom: 15px;
    }

    .steps-elem {
        height: 50px;
        margin-left: 1px;
        padding: 13px $half-gutter 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        background: $color8;
        color: $color1;

        &.steps-elem-heading {
            margin-left: 0;
            background: $color1;
            color: $color8;
        }
        &.step-active {
            background: $color1;
            color: $color12;
        }
        &.step-checked {
            background: $color1;
            color: $color12;

            .fa-check-circle {
                display: inline-block;
                position: relative;
                top: -2px;
                font-size: 28px;
            }
            .step-txt {
                position: relative;
                top: -7px;
            }
        }
        .fa-check-circle {
            display: none;
            margin-right: 7px;
        }

        .mobile-screen-txt {
            font-size: 18px;
            @include respond-from($screen-md-min) {
                display: none;
            }
        }
    }
}

.applied-rules {
    //margin-top: $margin-padding-sm;
    //padding-left: $half-gutter+7px;
    .form-panel-elem {
        padding: 0;
    }

    .rules-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .rules-list-elem {
        min-height: 52px;
        padding: 15px $half-gutter;
        border-bottom: 1px solid $color19;
    }
    .rules-elem-inner {
        vertical-align: middle;
    }

}

.discount-container {
    padding: $margin-padding-md;
    border-bottom: 1px solid $color19;
    font-size: 18px;

    .input-container {
        display: inline-block;
        max-width: 200px;
        margin: 0 $half-gutter;

        @include respond-to($screen-xs-max) {
            max-width: none;
            width: 100%;
            margin: 10px 0;
        }
    }

    .btn-check-discount {
        //font-size: 18px;
        min-width: 200px;

        @include respond-to($screen-xs-max) {
            width: 100%;
        }
    }
}

.error-messages-list {
    padding: 5px 0 0;
    list-style: none;

    .error-messages-elem {
        line-height: 21px;
    }
}

.flightbooking {

    .reservation-special-messages {
        margin-bottom: 15px;
        padding: $margin-padding-md $half-gutter;
        font-size: 18px;
        text-align: center;
    }

    .reservation-error-messages {
        @extend .reservation-special-messages;
    }

   /* .section-header-icon-small {
        display: inline-block;
        width: 34px;
        height: 34px;
        padding-top: 3px;
        text-align: center;
        background: white;
        border-radius: 100%;
    }

    .section-header-with-icon {
        h2 {
            padding-left: 9px;
        }
    }*/
}

.flightconfirmation {
    .confirmation-special-messages {
        margin-bottom: 3px;
        padding: $margin-padding-lg $half-gutter;
        font-size: 18px;
        text-align: center;

        & + .confirmation-special-messages.payment-special-info {
            .payment-messages {
                margin: 0 auto;
            }
        }

        .messages-booking-no {
            margin-top: $margin-padding-sm;
        }
        .messages-flights-no {
            max-width: 840px;
            margin: $margin-padding-sm auto 0;
            font-size: 14px;
        }
        .special-msg-big {
            font-size: 24px;
        }
    }

    .section-header {
        height: $widget-header-height;
        &.section-header-with-icon {
            padding: 0;
            height: auto;

            h2 {
	            line-height: 34px;
                padding-right: $margin-padding-md;
            }
        }
    }

    .conf-buttons {
        margin-bottom: $margin-padding-md;
        @include respond-to($screen-xs-max) {
            padding-top: $margin-padding-sm;
        }
        .btn {
            font-size: 18px;

            &:first-child {
                margin-right: $margin-padding-md;
            }

            @include respond-to($screen-xs-max) {
                display: block;
                margin-right: $margin-padding-md;
                margin-left: $margin-padding-md;

                &:first-child {
                    margin-bottom: $margin-padding-sm;
                }
            }
        }
    }

    .external-html {
        padding-top: $margin-padding-lg;

        .headerTitle:first-of-type {
            display: none;
        }

        .reservation-information td {
            padding: 0;
            text-align: left;
        }

        /* external colors override :/ */

        .txt-color-13 {
            @extend .text-color-11;
        }

        .read-more {
            @extend .text-color-7;
        }

        .descCol {
            @extend .background-color-3;
            @extend .text-color-9;
        }
        .contentTable {
            .typeCol {
                @extend .background-color-8;
                @extend .text-color-1;

                i {
                    @extend .text-color-1;
                }
            }
            .descCol2 {
                @extend .background-color-3;
                @extend .text-color-9;

                .persons-row2.bg-color-11 {
                    @extend .background-color-3;
                    @include respond-from($screen-md-min) {
                        background: transparent;
                    }
                }
            }

            &.contentTableFirst {
                .typeCol {
                    @extend .background-color-8;
                    @extend .text-color-1;

                    i {
                        @extend .text-color-1;
                    }
                }

                .descCol {
                    @extend .background-color-4;
                }
            }
        }
    }
}

.section-waiting-screen {
    .pay-waiting-box {
        @extend .waiting-box;
        @include respond-to($screen-xs-max) {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin-top: 0;
            margin-bottom: 0;
            z-index: 100;
        }
        font-size: 18px;

        &.error-bg {
            background: $color21;
        }

        .leading-infotext {
            font-size: 24px;
        }
        .infotext {
            margin-bottom: $margin-padding-lg;
            font-size: 18px;
        }
        .route {
            margin-top: 20px;
            font-size: 18px;
        }
        .route-date {
            margin-top: 0;
            font-size: 14px;
        }
    }
}

// seats?
.additional-services-header {
    margin: 0;
    font-size: 18px;
    color: $color1;

    @include respond-to($screen-sm-max) {
        margin-top: $margin-padding-md;
        margin-left: $margin-padding-md;
    }
}

.section-additional-services {
    .section-header {

        &.section-header-with-icon {

            .section-header-icon-small {
                display: inline-block;
                width: 34px;
                height: 34px;
                padding-top: 3px;
                text-align: center;
                background: white;
                border-radius: 100%;
            }

            h2 {
	            line-height: 34px;
                padding-right: $margin-padding-md;
            }
        }
    }

    .service-desc {
        min-height: $widget-header-height;
        padding: 0 $margin-padding-md;
        padding-top: 14px;
        padding-bottom: 13px;
        border-bottom: 1px solid $color19;
    }

    .services-elem {
        position: relative;
        min-height: 56px;
        border-bottom: 1px solid $color19;

        &.no-min-height {
            min-height: auto;
        }

        .services-elem-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            cursor: pointer;
        }

        .btn-collapse-services-elem {
            width: auto;
            margin-right: $margin-padding-md;
            color: $color1;

            .link {
                margin-right: $margin-padding-sm;
                padding-top: 8px;
            }
        }

        .services-elem-row {
            margin: 0 $margin-padding-md 0 66px;
            padding: $margin-padding-sm 0;
            position: relative;
        }

        .t-cell {
            padding-bottom: 0;
        }
        .cell-01 {
            width: 45%;
            padding-right: 15px;
            vertical-align: baseline;
            @include respond-to($screen-sm-max) {
                width: 60%;
            }
            @include respond-to(850px) {
                width: 48%;
            }
            @include respond-to($screen-xs-max) {
                display: block;
                width: 100%;
                padding-right: 0;
            }
        }
        .cell-02 {
            width: 20%;
        }
        .cell-03 {
            width: 35%;
            padding-bottom: $margin-padding-xs;
            white-space: nowrap;
            @include respond-to($screen-sm-max) {
                width: 40%;
            }
            @include respond-to(850px) {
                width: 52%;
            }
            @include respond-to($screen-xs-max) {
                display: block;
                width: 100%;
                //margin-top: 5px;
                padding-bottom: 0;
            }
        }

        .services-elem-opening-btn {
            position: absolute;
            width: 66px;
            height: 56px;
        }
        .btn-collapse-details {
            top: 0;
            height: 36px;
            width: 36px;
            margin: $margin-padding-sm $margin-padding-md;
            text-align: center;
            cursor: pointer;

            &:hover {
                background-color: $color18;
            }

            .fa {
                line-height: 36px;
                vertical-align: middle;
            }
        }

        .service-name {
            color: $color1;
        }

        .service-logo {
            max-width: 100%;
            //max-height: 38px;
            max-height: 34px;
            //height: 34px; // dimensions for testing
            //width: 160px; // dimensions for testing
        }

        .service-price {
            display: inline-block;
            position: relative;
            top: 3px;
            left: 0;
            white-space: nowrap;
            font-size: 18px;
            color: $color11;
        }

        .checkbox-stack{
            top: -12px;
            right: 9px;
        }

        .btn-services-reserve {
            min-width: 175px;
            margin-left: $margin-padding-sm;
            padding: 5px 10px 6px;
            font-size: 18px;
        }

        .services-elem-details{
            display: none;
            margin-top: $margin-padding-sm;

            .details-desc{
                text-align: justify;
            }
        }
    }

    .individuals-container{
        display: inline-block;
        margin-top: $margin-padding-sm;

        & + .btn-services-reserve {
            @include respond-to($screen-xs-max){
                width: 100%;
            }
        }
    }

    .list-header{
        display: inline-block;
        padding-right: $margin-padding-sm;
    }

    .individual{
        position: relative;
        display: inline-block;
        margin-right: $margin-padding-sm;
        padding-right: $margin-padding-md;

        @include respond-to($screen-xs-max){
            display: block;
            margin-bottom: $margin-padding-xs;
        }

        .individual-overlay{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0;
            padding: 0;
            cursor: pointer;
            z-index: 3;
        }

        .individual-name{
        }

        .checkbox-stack{
            top: -14px;
            right: 0;
        }
    }
    .row-not-show-on-start{
        display: none;
    }
}

.section-payment {
    padding: $margin-padding-lg $margin-padding-md;

    &.no-padding{
        padding: 0;
    }

    .payment-gateway-form {
        text-align: center;
    }

    .payment-open-text-lead {
        margin-bottom: $margin-padding-xs;
        font-size: 18px;
    }

    .btn-payment-window-open {
        min-width: 200px;
        margin: $margin-padding-lg auto 0;
        padding: $margin-padding-xs $margin-padding-sm 6px;
        font-size: 18px;
    }
}

.back-wrapper {
    padding: $margin-padding-md;

    .btn-back {
        min-width: 175px;
        font-size: 18px;
    }
}

.min-w-130{
    min-width: 130px;
}

.fa-gift:before {
    margin-left:4px;
}
