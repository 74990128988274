// Opacity

@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: #{alpha(opacity=$opacity-ie)};
}

// doesn't work due to php controlled color schemes
/*@mixin background-opacity($color, $opacity) {
  background: $color; !* The Fallback *!
  background: rgba( red($color), green($color), blue($color), $opacity );
}*/
