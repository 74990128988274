/*
	header specific styling
*/
@import '../common/inputs/_quick-search';

header {
	position: relative;
	@include respond-to($screen-sm-max) {
		min-height: 50px;
	}
}

.default-header, .modern-header, .simple-header, .functional-header {
	position: relative;

	.logo-container-wrapper {
		position: relative;
		margin: 10px 0 0;
		@include respond-to($screen-sm-max) {
			z-index: 110;
			margin: 0;
		}
	}

	.logo-container {
		margin: 0;
		vertical-align: middle;
		border: none;
		height: 100px;
		max-width: 400px;
		overflow: hidden;
		a {
			img {
				outline: none;
				border: none;
				max-height: 100%;
				max-width: 100%
			}
		}

		h2 {
			font-size: 24px;
			line-height: 28px;
			margin: 0;
			height: 100px;
			display: table-cell;
			vertical-align: middle;
			span {
				vertical-align: middle;
			}

			@include respond-to($screen-sm-max) {
				height: 50px;
				font-size: 14px;
				line-height: 20px;
			}
		}
		@include respond-to($screen-sm-max) {
			height: 50px;
			line-height: 45px;
			text-align: center;
			width: auto;
            max-width: none;

			img {
				max-height: 100%;
			}
		}
	}

	.container, .container-fluid {
		position: relative;
	}

	@include respond-to($screen-sm-max) {
		display: none;

	}
}

.functional-header{
	.top{
		padding: 5px 0;
		height: 32px;
		overflow: hidden;
		.top-menu{
			padding: 0 15px;
			text-align: justify;
			list-style: none;
			list-style-image: none;
			margin: 0;
			width: 100%;
			&:after{
				content: ";";
				visibility: hidden;
				margin-left: 100%;
			}
			&>li {
				display: inline;
				float: none;
				margin: 0;
				&>*{
					display: inline-block;
				}
			}
		}
		ul{
			padding: 0;
			li{
				list-style: none;
				display: inline-block;
				margin-right: 10px;
				i{
					margin-right: 5px;
				}
			}
		}
		p{
			margin: 0;
			strong{
				margin-left: 5px;
			}
		}
		.social-links{
			ul{
				@include respond-to($screen-sm-max) {
					margin:auto;
					float: right;
				}
			}
			.btn-social{
				width: 20px;
				height: 20px;
				display: block;
				border-radius: 10px;
				text-align: center;
				line-height: 20px;
				-webkit-transition: all .3s;
				-moz-transition: all .3s;
				-ms-transition: all .3s;
				-o-transition: all .3s;
				transition: all .3s;
				border: 2px solid transparent;
				.fa{
					display: block;
					padding-top: 1px;
				}
				&:hover{
					color: $color12;
					background-color: $color1;
					border: 2px solid $color12;
					text-decoration: none;
				}
			}
		}
		.system-functions{
			.def-header-elems{
				text-align: center;
			}
			a,span{
				color: $color12;
				&:hover{
					color: $color12;
				}
			}
		}
	}
	.main{
		.logo-container-wrapper{
			margin: 0;
		}
		.functional-header-center{
			height: 100px;
			position: relative;
			h2{
				//display: inline-block;
				font-size: 19px;
				line-height: 21px;
				margin: 0;
				padding: 0;
				font-weight: 400;
				//vertical-align: middle;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				position: absolute;
			}
		}
		.main-text{
			height: 100px;
			border-left:1px solid #eee;
		}
		h3{
			margin-top: 25px;
			margin-bottom: 0;
		}
	}

	.container-fluid {
		padding: 10px 15px;
	}

	@include respond-to($screen-sm-max) {
		.container-fluid {
			padding: 0;
		}
	}

	.top {
		.container-fluid {
			padding: 0;
		}
	}
}

.sm-center{
	display: table;
	margin: 0 auto!important;
	text-align: center;
}
/* start header 2*/

.container-fluid .alternative-logo-container {
	margin: 10px 0;
	@include respond-to($screen-sm-max) {
		margin-top: 17px;
	}

	@include respond-to($screen-sm-max) {
		margin-top: 0px;
	}

	.logo-container {
		line-height: 16px;
		height: 48px;
		@include respond-to($screen-sm-max) {
			line-height: 50px;
			height: 50px;
		}
	}
}

.headerAlternative {
	float: right;
	margin-top: 26px;
	@include respond-to($screen-sm-max) {
		margin-top: 16px;
	}
	.containerHeaderAlternative {
		float: right;
		margin-bottom: 20px;
		@include respond-to($screen-sm-max) {
			height: 63px;
		}
		.cart-container {
			float: right;
			overflow: hidden;
			a.btn-cart {
				padding-left: 18px;
				position: relative;
				text-decoration: none;
				font-weight: bold;
				display: block;
				.fa-shopping-cart {
					width: 19px;
				}
				em {
					font-style: normal;
					color: inherit;
					text-decoration: underline;
				}
				&:hover {
					.yourCart {
						text-decoration: underline;
					}
				}
			}
		}
		.header-nav-elem {
			float: right;
			margin-bottom: 0;
		}
		.phone-container {
			float: right;
		}
	}
	.panel-elem.quick-search-elem {
		float: right;
		margin-left: 20px;
		margin-bottom: 0;
		margin-top: -4px;
		@include respond-to($screen-sm-max) {
			position: absolute;
			right: 15px;
			top: 36px;
		}
		.input-container {
			width: 100%;
			input {
				height: 28px;
				width: 300px;
			}
			.fa-search {
				top: 13px;
			}
		}
	}
}

/*  koniec header 2*/

.header-center-panel {
	//margin: 10px 10px 0 0;
	//padding: 6px 26px 12px 0;
	padding-right: 15px;
	border-right: 1px solid $color19;
	text-align: right;
	height: 100px;
}

.header-right-panel {
	min-height: 125px;
	background-position: 100% 100%;
	background-size: contain;
	background-repeat: no-repeat;
	position: relative;
	padding-top: 11px;

	.phone-container {
		padding: 13px 0 0 0;
		position: relative;

		.main-label {
			font-size: 12px;
			line-height: 18px;
			display: block;
			padding-bottom: 5px;
		}
		.value {
			font-size: 25px;
			line-height: 28px;
			display: block;
			color: $color2;
			a {
				text-decoration: none;
			}
		}
	}

	.quick-search-elem {
		position: relative;
		width: 50%;
		input {
			font-size: 12px !important;
			padding: 3px 7px !important;
		}
		.fa-search {
			position: absolute;
			top: 60%;
			right: 6px;
			opacity: .5;
			margin-top: 0;
		}
	}

	.cart-container {
		margin: 0;
		padding: 0;

		max-width: 50%;
		.btn-cart {
			text-decoration: none;
			display: inline-block;
			padding: 10px 0 6px 0;
			font-size: 14px;
			line-height: 17px;
			color: $color16;
			width: 100%;
			white-space: nowrap;
			@include respond-to($screen-sm-max) {
				padding: 5px 0 6px 0;
			}
			em {
				font-style: normal;
			}
			//span{display:inline-block;}
			.fa-shopping-cart {
				font-size: 30px;
				width: 25px;
			}

			&:hover {
				.yourCart {
					text-decoration: underline;
				}
			}
			.yourCart {
				padding-left: 10px;
			}
		}
	}

	.commerce-container {
		float: right;
		height: 125px;
		text-align: right;
		max-width: 50%;
		img {
			max-height: 100%;
			max-width: 100%;
		}
	}

	.commerce-text-container {
		float: left;
		max-width: 50%;
		padding: 15px 0 0;

		p {
			font-size: 14px;
			line-height: 20px;
			margin: 0;
		}
		img:not(.lng-img-sel-padding) {
			position: relative;
			top: -25px;
		}
	}

	.livechat-container {
		padding: 16px 0 0 0;
		position: relative;
		.main-label {
			font-size: 12px;
			line-height: 18px;
			display: block;
			color: $color2;
		}
		.value {
			font-size: 25px;
			line-height: 28px;
			display: block;
			color: $color2;
			a {
				text-decoration: none;
			}
		}
	}
	.header-r-image {
		max-width: 100%;
		height: auto;
	}
}

// header right panel

.panel-elem {
	&.facebook-join-elem {
		label {
			position: relative;
			top: -5px;
		}
		.fb-like {
			overflow: hidden !important;
			height: 20px !important;
		}
	}

	margin: 0 0 10px 10px;
	font-size: 13px;
	&:last-child {
		margin-bottom: 0;
	}
	label {
		display: inline;
		font-weight: 400;
	}
	&.header-nav-elem {

		ul {
			margin: 0;
			padding: 0;
			li {
				display: inline;
				list-style: none;
				margin: 0 0 0 10px;
				a {
					&:hover {
						text-decoration: underline;
					}
				}
				&.lng {
					position: relative;
					.lng_menu {
						display: none;
					}
				}
			}
		}

	}
	&.quick-search-elem {
		.input-container {
			position: relative;
			input {
				padding: 4px 20px 4px 5px;
			}
		}
	}
}

.qtip-langs {
	border: 1px solid;

	ul {
		border: none;
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			margin: 0;
			text-align: left;
			a {
				display: block;
				position: relative;
				padding: 7px 50px 5px 5px;
				border-bottom: 1px solid;
				width: 100%;

				img {
					position: absolute;
					top: 6px;
					right: 5px;
				}
			}
			&:last-child {
				a {
					border-bottom: none;
				}
			}
		}
	}
}

.qtip-currency {
	//width: 100px
	text-align: center;
}

.lng {
	position: relative;
	.lng_menu {
		display: none;
	}
}
.lng-img-sel-padding {
	padding-left: 3px;
	position: relative;
	top: -1px;
}
.s-h-elems {
	padding-left: 20px;

	.current-curr{
		position: relative;
	}
	&:hover {
		color: $color18;
	}
}
.modern-h-quick {
	padding-right: 5px;
}

.link-d-h-elems {
	position: relative;
	padding-top: 14px;
	padding-bottom: 8px;
	margin: 0;
}
.header-links-in-sm {
	visibility: hidden;
	position: absolute;
	top: -10px;
	z-index: 20;
	&:hover {
		color: $color18;
	}
	@include respond-to($screen-sm-max){
		visibility: visible;
		float: left;
		position: relative;
		top: 0;
	}
}

.mid-panel-def-header {
	padding-left: 5px;
	&:last-of-type {
		padding-left: 5px;
	}
}
.link-d-h-elems {
	.def-header-elems{
		&:hover {
			color: $color18;
		}
		padding-right: 5px !important;
		&:last-of-type {
			padding-right: 10px !important;
		}
	}
}

.containerHeaderAlternative {
	.header-nav-elem {
		padding: 0;
	}
	.mod-header-links {
		padding-right: 0;
		padding-left: 20px;
	}
}

.modern-header {
	padding-left: 0;
	padding-right: 0;
	max-width: 1153px;

	.containerHeaderAlternative {
		@include respond-from($screen-md-min){
			min-height: 38px;
		}
	}

	.quick-search-elem {
		margin: -7px 0 0 0;
		height: 34px;

		.input-container {
			input {
				padding: 7px 20px 7px 7px;
			}

			.fa-search {
				margin-top: -10px;
			}
		}
	}
}

.header-without-logo {
	.links-in-modern-h {
		top: 9px;
	}
}
.m-h-paddings {
	@include respond-from($screen-md-min){
		padding-right:15px;
		padding-left: 15px;
	}
}
.right-panel-phone {
	@include respond-from($screen-md-min){
		top: -12px;
		margin-left: 10px;

	}
}
.link-under-tel {
	&:hover {
		color: $color18;
	}
	@include respond-from($screen-md-min){
		position: relative;
		top: -8px;
		margin-left: 10px;
	}

}

.def-header-phone-label {
	@include respond-to($screen-sm-max){
		display: none !important;
	}
}
.def-header-r-img {
	padding: 0;
	margin-top: -10px;
	img {
		max-height: 100%;
		max-width: 100%;
	}
}
.header-right-panel {
	.def-header-r-custom {
		margin-top: 5px;
		margin-bottom: 10px;
	}
}

.links-in-modern-h{
	position: relative;
	@include respond-from($screen-sm-max){
		top: 24px;
	}
	.fa {
		font-size: 20px;
		vertical-align: -20%;
	}
}
.header-p-r-5 {
	padding-right: 10px !important;
}

.current-curr {
	color: inherit;
}
.current-language {
	color: inherit;
}

.office-select-container {
	margin-top: -7px;

	.select2-container {
		background-color: $color4;

		.select2-choice {
			color: $color15 !important;
			font-size: $font-size-large;
			padding-top: 2px;
			padding-bottom: 2px;

			&:after {
				color: $color15;
				text-shadow: 0 1px 0 $color9;
			}
		}

	}
}

.link-empty-container {
	margin-top: -7px;

	.link-empty {
		padding: 5px $half-gutter;
		background-color: $color4;
		font-size: $font-size-large;
		color: $color15;
	}
}
