@import "../../mixin-simple-carousel";
/* Offer list <UL> styling */
.object-listing {
    margin: 0;
    padding: 0;

    .object-listing-elem {
        list-style: none;

        .facilities {
            padding-bottom: 5px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            margin-bottom: 10px;
        }
    }

    .omnibus {
        font-size: 0.8em;
        position: relative;
        text-align: right;
        width: 100%;
        right: 208px;
        top: -1px;
        .omnibus-loader:after {
            animation-name: ellipsis-l;
        }

        @include respond-to($screen-xs-max) {
            text-align: center;
            right: 0;
            top: -44px;
        }
    }

    .omnibus-inline {
        .omnibus {
            right: 0;
            margin-bottom: 8px;
        }
    }

    .history-price {
        position: relative;
        top: -2px;
        .amount {
            top: 0;
        }
        .diff {
            top: -2px;
            position: relative;
        }
    }
}

#cart-offers-listing.object-listing, #offers-listing.old-trips.object-listing  {
    .object-listing-elem .price-container .price-label-container {
        margin-bottom: 0;
        .price-label {
            font-size: 1.6em;
        }
    }

    .omnibus {
        margin-bottom: 15px;
        top: 0;
        right: 0;
    }
}
/* Offer styles */
.object-listing-elem {
    position: relative;
    @include cf;

    h3 {
        margin: 0 0 2px;
        font-weight: 600;
        font-size: 26px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .first-part .stars, .second-part .stars {
        top: 0;
        vertical-align: revert;
    }
    .stars {
        position: relative;
        top: -2px;
    }
    i.fa-map-marker {
        margin-top: -.1em;
        position: relative;
        top: .1em;
        font-size: 18px;
    }

    .listing-showOnMap {
        h4 {
            padding-left: 15px;
            position: relative;
        }

        .fa-map-marker {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .occupancy-section {
        margin: 10px 0;
    }

    .link-section {
        float: left;

        a {
            margin-right: 12px;
            white-space: nowrap;
            @include respond-to($screen-sm-max) {
                margin-right: 0;
            }
            i.fa {
                margin-right: 0.1em;
            }
        }
    }

    &.zandw {
        .data-container {
            border-right: 1px dotted;

            @include respond-to($screen-sm-max) {
                border: none;
            }
        }

        .price-container {
            @include respond-to($screen-sm-max) {
                padding-top: 15px;
                border-top: 1px dotted;
            }

            @include respond-to($screen-xs-max) {
                border-top: none;
            }
        }
    }

    .zandw-divider {
        border-bottom: 1px dotted;
    }

    .fa-stack.zandw-plus {
        position: absolute;
        top: -27px;
        left: 50%;
        margin-left: -21px;
        font-size: 50px;

        @include respond-to($screen-xs-max) {
            top: 50%;
            left: 0;
            margin-top: -25px;
        }

        .fa {
            position: absolute;
        }

        .fa-circle {
            font-size: 60px;
            top: -4px;
            left: -4px;
        }

        .fa-plus-circle {
            top: 1px;
        }
    }

    .ribbons {
        position: absolute;
        top: 30px;
        left: 8px;

        .zandw-ribbon {
            display: inline-block;
            border: 2px solid;
            padding: 5px 10px;
            position: relative;
            left: 5px;
            min-width: 110px;
            text-align: center;
            text-transform: capitalize;
        }
    }

    .info {
        padding: 15px 15px 15px 0;

        @include respond-to($screen-xs-max) {
            padding: 0 15px;
        }

        .info-footer {
            float: right;
        }

        h4 {
            margin: 0;
            font-weight: 400;
            font-size: 15px;
            display: block;
        }

        @include respond-to($screen-xs-max) {
            h3 {
                font-size: 18px;
                margin: 10px 0 5px;
            }
        }
    }

    .services {
        margin-top: 5px;
        .icon {
            display: inline-block;
            border-radius: 100%;
            padding-top: 5px;
            text-align: center;
            width: 27px;
            height: 27px;
            margin: 0 5px;
        }
    }

    .top-img {
        display: block;
    }

    div.image {
        padding: 15px;
        position: relative;

        @include respond-to($screen-xs-max) {
            padding-bottom: 0;
        }

        @include simple-carousel();

        .img-container {
            overflow: hidden;
            max-height: 200px;
            min-height: 170px;
            text-align: center;

            &.img-container-lmplus{
                max-height: 220px;
            }

            @include respond-to($screen-xs-max) {
                max-height: 400px;
                min-height: initial;
            }

            img {
                height: auto;
                max-width: 100%;
                width: 100%;
            }
        }

        .img-container-op {
            img {
                width: 100%;
            }
        }
    }

    .elem-meta {
        margin: 15px 0 10px 0;
        width: 100%;
        text-align: left;

        &.border-separate {
            border-collapse: separate;
        }

        tr {
            position: relative;
            &:nth-child(even){
                background-color: $darkerBackground;
            }
        }

        .meta-label{
            padding: 1px 2px;
            width: 90px;
            vertical-align: top;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.media-middle {
                vertical-align: middle;
            }
        }

        .meta-value {
            padding: 1px 2px;
            position: relative;
            vertical-align: top;

            i.fa:not(.fa-spinner):not(.angle-icon) {
                display: none;
            }
        }

        .variant {
            cursor: pointer;
            padding-right: 20px;

            i.fa {
                display: block !important;
                position: absolute;
                top: 50%;
                right: 4px;
                margin-top: -8px;
                line-height: 20px;
                font-size: 21px;
                font-weight: bold;
            }
        }

		.check-holiday-logo{
			height: 22px;
		}

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                display: inline;
                margin: 0 5px 0 0;
                padding: 0;

                @include respond-to($screen-xs-max) {
                    font-size: 12px;
                }
            }
        }

        @include respond-to($screen-xs-max) {
            margin: 0;
        }

        td.meta-label {
            padding: 3px 5px 3px 15px;
            @include respond-from($screen-xs-max) {
                padding: 3px 5px 3px 0;
            }
        }
        td.meta-value {
            padding: 3px 15px 3px 5px;
        }
    }
    @include respond-to($screen-xs-max) {
        .elem-name {
            margin: 0 15px;
        }
        .merlin-gallery-container {
            margin-top: 10px;
            overflow: hidden;
            min-height: auto;
        }
    }

    .rating-container {
        background-color: $darkerBackground;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .facilities {
        padding-bottom: 5px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
    }

    .price-container {
        padding-top: 15px;
        text-align: right;

        @include respond-to($screen-sm-max) {
            padding-top: 0;
            text-align: left;
        }

        .price-label-container {

            @include respond-to($screen-xs-max) {
                padding-top: 15px;
            }

            .strong {
                font-size: 35px;
                line-height: 23px;

                @media screen and (min-width: $screen-md-min) {
                    display: block;
                    margin: 5px 0;
                }
            }
        }


        .more-info {
            font-size: 15px;
            margin-top: 15px;
            margin-bottom: 15px;

            i {
                font-weight: bold;
                font-size: 25px;
                line-height: 25px;
            }

            @include respond-to($screen-xs-max) {
                margin: 0;
            }

            @include respond-from($screen-md-min) {
                margin: 0;
            }
        }

        h3 {
            font-size: 21px;
            margin: 3px 0 2px;
            font-weight: 400;
        }
    }

    .actions-container {
        margin: 0;
        padding: 0;
        height: 34px;

        @include respond-to($screen-xs-max) {
            padding: 0 0 15px 0;
        }

        li {
            list-style: none;

            @include respond-to($screen-sm-max) {
                float: right;
            }

            @include respond-to($screen-xs-max) {
                display: block;
                float: none;

                .btn {
                    display: block;
                }
            }

            &.add-to-comparison, .remove-from-cart {
                display: none;
            }

            .remove-from-cart.removes1 {
                display: block;
            }
        }

        .checkbox-container {
            clear: right;
            @include border-radius(6px);
            border: 1px solid;
            padding: 2px 10px;
            margin: 14px 0 0;

            @include respond-to($screen-sm-max) {
                display: inline-block;
                float: left;
                margin: 0 15px 0 0;
                font-size: 14px;
                padding: 5px 15px 6px 8px;
            }
        }

        .btn {
            margin: 0 0 15px;
            min-width: 182px;
            float: right;

            &.remove-from-cart:hover, &.add-to-cart:hover {
                text-decoration: underline !important;
            }

            &.remove-from-cart, &.add-to-cart {
                @include respond-from($screen-md-min) {
                    margin-right: -10px;
                }
            }

            @include respond-to($screen-sm-max) {
                margin: 0;
                float: none;
            }

            &.adds1 {
                display: none;
            }
        }

        label {
            &.btn {
                position: relative;
                padding: 6px 12px 6px 12px;
            }

            .dp-checkbox {
                padding: 0;
                margin: 9px 9px 11px;
            }

            border: 1px solid;

            span {
                text-decoration: underline;
            }
        }
    }

    .btn input[type="checkbox"] {
        margin: -1px 2px;
        position: relative;
        top: 1px;
        visibility: hidden;
    }

    .show-facilities {
        padding: 0 4px;
        cursor: pointer;

        i:before {
            font-weight: bold;
            font-size: 21px;
        }

        &.facilities-shown .fa{
            transform: rotateZ(180deg);
        }
    }

    .facilities-content {
        display: none;
    }

    .additional-information {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .hygienic-measures {
        border: 1px solid;
        border-radius: 12px 0 0 12px;
        padding: 0 3px;
    }

    .fa-hygienic-measures {
        transform: scaleX(-1);
        margin: 3px 3px 0 0;
    }

    .rating-details {
        display: none;
    }

    .elem-rating {
        cursor: pointer;

        &.like-in-table{
            @include respond-from(992) {
                padding-left: 2px;
                margin-top: -10px !important;
            }
        }
    }

    .text-overflow-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        .fa-stack {
            font-size: 0.5em;
        }
    }

    .line-height-half {
        line-height: 0.5;
        padding-left: 3px;
    }

    .font-size-26 {
        font-size: 26px;
        margin: 0 0 0 0;
        font-weight: 600;
    }
    .font-size-18 {
        position: relative;
        display: inline;

        overflow: inherit;
        font-size: 18px;
        font-weight: 500;
        text-shadow: inherit !important;
        background: none;
        padding-left: 0;
    }

    .font-size-14 {
        position: relative;
        display: inline;

        overflow: inherit;
        font-size: 14px;
        font-weight: 500;
        text-shadow: inherit !important;
        background: none;
        padding-left: 0;
    }

    .location-element-hover {
        &:hover {
            text-decoration: underline;
        }
    }

    &.tt-elem:not(:last-child) {
        margin-bottom: 5px;
        border-bottom: 1px solid $color1;
    }
}

.font-size-19-weight-normal {
    font-size: 19px !important;
    font-weight: 400 !important;
}

.region-info-h3 {
    padding-left: 38px;
    line-height: 28px !important;
}

.font-size-19-f-l {
    float: left;
    line-height: 31px !important;
    font-weight: 500;
}

/* List section style */
.object-listing-box {
    .section-header {

        .list-options {
            margin: 0 5px;
            overflow: hidden;
            float: right;
            background: rgba(255, 255, 255, .3);
            border: 1px solid $color19;

            @include respond-to($screen-xs-max) {
                float: none;
                width: 100%;
                margin: 5px 0 0;
            }

            a {
                padding-top: 5px;
                padding-bottom: 5px;
                line-height: 20px;
                border-left: 1px solid $color19;
                @include respond-to($screen-xs-max) {
                    width: 50%;
                }
                &:first-child {
                    border: none;
                }

                &.active {
                    color: $color1;
                    background-color: $color12;
                    pointer-events: none;
                }
            }
            &.with-regions {
                a {
                    @include respond-to($screen-xs-max) {
                        width: 33.42%;
                    }
                }
            }
        }
    }

    &.cart {
        .elem-actions-container ul {
            li {
                &.add-to-comparison {
                    visibility: visible;
                }
            }
        }

        .elem-footer {
            .remove-from-cart {
                display: block;
            }

            .add-to-cart {
                display: none;
            }
        }
    }

}

.triplist-pending-info {
    position: absolute;
    top: 120px;
    left: calc(50% - 200px);
    width: 400px;
    padding: 40px 30px; //0 $margin-padding-lg;
    text-align: center;
    background-color: $color1;
    color: $color12;
    z-index: 999;
    @include respond-to($screen-xs-max) {
        position: fixed;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        width: inherit !important;
    }

    .loading-results-pending,
    .loading-reservation-pending {
        margin: 0 auto;
        font-size: 14px;
    }
    .icon-spinner /*.spinner-5-items*/
    {
        padding: 10px;
        font-size: 12px;
        letter-spacing: 2px;

        .fa {
            margin: 0 3px;
            @include opacity(0.4);

            &.semi3 {
                @include opacity(0.55);
            }
            &.semi2 {
                @include opacity(0.7);
            }
            &.semi {
                @include opacity(0.85);
            }
            &.bright {
                @include opacity(1);
            }
        }
    }
}

.trip-filters .triplist-pending-info {
    width: 200px;
    position: relative;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: transparent;
    margin: 15px 0;
}

.qtip-interchangeable {
    max-width: 100%;

    .qtip-content {
        padding: 0;
    }

    thead {
        padding: 3px 5px;
    }

    th {
        padding: 10px 8px 12px;
        font-size: 12px;
    }

    td {
        padding: 8px 8px 9px;
        font-size: 12px;
        line-height: 12px;
    }

    tbody {
        tr {
            cursor: pointer;
            border-top: 1px solid;

            &.current {
                background: #ff0000;

                td {
                    color: #fff;
                }
            }

            &.text-color-12 td {
                color: inherit !important;
            }
        }
    }

    .load-more-alt {
        padding: 7px;
        float: right;
        span {
            padding-right: 4px;
        }
    }
}

.qtip-freebie, .qtip-facilities, .qtip-rating, .qtip-interchangeable {
    border: 1px solid;
}

.qtip-rating {
    min-width: 130px;

    ul {
        margin: 0;
        padding: 0;
        display: table;
        width: 100%;
        list-style: none;

        li {
            font-size: 13px;
            border-top: 1px dotted;
            padding: 3px 3px 1px;

            &:first-child {
                border: none;
                font-weight: bold;
            }
        }

        .rating-detail-name, .rating-detail-value {
            display: table-cell;
            text-transform: capitalize;
        }

        .rating-detail-name {
            width: 100%;
            padding-right: 2em;
        }
    }

    p {
        margin: 10px -10px -10px -10px;
        padding: 10px;
        font-size: 13px;
        line-height: 12px;
    }
}

.object-listing-box {
    #load-more-offers {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .fa-spinner {
            position: absolute;
            left: 50%;
        }
    }
    .section-content {
        padding: 0;
    }
}

.beach-distance-wrapper {
    display: block;
    padding-left: 10px;
    padding-right: 10px;

    .beach-distance-text {
        overflow: hidden;
        padding-right: 0;
    }

    .beach-image {
        width: 100%;
        height: 100%;
    }
    .beach-accordion {
        padding: 0 5px 0 5px;
    }
    h4 {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .beach-img-cont {
        cursor: pointer;
    }

    .map-load-error {
        text-align: center;
        margin: 10px;
        color: $color21;
    }
}

.beach-d-tootlip {
    white-space: nowrap;
}

.weather-w-step-two {
    position: absolute;
    left: 30px;
    color: white;
    text-align: center;
}

#message-no-results {
    h4 {
        margin: 0;
        padding: 15px 0 10px;
    }

}

.trip-list {
    &.object-listing-elem {

        div.image {
            padding: 15px 15px 5px;
        }

        .price-container {
            padding-top: 0;

            @include respond-to($screen-sm-max) {
                text-align: right;
            }
            @include respond-to($screen-xs-max) {
                text-align: left;
            }
        }

        .actions-container {
            .btn {
                margin: 0 0 5px;

                @include respond-to($screen-xs-max) {
                    margin: 10px 0 5px 0;
                }

                @include respond-to($screen-sm-max) {
                    float: right;
                }
                @include respond-to($screen-xs-max) {
                    float: none;
                }

                &>span.visible-xs {
                    &>span:first-of-type{
                        font-size: 1.2em;
                    }
                }

            }

            li {
                @include respond-to($screen-sm-max) {
                    float: none;
                }
            }

            li.inlined {
                display: inline;
            }

            .add-to-cart, .remove-from-cart.removes1 {
                float: left;
                min-width: initial;
                padding-top: 10px;
                padding-left: 5px;
                line-height: 30px;
            }

            .price-label-container {
                sup{
                    margin-left: -3px;
                }
                label {
                    border: 0 none;
                }
                &>span{
                    line-height: 48px;
                    color: $color10;
                }
                &>span:first-of-type {
                    color: $color10;
                }
                &>span:last-of-type {
                    span {
                        font-size: 35px;
                        line-height: 23px;
                        font-weight: bold;
                    }
                    color: $color11;
                    @media screen and (min-width: $screen-md-min) {
                        margin: 0 10px 0 5px;
                    }
                }
                .main-price sup {
                    font-size: 21px;
                    font-weight: bold;
                }
            }
        }

        .one-column-layout.price-container {
            @media (min-width: $screen-md) {
                padding-top: 15px;

                .actions-container {
                    .price-label-container {
                        display: inline-block;
                        padding: 10px 0;

                        span {
                            padding: 0;
                            line-height: initial;
                        }
                        &>span:last-of-type {

                            @media screen and (min-width: $screen-md-min) {
                                margin: 0;
                            }
                        }
                    }

                    .add-to-cart, .remove-from-cart.removes1 {
                        float: none;
                        min-width: 182px;
                    }
                }
            }
        }
    }
}

#alternatives-filter {
    .select2-container {
        display: block !important;
    }

    .sort-col {
        color: $color7;

        &.active {
            background: $color8;

            &.asc:after {
                font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
                content: unquote("\"#{ $fa-var-chevron-down }\"");
                font-weight: var(--#{$fa-css-prefix}-style);
            }
            &.desc:after {
                font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
                content: unquote("\"#{ $fa-var-chevron-down }\"");
                font-weight: var(--#{$fa-css-prefix}-style);
            }
        }

        &:hover {
            color: $color18;
            text-decoration: underline;
        }
    }
}

#more-alternatives {
    display: none;
    float: right;
    margin: 5px
}

#alternatives-container {
    position: relative;

    .is-reloading-alternatives {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: .8;
        display: none;

        i.fa {
            position: absolute;
            top: 50%;
            margin-top: -40px;
            margin-left: -30px;
        }
    }

    .select2-container .select2-choice:after, .select2-container .select2-choices:after {
        width: 24px;
    }
}

.hc-panel {
    display: block;
    background-color: #fff;
    text-align: center;
    border: 1px solid $color19;
    margin-bottom: 15px;
    padding: 5px;
    color: #315eac;

    &:hover {
        text-decoration: none;
    }

    .hc-panel-text {
        margin: 0;
    }

    .check-holiday-logo {
        margin: 0 auto;
    }

    .hc-panel-rating {
        display: inline-block;
        position: relative;

        .hc-panel-rating-overlay {
            right: 0;
            top: 0;
            bottom: 0;
            height: 18px;
            background: rgba(255, 255, 255, 0.8);
            position: absolute;
            z-index: 2;
        }

        i.fa {
            display: block;
            float: left;
            position: relative;
            font-size: 18px;
            &::after {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 1px;
                left: 1px;
                content: '';
                background-color: #fdd73d;
                z-index: 1;
                border-radius: 2px;
            }

            &::before {
                position: relative;
                z-index: 2;
            }
        }
    }

    &.hc-panel-inline {
        display: inline-block;
        background-color: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        height: 19px;
        border-radius: 2px;
        vertical-align: middle;

        i.fa {
            display: block !important;
            color: $color1;

            &::after {
                display: none;
            }
        }

        & + span {
            vertical-align: middle;
        }

        .hc-panel-rating-overlay {
            background-color: $color3;
            opacity: .8;
        }
    }
}

.holiday-check-inline-container {
	&:hover {
		text-decoration: none;
	}

	.check-holiday-logo{
		height: 22px;
	}
}

#htl-gallery {
    .hotel-gallery-image-container {
        margin-bottom: 20px;
        height: 180px;

        .hotel-gallery-image {
            height: 180px;
            width: 100%;
            background-size: cover;
            background-position: center;
            cursor: pointer;
        }
    }
}

@include respond-to($screen-xs-max) {
    .alternative-object-listing {
        .elem-details {
            margin: 10px 0;
        }
    }
}

@include respond-from($screen-md-min) {
    .alternative-object-listing {
        @include display-flex(flex);
        min-height: min-content;
        width: 100%;
        .link-section {
            position: absolute;
            bottom: 0;
        }

        .elem-details {
            padding-bottom: 10px;
        }

        &.full-width-gallery{
            display: block;
            .link-section {
                right: 0;
            }
        }
    }
}

// Fix for two column layout
.layout-col.col-md-9 {
    .region-tile {
        @media (min-width: $screen-md) {
            width: 33%;
        }
    }
}

.nur-flug-list-heading{
    padding: 10px 0;
}

.object-listing-elem.nur-flug{

    &.offer-checked{
        border: 1px solid;

        &.offer-checked-positive {
            border-color: $color20;

            .state-positive {
                background: $color20;
            }

            .main-price {
                span:first-child>.no-wrap{
                    font-size: 24px;
                }
                span:nth-child(2)>.no-wrap{
                    font-size: 18px;
                }
            }
        }

        &.offer-checked-negative {
            border-color: $color21;

            .state-negative {
                background: $color21;
            }
        }
    }

    .state{

        .positive,
        .negative{
            display: inline-block;
            margin: 10px 0;
            font-size: 16px;
        }
    }

    /*
    .heading-xs{

    }
    */

    .operator-logo{
        max-width: 86px;

        @include respond-to($screen-sm-max){
            max-width: none;
        }

        &.operator-logo-wide{
            max-width: 144px;
        }
    }

    .price-container{
        @include respond-to($screen-sm-max){
            text-align: center;
        }
    }
    .main-price{
        @include respond-to($screen-sm-max){
            //display: inline-block;
        }

        .no-wrap{
            font-size: 18px;
        }
    }
    .secondary-price{
        @include respond-to($screen-sm-max){
            //display: inline-block;
            //margin-left: 5px;
        }
    }

    .overlay-relative{
        position: relative;
    }

    .offer-overlay{
        @include overlay;
        //height: auto;
        background: #fff;
        @include opacity($pending-opacity);
        cursor: auto;
    }

    .label-bigger{
        font-size: 18px;
    }

    .btn-not-available{
        display: block;
        text-align: center;
    }

    .cell-right-padding{
        td{
            padding-right: 5px;
        }
    }

    .js-payment-icons {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;

        & > *:not(:first-child) {
            margin-left: 5px;
        }

        i {
            font-size: 1.5em;
        }

        .payment-icon {
            border: 1px solid;
            border-radius: 3px;
            padding: 0 3px;
            line-height: normal;
        }
    }
}

.box-container {
    display: flex;

    &.nur-flug-box-container{
        @include respond-to($screen-sm-max){
            display: block;
        }
    }

    .box-item {
        align-self: center;
    }
}

.extra-info-links {
    margin-top: 5px;
    a {
        border-right: 1px solid $color10;
        padding: 0 10px 0 8px;

        &:last-child {
            border: 0;
        }
    }
}

.heading-input-wrapper {
    margin: -15px -15px 15px;

    .select2-container {
        .select2-choice:after, .select2-choices:after {
            @include respond-to($screen-xs-max) {
                content: unquote("\"#{ $fa-var-angle-down }\"");
                font-weight: var(--#{$fa-css-prefix}-style);
            }
        }
    }

    h4 {
        @include respond-from($screen-sm-min){
            line-height: 1.8;
        }
        @include respond-to($screen-xs-max){
            margin-bottom: 10px;
        }
    }
}

.LMPlus-NF-tourop-container{
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
        margin-bottom: 50px;
    }
    @include respond-from($screen-md-min){
        margin-bottom: 10px;
    }
}

.xs-text-left{
    @include respond-to($screen-xs-max){
        text-align: left;
    }
}

.offer-rating{
    display: inline-block;
    width: 30px;
    height: 30px;
    color: #ffffff;
    padding: 0;
    margin: auto 5px auto 0;
    line-height: 30px;
    text-align: center;
    font-weight: normal;

    &-td {
        display: flex;
        align-items: center;
        justify-content: left;
    }
    &-span {
        display: inline-block;

        .fa {
            font-weight: bold;
        }
    }
    &-element {
        margin-bottom: 5px;
    }
    &-recommendation {
        margin: 10px auto;
        display: flex;
        align-items: center;

        i {
            font-size: 20px;
        }
    }
    &-group {
        margin: 10px auto;
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-top: 1px solid $color9;
        border-bottom: 1px solid $color9;
    }
    &--6 {
        background-color: $color-rating-6 !important;
    }
    &--5 {
        background-color: $color-rating-5 !important;
    }
    &--4 {
        background-color: $color-rating-4 !important;
    }
    &--3 {
        background-color: $color-rating-3 !important;
    }
    &--2 {
        background-color: $color-rating-2 !important;
    }
    &--1 {
        background-color: $color-rating-1 !important;
    }
    &--0 {
        background-color: $color-rating-0 !important;
    }
}

.alternatives-qtip {
    max-height: 30vh;
    max-width: min-content;
    overflow-y: auto;
    overflow-x: hidden;
}
.region-listing.cluster .covid-container {
    white-space: nowrap;
    margin-left: auto;
}

.covid-icon, .region-listing.cluster .region-name .covid-icon  {
    top: 5px;
    position: relative;
    font-size: 1.6em;
    margin-right: 4px;
}
.covid-label{
    @include respond-to($screen-xs-max){
        display: none;
    }
}
.covid-info-out, .covid-info-in {
    display: inline-block;
    cursor: pointer;
}
.covid-country-marker {
    display: inline-block;
    padding: 2px;
    @include border-radius("$block-br");
    color: black;
    border: 1px ridge black;
    min-width: 26px;
    text-align: center;
    &.covid-borders-closed {
        background-color: #ff3913;
    }
    &.covid-borders-open {
        background-color: #28a300;
    }
    &.covid-borders-conditions {
        background-color: #edff27;
    }
}

.qtip-covid-info {
    background-color: $color100;
    @include border-radius("$block-br");
    border: 1px solid $color1;
    max-width: 400px;
    min-width: 300px;
    line-height: inherit;
    .qtip-content {
        background-color: $color5;
        padding:0;
    }
    .qtip-tip {
        @include respond-to($screen-xs-max){
            transform: translateX(20px);
        }
    }
    @include respond-to($screen-xs-max){
        max-width: unset;
    }
    .covid-info-in-content, .covid-info-out-content {
        background-color: $color3;
        color: $color1;
        padding:10px;
        display: block;
        >span {
            display: table;
            >span {
                display: table-row;
                >span{
                    &:first-child{
                        font-weight: bold;
                        white-space: nowrap;
                        @include respond-to($screen-xs-max){
                            padding-bottom: 0px;
                            padding-top: 10px;
                        }
                    }
                    padding: 5px;
                    display: table-cell;
                    @include respond-to($screen-xs-max){
                        display: block;
                    }
                }
            }
        }
    }
}

.object-listing-elem.trip-list, .object-listing-elem.elem-booking{
    .covid-info-in, .covid-info-out{
        padding: 0px 3px;
        min-width: unset;
        min-height: unset;
        margin: 3px 0;
    }
}

.object-listing-elem.elem-booking {
    .covid-container {
        @include respond-to($screen-xs-max){
            padding-left: 15px;
            display: inline-block;
        }
    }
}
