.mobile-header {
  height: 70px;
  z-index: 1000;

  .mobile-header-navigation {
    padding: 20px;

    .hide-menu {
      display: none;
    }
  }

  .mobile-header-quick-search {
    padding: 20px;
  }

  .mobile-header-logo {
    text-align: center;
    padding: 10px;

    img {
      max-height: 50px;
    }
  }

  .mobile-menu-popup-heading span {
    font-weight: bold;
  }

  &.mobile-header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  #mobile-menu-popup {
    position: fixed;
    background: red;
    top: 70px;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    border-top: solid 3px;
    z-index: 100;

    ul {
      list-style: none;
      padding: 0;
      float: left;
      width: 100%;
      height: calc(100% - 100px);
      overflow-y: auto;

      li {
        text-transform: uppercase;
        width: 100%;
        border-bottom: 1px solid;
        color: inherit;
        height: 40px;
        line-height: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        * {
          color: inherit;
          vertical-align: middle;
          line-height: inherit;
        }

        .fa {
          font-size: 1.2em;
          width: 40px;
          text-align: center;
          height: 100%;

          &:first-child {
            float: left;
          }

          &:last-child {
            border-left: 1px solid;
            float: right;
          }

          .span {
            width: calc(100% - 80px);
          }
        }
      }
    }

    .mobile-popup-socials {
      display: flex;

      .menu-tab {
        border-top: 1px solid;
        padding: 20px 0;
        text-align: center;
        flex-grow: 1;

        a {
          color: inherit;

          &:not(:first-child) {
            border-left: 1px solid;
          }
        }
      }
    }
  }
}







