.offer-nav-bar {

    .offer-nav {
        padding: 0 0 3px;
        border-bottom: 4px solid;
        min-height: 31px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline;
                margin: 0 15px 0 0;

                a {
                    text-decoration: none;
                    font-size: 15px;
                    line-height: 24px;
                    position: relative;
                    font-weight: 600;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .offer-nav-bar-home {
                    a {
                        font-size: 24px;

                        .fa {
                            position: relative;
                            top: 4px;
                        }
                    }
                }
            }
        }
    }

}
