.weather-box {
	.more {
		padding: 12px 5px 1px 0;
	}

	.carousel-inner {
		ul.weather-data {
			margin-top: 15px;
			margin-bottom: 2px;
		}
	}

	.weather-provider {
		height: 38px;
	}

	.carousel-control {
		@include respond-to($screen-sm-max) {
			margin: 0;
			top: 35px;
		}
	}
}

.weather-header {
	h4 {
		display: inline-block;
		width: 100%;
	}

	span {
		display: inline-block;
		padding-top: 15px;

		&.in-context {
			font-size: 14px;
		}
	}

	img.in-context {
		padding-right: 15px;
	}

	@media (max-width: 520px) {
		img.in-context {
			max-width: 130px;
			margin-top: 6px;
		}

		h4.in-context, span {
			display: block;
		}
	}
}

.weather-description {
	h3 {
		margin: 9px 0 7px 0;
		line-height: 32px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.values{
	  height: 50px;
	  margin-bottom: 5px;
	}
	p {
		margin: 0 0 0;
	}
}

.weather-data-container {
	border-bottom: 1px solid;

	@include respond-to($screen-sm-max) {
		border-bottom: none;
	}
}

.weather-data {
	padding: 0;
	list-style: none;
	display: table;
	width: 100%;

	/*@include respond-to($screen-sm-max) {
		padding: 0 52px 0 0;
	}*/

	@include respond-to($screen-xs-max) {
		padding: 0;
	}

	li {
		border-top: 1px dotted;
		overflow: hidden;
		font-size: 14px;
		line-height: 14px;

		span {
			padding: 5px;
			display: table-cell;

			&:last-of-type {
				text-align: right;
			}

			&.weather-attr {
				width: 100%;
			}
		}

		&:first-child {
			border-top: none;
		}
	}
}
.weather-box{
  .carousel-inner{
	.weather-data-container{
		  .weather-data{
			margin-top:5px;
			  li{
				line-height: 16px;
				span{
				  padding:4px 5px;
				}
			  }
		  }
	  }
	.section-footer{
	  ul{
		padding: 0;
		height:42px;
		.more{
		  padding-right: 0;
		  padding-left: 0;
		  padding-top:10px;
		}
	  }
	}
  }
}
.weather-boxes {
	margin: 0;
	width: 100%;

	.weather-data {
		li {
			width: 100%;

			span {
				padding: 3px;
				display: inline-block;
				border-bottom: none;

				&:last-of-type {
					float: right;
				}
			}
		}
	}

	.weather-item-header {
		display: table;
		width: 100%;

		p {
			display: table-cell;
			text-align: left;
		}
	}

	.weather-description {
		display: inline-block;
		font-weight: bold;
		width: 100%;
		padding-top: 5px;
	}


	.weather-attributes {
		display: table-cell;
		text-align: right;
		vertical-align: middle;

		li {
			list-style: none;
			display: inline-block;
			border: 1px solid rgb(183,184,184);
			border-radius: 4px;
			padding: 5px;
			color: #000;
		}
	}

	.temperature {
		display: block;
		padding: 10px;
		padding-top: 0;

		p {
			font-size: 2em;
			font-weight: bold;
			margin: 0;
		}
	}

	&.in-context > li {
		padding: 0;
		border-top: 1px solid;
		float: left;
		border-right: 1px solid;

		&:nth-child(4n+4) {
			border-right-width: 0;
		}

		@include respond-to($screen-md-max) {
			&:nth-child(4n+4) {
				border-right-width: 1px;
			}

			&:nth-child(4n+4) {
				border-right-width: 0;
			}
		}

		@include respond-to($screen-sm-max) {
			&:nth-child(4n+4) {
				border-right-width: 1px;
			}

			&:nth-child(3n+3) {
				border-right-width: 0;
			}
		}

		@include respond-to($screen-xs-max) {
			border-right-width: 0;
			/*&:nth-child(3n+3) {
					border-right-width: 1px;
				}

				&:nth-child(even) {
					border-right-width: 0;
				}*/
		}

		.weather-data {
			padding: 10px;
		}
	}

	&.in-popup > li {
		padding: 0;
		border-top: 1px solid;
		float: left;
		border-left: none;
		width: 50%;

		&:nth-child(even) {
			border-left: 1px solid;
		}

		@media all and (max-width:750px) {
			width: 100%;
			float: none;
		}

		.weather-data {
			padding: 10px;
		}
	}
}

.dig {
	background: url('/img/meteo_pictograms.gif') no-repeat scroll 0% 0% transparent;
	height: 22px;
	width: 43px;
}

.beachdigit10 {
	background-position: -0px -44px;
}

.beachdigit9 {
	background-position: -43px -44px;
}

.beachdigit8 {
	background-position: -86px -44px;
}

.beachdigit7 {
	background-position: -129px -44px;
}

.beachdigit6 {
	background-position: -172px -44px;
}

.beachdigit5 {
	background-position: -215px -44px;
}

.beachdigit4 {
	background-position: -258px -44px;
}

.beachdigit3 {
	background-position: -301px -44px;
}

.beachdigit2 {
	background-position: -344px -44px;
}

.beachdigit1 {
	background-position: -387px -44px;
}

.bikingdigit10 {
	background-position: -0px -22px;
}

.bikingdigit9 {
	background-position: -43px -22px;
}

.bikingdigit8 {
	background-position: -86px -22px;
}

.bikingdigit7 {
	background-position: -129px -22px;
}

.bikingdigit6 {
	background-position: -172px -22px;
}

.bikingdigit5 {
	background-position: -215px -22px;
}

.bikingdigit4 {
	background-position: -258px -22px;
}

.bikingdigit3 {
	background-position: -301px -22px;
}

.bikingdigit2 {
	background-position: -344px -22px;
}

.bikingdigit1 {
	background-position: -387px -22px;
}

.campingdigit10 {
	background-position: -0px -66px;
}

.campingdigit9 {
	background-position: -43px -66px;
}

.campingdigit8 {
	background-position: -86px -66px;
}

.campingdigit7 {
	background-position: -129px -66px;
}

.campingdigit6 {
	background-position: -172px -66px;
}

.campingdigit5 {
	background-position: -215px -66px;
}

.campingdigit4 {
	background-position: -258px -66px;
}

.campingdigit3 {
	background-position: -301px -66px;
}

.campingdigit2 {
	background-position: -344px -66px;
}

.campingdigit1 {
	background-position: -387px -66px;
}

.walkingdigit10 {
	background-position: -0px -330px;
}

.walkingdigit9 {
	background-position: -43px -330px;
}

.walkingdigit8 {
	background-position: -86px -330px;
}

.walkingdigit7 {
	background-position: -129px -330px;
}

.walkingdigit6 {
	background-position: -172px -330px;
}

.walkingdigit5 {
	background-position: -215px -330px;
}

.walkingdigit4 {
	background-position: -258px -330px;
}

.walkingdigit3 {
	background-position: -301px -330px;
}

.walkingdigit2 {
	background-position: -344px -330px;
}

.walkingdigit1 {
	background-position: -387px -330px;
}

.watersportsdigit10 {
	background-position: -0px -154px;
}

.watersportsdigit9 {
	background-position: -43px -154px;
}

.watersportsdigit8 {
	background-position: -86px -154px;
}

.watersportsdigit7 {
	background-position: -129px -154px;
}

.watersportsdigit6 {
	background-position: -172px -154px;
}

.watersportsdigit5 {
	background-position: -215px -154px;
}

.watersportsdigit4 {
	background-position: -258px -154px;
}

.watersportsdigit3 {
	background-position: -301px -154px;
}

.watersportsdigit2 {
	background-position: -344px -154px;
}

.watersportsdigit1 {
	background-position: -387px -154px;
}

.tennisdigit10 {
	background-position: -0px -286px;
}

.tennisdigit9 {
	background-position: -43px -286px;
}

.tennisdigit8 {
	background-position: -86px -286px;
}

.tennisdigit7 {
	background-position: -129px -286px;
}

.tennisdigit6 {
	background-position: -172px -286px;
}

.tennisdigit5 {
	background-position: -215px -286px;
}

.tennisdigit4 {
	background-position: -258px -286px;
}

.tennisdigit3 {
	background-position: -301px -286px;
}

.tennisdigit2 {
	background-position: -344px -286px;
}

.tennisdigit1 {
	background-position: -387px -286px;
}

.terracedigit10 {
	background-position: -0px -308px;
}

.terracedigit9 {
	background-position: -43px -308px;
}

.terracedigit8 {
	background-position: -86px -308px;
}

.terracedigit7 {
	background-position: -129px -308px;
}

.terracedigit6 {
	background-position: -172px -308px;
}

.terracedigit5 {
	background-position: -215px -308px;
}

.terracedigit4 {
	background-position: -258px -308px;
}

.terracedigit3 {
	background-position: -301px -308px;
}

.terracedigit2 {
	background-position: -344px -308px;
}

.terracedigit1 {
	background-position: -387px -308px;
}

.section {
	&.weather-box {
		margin-bottom: 15px;

		@include respond-to($screen-xs-max) {
			margin-bottom: 0;
		}
	}
}
