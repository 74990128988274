@mixin simple-carousel(){

  .handler-left, .handler-right, .load-images-handler {
    position: absolute;
    top: calc(50% - 20px);
    padding: 5px;
    background: rgba(0,0,0,.4);
    border: 1px solid #867979;
    border-radius: 3px;
    display: none;
    color: white;
  }

  .handler-left {
    left: 20px;
  }

  .handler-right, .load-images-handler {
    right: 20px;
  }

  @include respond-to($screen-xs-max) {
    .handler-left, .handler-right {
      display: inline-block;
    }

    &[data-current-image="1"]{
      .handler-left{
        display: none;
      }
      .handler-right {
        display: inline-block;
      }
    }
    &[data-current-image="last"]{
      .handler-left{
        display: inline-block;
      }
      .handler-right {
        display: none;
      }
    }
  }

  &:hover{
    .handler-left, .handler-right, .load-images-handler {
      display: inline-block;
    }
  }
  &[data-current-image="1"]:hover{
    .handler-left{
      display: none;
    }
    .handler-right {
      display: inline-block;
    }
  }
  &[data-current-image="last"]:hover{
    .handler-left{
      display: inline-block;
    }
    .handler-right {
      display: none;
    }
  }

  .simple-carousel-items-container {
    display: flex;
    .simple-carousel-item {
      flex-basis: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      transition: all 0.5s;
      img{
        width: 100%;
      }
    }
  }

  &[data-view-images-count="3"] {
    .simple-carousel-items-container {
      .simple-carousel-item{
        flex-basis: calc(100% / 3);
      }
    }
  }

  &[data-gap="3"] {
    .simple-carousel-items-container {
      gap: 3px;
    }
  }
}
