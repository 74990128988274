.elem-rating {
	float: left;
	white-space: nowrap;
	@include cf;
	.rating-label {
		float: left;
	}
	.rating-container {
		float: left;
		@include cf;
		width: 180px;
		.rating {
			width: 140px;
			height: 12px;
			border-width: 1px;
			border-style: solid;
			margin: 4px 8px 0 8px;
			float: left;
			.value {
				width: 70%;
				height: 100%;
			}
			
		}
		.rating-value {
			font-weight: 600;
		}
	}
}

.customer-ratings {
	.rate {
		font-size: 3em;
		margin: 0;
	}
	.elem-rating {
		float: none;
		margin: 0;
		p {
			margin: 0;
		}
		.rating-label {
			float: none;
			font-size: 1em;
			line-height: 1em;
		}
		.rating-container {
			height: 1.3em;
			width: 100%;
			.rating {
				width: 100%;
				margin: 0;
			}
		}
	}
	.rating-list {
		li {
		margin: .5em 0;
		}
	}
}