.breadcrumbs {
    list-style: none;
    margin: 0;
    padding: 20px 0 0;

    > li {
        display: inline-block;
        position: relative;
        margin: 0 25px 0 0;
        padding: 8px 10px;
        height: 36px;

        a {
            font-size: 12px;
            line-height: 12px;
        }
        .nonclickable {
            padding: 8px 10px 8px 12px;
            font-size: 12px;
            line-height: 12px;
        }

        &:after {
            display: block;
            position: absolute;
            right: -15px;
            top: 0;
            height: 100%;
            content: '';
            border-top: 18px solid rgba(255, 255, 255, 0);
            border-bottom: 18px solid rgba(255, 255, 255, 0);
            border-left-width: 15px;
            border-left-style: solid;
            color: inherit;
            -moz-transform: scale(.9999);
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -15px;
            height: 100%;
            border-width: 18px 0 18px 15px;
            border-style: solid;
            border-left-color: rgba(255, 255, 255, 0);
            color: inherit;
            -moz-transform: scale(.9999);
        }

        &:first-child:before {
            display: none;
        }
    }
}

/* rwdflights breadcrumbs overrides above */
.breadcrumbs {

    > li {
        padding: 0;
        margin-right: 0;
        background-image: none;
        background-color: transparent;

        &:before {
            position: static;
            height: auto;
            border-width: 0;
            vertical-align: middle;
            font-size: 14px;
            line-height: 13px;
            content: unquote("\"#{ $fa-var-long-arrow-right }\"");
            font-weight: var(--#{$fa-css-prefix}-style);
            color: $color10;
            font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
        }
        &:first-of-type {
            padding-right: 0;
            height: auto;

            + li:before {
                display: none;
            }

            span {
                color: $color10;
            }
        }
        &:after {
            display: none;
        }

        a {
            display: inline-block;
            padding: 8px 10px 8px 12px;
            font-size: 14px;
            line-height: 14px;
            text-decoration: underline;
            text-transform: capitalize;
        }
        .nonclickable {
            padding: 8px 10px 8px 12px;
            font-size: 14px;
            line-height: 14px;
        }
    }
}
