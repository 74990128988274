.dynamicresult {
    &.min-width-1{ // tourop #loaderscreen width:0 workaround (tourop styles bug)
        min-width: 1px;
    }

    .element-hide {
        visibility: hidden !important;
        height: 0 !important;
        max-height: 0 !important;
        min-height: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        border: none !important;
    }

    .js-show-more-disabled {

        .btn-load-more {
            background: $color10;
            opacity: $pending-opacity;
            cursor: auto;
        }

        .js-show-more-text {
            visibility: hidden;
        }
    }

    .icon-placeholder.fa-nofoto{
        @include icon-placeholder;
    }

    .element-loading {

        $icon-placeholder-size: 60px;

        .icon-placeholder {
            @include icon-placeholder($icon-placeholder-size);
        }

        .img-container {
            min-height: 230px;
            background-color: $color4;
        }

        .offer-container-only-transfer, .offer-container-only-parking, .offer-container-only-insurance {
            .img-container {
                min-height: 80px;
            }
        }

        .offer-img {
            visibility: hidden;
        }
    }

    @include border-top-radius("$block-br");
    @include respond-to($screen-xs-max) {
        margin-bottom: $margin-padding-md;
    }

    #dynamic-list-header {

        .select-order-container {
	        $select-order-container-top: ($widget-header-height - $half-gutter - 32) / 2;
	        $select-order-container-top: if($select-order-container-top < 0, 0, $select-order-container-top);

            position: relative;
	        top: $select-order-container-top;
            height: 32px;
            min-width: 220px;
            margin-left: $half-gutter;
            border: 1px solid transparent;
            background-color: rgba(255, 255, 255, 0.3);
        }

        .sort-results .select2-choice {
            height: 32px;
            padding: 0 10px;
        }
        .sort-results .select2-choice .select2-chosen,
        .sort-results .select2-choice:after {
            padding-top: 2px;
            line-height: 27px;
            //color: $color12 !important;
        }
    }

    /*#dynamic-list-header {
        padding: 0 0 15px 0;
        @include respond-to($screen-xs-max) {
            padding: 5px $half-gutter 15px;

            !* only for ungrouped xs-screen *!
            & + div[data-section=fl] > .section-content {
                border-top: 1px solid $color19;
            }
        }
    }*/

    .data-section-box {
        position: relative;
    }

    .data-section-dates {

    }

    .data-section-box-overlay {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #ffffff;
        z-index: 100;
        @include opacity(0.5);
    }

    .reserve-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        @include opacity($pending-opacity);
        z-index: 1000;
    }

    .icon-spinner /*.spinner-5-items*/
    {
        padding: 10px;
        font-size: 12px;
        letter-spacing: 2px;

        .fa {
            margin: 0 3px;
            @include opacity(0.4);

            &.semi3 {
                @include opacity(0.55);
            }
            &.semi2 {
                @include opacity(0.7);
            }
            &.semi {
                @include opacity(0.85);
            }
            &.bright {
                @include opacity(1);
            }
        }
    }

    .dynamiclist-pending-info {
        display: none;
        position: absolute;
        top: 50px;
        left: calc(50% - 200px);
        width: 400px;
        padding: 40px 30px; //0 $margin-padding-lg;
        text-align: center;
        background-color: $color1;
        color: $color12;
        z-index: 15;
        @include respond-to($screen-xs-max) {
            position: fixed;
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            width: inherit !important;
        }

        .loading-results-pending,
        .loading-reservation-pending {
            margin: 0 auto;
            font-size: 14px;
        }
    }
    &.dynamiclist-pending .data-section-box-overlay,
    &.dynamiclist-pending .dynamiclist-pending-info {
        display: block;
    }

    .y-dynamic-search {
        font-size: 18px;
    }

    .view-buttons {
        $view-buttons-top: ($widget-header-height - $half-gutter - 32) / 2;
        $view-buttons-top: if($view-buttons-top < 0, 0, $view-buttons-top);

        border: none;
        position: relative;
	    top: $view-buttons-top;

        @include respond-to($screen-xs-max) {
            width: 100%;

            &.view-buttons-2 {
                .btn {
                    width: (100%/2);
                }
            }
            &.view-buttons-3 {
                .btn {
                    width: (100%/3);
                }
            }
        }

        &.view-buttons-pending {
            @include opacity($pending-opacity);
        }

        .btn {
            float: left;
            height: 32px;
            min-width: 95px;
            border: none;
            color: $color12;
            background-color: rgba(255, 255, 255, 0.3);
            cursor: pointer;

            &:first-of-type {
                @include border-left-radius("$br-btn");
                @include border-right-radius(0);
            }
            &:last-of-type {
                @include border-left-radius(0);
                @include border-right-radius("$br-btn");
            }

            &.btn-active {
                color: $color1;
                background-color: $color12;
                cursor: auto;
            }

            &:not(.btn-active):hover .fa {
                animation: scale-150-proc .2s ease-out 1 normal;
            }
        }

        i {
            margin-right: 10px;
            position: relative;
            top: 1.5px;
            left: 0;
        }
    }

    .img-container {
        &.js-img-link {
	        cursor: pointer;
        }

        @media (min-width: $screen-sm) {
            margin-right: -$margin-padding-md;
        }
    }

    .offerlist {
    }

    .offerlist-highlighted {
        margin: 0;

        .data-section-box {
            &:first-child {
                margin-top: 10px;
            }
            &:last-child {
                margin-bottom: 10px;
            }
        }
    }

    .offer-container-highlighted {

    }

    .offer-container {
        padding: 15px $margin-padding-md 10px;
        border-bottom: 1px solid $color19;

        .offer-img {
            min-height: 100px;
            width: 100%;
        }

        .offer-dates {
            @extend .offer-package;
            padding: 6px 0;
            color: $color9;

            .dates,
            .duration-wrapper {
                white-space: nowrap;
            }

            .day-name {
                color: $color10;
            }
        }

        &.services-hidden-xs {
            @include respond-to($screen-xs-max) {
                .offer-dates {
                    padding-bottom: 0;
                }
                .offer-package {
                    display: none;
                }
                .offer-footer {
                    padding-top: $margin-padding-sm;
                }
            }
        }
    }

    .packagelist {
        border-bottom: 2px dotted $color19;

        .package-elem {
            position: relative;
            min-height: 63px; /* for elements without description */
            padding: $margin-padding-sm 0;
            border-top: 2px dotted $color19;

            .elem-vertical-container {
                min-height: 42px;
                line-height: 42px;

                .elem-vertical {
                    display: inline-block;
                    vertical-align: middle;
                    line-height: initial;
                }
            }
        }

        .icon-pos,
        .icon-pos-r{
            position: absolute;
            left: 0;
            top: $margin-padding-sm; /*for absolute positioned icons*/
            /*
            width: 45px;
            height: 45px;*/

            /*.fa:before {
                margin-left: 1.5px;
                margin-right: 10px;
            }*/
        }
        .icon-pos-r {
            left: auto;
            right: 0;
        }
        .icon-pos-offset {
            margin-left: 60px;
        }
        .icon-pos-offset-r {
            margin-right: 60px;
        }
        .luggage {
            display: inline-block;
            padding-top: 7px;
            color: $color1;
            cursor: pointer;

            .fa-bezbagazu {
                @include opacity(0.5);
            }
        }

        .elem-title {
            font-size: 16px;
            color: $color1;
        }
        .name {
            margin-right: 4px;
            font-weight: bold;

            &.name-bigger {
                font-size: 24px;
            }
        }
        .stars {
            top: -0.2em;
            font-size: 0.8em;
            white-space: nowrap;

            .fa {
                margin-left: 0.2em;
            }
        }

        .elem-info {

        }
        .hotel-address {

            &.has-spinner-centering-container {
                display: block;
                margin: 0 auto;
            }

            .fa {
                vertical-align: -24%;
            }
        }
        .hotel-distance {
            //position: relative;
            display: inline-block;
            cursor: pointer;

            .fa {
                font-size: 1.7em;
                vertical-align: -24%;
            }
        }
    }
    .spinner-centering-container {
        display: block;
        margin: 0 auto;
        text-align: center;
        color: $color10;
        .fa-spinner {
            display: inline-block;
            margin: $margin-padding-sm auto;
        }
    }

    .offer-footer {
        padding-top: $margin-padding-md;

        .btn-go-step3 {
            float: right;
            @include respond-to($screen-xs-max) {
                display: block;
                float: none;
            }
            min-width: 175px;
            font-size: 18px;
            padding: 5px 10px 6px;

            .xs-prices {
                font-size: 16px;
            }
            .xs-price-main {
                .price-val, .price-curr {
                    font-size: 18px;
                }
            }
            .xs-price-second {
                font-size: 14px;
            }
        }

        .link {
            display: inline-block;
            //padding-top: 11px;
            text-decoration: underline;
            color: $color16;
            cursor: pointer;

            &:hover {
                color: $color18;

                /*i:before {
                    color: $color18;
                }*/
            }
            i {
                color: inherit;
                position: relative;
                top: 2px;
                left: 0;
                margin-right: 5px;
            }
        }

        .link-clipboard {
            display: inline-block;
        }

        .links-wrapper {
            margin-top: 17px;
            @include respond-to($screen-md-max) {
                margin-top: 0; //$margin-padding-xs;
                text-align: right;
            }
            @include respond-to($screen-xs-max) {
                margin-top: $margin-padding-sm;
                text-align: center;

                //hide for xs screen since 13.05.2016
                visibility: hidden;
                height: 0;
                margin-top: 5px;
            }

            a:not(:first-child) {
                margin-left: 10px;
            }
        }

        .priceblock-single {
            .links-wrapper {
                text-align: left;
                margin-top: 17px;
            }
        }

        .priceblock-single {

        }

        .priceblock-double {

        }

        .price-main,
        .price-second {
            display: inline-block;
            line-height: 36px;
            margin-right: 10px;
            white-space: nowrap;
        }
        .price-main {
            .price-val,
            .price-curr {
                font-size: 36px;
            }
            .price-dec {
                position: relative;
                top: -11px;
                vertical-align: baseline;
                font-size: 22px;
                line-height: 0;
            }
        }
        .price-second {
            margin-right: 15px;
            margin-top: 8px;
            @include respond-from($screen-lg-min) {
                margin-left: -15px;
            }
        }
    }
}

.waiting-box {
    max-width: 714px;
    margin: $margin-padding-lg auto 40px; //top margin like main
    padding: 40px;
    text-align: center;
    background-color: $color1;
    color: $color12;

    &.in-results-list {
        max-width: none;
        margin-top: 0;
    }

    .awaiting-response,
    .no-results {
        .search-elements {
            margin-top: 0;
            font-size: 24px;
        }
        .search-additional-info {
            margin-top: 0;
            font-size: 14px;
        }
        .search-legible-paragraph {
            font-size: 18px;
        }

        .no-results-replacer { // html from mdsws
            font-size: 18px;
            .search-paragraph {
                margin: 5px auto 0;

                &.no-results-text {
                    margin: 20px 0;
                    text-transform: uppercase;
                }

                &.search-legible-paragraph {
                    margin-bottom: 0;

                    &:last-of-type {
                        margin-bottom: 20px;
                    }
                }

                &.no-results-suggestions {
                    font-size: 14px;
                    margin-bottom: 0;
                }
            }
        }

        .btn-search-again {
            margin: 20px 0;
            padding: 8px 15px;
            border: 3px solid $color12;
            font-size: 18px;
            background-color: $color7;
            color: $color12;

            &:hover {
                background-color: $color18;
            }
        }
        .btn-clear-all-filters {
            @extend .btn-search-again;
            margin-bottom: 0;
        }
    }
}

.singledynamicresult {

    .offer-container {
        /* TODO: refactor .img-container nesting*/
        .img-container {
            position: relative;
            text-align: center;
        }
        /* TODO: refactor into molecule */
        .img-container.img-has-label {
            //position: relative;

            & > .img-labels-wrapper {
                position: absolute;
                top: 18px;
                left: -1-($margin-padding-md);
                @include respond-to($screen-xs-max) {
                    left: 0;
                }
                white-space: nowrap;
                z-index: 10;

                & > .hotel-img-label {
                    border: 1px solid #fff;
                    @include respond-to($screen-xs-max) {
                        border-left: none;
                    }
                    padding: 5px $margin-padding-md;
                    font-size: 14px;
                    color: $color12;
                    cursor: pointer;
                    @include border-right-radius("$block-br");

                    & + .hotel-img-label {
                        margin-top: 2px;
                    }

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }
            }
        }

        .img-container .mask {
            //width: 300px;
            //height: 200px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            overflow: visible;
            //border: 100px solid rgba(0,0,0,0.3);
            background-color: transparent;
            transition: all 0.1s ease-in-out;
            z-index: 200;
            -webkit-transform-style: preserve-3d;
            -moz-transform-style: preserve-3d;
            transform-style: preserve-3d;

            .mask-btn-wraper-wrapper {
                display: table;
                width: 100%;
                height: 100%;
            }

            .mask-btn-wraper {
                //position: relative;
                display: table-cell;
                vertical-align: middle;
                //top: 50%; /* Center the link */
                //left: 50%;
                text-align: center;

                .mask-btn {
                    //position: relative;
                    //transform: translate(-50%, -50%);
                    margin: 0 auto;
                    opacity: 0;
                    transition: opacity 0.1s 0s ease-in-out;
                    font-size: 18px;
                    color: $color12;
                }
            }
        }

        .img-container:hover .mask {
            opacity: 0.5;
            //border: 100px solid rgba(0,0,0,0.7);
            background-color: black;

            .mask-btn {
                opacity: 1;
                transition-delay: 0.1s;
            }
        }

        .packagelist {

            .package-elem {
                margin-top: 0;
                border-top: none;
                padding-top: 0;
                padding-bottom: 8px;
                min-height: 56px;

                .icon-pos {
                    top: 0;
                }
            }

            .name {
                font-size: 18px;

                &.name-bigger {
                    font-size: 24px;
                }
            }
        }

    }

    .sm-md-lg-container {

        .hotel-desc {
            position: relative;
            //max-height: 14px * 1.42857 * 2;/* font-size * line-height * lines_no */
            max-height: (14px * 1.42857 * 2) + (2 * 8px); /* font-size * line-height * lines_no  + 2 * vertical_padding */
            min-height: 14px * 1.42857;
            overflow: hidden;
            //text-align: justify;
            text-overflow: ellipsis;
            //white-space: nowrap;
        }
        .desc-link-more {
            //position: absolute;
            //bottom: 0;
            //right: 0;
            cursor: pointer;
        }

        /*.spinner-centering-container{
            text-align: center;
            color: $color10;
            .fa-spinner{
                display: inline-block;
                margin: $margin-padding-sm auto;
            }
        }*/

        .hotel-attr {
            margin-top: 2px; //$margin-padding-xs;
            cursor: pointer;
        }

        .attr-icon-wrapper {
            position: relative;
            top: -6px;
            display: inline-block;
            margin-right: $margin-padding-sm;
            //vertical-align: top;

            .fa {
                font-size: 1.7em;
                vertical-align: -20%;
            }
        }
        .elem-rating {
            float: none;
            display: inline-block;
            margin-bottom: -2px;
        }
    }

    .offer-description /*,
    .services*/
    {
        padding: 8px 0;
        border-bottom: 2px dotted $color19;
    }

    .xs-container {
        color: $color12;

        .img-container {
            margin-right: inherit;
        }

        .offer-heading {
            padding: $margin-padding-md;
            padding-bottom: 13px;
            background: $color2;
            color: $color15;
            @include border-top-radius("$btn-br"); // maybe "$block-br"
            cursor: default; /* hotel, car */

            .elem-title {
                position: relative;
                font-size: 24px;
                line-height: 24px;
                margin-bottom: $margin-padding-xs;

                &.elem-title-table {
                    width: 100%;

                    .td2 {
                        float: right;
                    }
                }
            }
            .stars {
                float: right;
                display: inline-block;
                top: 0;
                font-size: 0.8em;
                line-height: inherit;
                white-space: nowrap;

                .fa {
                    margin-left: 0.2em;
                }
            }
        }

        .img-container {
            margin-top: 2px;
            margin-bottom: 2px;
        }

        .xs-prices-container {
            padding: $margin-padding-sm $margin-padding-md;
            color: $color12;
            @include border-bottom-radius("$btn-br"); // maybe "$block-br"

            .xs-prices {
                font-size: 16px;
                text-align: center;
            }
        }
        .xs-price-main {
            .price-val, .price-curr {
                font-size: 18px;
            }
        }
        .xs-price-second {
            font-size: 14px;
        }
    }
}

.car-tooltip-content {
    width: 230px;
    line-height: $base-line-height !important;
    overflow: hidden;
}

.offer-container {

    /*
    &.offer-container-only-hotel {

    }
    */

    &.offer-container-only-car {

        .packagelist {

            .package-elem {
                margin-top: 0;
                border-top: none;
                padding-top: 0;
                padding-bottom: 4px;
                min-height: 52px;
            }
        }

        .priceblock-single {
            .links-wrapper {
                margin-top: 0;
                display: flex;
            }
        }

        .img-container{
            background: #fff;

            .element-loading &{
                background: $color4;
            }

            // different img sizes from different car rentals <150px, 240px, 320px, 360px, 640px>
            .offer-img {
                margin: 0 auto;
                border: $margin-padding-md solid #fff;
                max-width: 320px;
                background-color: #fff;
            }
        }

        .car-name-ext,
        .elem-info {
            font-size: 14px;
            color: $color10
        }

        .offer-description {

            .car-rental-val,
            .rating-val {
                font-weight: 700;
            }
        }

        .elem-car-option {
            display: inline-block;
            margin-right: $margin-padding-sm;
            &:last-of-type {
                margin-right: 0;
            }
        }

        .xs-container {

            .img-container {

                &.img-container-has-border {
                    padding: 0 1px;
                    background-color: #fff;

                    .element-loading &{
                        background-color: $color4;
                    }
                }
            }
            .elem-info {
                color: $color15;
            }
        }
    }

    &.offer-container-only-transfer, &.offer-container-only-parking, &.offer-container-only-insurance {

        .img-container {

            .offer-img {
                min-height: 0;
                max-width: 100%;
                width: auto;
                max-height: 2 * $base-line-height;
                margin-top: 5px;
            }
        }

        .packagelist {
            border-bottom: none;

            .package-elem {
                padding-bottom: 0;
            }
        }
    }

    &.offer-container-only-transfer {

        .transfer-info {

        }

    }

    &.offer-container-only-parking {

        .parking-address {

        }
    }

    &.offer-container-only-insurance {

        .offer-insurance-allianz {

            .priceblock-single {

                .price-main {

                }
                .links-wrapper {
                    margin-top: 0;
                    text-align: center;
                    min-width: 125px;
                    float: right;
                }
            }

            .offer-footer .btn-go-step3 {
                min-width: 125px;
            }
        }

        .insurance-details {

        }
    }
}

.js-htlText2Region {
    cursor: pointer;
}

.js-close-map-btn{
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 2;
    font-size: 36px;
    color: $color7;
    cursor: pointer;
}
