/*
	offer box section styling
*/

section.offer-box {
	overflow: visible; // shadow requrement
	min-height: $widget-header-height;

	.row .no-padding {
		@include respond-to($screen-xs-max) {
			border-bottom: 1px solid $color19;
		}
		&:last-child {
			border: none;
		}
	}

	.offer {
		margin: 0;
		padding: 15px;
		display: block;
		text-decoration: none;
		position: relative;

        /*.shadow-container{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
        }*/

		.img-container-wrap {
			position: relative;
			overflow: hidden;
			min-height: 170px;
			margin: 0 0 13px;
		}

		.img-container {
			overflow: hidden;
			background-repeat: no-repeat;
			background-size: cover;
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			left: 0;
			@include transition(all .5s ease);

			&.scale-x-1 {
				background-position: 0% 0%;
			}

			&.scale-x-2 {
				background-position: 50% 0%;
			}

			&.scale-x-3 {
				background-position: 100% 0%;
			}
		}

		.img-mobile-container {
			margin: 0 0 15px;
			overflow: hidden;
			img {
				@include transition(all .5s ease);
			}
		}

		.text-container {
			position: relative;
			.history-price{
				float: right;
				padding-left: 10px;
                position: relative;
                top: 1px;
                display: inline-block;
                .amount {
                    position: relative;
                    top: 1px;
                }
			}
		}
		h3 {
			margin: 0;
			padding: 0;
			font-size: $font-size-large;
			line-height: $font-size-large * 1.25;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
        .box-header {
            h4 {
                span:first-child{
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                margin: 3px 0 4px 0;
                font-weight: 400;
                font-size: 13px;
                line-height: 14px;
                display: inline-block;
                em {
                    font-style: normal;
                    font-weight: 600;
                }

            }

            .history-price {
                float: none;
                top: -1px;
                padding-left: 3px;
            }
        }

		.data {
			display: table;
			width: 100%;
			& > * {
				display: table-row;
				& > * {
					display: table-cell;
				}
			}
			p {
				font-size: 13px;
				line-height: 16px;
				margin: 0;
			}
		}
		.price {
			vertical-align: bottom;
			text-align: right;
			span {
				font-size: 19px;
				line-height: 19px;
				font-weight: 600;
			}
		}
		.stars {
			font-size: $font-size-small;
		}

		&:hover {

			.img-container.with-zoom {
				@include scale3d(1.1, 1.1, 1.1);
			}
			.img-mobile-container.with-zoom {
				img {
					@include scale3d(1.1, 1.1, 1.1);
				}
			}
		}

		@include respond-to($screen-xs-max) {
			margin: 0;
			padding: 15px;
		}
      &+.omnibus {
        font-size: 0.7em;
        bottom: 5px;
        right: 15px;
      }
	}

	.offer-rich, .offer-big {
		.img-container-wrap {
			margin: 0;
		}

		.img-container {
			border-top-left-radius: "$block-br";
			border-top-right-radius: "$block-br";
		}

		.text-container {
			padding: 15px;
			border-bottom-left-radius: "$block-br";
			border-bottom-right-radius: "$block-br";
		}

		.img-mobile-container {
			margin: 0;
		}
	}

    .offer-rich {
        &+.omnibus {
            font-size: 0.7em;
            bottom: 22px;
            right: 30px;
        }
    }

    .offer-big {
        &+.omnibus {
            font-size: 0.7em;
            bottom: 21px;
            right: 30px;
        }
    }

	.one-of-two-offers {
		.text-container {
			padding: 15px !important;
		}
		@include respond-from($screen-sm-min) {
			.offer-big-wrapper, .offer-rich-wrapper {
				@include display-flex(flex);
				@include align-items(center);
			}

			.text-container {
				min-height: 140px;
				@include display-flex(flex);
				@include justify-content(center);
				@include flex-direction(column);
				border-top-right-radius: "$block-br";
			}

			.img-container {
				border-top-left-radius: "$block-br";
				border-top-right-radius: 0;
				border-bottom-left-radius: "$block-br";
			}

			.offer-rich-wrapper {
				.img-container-wrap {
					&::after {
						position: absolute;
						content: '';
						right: 0;
						height: 100%;
						width: 4px;
						background-color: $color11;
					}
				}
			}
		}
	}

	.offer-rich {
		.img-container-wrap {
			&::after {
				position: absolute;
				content: '';
				bottom: 0;
				width: 100%;
				height: 4px;
				background-color: $color11;
			}
		}
		.history-price{
			position: relative;
			bottom: 6px;
            height: auto;
            margin-top: 0;
            .amount {
                position: relative;
                top: 1px;
            }
		}
		.star-container {
			font-size: 24px;
			line-height: 18px;

			.stars {
				vertical-align: sub;
				font-size: $font-size-small;
			}
		}

		.price {
			text-align: left;
			span {
				text-align: right;
			}
		}
	}

	.offer-big {
		.offer-big-wrapper {
            .text-container{
				overflow: hidden;
				display: flex;
			}
			.hotel-data
			{
				width: 60%;
			}
			.data{
				width: 40%;
				& > * {
					display: unset;
					& > * {
						display: unset;
					}
				}
			}

			.star-container{
				margin-top: 8px;
			}

			h4, h3, .star-container{
				float: left;
				clear: both;
				width: 100%;
			}

			.history-price{
				height: 23px;
			}

			.price {
				text-align: left;

				>span {
					vertical-align: middle;
					float: right;
					clear: both;
				}


				span.btn {
					font-size: 14px;
					font-weight: normal;
					width: 100%;
					margin-top: 4px;
					display: block;
				}
			}
		}
	}

	&.small {
		margin: 15px 0 0;
		.offer {
			border-bottom: 1px solid $color19;
			.data {
				padding: 0 120px 0 0;
				@include cf;
				p {
					float: left;
				}
			}
		}
		.section-footer {
			padding: 10px 15px;
		}
	}

	.background-hover-5:hover {
		background-image: none;
	}
}

.negative-margin {
    margin: 0 -15px;
}

[data-widgetname="OfferListBoxWidget"][data-header="show"]{
	.offer-box {
		box-shadow: "$shadow-block";
		@include respond-to($screen-xs-max){
			box-shadow: none;
		}
		@include border-bottom-radius("$block-br");

		.offer {
			@include border-radius(0);
			box-shadow: none;
		}

		[class^="col-"] {
			&:first-child {
				.offer {
					border-bottom-left-radius: "$block-br";
					border-bottom-right-radius: 0;
					@include respond-to($screen-sm-max){
						@include border-bottom-radius(0);
					}
				}
			}

			&:last-child {
				.offer {
					border-bottom-right-radius: "$block-br";
					border-bottom-left-radius: 0;
					@include respond-to($screen-sm-max){
						@include border-bottom-radius(0);
					}
				}
			}
		}
	}
}

