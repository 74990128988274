.testimonials {
	.testimonials-list {
		height: 237px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.section-content {
		padding: 0;
		position: relative;
		overflow: hidden;
	}

	.selected-container {
		display: none;
	}

	.img-cont {
		.fa-user-circle{
			font-size: 4.4em;
		}
	}

	.selected {
		width: 100%;
		overflow: hidden;
		position: relative;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding: 20px 10px;
		@include respond-to($screen-sm-max) {
			overflow-y: auto;
			height: 100%;
		}

		&.narrow {
			.trip-photos .photos li {
				width: 50%;
			}
		}

		&.medium {
			.trip-photos .photos li {
				width: 25%;
			}
		}

		.selected-img {
			width: 150px;
			max-height: 200px;
			margin: 0 20px;
			img {
				margin: 20px auto;
			}
		}

		.selected-content {
			font-style: italic;
			line-height: 22px;
			font-size: 16px;
			p {
				padding: 0 15px;
			}
		}

		.testimonial-footer {
			margin: 0 15px 0 0;
		}
	}

	.selected-footer {
		margin-top: 15px;
		outline: none;
		padding: 7px 15px 8px;
		white-space: nowrap;
		overflow: hidden;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		h2 {
			margin: 0;
			font-size: 19px;
			line-height: 21px;
		}

		a {
			text-decoration: underline;
			margin-top: 2px;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.trip-photos {
		padding: 5px 10px 0;

		h4 {
			margin: 0;
			padding-left: 5px;
			font-size: 16px;
			font-weight: bold;
		}

		.photos {
			padding: 0;
			margin: 0;
			li {
				position: relative;
				list-style: none;
				float: left;
				cursor: pointer;
				width: 25%;
			}
			img {
				margin-top: 20px;
				padding: 0 5px;
				max-width: 100%;
			}
		}
	}

	.testimonial-item {
		margin: 20px 0;
		padding: 0 15px;
		white-space: normal;

		&:nth-child(3n+3) {
			margin-right: 0;
		}

		.testimonial-content {
			padding: 10px;
			position: relative;
			height: 120px;
			border: 1px solid $color4;

			@include respond-to($screen-xs-max) {
				height: auto;
				padding-bottom: 30px;
			}

			&::after {
				position: absolute;
				left: 47px;
				bottom: -15px;
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 15px 15px 0 15px;
				border-color: $color4 transparent transparent transparent;
			}

			p {
				margin: 0;
				text-align: center;
				font-style: italic;
			}

			.read-more {
				position: absolute;
				bottom: 10px;
				right: 10px;
				text-decoration: underline;

				span {
					padding-left: 3px;
				}

				&:hover {
					text-decoration: none;
				}

				.fa-chevron-right {
					line-height: 2em;
					font-size: 13px;
					left: 1px;
				}
			}
		}
	}

	&.qtip {
		overflow: visible;
		@include respond-to($screen-sm-max) {
			overflow: hidden;
		}
		& * {

			@include respond-from($screen-md-min) {
				overflow: visible;
			}
		}
		.img-cont {
			pointer-events: none;
			cursor: default;
		}
	}

	.testimonial-footer {
		margin-top: 17px;

		.img-cont {
			display: block;
			overflow: hidden;
			border-radius: 50%;
			width: 65px;
			height: 65px;
			margin: 0 15px 0 30px;
			float: left;
			cursor: pointer;
			img {
				height: 65px;
				width: 65px;
			}

			&:hover {
				opacity: .7;
			}
		}

		h3 {
			margin: 13px 0 0;
			font-size: 20px;
		}
	}
	.add-testimonial {
		margin-top: 2px;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}

	.add-testimonial-container {
		display: none;
		width: 100%;
		padding: 20px 15px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		@include respond-to($screen-sm-max) {
			overflow-y: scroll;
			height: 100%;
			padding-bottom: 45px;
		}

		&.narrow-container {
			[class*="col-"] {
				width: 100%;
				margin-left: 0;
			}

			.pr-15, .pl-15 {
				padding: 0 !important;
			}

			.button-send {
				margin-bottom: 10px;
			}
		}

		form {
			.row > div div[class^="col"] {
				padding: 0;
				&.pr-15 {
					padding-right: 15px;
					@include respond-to($screen-xs-max) {
						padding: 0;
					}
				}
				&.pl-15 {
					padding-left: 15px;
					@include respond-to($screen-xs-max) {
						padding: 0;
					}
				}
			}

			textarea {
				height: 103px;
			}
			.testimonial-spinner {
				display: none;
			}
		}

		.button-send {
			input {
				border-color: transparent !important;
			}
		}

		.checkbox-section {
			margin: 20px 0;

			.checkbox {
				display: block;
				margin: 0;
				&:first-child {
					padding: 6px 0 0;
					border-top: 1px solid $color19;
				}
				&:last-child {
					padding: 6px 0 6px;
					border-bottom: 1px solid $color19;
				}

				&.error {
					border: 1px solid;
					border-color: $color21 !important;
					background: $input-err-col-transp !important;
					padding-top: 3px;
					padding-bottom: 2px;
				}

				label {
					padding-left: 30px;
					line-height: 25px;
				}

				input[type=checkbox] {
					display: none;
				}

				input[type=checkbox] + label:before {
					font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
					color: #fff;
					margin-right: 7px;
					display: inline-block;
					content: unquote("\"#{ $fa-var-square }\"");
					font-weight: var(--#{$fa-css-prefix}-style);
					float: left;
					font-size: 23px;
					top: 5px;
					left: 2px;
					position: absolute;
					z-index: 1;
				}
				/* unchecked icon */

				input[type=checkbox] + label:after {
					font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
					color: $color19;
					display: inline-block;
					content: unquote("\"#{ $fa-var-square }\"");
					font-weight: var(--#{$fa-css-prefix}-style);
					position: absolute;
					left: 2px;
					font-size: 23px;
					top: 5px;
				}

				input[type=checkbox]:checked + label:before {
					content: unquote("\"#{ $fa-var-check }\"");
					font-weight: var(--#{$fa-css-prefix}-style);
					color: #fff;
					left: 6px;
					margin-right: 16px;
					font-size: 14px;
				}
				/* checked icon */

				input[type=checkbox]:checked + label:after {
					color: $color7;
				}
				/* checked icon */
			}
		}
		.testimonial-input {
			margin-bottom: 5px;
		}
		.image-container {
			cursor: pointer !important;
			position: relative;
			overflow: hidden;
			margin-bottom: 5px;

			.error {
				border: 1px solid;
				border-color: $color21 !important;
				background: $input-err-col-transp !important;
			}

			&.is-dragover {
				.file-container {
					border: 5px dashed $color19;
					padding: 16px;
				}
			}

			input {
				cursor: pointer;
				position: absolute;
				height: 150px;
				width: 100%;
				top: 0;
				right: 0;
				margin: 0;
				padding: 0;
				font-size: 20px;
				opacity: 0;
				filter: alpha(opacity=0);
			}
			.file-container {
				width: 100%;
				height: 150px;
				background: #fff;
				text-align: center;
				border: 1px solid $color19;
				padding: 20px;
				overflow: hidden;

				&.filled {
					padding: 5px;
					line-height: 135px;
				}

				.delete-image {
					position: absolute;
					right: 3px;
					top: 3px;
				}

				img {
					max-height: 140px;
				}

				span {
					display: block;
					text-align: center;
					text-decoration: underline;
					line-height: 16px;
				}

				.fa {
					display: block;
				}
			}
		}

		.button-cancel {
			span {
				width: 100%;
				border: 1px solid $color7;
				&:hover {
					border-color: transparent;
				}
			}

			input {
				border-color: $color7;
				&:hover {
					border-color: transparent;
				}
			}
		}

		.buttons {
			margin-top: 5px;
			input {
				border: 1px solid;
				width: 100%;
			}
		}
	}

	.testimonial-jcarousel {
		position: relative;
		overflow: hidden;
		width: 100%;

		ul {
			white-space: nowrap;
			position: relative;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				vertical-align: top;
			}
		}
	}

	.carousel-inner {
		padding: 15px 0;
	}

	.controls {
		&.blocked {
			opacity: .1;
			cursor: default;
			pointer-events: none;
		}

		a {
			display: block;
			opacity: 1;
			text-shadow: none;
			font-size: 18px;
			width: 27px;
			height: 27px;
			line-height: 24px;
			background-color: transparent;
			color: $color16;
			border: 3px solid $color16;
			border-radius: 50%;
			&:hover {
				color: $color18;
				border-color: $color18;
			}
		}
		.jcarousel-control-prev, .jcarousel-control-next {
			position: absolute;
			text-align: center;
			top: calc(50% - 13px);

		}
		.jcarousel-control-prev {
			left: 7px;
			padding-right: 2px;
		}
		.jcarousel-control-next {
			right: 7px;
			padding-left: 2px;
		}
	}

	&.qtip {
		height: auto;
		box-shadow: none;
		border: none;
		.qtip-content {
			background: transparent;
			max-height: 70vh;
			overflow: hidden auto;
			@include respond-to($screen-sm-max) {
				background: $color3;
			}
		}
	}
	&.add-test {
		.qtip-content {
			overflow-y: auto;
			padding-bottom: 0;
		}
	}

	.qtip-titlebar {
		@include border-radius("$block-br");
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.gallery-control a {
		display: block;
		opacity: 1;
		text-shadow: none;
		font-size: 18px;
		width: 40px;
		height: 40px;
		line-height: 36px;
		background-color: rgba(255, 255, 255, .5);
		color: $color16;
		border: 4px solid $color16;
		border-radius: 50%;
		text-align: center;
		margin-top: 40px;
		&:hover {
			color: $color18;
			border-color: $color18;
		}
	}

	.gallery-prev {
		position: absolute;
		left: -80px;
		padding-right: 3px;
	}

	.gallery-next {
		padding-left: 3px;
		position: absolute;
		right: -80px;
	}
}

