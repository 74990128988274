.officeslider-box {

	position: relative;
	margin: 0 0 15px;

	.section-content {
		padding: 0;

		@include respond-to($screen-sm-max) {
			padding: 0;
		}
		@include respond-to($screen-xs-max) {
			padding: 0 15px;
		}
	}

	.carousel-control {
		opacity: 1;
	}

	.mobile-control {
		text-shadow: 1px 1px 2px rgba(150, 150, 150, 0.8);
	}

	.desktop-control {
		position: absolute;
		top: 0;
		right: 0;
		.carousel-control {
			position: initial;
			@include text-shadow(none);
			width: 45px;
			height: 45px;
			font-size: 22px;
			line-height: 45px;
			margin: -22px 11px 0 11px;
			margin: 0;
			text-align: center;
			display: block;
			.fa {
				font-weight: bold;
			}
		}
	}

	@include respond-to($screen-xs-max) {
		margin: 0;
	}

	.office-list-wrap {
		position: relative;
	}

	a.inner {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}

	// carousel
	.office-list {
		position: relative;
		overflow: hidden;
	  	margin:15px -7.5px;

		ol {
			white-space: nowrap;
			position: relative;
			padding-left: 0;
			list-style: none;

			li {
				display: inline-block;
				margin: 0 1px 0 0;
				width: 540px;
				padding: 0 7.5px;
				white-space: nowrap;
				overflow: hidden;
				position: relative;
			}
		}

		.img-container {
			margin: 0px 0 15px;
			overflow: hidden;

			img {
				width: 100%;
				height: auto;
			}
		}

		.text-container {
			h3 {
				font-size: 16px;
				line-height: 22px;
				margin: 0;
				font-weight: 400;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

	} // office list


}
