.comapreType {

    .compare-box {

        .section-content {
            padding: 0 50px;
            position: relative;
        }
        .section-footer {
            border-top: 1px solid $color19;
            padding: 15px;

            a.close {
                top: 0;
                padding: 0;
            }
        }
        a.left.carousel-control {
            display: none;
        }

    }

    .compare-list {
        position: relative;
        overflow: hidden;
        > ol {
            position: relative;
            list-style: none;
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }
        .compare-item {
            display: inline-block;
            width: 33%;
            overflow: hidden;
            @include box-sizing(border-box);
            padding: 15px;
            text-align: center;

            .name-container {
                clear: both;
            }
            &:last-child {
                // border-right: none;
            }
            .rating-details {
                display: none;
            }
            .img-container {
                margin: 0 auto;
                img {
                    width: 100%;
                }
                width: auto;
                height: 200px;
                overflow: hidden;
            }
            .imgs-container {
                margin-bottom: 20px;
                float: left;
                width: 100%;
                height: auto;
                position: relative;
                img {
                    width: 49%;
                    &.offer1image {
                        float: left;
                    }
                    &.offer2image {
                        float: right;
                    }
                }
                .fa-stack {
                    font-size: 50px;
                    left: 50%;
                    margin-left: -12px;
                    position: absolute;
                    top: 50%;
                    margin-top: -14px;
                    .fa-circle {
                        font-size: 36px;
                        left: -4px;
                        top: -4px;
                        position: absolute;;
                    }
                    .fa-plus-circle {
                        position: absolute;
                        top: 0;
                        left: -1px;
                        font-size: 28px;
                    }
                }

            }
            h3 {
                font-size: 20px;
                line-height: 24px;
                color: $color1;
                margin: 9px 0 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            h4 {
                font-size: 14px;
                line-height: 16px;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .show-on-map {
                text-decoration: underline;
                font-size: 14px;
                line-height: 16px;
            }
            p {
                margin: 0;
                font-size: 14px;
                line-height: 14px;
                color: inherit;
            }
            .lighter {
                color: $color10;
            }
            .elem-rating {
                float: none;
                display: inline-block;
                @include respond-to($screen-sm-max) {
                    margin: 0;
                }
            }
            .price-label {
                font-size: 14px;
                line-height: 30px;
                color: inherit;
                white-space: nowrap;
                @include respond-to($screen-xs-max) {
                    display: block;
                }
            }
            .price-value {
                font-size: 34px;
                line-height: 30px;
                color: inherit;
                text-transform: uppercase;
                white-space: nowrap;
                position: relative;
                .totalPrice {
                    font-size: 14px;
                    position: absolute;
                    top: 32px;
                    right: 1px;
                }
                @include respond-to($screen-xs-max) {
                    display: block;
                }
            }
            .prices {
                padding-bottom: 15px;
                .price-value {
                    padding-bottom: 10px;
                    display: inline-block;
                }
            }
        }
        .compare-row {
            padding: 10px 5px;
            &:not(.facilities-content)
            {
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border: none;
                }
            }
            .facilities {
                li {
                    display: inline-block;
                    margin-right: 2px;
                    span {
                        position: static;
                    }
                }
            }
        }

        .omnibus {
            position: relative;
            width: 100%;
            clear: both;
            display: block;
            right: 0;
            font-size: 0.8em;
        }
    }

    .btn-compare-nav {
        display: block;
        position: absolute;
        top: 50%;
        bottom: 50%;
        width: 34px;
        margin: -36px 0 -36px;
        text-align: center;
        font-size: 22px;
        line-height: 0;
        padding: 25px 0;
        font-weight: 800;

        &.inactive {
            @include opacity(.8);
        }
        &.btn-compare-nav-prev {
            left: 8px;
        }
        &.btn-compare-nav-next {
            right: 8px;
        }
    }

}
