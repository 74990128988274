.chart-container {
  position: relative;
  --orientation: landscape;

  @media (max-width: $screen-sm-max) {
    height: 95vh;
    --orientation: portrait;
  }

  width: 100%;
  height: 260px;
  display: flex;
  align-items: flex-end;

  .chart-nav-button-prev, .chart-nav-button-next {
    height: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    @include border-radius("$btn-br");
    @media (max-width: $screen-sm-max) {
      left: 10px;
    }

    .btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      padding: 12px;
      width: 28px;

      .fa {
        font-weight: bold;
        margin-right: 3px; // compensation for bad glyph positioning
        @media (max-width: $screen-sm-max) {
          transform: rotate(90deg);
        }
      }

      @media (max-width: $screen-sm-max) {
        width: 38px;
      }
    }
  }

  .chart-nav-button-prev {
    order: 0;
    @media (max-width: $screen-sm-max) {
      position: absolute;
      top: 0;
      height: 28px;
      z-index: 1;
    }
  }

  .chart-nav-button-next {
    order: 2;
    @media (max-width: $screen-sm-max) {
      position: absolute;
      bottom: 0;
      height: 28px;
      z-index: 1;
    }
  }

  .chart-bars-container {
    height: 100%;
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    margin: 0 10px;
  }

  .chart-page-container {
    .chart-page-header, .chart-page-info {
      width: 100%;
      text-align: center;
      padding: 5px;
      flex-basis: 100%;
      flex-shrink: 0;
      height: 14%;
      white-space: nowrap;
      order: 0;
      overflow: hidden;
      display: flex;
      &.full-month {
        .first-month
        {
          padding: 0;
        }
        .second-month{
          margin: 0;
          flex-basis: 0;
        }
      }
      .first-month, .second-month {
        display: block;
        margin: 0;
        float: left;
        overflow: hidden;
        height: 100%;
        text-align: center;

        span{
          width: 100%;
          border-bottom: 1px solid black;
          display: block;
          @media (max-width: $screen-sm-max) {
            border-bottom: none;
            border-left: 1px solid black;
          }
        }
      }
      .first-month {
        width: 50%;
        padding: 0 10px 0 0;
        margin: 0 -5px 0 0;
        transition: 1s ease-in-out;
        @media (min-width: $screen-md-min) {
          height: 100% !important;
          transition-property: width, margin, padding;
        }
        @media (max-width: $screen-sm-max) {
          width: 94% !important;
          transition-property: height, margin, padding;
          padding: 0 0 5px 0;
          margin: 0 0 0 0;
        }
      }

      .second-month {
        flex-grow: 1;
        height: auto;
        padding: 0 0 0 5px;
        @media (max-width: $screen-sm-max) {
          width: 94% !important;
          padding: 5px 0 0 0;
          margin: 0 0 0 0;
        }
      }

      @media (max-width: $screen-sm-max) {
        width: 40px;
        flex-basis: auto;
        height: 100%;
        font-weight: bold;
        writing-mode: tb;
        transform: rotate(-180deg);
        padding: 0 ;
      }
    }

    .chart-page-info {
      font-weight: normal;
      order: 1;
    }

    .chart-page-body {
      width: 100%;
      display: flex;
      flex-shrink: 1;
      height: 86%;
      order: 2;
      @media (max-width: $screen-sm-max) {
        width: calc(100% - 30px); /* subtract header width*/
        flex-basis: auto;
        height: 100%;
        writing-mode: tb;
        overflow: auto;
      }
    }

    width: 100%;
    position: static;
    transition: transform 1s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    @media (max-width: $screen-sm-max) {
      flex-direction: row;
      flex-flow: row;
      align-items: stretch;
      flex-shrink: 1;
      flex-wrap: nowrap;
    }

    &.chart-no-data{
      .chart-page-body{
        height: 73%;
      }
    }


  }

  .chart-column {
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    text-decoration: none;
    flex-shrink: 0;
    will-change: transform;
    transition:  transform 100ms ease-in-out;
    overflow: hidden;
    transition-delay: 50ms;
    .chart-date{
      transition: transform 150ms ease-in-out;
      transition-delay: 150ms;
    }
    &.chart-bar-rotate {
      transform: rotateY(90deg);
      .chart-date {
        transform: translateY(120%);
        transition-delay: 0s;
      }

    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 20px;
    }
    @media (max-width: $screen-sm-max) {
      padding: 4px 0;
      height: 28px;
      box-sizing: content-box;
    }
    @media (min-width: $screen-md-min) {
      //fix problem with animation on flex items in some browsers
      width: 0;
    }

    &.empty-price{
      .price-bar {
        padding: 0 0 1px 0;
        @media (max-width: $screen-sm-max) {
          padding: 0 1px 0 0;
        }
      }
    }
  }

  .chart-column:hover, .chart-column:visited, .chart-column:active {
    text-decoration: none;
  }

  .price-bar {
    margin: 0 5px 4px 5px;
    padding: 5px 0;
    text-align: center;
    width: 50%;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    min-width: 20px;
    position: relative;
    @media (min-width: $screen-md-min) {
      width: auto !important;
      height: calc(50% - 48px);
      transition-property: height;
    }
    @media (max-width: $screen-sm-max) {
      height: 100% !important;
      width: 50%;
      min-width: 0;
      transition-property: width;
    }

    span {
      font-size: 1.2em;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      @media (max-width: $screen-sm-max) {
        writing-mode: lr;
        transform: unset;
        width: 100%;
        text-align: left;
        padding: 0 10px;
        position: relative;
      }
    }
  }

  .chart-date {
    height: 48px;
    padding: 4px 4px;
    text-align: center;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      transform: rotate(-90deg);
      top: 20px;
      display: block;
    }
    @media (max-width: $screen-sm-max) {
      writing-mode: lr;
      transform: unset;
      min-width: 60px;
      height: auto;
      min-height: 30px;
    }
  }

  &.hide-empty-price {
    @media (max-width: $screen-sm-max) {
      max-height: 95vh;
      height: auto;

      .chart-bars-container {
        max-height: 100vh;

        .hide-empty-price-page{
          .empty-price {
            height: 0 !important;
            padding: 0;
            overflow: hidden;
            transition-property: height, padding;
            transition-duration: 1s;
            transition-timing-function: ease-in-out;
            flex-grow: 0;

            .price-bar {
              height: 0 !important;
            }
          }
        }

        .chart-page-header, .chart-page-info {
          height: auto;
        }

        .chart-page-body, .chart-no-data .chart-page-body{
          max-height: 95vh;
          height: auto;
          min-height:30vh;
        }
      }
    }
  }
}
