$experimental-support-for-pie: true;

// It is recommended that you use Sass's @extend directive to apply the behavior
// to your PIE elements. To assist you, Compass provides this variable.
// When set, it will cause the `@include pie` mixin to extend this class.
// The class name you provide should **not** include the `.`.
$pie-base-class: false !default;

// The default approach to using PIE.
// Can be one of:
//
// * relative (default)
// * z-index
// * none
$pie-default-approach: relative !default;

// The location of your PIE behavior file
// This should be root-relative to your web server
// relative assets don't work. It is recommended that
// you set this yourself.
$pie-behavior: stylesheet-url("PIE.htc") !default;

// When using the z-index approach, the
// first ancestor of the PIE element at
// or before the container's opaque background
// should have a z-index set as well to ensure
// propert z-index stacking.
//
// The `$position` argument must be some non-static
// value (absolute, relative, etc.)
@mixin pie-container($z-index: 0, $position: relative) {
  z-index: $z-index;
  position: $position;
}

// PIE elements must have this behavior attached to them.
// IE is broken -- it doesn't think of behavior urls as
// relative to the stylesheet. It considers them relative
// to the webpage. As a result, you cannot reliably use
// compass's relative_assets with PIE.
// 
// * `$approach` - one of: relative, z-index, or none
// * `$z-index` - when using the z-index approach, this
//                is the z-index that is applied.
@mixin pie-element(
  $approach: $pie-default-approach,
  $z-index: 0
) {
  behavior: $pie-behavior;
  @if $approach == relative {
    position: relative;
  }
  @else if $approach == z-index {
    z-index: $z-index;
  }
}

// a smart mixin that knows to extend or include pie-element according
// to your stylesheet's configuration variables.
@mixin pie($base-class: $pie-base-class) {
  @if $base-class {
    @extend .#{$base-class};
  }
  @else {
    @include pie-element;
  }
}

// Watch `$n` levels of ancestors for changes to their class attribute
// So that cascading styles will work correctly on the PIE element.
@mixin pie-watch-ancestors($n) {
  -pie-watch-ancestors: $n;
}