
.visa-container {
  display: flex;
  flex-shrink: 0;
  gap: 1px;

  .visa-section {
    flex-basis: 0;
    text-align: center;
    flex-grow: 1;
    padding-bottom: 10px;

    span {
      display: inline-block;
      padding: 0 10px;

      &:first-child {
        display: block;
        font-weight: bold;
        padding: 10px 10px;
      }

      &:nth-child(2) {
        padding-top: 10px;

        &.single-line {
          padding-top: 20px;
        }
      }
    }
  }

  &.stamp {
    text-align: right;
    display: block;
    span {
      font-size: 0.7em;
      font-style: italic;
    }
  }
}

.visa-message {
  padding: 5px;

  &:first-child {
    padding-top: 15px;
  }

  span:first-child {
    font-weight: bold;
  }
}