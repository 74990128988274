.imagelinks-box {

	&.low {
		height: 102px;
		.box {
			height: 102px;
		}
		.section-header + .section-content {
			.box {
				height: calc(102px - #{$widget-header-height});
				@include respond-to($screen-xs-max) {
					height: initial!important;
				}
			}
		}
	}

	&.medium {
		height: 285px;
		.box {
			height: 285px;
		}
		.section-header + .section-content {
			.box {
				height: calc(285px - #{$widget-header-height});
				@include respond-to($screen-xs-max) {
					height: initial!important;
				}
			}
		}
	}

	&.high {
		height: 408px;
		.box {
			height: 408px;
		}
		.section-header + .section-content {
			.box {
				height: calc(408px - #{$widget-header-height});
				@include respond-to($screen-xs-max) {
					height: initial!important;
				}
			}
		}
	}
	@include respond-to($screen-xs-max) {
		height: auto!important;
	}

	.section-content {
		margin-left: -15px;
		margin-right: -15px;

		@include respond-to($screen-xs-max) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.box-col {
		//float: left;
		//width: 33.3%;
		padding-left: 15px;
		padding-right: 15px;

		@include respond-to($screen-sm-max) {
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	.box {
		position: relative;
		overflow: hidden;

		@include respond-to($screen-xs-max) {
			min-height: 0px!important;
		}

		a.inner {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 10;
		}

		.img-container-wrap {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			z-index: 8;

			.img-container {
				position: absolute;
				background-repeat: no-repeat;
				background-size: cover;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				@include transition(all .5s ease);

				&.scale-x-1 {
					background-position: 0% 0%;
				}

				&.scale-x-2 {
					background-position: 50% 0%;
				}

				&.scale-x-3 {
					background-position: 100% 0%;
				}
			}
		}

		.img-mobile-container {
			margin: 0;
			overflow: hidden;
			img {
				@include transition(all .5s ease);
			}
		}

		.text-container {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			max-height: 100%;
			overflow: hidden;
			z-index: 9;
			padding: 0 10px;
			@include translateY(-50%);
			@include text-shadow(0 1px 2px rgba(0,0,0,0.6));
			text-align: center;
		}

		h2 {
			font-size: 34px;
			line-height: 40px;
			margin: 0;
			padding: 0;
			text-transform: uppercase;
			font-weight: 300;
			color: inherit;
			letter-spacing: 1px;

			@include respond-to($screen-xs-max) {
				font-size: 25px;
				line-height: 32px;
			}
		}

		p {
			font-size: 14px;
			line-height: 20px;
			margin: 5px 0;
			padding: 0;
			color: inherit;
		}

		.fa-stack {
			
			.fa-angle-right {
				left: -1px;
				font-size: 1.4em;
				font-weight: bold;
			}
		}

		&:hover {

			.img-container.with-zoom {
				@include scale3d(1.1, 1.1, 1.1);
			}
			.img-mobile-container.with-zoom {
				img {
					@include scale3d(1.1, 1.1, 1.1);
				}
			}
		}
	}
}

[data-header="hide"] {
	.imagelinks-box {
		&.low {
			height: calc(102px - #{$widget-header-height});
			.box {
				height: calc(102px - #{$widget-header-height});
			}
		}

		&.medium {
			height: calc(285px - #{$widget-header-height});
			.box {
				height: calc(285px - #{$widget-header-height});
			}
		}

		&.high {
			height: calc(408px - #{$widget-header-height});
			.box {
				height: calc(408px - #{$widget-header-height});
			}
		}
	}
}