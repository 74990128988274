.link-share-options-container {
  display: none;
}

.qtip-link-share {
  max-width: unset;
  background-color: white;
}
.qtip-link-share .qtip-content {
  background-color: "$bgcolor5";
  padding: 0;
}

.link-share-wrapper{
  background: "$bgcolor8";
  @media (max-width: $screen-sm-max) {
    margin-top: 46px;
    height: 100%;
  }
}

.link-share-options {
  padding: 10px;
  z-index: 2;
  display: flex;
  visibility: visible;
  flex-wrap: wrap;
  gap: 1px;
  color: $color9;
  flex-direction: column;
  @media (max-width: $screen-sm-max) {
    flex-direction: row;
    padding: 0 0 1px 0;
    justify-content: center;
    width: auto;
    background: "$bgcolor1";
  }

  .fa-spinner {
    text-align: center;
  }

  a {
    padding: 5px 10px;
    font-size: medium;
    flex-basis: 100%;
    flex-grow: 1;
    background: transparent;
    white-space: nowrap;
    text-decoration: none;
    background: "$bgcolor8";

    &:hover {
      background: "$bgcolor1";
      color: $color12 !important;

      .fa, span {
        color: $color12 !important;
      }
    }

    @media (max-width: $screen-sm-max) {
      flex-basis: 24%;
      margin: 0;
      text-align: center;
      padding: 2px 10px 10px 10px;
      white-space: normal;
      //background: "$bgcolor5" linear-gradient(0, "$bgcolor8", "$bgcolor8");

    }
    @media (max-width: $screen-xs-max) {
      flex-basis: 33%;
    }

    .fa {

      vertical-align: middle;
      font-size: 2em;
      margin-right: 5px;
      width: 30px;
      @media (max-width: $screen-sm-max) {
        display: block;
        width: 100%;
        margin: 10px 0 10px 0;
      }
    }
  }
}
