.reservation-path-container-lay1 {
  width: 100%;
  margin: 20px 0 0;
  height: 55px;

  .mobile-title{
    text-align: center;
    font-size: 20px;
  }
  ul {
    padding: 0;
    bottom: 0;
    height: 55px;

    li {
      position: relative;
      float: left;
      list-style: none;
      height: 55px;
      white-space: nowrap;
      &:first-child .text-container{
        text-align: left;
        padding-left: 20px;
      }
    }

    a{
      display: block;
      position: relative;
      &.current, &.future{
        pointer-events: none;
        cursor: default;
        i{
          display: none;
        }
      }
      &.past{
        .text-container{
          padding-right: 45px;
        }
      }
      &.future{
        opacity: .6;
      }
      &.past:hover{
        text-decoration: none;
        opacity: .6;
      }
      &.disabled{
        opacity: .2;
        .fa-check{
          display: none;
        }
        &:hover{
          cursor: not-allowed;
          text-decoration: none;
        }
      }
    }

    .text-container{
      //border-bottom: 7px solid $color1;
      margin: 0 1px;
      text-align: center;
      font-size: 18px;
      padding-bottom: 5px;
      position: relative;

      .border-bg{
        content: '';
        width: 100%;
        height: 7px;
        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;
      }

      .pos-r{
        position: relative;
      }

      .overflow-hidden{
        overflow: hidden;
      }
      .step-text{
        white-space: nowrap;
      }

      i{
        color: $color20;
        font-size: 38px;
        position: absolute;
        top: -8px;
        left: 100%;
        margin-left: 5px;
      }
    }

    .path-title{
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }
}


.reservation-path-container-lay2{
  width:100%;
  margin: 20px 0 0;

  .mobile-title{
    text-align: center;
    font-size: 20px;
  }

  ul{
    padding: 0;
    overflow: hidden;

    li{
      position: relative;
      float: left;
      height: 55px;
      list-style: none;

      &:first-child .path-text-container{
        padding-left: 5px;
      }

      a{
        position: relative;
        &.current{
           pointer-events: none;
           cursor: default;
         }
        &.gray, &.disabled{
          pointer-events: none;
          cursor: not-allowed;
        }
        &.disabled{
          &:hover{
            .path-text-container{
              background-color: $color5;
            }
          }
          .path-text-container{
            //opacity: .2;
            background-color: $color5;

            .path-text-center{
              opacity: .2;
              color: $color1;
            }
          }
        }
      }
      a:not(.gray):not(.current):not(.disabled):hover{
        .path-text-container{
          color: $color12;
          background: $color18!important;
          &::after{
            border-left-color: $color18!important;
          }
        }
        .arrow-bg{
          display: none;
        }
      }

      .path-text-center{
        margin: 0 auto;
        display: table;
        .overflow-hidden{
          overflow: hidden;
        }
      }
      .path-nr{
        float: left;
        font-size: 40px;
        font-weight: bold;
        line-height: 55px;
      }

      .path-text{
        float: left;
        font-size: 14px;
        margin-top: 10px;
        margin-left: 5px;

        span{
          display: block;
        }
      }

      .path-text-container{
        display: block;
        position: relative;
        height:100%;
        padding-left: 35px;
        text-decoration: none;

        &::before{
          content: " ";
          display: block;
          width: 0;
          height: 0;
          border-top: 50px solid transparent;
          border-bottom: 50px solid transparent;
          border-left: 30px solid $color5;
          position: absolute;
          top: 50%;
          margin-top: -50px;
          margin-left: 5px;
          left: 100%;
          z-index: 1;
        }

        &::after{
          content: " ";
          display: block;
          width: 0;
          height: 0;
          border-top: 50px solid transparent;
          border-bottom: 50px solid transparent;
          border-left: 30px solid;
          position: absolute;
          top: 50%;
          margin-top: -50px;
          left: 100%;
          z-index: 2;
        }
      }
      .bg-container{
        position: relative;
        top: -55px;
        width: 100%;
        display: block;
      }
      .arrow-bg{
        display: block;
        position: relative;
        height:55px;
        padding-left: 50px;
        text-decoration: none;

        &::before{
          content: " ";
          display: block;
          width: 0;
          height: 0;
          border-top: 50px solid transparent;
          border-bottom: 50px solid transparent;
          border-left: 30px solid white; /*kolor tła strony bgc-5 */
          position: absolute;
          top: 50%;
          margin-top: -50px;
          margin-left: 5px;
          left: 100%;
          z-index: 1;
        }

        &::after{
          content: " ";
          display: block;
          width: 0;
          height: 0;
          border-top: 50px solid transparent;           /* Go big on the size, and let overflow hide */
          border-bottom: 50px solid transparent;
          border-left: 30px solid;
          position: absolute;
          top: 50%;
          margin-top: -50px;
          left: 100%;
          z-index: 2;
        }
      }
      //colors
      .colored .path-text-container       { background:        $color3; }
      .colored .path-text-container::after { border-left-color: $color3; }
      .colored .arrow-bg::after { border-left-color: $color3; }
      .gray .path-text-container       { background:        #bbb; }
      .gray .path-text-container::after { border-left-color: #bbb; }
      .gray .arrow-bg::after { border-left-color: #bbb; }
    }
  }
}


