// Creating all extended grid classes for defined number of columns
@each $num-columns in $possible-extended-grid-columns-number {

  // Columns

  @include make-extended-grid-columns($num-columns);

  // Extra small grid

  @include make-extended-grid(xs, $num-columns);

  // Small grid

  @media (min-width: $screen-sm-min) {
    @include make-extended-grid(sm, $num-columns);
  }

  // Medium grid

  @media (min-width: $screen-md-min) {
    @include make-extended-grid(md, $num-columns);
  }

  // Large grid

  @media (min-width: $screen-lg-min) {
    @include make-extended-grid(lg, $num-columns);
  }

}