#externals {
	.car-thumb {
		width: 90px;
		border: 10px solid #ffffff;
		overflow: hidden;
	}
	.external-cars-date-input {
		padding-left: 114px;
		@include respond-to($screen-xs-max) {
			padding-left: 4px;
		}
	}
}