@import "merlin-variable";

@font-face {
  font-family: '#{$mxicon-font-family}';
  src:
          url('#{$mxicon-font-path}/#{$mxicon-font-family}.ttf?fbn68g') format('truetype'),
          url('#{$mxicon-font-path}/#{$mxicon-font-family}.woff?fbn68g') format('woff'),
          url('#{$mxicon-font-path}/#{$mxicon-font-family}.svg?fbn68g##{$mxicon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.mxicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$mxicon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: var(--#{$fa-css-prefix}-style);
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mxicon-ac {
  &:before {
    content: $mxicon-ac;
  }
}
.mxicon-activity {
  &:before {
    content: $mxicon-activity;
  }
}
.mxicon-airport-group {
  &:before {
    content: $mxicon-airport-group;
  }
}
.mxicon-attr-seniors2 {
  &:before {
    content: $mxicon-attr-seniors2;
  }
}
.mxicon-attr_ac {
  &:before {
    content: $mxicon-attr_ac;
  }
}
.mxicon-attr_adult {
  &:before {
    content: $mxicon-attr_adult;
  }
}
.mxicon-attr_agdrtv {
  &:before {
    content: $mxicon-attr_agdrtv;
  }
}
.mxicon-attr_airport {
  &:before {
    content: $mxicon-attr_airport;
  }
}
.mxicon-attr_animation {
  &:before {
    content: $mxicon-attr_animation;
  }
}
.mxicon-attr_beach {
  &:before {
    content: $mxicon-attr_beach;
  }
}
.mxicon-attr_beachvolleyball {
  &:before {
    content: $mxicon-attr_beachvolleyball;
  }
}
.mxicon-attr_bikes {
  &:before {
    content: $mxicon-attr_bikes;
  }
}
.mxicon-attr_businesshotel {
  &:before {
    content: $mxicon-attr_businesshotel;
  }
}
.mxicon-attr_citybreak {
  &:before {
    content: $mxicon-attr_citybreak;
  }
}
.mxicon-attr_club {
  &:before {
    content: $mxicon-attr_club;
  }
}
.mxicon-attr_disco {
  &:before {
    content: $mxicon-attr_disco;
  }
}
.mxicon-attr_diving {
  &:before {
    content: $mxicon-attr_diving;
  }
}
.mxicon-attr_exotic {
  &:before {
    content: $mxicon-attr_exotic;
  }
}
.mxicon-attr_fireplace {
  &:before {
    content: $mxicon-attr_fireplace;
  }
}
.mxicon-attr_fitness {
  &:before {
    content: $mxicon-attr_fitness;
  }
}
.mxicon-attr_gay {
  &:before {
    content: $mxicon-attr_gay;
  }
}
.mxicon-attr_golf {
  &:before {
    content: $mxicon-attr_golf;
  }
}
.mxicon-attr_grill-bbq {
  &:before {
    content: $mxicon-attr_grill-bbq;
  }
}
.mxicon-attr_handicaped {
  &:before {
    content: $mxicon-attr_handicaped;
  }
}
.mxicon-attr_horse {
  &:before {
    content: $mxicon-attr_horse;
  }
}
.mxicon-attr_insidepool {
  &:before {
    content: $mxicon-attr_insidepool;
  }
}
.mxicon-attr_internet {
  &:before {
    content: $mxicon-attr_internet;
  }
}
.mxicon-attr_kids {
  &:before {
    content: $mxicon-attr_kids;
  }
}
.mxicon-attr_kindergarten {
  &:before {
    content: $mxicon-attr_kindergarten;
  }
}
.mxicon-attr_lakes {
  &:before {
    content: $mxicon-attr_lakes;
  }
}
.mxicon-attr_massage {
  &:before {
    content: $mxicon-attr_massage;
  }
}
.mxicon-attr_minigolf {
  &:before {
    content: $mxicon-attr_minigolf;
  }
}
.mxicon-attr_nearcenter {
  &:before {
    content: $mxicon-attr_nearcenter;
  }
}
.mxicon-attr_nearslopes {
  &:before {
    content: $mxicon-attr_nearslopes;
  }
}
.mxicon-attr_nightclub {
  &:before {
    content: $mxicon-attr_nightclub;
  }
}
.mxicon-attr_outsidepool {
  &:before {
    content: $mxicon-attr_outsidepool;
  }
}
.mxicon-attr_parking {
  &:before {
    content: $mxicon-attr_parking;
  }
}
.mxicon-attr_passport {
  &:before {
    content: $mxicon-attr_passport;
  }
}
.mxicon-attr_pets {
  &:before {
    content: $mxicon-attr_pets;
  }
}
.mxicon-attr_pilgrimage {
  &:before {
    content: $mxicon-attr_pilgrimage;
  }
}
.mxicon-attr_poolprivat {
  &:before {
    content: $mxicon-attr_poolprivat;
  }
}
.mxicon-attr_quiet_location {
  &:before {
    content: $mxicon-attr_quiet_location;
  }
}
.mxicon-attr_sailing {
  &:before {
    content: $mxicon-attr_sailing;
  }
}
.mxicon-attr_sandy_beach {
  &:before {
    content: $mxicon-attr_sandy_beach;
  }
}
.mxicon-attr_sauna {
  &:before {
    content: $mxicon-attr_sauna;
  }
}
.mxicon-attr_seaside {
  &:before {
    content: $mxicon-attr_seaside;
  }
}
.mxicon-attr_seniors {
  &:before {
    content: $mxicon-attr_seniors;
  }
}
.mxicon-attr_ski {
  &:before {
    content: $mxicon-attr_ski;
  }
}
.mxicon-attr_skiregion {
  &:before {
    content: $mxicon-attr_skiregion;
  }
}
.mxicon-attr_skirent {
  &:before {
    content: $mxicon-attr_skirent;
  }
}
.mxicon-attr_spa {
  &:before {
    content: $mxicon-attr_spa;
  }
}
.mxicon-attr_sports {
  &:before {
    content: $mxicon-attr_sports;
  }
}
.mxicon-attr_squash {
  &:before {
    content: $mxicon-attr_squash;
  }
}
.mxicon-attr_surfing {
  &:before {
    content: $mxicon-attr_surfing;
  }
}
.mxicon-attr_table_tennis {
  &:before {
    content: $mxicon-attr_table_tennis;
  }
}
.mxicon-attr_tennis {
  &:before {
    content: $mxicon-attr_tennis;
  }
}
.mxicon-attr_walking {
  &:before {
    content: $mxicon-attr_walking;
  }
}
.mxicon-attr_waterski {
  &:before {
    content: $mxicon-attr_waterski;
  }
}
.mxicon-attr_waterslides {
  &:before {
    content: $mxicon-attr_waterslides;
  }
}
.mxicon-attr_watersports {
  &:before {
    content: $mxicon-attr_watersports;
  }
}
.mxicon-attr_wintersport {
  &:before {
    content: $mxicon-attr_wintersport;
  }
}
.mxicon-bagaz {
  &:before {
    content: $mxicon-bagaz;
  }
}
.mxicon-bagaz-circle {
  &:before {
    content: $mxicon-bagaz-circle;
  }
}
.mxicon-bus {
  &:before {
    content: $mxicon-bus;
  }
}
.mxicon-bus3 {
  &:before {
    content: $mxicon-bus3;
  }
}
.mxicon-campattr_art {
  &:before {
    content: $mxicon-campattr_art;
  }
}
.mxicon-campattr_bio {
  &:before {
    content: $mxicon-campattr_bio;
  }
}
.mxicon-campattr_comp {
  &:before {
    content: $mxicon-campattr_comp;
  }
}
.mxicon-campattr_game {
  &:before {
    content: $mxicon-campattr_game;
  }
}
.mxicon-campattr_health {
  &:before {
    content: $mxicon-campattr_health;
  }
}
.mxicon-campattr_lang {
  &:before {
    content: $mxicon-campattr_lang;
  }
}
.mxicon-campattr_media {
  &:before {
    content: $mxicon-campattr_media;
  }
}
.mxicon-campattr_misc {
  &:before {
    content: $mxicon-campattr_misc;
  }
}
.mxicon-campattr_music {
  &:before {
    content: $mxicon-campattr_music;
  }
}
.mxicon-campattr_theme {
  &:before {
    content: $mxicon-campattr_theme;
  }
}
.mxicon-car-door {
  &:before {
    content: $mxicon-car-door;
  }
}
.mxicon-charter {
  &:before {
    content: $mxicon-charter;
  }
}
.mxicon-columns {
  &:before {
    content: $mxicon-columns;
  }
}
.mxicon-cruise {
  &:before {
    content: $mxicon-cruise;
  }
}
.mxicon-cruise-circle {
  &:before {
    content: $mxicon-cruise-circle;
  }
}
.mxicon-fly0 {
  &:before {
    content: $mxicon-fly0;
  }
}
.mxicon-fly1 {
  &:before {
    content: $mxicon-fly1;
  }
}
.mxicon-fly2 {
  &:before {
    content: $mxicon-fly2;
  }
}
.mxicon-holidaycheck {
  &:before {
    content: $mxicon-holidaycheck;
  }
}
.mxicon-insurance {
  &:before {
    content: $mxicon-insurance;
  }
}
.mxicon-layout-tab-navigation {
  &:before {
    content: $mxicon-layout-tab-navigation;
  }
}
.mxicon-layout-tab-offerbox {
  &:before {
    content: $mxicon-layout-tab-offerbox;
  }
}
.mxicon-layout-tab-search {
  &:before {
    content: $mxicon-layout-tab-search;
  }
}
.mxicon-merlin-activity {
  &:before {
    content: $mxicon-merlin-activity;
  }
}
.mxicon-merlin-activity-circle {
  &:before {
    content: $mxicon-merlin-activity-circle;
  }
}
.mxicon-merlin-attractions {
  &:before {
    content: $mxicon-merlin-attractions;
  }
}
.mxicon-merlin-attractions-circle {
  &:before {
    content: $mxicon-merlin-attractions-circle;
  }
}
.mxicon-merlin-hotel {
  &:before {
    content: $mxicon-merlin-hotel;
  }
}
.mxicon-merlin-hotel-circle {
  &:before {
    content: $mxicon-merlin-hotel-circle;
  }
}
.mxicon-merlin-meeting {
  &:before {
    content: $mxicon-merlin-meeting;
  }
}
.mxicon-merlin-meeting-circle {
  &:before {
    content: $mxicon-merlin-meeting-circle;
  }
}
.mxicon-merlin-plane {
  &:before {
    content: $mxicon-merlin-plane;
  }
}
.mxicon-merlin-restaurant {
  &:before {
    content: $mxicon-merlin-restaurant;
  }
}
.mxicon-merlin-restaurant-circle {
  &:before {
    content: $mxicon-merlin-restaurant-circle;
  }
}
.mxicon-merlin-train {
  &:before {
    content: $mxicon-merlin-train;
  }
}
.mxicon-merlin-transfer {
  &:before {
    content: $mxicon-merlin-transfer;
  }
}
.mxicon-merlinx-logo {
  &:before {
    content: $mxicon-merlinx-logo;
  }
}
.mxicon-meta {
  &:before {
    content: $mxicon-meta;
  }
}
.mxicon-meta2 {
  &:before {
    content: $mxicon-meta2;
  }
}
.mxicon-page-double-col-left {
  &:before {
    content: $mxicon-page-double-col-left;
  }
}
.mxicon-page-double-col-right {
  &:before {
    content: $mxicon-page-double-col-right;
  }
}
.mxicon-page-eguide {
  &:before {
    content: $mxicon-page-eguide;
  }
}
.mxicon-page-experts {
  &:before {
    content: $mxicon-page-experts;
  }
}
.mxicon-page-single-col {
  &:before {
    content: $mxicon-page-single-col;
  }
}
.mxicon-start2 {
  &:before {
    content: $mxicon-start2;
  }
}
.mxicon-transfer {
  &:before {
    content: $mxicon-transfer;
  }
}
.mxicon-attr_supermarket {
  &:before {
    content: $mxicon-attr_supermarket;
  }
}
.mxicon-widget-beachboxwidget {
  &:before {
    content: $mxicon-widget-beachboxwidget;
  }
}
.mxicon-widget-breadcrumbswidget {
  &:before {
    content: $mxicon-widget-breadcrumbswidget;
  }
}
.mxicon-widget-breadcrumbswidget-2 {
  &:before {
    content: $mxicon-widget-breadcrumbswidget-2;
  }
}
.mxicon-widget-contactboxwidget {
  &:before {
    content: $mxicon-widget-contactboxwidget;
  }
}
.mxicon-widget-eguidewidget {
  &:before {
    content: $mxicon-widget-eguidewidget;
  }
}
.mxicon-widget-expertboxwidget {
  &:before {
    content: $mxicon-widget-expertboxwidget;
  }
}
.mxicon-widget-footercontactwidget {
  &:before {
    content: $mxicon-widget-footercontactwidget;
  }
}
.mxicon-widget-footerwidget {
  &:before {
    content: $mxicon-widget-footerwidget;
  }
}
.mxicon-widget-functionalheaderwidget {
  &:before {
    content: $mxicon-widget-functionalheaderwidget;
  }
}
.mxicon-widget-gallerywidget {
  &:before {
    content: $mxicon-widget-gallerywidget;
  }
}
.mxicon-widget-headerwidget {
  &:before {
    content: $mxicon-widget-headerwidget;
  }
}
.mxicon-widget-iframewidget {
  &:before {
    content: $mxicon-widget-iframewidget;
  }
}
.mxicon-widget-imagelinksbox {
  &:before {
    content: $mxicon-widget-imagelinksbox;
  }
}
.mxicon-widget-locationwidget {
  &:before {
    content: $mxicon-widget-locationwidget;
  }
}
.mxicon-widget-megamenuwidget {
  &:before {
    content: $mxicon-widget-megamenuwidget;
  }
}
.mxicon-widget-meteowidget {
  &:before {
    content: $mxicon-widget-meteowidget;
  }
}
.mxicon-widget-modernheaderwidget {
  &:before {
    content: $mxicon-widget-modernheaderwidget;
  }
}
.mxicon-widget-navibarwidget {
  &:before {
    content: $mxicon-widget-navibarwidget;
  }
}
.mxicon-widget-newsletterwidget {
  &:before {
    content: $mxicon-widget-newsletterwidget;
  }
}
.mxicon-widget-offerlistboxwidget {
  &:before {
    content: $mxicon-widget-offerlistboxwidget;
  }
}
.mxicon-widget-offernavbarwidget {
  &:before {
    content: $mxicon-widget-offernavbarwidget;
  }
}
.mxicon-widget-offertriprestboxwidget {
  &:before {
    content: $mxicon-widget-offertriprestboxwidget;
  }
}
.mxicon-widget-offervcustomlistboxwidget {
  &:before {
    content: $mxicon-widget-offervcustomlistboxwidget;
  }
}
.mxicon-widget-offervlistboxwidget {
  &:before {
    content: $mxicon-widget-offervlistboxwidget;
  }
}
.mxicon-widget-offervratingboxwidget {
  &:before {
    content: $mxicon-widget-offervratingboxwidget;
  }
}
.mxicon-widget-officemap {
  &:before {
    content: $mxicon-widget-officemap;
  }
}
.mxicon-widget-officeslider {
  &:before {
    content: $mxicon-widget-officeslider;
  }
}
.mxicon-widget-operbarwidget {
  &:before {
    content: $mxicon-widget-operbarwidget;
  }
}
.mxicon-widget-pagemap {
  &:before {
    content: $mxicon-widget-pagemap;
  }
}
.mxicon-widget-pathwidget {
  &:before {
    content: $mxicon-widget-pathwidget;
  }
}
.mxicon-widget-promoboxwidget {
  &:before {
    content: $mxicon-widget-promoboxwidget;
  }
}
.mxicon-widget-searchboxwidget {
  &:before {
    content: $mxicon-widget-searchboxwidget;
  }
}
.mxicon-widget-simpleexpertwidget {
  &:before {
    content: $mxicon-widget-simpleexpertwidget;
  }
}
.mxicon-widget-simpleheader {
  &:before {
    content: $mxicon-widget-simpleheader;
  }
}
.mxicon-widget-subscriptionswidget {
  &:before {
    content: $mxicon-widget-subscriptionswidget;
  }
}
.mxicon-widget-testimonialswidget {
  &:before {
    content: $mxicon-widget-testimonialswidget;
  }
}
.mxicon-widget-textwidget {
  &:before {
    content: $mxicon-widget-textwidget;
  }
}
.mxicon-widget-weathersearchboxwidget {
  &:before {
    content: $mxicon-widget-weathersearchboxwidget;
  }
}
.mxicon-widget-weathersimplewidget {
  &:before {
    content: $mxicon-widget-weathersimplewidget;
  }
}
.mxicon-www {
  &:before {
    content: $mxicon-www;
  }
}
.mxicon-zoover-logo {
  &:before {
    content: $mxicon-zoover-logo;
  }
}
.mxicon-infant {
  &:before {
    content: $mxicon-infant;
  }
}
.mxicon-dorosli {
  &:before {
    content: $mxicon-dorosli;
  }
}
.mxicon-clubhotel {
  &:before {
    content: $mxicon-clubhotel;
  }
}
.mxicon-merlin-car {
  &:before {
    content: $mxicon-merlin-car;
  }
}
.mxicon-attr_wifi {
  &:before {
    content: $mxicon-attr_wifi;
  }
}
.mxicon-zw {
  &:before {
    content: $mxicon-zw;
  }
}
.mxicon-check-seats {
  &:before {
    content: $mxicon-check-seats;
  }
}
.mxicon-check-beds {
  &:before {
    content: $mxicon-check-beds;
  }
}
.mxicon-candy {
  &:before {
    content: $mxicon-candy;
  }
}
.mxicon-check-commision {
  &:before {
    content: $mxicon-check-commision;
  }
}
.mxicon-installment {
  &:before {
    content: $mxicon-installment;
  }
}
.mxicon-best-price {
  &:before {
    content: $mxicon-best-price;
  }
}
.mxicon-round-trip {
  &:before {
    content: $mxicon-round-trip;
  }
}
.mxicon-check-status {
  &:before {
    content: $mxicon-check-status;
  }
}
.mxicon-cart-add {
  &:before {
    content: $mxicon-cart-add;
  }
}
.mxicon-cart-remove {
  &:before {
    content: $mxicon-cart-remove;
  }
}
.mxicon-attr_mountains {
  &:before {
    content: $mxicon-attr_mountains;
  }
}
.mxicon-attr_tt_vacation_in_the_mountains {
  &:before {
    content: $mxicon-attr_tt_vacation_in_the_mountains;
  }
}
.mxicon-attr_tt_vacation_in_germany {
  &:before {
    content: $mxicon-attr_tt_vacation_in_germany;
  }
}
.mxicon-attr_tt_vacation_in_austria {
  &:before {
    content: $mxicon-attr_tt_vacation_in_austria;
  }
}
.mxicon-attr_tt_vacation_at_north_baltic_sea {
  &:before {
    content: $mxicon-attr_tt_vacation_at_north_baltic_sea;
  }
}
.mxicon-attr_tt_vacation_at_mediterranean_sea {
  &:before {
    content: $mxicon-attr_tt_vacation_at_mediterranean_sea;
  }
}
.mxicon-attr_tt_theme_parks {
  &:before {
    content: $mxicon-attr_tt_theme_parks;
  }
}
.mxicon-attr_tt_thalasso_treatments {
  &:before {
    content: $mxicon-attr_tt_thalasso_treatments;
  }
}
.mxicon-attr_tt_swim_school {
  &:before {
    content: $mxicon-attr_tt_swim_school;
  }
}
.mxicon-attr_tt_surfing_close_to_hotel {
  &:before {
    content: $mxicon-attr_tt_surfing_close_to_hotel;
  }
}
.mxicon-attr_tt_sporting_activities_for_children {
  &:before {
    content: $mxicon-attr_tt_sporting_activities_for_children;
  }
}
.mxicon-attr_tt_specials_for_newly_married {
  &:before {
    content: $mxicon-attr_tt_specials_for_newly_married;
  }
}
.mxicon-attr_tt_special_culinary_delights {
  &:before {
    content: $mxicon-attr_tt_special_culinary_delights;
  }
}
.mxicon-attr_tt_special_atmosphere_design {
  &:before {
    content: $mxicon-attr_tt_special_atmosphere_design;
  }
}
.mxicon-attr_tt_soccer_school {
  &:before {
    content: $mxicon-attr_tt_soccer_school;
  }
}
.mxicon-attr_tt_sandy_beach {
  &:before {
    content: $mxicon-attr_tt_sandy_beach;
  }
}
.mxicon-attr_tt_sailing_close_to_hotel {
  &:before {
    content: $mxicon-attr_tt_sailing_close_to_hotel;
  }
}
.mxicon-attr_tt_rewe_calimera_aktivhotels {
  &:before {
    content: $mxicon-attr_tt_rewe_calimera_aktivhotels;
  }
}
.mxicon-attr_tt_restaurant {
  &:before {
    content: $mxicon-attr_tt_restaurant;
  }
}
.mxicon-attr_tt_quiet_location {
  &:before {
    content: $mxicon-attr_tt_quiet_location;
  }
}
.mxicon-attr_tt_pool_for_children {
  &:before {
    content: $mxicon-attr_tt_pool_for_children;
  }
}
.mxicon-attr_tt_playroom_for_children {
  &:before {
    content: $mxicon-attr_tt_playroom_for_children;
  }
}
.mxicon-attr_tt_playground_for_children {
  &:before {
    content: $mxicon-attr_tt_playground_for_children;
  }
}
.mxicon-attr_tt_phantasialand {
  &:before {
    content: $mxicon-attr_tt_phantasialand;
  }
}
.mxicon-attr_tt_petting_zoo {
  &:before {
    content: $mxicon-attr_tt_petting_zoo;
  }
}
.mxicon-attr_tt_pets_allowed {
  &:before {
    content: $mxicon-attr_tt_pets_allowed;
  }
}
.mxicon-attr_tt_parking_spaces_available {
  &:before {
    content: $mxicon-attr_tt_parking_spaces_available;
  }
}
.mxicon-attr_tt_own_wellness_facilities {
  &:before {
    content: $mxicon-attr_tt_own_wellness_facilities;
  }
}
.mxicon-attr_tt_own_water_sports_facilities {
  &:before {
    content: $mxicon-attr_tt_own_water_sports_facilities;
  }
}
.mxicon-attr_tt_own_water_slide {
  &:before {
    content: $mxicon-attr_tt_own_water_slide;
  }
}
.mxicon-attr_tt_own_tennis_court {
  &:before {
    content: $mxicon-attr_tt_own_tennis_court;
  }
}
.mxicon-attr_tt_own_squash_court {
  &:before {
    content: $mxicon-attr_tt_own_squash_court;
  }
}
.mxicon-attr_tt_own_sauna_bathing_facilities {
  &:before {
    content: $mxicon-attr_tt_own_sauna_bathing_facilities;
  }
}
.mxicon-attr_tt_own_outdoor_pool {
  &:before {
    content: $mxicon-attr_tt_own_outdoor_pool;
  }
}
.mxicon-attr_tt_own_indoor_pool {
  &:before {
    content: $mxicon-attr_tt_own_indoor_pool;
  }
}
.mxicon-attr_tt_own_fitness_facilities {
  &:before {
    content: $mxicon-attr_tt_own_fitness_facilities;
  }
}
.mxicon-attr_tt_other_theme_parks {
  &:before {
    content: $mxicon-attr_tt_other_theme_parks;
  }
}
.mxicon-attr_tt_non_smoking_rooms {
  &:before {
    content: $mxicon-attr_tt_non_smoking_rooms;
  }
}
.mxicon-attr_tt_non_smoking_hotel {
  &:before {
    content: $mxicon-attr_tt_non_smoking_hotel;
  }
}
.mxicon-attr_tt_nice_small_family_run_hotel {
  &:before {
    content: $mxicon-attr_tt_nice_small_family_run_hotel;
  }
}
.mxicon-attr_tt_moviepark {
  &:before {
    content: $mxicon-attr_tt_moviepark;
  }
}
.mxicon-attr_tt_mini_club_3to6_years {
  &:before {
    content: $mxicon-attr_tt_mini_club_3to6_years;
  }
}
.mxicon-attr_tt_medical_service {
  &:before {
    content: $mxicon-attr_tt_medical_service;
  }
}
.mxicon-attr_tt_maxi_club_7to12_years {
  &:before {
    content: $mxicon-attr_tt_maxi_club_7to12_years;
  }
}
.mxicon-attr_tt_massages_and_body_treatments {
  &:before {
    content: $mxicon-attr_tt_massages_and_body_treatments;
  }
}
.mxicon-attr_tt_long_haul_journey {
  &:before {
    content: $mxicon-attr_tt_long_haul_journey;
  }
}
.mxicon-attr_tt_legoland_d {
  &:before {
    content: $mxicon-attr_tt_legoland_d;
  }
}
.mxicon-attr_tt_laundry_service {
  &:before {
    content: $mxicon-attr_tt_laundry_service;
  }
}
.mxicon-attr_tt_kids_disco {
  &:before {
    content: $mxicon-attr_tt_kids_disco;
  }
}
.mxicon-attr_tt_hotel_near_beach {
  &:before {
    content: $mxicon-attr_tt_hotel_near_beach;
  }
}
.mxicon-attr_tt_hotel_in_hiking_region {
  &:before {
    content: $mxicon-attr_tt_hotel_in_hiking_region;
  }
}
.mxicon-attr_tt_hotel_brand_common {
  &:before {
    content: $mxicon-attr_tt_hotel_brand_common;
  }
}
.mxicon-attr_tt_horseback_riding_close_to_hotel {
  &:before {
    content: $mxicon-attr_tt_horseback_riding_close_to_hotel;
  }
}
.mxicon-attr_tt_heidepark {
  &:before {
    content: $mxicon-attr_tt_heidepark;
  }
}
.mxicon-attr_tt_heated_pool {
  &:before {
    content: $mxicon-attr_tt_heated_pool;
  }
}
.mxicon-attr_tt_handicapped_accessible {
  &:before {
    content: $mxicon-attr_tt_handicapped_accessible;
  }
}
.mxicon-attr_tt_great_sports_offer {
  &:before {
    content: $mxicon-attr_tt_great_sports_offer;
  }
}
.mxicon-attr_tt_golf_close_to_hotel_2 {
  &:before {
    content: $mxicon-attr_tt_golf_close_to_hotel_2;
  }
}
.mxicon-attr_tt_golf_close_to_hotel {
  &:before {
    content: $mxicon-attr_tt_golf_close_to_hotel;
  }
}
.mxicon-attr_tt_german_entertainment_for_kids {
  &:before {
    content: $mxicon-attr_tt_german_entertainment_for_kids;
  }
}
.mxicon-attr_tt_gently_sloping_sandy_beach {
  &:before {
    content: $mxicon-attr_tt_gently_sloping_sandy_beach;
  }
}
.mxicon-attr_tt_gay_hotels {
  &:before {
    content: $mxicon-attr_tt_gay_hotels;
  }
}
.mxicon-attr_tt_free_wlan {
  &:before {
    content: $mxicon-attr_tt_free_wlan;
  }
}
.mxicon-attr_tt_free_parking {
  &:before {
    content: $mxicon-attr_tt_free_parking;
  }
}
.mxicon-attr_tt_family_friendly_2 {
  &:before {
    content: $mxicon-attr_tt_family_friendly_2;
  }
}
.mxicon-attr_tt_family_friendly {
  &:before {
    content: $mxicon-attr_tt_family_friendly;
  }
}
.mxicon-attr_tt_family_benefits {
  &:before {
    content: $mxicon-attr_tt_family_benefits;
  }
}
.mxicon-attr_tt_elegant_deluxe {
  &:before {
    content: $mxicon-attr_tt_elegant_deluxe;
  }
}
.mxicon-attr_tt_diving_close_to_hotel {
  &:before {
    content: $mxicon-attr_tt_diving_close_to_hotel;
  }
}
.mxicon-attr_tt_disneyland {
  &:before {
    content: $mxicon-attr_tt_disneyland;
  }
}
.mxicon-attr_tt_direct_proximity_ski_lift {
  &:before {
    content: $mxicon-attr_tt_direct_proximity_ski_lift;
  }
}
.mxicon-attr_tt_direct_beach_access {
  &:before {
    content: $mxicon-attr_tt_direct_beach_access;
  }
}
.mxicon-attr_tt_cosmetic_treatments {
  &:before {
    content: $mxicon-attr_tt_cosmetic_treatments;
  }
}
.mxicon-attr_tt_club_with_entertainment {
  &:before {
    content: $mxicon-attr_tt_club_with_entertainment;
  }
}
.mxicon-attr_tt_city_breaks {
  &:before {
    content: $mxicon-attr_tt_city_breaks;
  }
}
.mxicon-attr_tt_central_location {
  &:before {
    content: $mxicon-attr_tt_central_location;
  }
}
.mxicon-attr_tt_buggy_rent {
  &:before {
    content: $mxicon-attr_tt_buggy_rent;
  }
}
.mxicon-attr_tt_buffet_for_children {
  &:before {
    content: $mxicon-attr_tt_buffet_for_children;
  }
}
.mxicon-attr_tt_bike_mountainbike_rental {
  &:before {
    content: $mxicon-attr_tt_bike_mountainbike_rental;
  }
}
.mxicon-attr_tt_beauty_farm {
  &:before {
    content: $mxicon-attr_tt_beauty_farm;
  }
}
.mxicon-attr_tt_babysitting {
  &:before {
    content: $mxicon-attr_tt_babysitting;
  }
}
.mxicon-attr_tt_baby_equipment {
  &:before {
    content: $mxicon-attr_tt_baby_equipment;
  }
}
.mxicon-attr_tt_baby_cot {
  &:before {
    content: $mxicon-attr_tt_baby_cot;
  }
}
.mxicon-attr_tt_ayurveda {
  &:before {
    content: $mxicon-attr_tt_ayurveda;
  }
}
.mxicon-attr_tt_attractive_for_singles_w_child {
  &:before {
    content: $mxicon-attr_tt_attractive_for_singles_w_child;
  }
}
.mxicon-attr_tt_attractive_for_singles {
  &:before {
    content: $mxicon-attr_tt_attractive_for_singles;
  }
}
.mxicon-attr_tt_attractive_for_couples {
  &:before {
    content: $mxicon-attr_tt_attractive_for_couples;
  }
}
.mxicon-attr_tt_adults_only {
  &:before {
    content: $mxicon-attr_tt_adults_only;
  }
}
.mxicon-attr_tt_action_adventures_parties_fun {
  &:before {
    content: $mxicon-attr_tt_action_adventures_parties_fun;
  }
}
.mxicon-attr_tt_winter_sports_ski_area {
  &:before {
    content: $mxicon-attr_tt_winter_sports_ski_area;
  }
}
.mxicon-covid-text-circle-o {
  &:before {
    content: $mxicon-covid-text-circle-o;
  }
}
.mxicon-covid-text-circle {
  &:before {
    content: $mxicon-covid-text-circle;
  }
}
.mxicon-covid {
  &:before {
    content: $mxicon-covid;
  }
}
.mxicon-covid-circle {
  &:before {
    content: $mxicon-covid-circle;
  }
}
.mxicon-covid-o {
  &:before {
    content: $mxicon-covid-o;
  }
}
.mxicon-attr_children_friendly {
  &:before {
    content: $mxicon-attr_children_friendly;
  }
}
.mxicon-covid2 {
  &:before {
    content: $mxicon-covid2;
  }
}
.mxicon-assistant-male {
  &:before {
    content: $mxicon-assistant-male;
  }
}
.mxicon-assistant-male-circle {
  &:before {
    content: $mxicon-assistant-male-circle;
  }
}
.mxicon-assistant-male-circle-o {
  &:before {
    content: $mxicon-assistant-male-circle-o;
  }
}
.mxicon-assistant-female {
  &:before {
    content: $mxicon-assistant-female;
  }
}
.mxicon-assistant-female-circle {
  &:before {
    content: $mxicon-assistant-female-circle;
  }
}
.mxicon-assistant-female-circle-o {
  &:before {
    content: $mxicon-assistant-female-circle-o;
  }
}
.mxicon-assistant-profile {
  &:before {
    content: $mxicon-assistant-profile;
  }
}
.mxicon-assistant-profile-circle {
  &:before {
    content: $mxicon-assistant-profile-circle;
  }
}
.mxicon-assistant-profile-circle-o {
  &:before {
    content: $mxicon-assistant-profile-circle-o;
  }
}
.mxicon-assistant-team-small {
  &:before {
    content: $mxicon-assistant-team-small;
  }
}
.mxicon-assistant-team-small-circle {
  &:before {
    content: $mxicon-assistant-team-small-circle;
  }
}
.mxicon-assistant-team-small-circle-o {
  &:before {
    content: $mxicon-assistant-team-small-circle-o;
  }
}
.mxicon-assistant-team {
  &:before {
    content: $mxicon-assistant-team;
  }
}

