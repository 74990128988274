section.guide-box {
	position: relative;

	.no-padding-xs {
		.carousel-inner {
			.row {
				margin: 0;
			}
		}
	}

	.img-container {
		height: 160px;
		overflow: hidden;
		@include respond-to(980px) {
			height: 170px;
		}		
		@include respond-to(801px) {
			height:auto;
		}

				
	}

	.text-container {
		max-height: 124px;
		overflow: hidden;
		margin-bottom:2px;
		@include respond-to($screen-sm-max) {
			max-height: auto;
		}
	}

	.section-content {
		height: 190px;
		overflow: hidden;
		padding: 15px 0;

		@include respond-to(980px) {
			height: 200px;
		}
		@include respond-to(801px) {
			height:191px;
		}
	  @include respond-to($screen-xs-max) {
		height:auto;
	  }
	}
  	&.guide-box-hp .section-content {
  		@include respond-to($screen-xs-max) {
  			padding: 15px;
  		}
  	}
	div.eguideLink{
		float:right;
		padding: 10px 10px 10px 0;
		text-align:right;
		a{
			white-space:nowrap;
			text-overflow:ellipsis;
			float:right;
		}
		@include respond-to(801px) {
			width: 35%;
		}			
		
		
	}
	a.more {
		display: inline-block;
		padding: 4px 0;
	}
	img.scalable-x {
		@include respond-to($screen-xs-max) {
			margin-bottom: 10px;
		}
	}
	.eguide-img {
		height: 100%;
		@include respond-to($screen-xs-max) {
			margin-bottom: 10px;
			height: auto;
			width: 100%;
		}
	}
	$eguideImgWrapperWidth: 256px;
	.eguide-img-wrapper{
		float: left;
		width: $eguideImgWrapperWidth;
		padding-left: 15px;
		padding-right: 15px;
		@include respond-to($screen-xs-max){
			width: 100%;
			padding: 0;
		}
	}
	.eguide-info-wrapper{
		float: left;
		width: calc(100% - #{$eguideImgWrapperWidth} - 15px);
		padding-right: 15px;
		@include respond-to($screen-xs-max){
			width: 100%;
		}
	}
	.btn {
		margin: 0 0 15px 0;
	}
	.info {
		padding: 0 15px 0 0;
		@include respond-to($screen-xs-max) {
			padding: 0;
		}
	}
	.tab-content {
		.row {
			display: none;	
			&.active {
				display: block;
			}
		}
	}
	ul {
		list-style: none;
		margin: 0 10px;
		padding: 0;
		&.tablist{
			width:61%;
			float:left;
			overflow:hidden;
			height: 39px;
			.tablist-label{
				font-weight: bold;
				padding: 13px 5px;
				font-size: 14px;
				display: inline;
				line-height: 1;
				text-align: center;
				white-space: nowrap;
				vertical-align: baseline;
				border-radius: .25em;
			}
		}
	}
	h3 {
		display: inline-block;
		margin: 5px 0 0 0;
		@include respond-to($screen-sm-max) {
			margin-top:4px;
		}
		a{
			color: $color1;
			&:hover{
				color:$color9;
				text-decoration:none;
			}
		}
		font-size: 1.3em;
		font-weight: bold;
	}
	h4 {
		font-size: 1.1em;
		font-weight: bold;
		margin: 0 0 10px 0;
		height:33px;
		overflow:hidden;
	}
	p {
		margin: 0;
		&.text {
			font-size: 13px;
			line-height: 18px;
			text-align:justify;
			@include respond-to($screen-sm-max) {
				height:35px;
			}
		}
	}
  .eguideOnlyText{
	padding-left:15px;
	padding-right:15px;
	@include respond-to($screen-xs-max) {
	  padding-left:0;
	  padding-right:0;
	}

	@include respond-to($screen-xs-max - 0.1px) {

		.text-container {
			margin-left: 50px;
			margin-right: 50px;
		}
	}

	p{
	  &.text {
		  height:auto;

	  }
	}
  }
	.section-footer {

		padding-bottom:0;
	
		&.hidden-sm
		{
			border-top: 1px solid $color19;
			padding-top: 15px;
		}
	}


	.carousel-control {
		@include respond-to($screen-xs-max) {
			top: 15px;
			height: 61px;
		}
	}
}

//styles for the smallest widget
[data-widgetname="EguideWidget"] {
	&.col-lg-4 {
		@include respond-from($screen-md-min) {
			section.guide-box {
				ul.tablist {
					width: 50%;
				}

				.eguide-info-wrapper.info {
					.text-container p {
						display: none;
					}
				}
			}
		}
	}
}
