.cf:before,
.cf:after { content: " "; display: table; }
.cf:after { clear: both; }
@mixin cf {
    &:before,
    &:after { content: " "; display: table; }
    &:after { clear: both; }
}
@mixin respond-to($breakpoint) {
    @media all and (max-width: $breakpoint) { @content; }
}

@mixin respond-from($breakpoint) {
    @media all and (min-width: $breakpoint) { @content; }
}

@mixin no-selection {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content;opacity: 1;}
    &::-moz-placeholder          {@content;opacity: 1;}
    &:-ms-input-placeholder      {@content}
    &::placeholder               {@content}
}

@mixin vendor-prefix($property, $value) {
    $prefixes: '', '-moz-', '-webkit-', '-ms-';
    @each $prefix in $prefixes {
        #{$prefix}#{$property}: #{$value};
    }
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

// Extended bootstrap grid system
//
// Framework grid generation
//
// Based on Bootstrap 'bootstrap/_grid-framework.scss'. Generates classes in form of `.col-(size)-x-num` of width x/num.

@mixin make-extended-grid-columns($num-columns, $i: 1, $list: ".col-xs-#{$i}-#{$num-columns}, .col-sm-#{$i}-#{$num-columns}, .col-md-#{$i}-#{$num-columns}, .col-lg-#{$i}-#{$num-columns}") {
    @for $i from (1 + 1) through $num-columns {
        $list: "#{$list}, .col-xs-#{$i}-#{$num-columns}, .col-sm-#{$i}-#{$num-columns}, .col-md-#{$i}-#{$num-columns}, .col-lg-#{$i}-#{$num-columns}";
    }
    #{$list} {
        position: relative;
        min-height: 1px;
        padding-left:  ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);
    }
}


@mixin float-extended-grid-columns($class, $num-columns, $i: 1, $list: ".col-#{$class}-#{$i}-#{$num-columns}") {
    @for $i from (1 + 1) through $num-columns {
        $list: "#{$list}, .col-#{$class}-#{$i}-#{$num-columns}";
    }
    #{$list} {
        float: left;
    }
}


@mixin calc-extended-grid-column($index, $num-columns, $class, $type) {
    @if ($type == width) and ($index > 0) {
        .col-#{$class}-#{$index}-#{$num-columns} {
            width: percentage(($index / $num-columns));
        }
    }
    @if ($type == push) and ($index > 0) {
        .col-#{$class}-push-#{$index}-#{$num-columns} {
            left: percentage(($index / $num-columns));
        }
    }
    @if ($type == pull) and ($index > 0) {
        .col-#{$class}-pull-#{$index}-#{$num-columns} {
            right: percentage(($index / $num-columns));
        }
    }
    @if ($type == offset) and ($index > 0) {
        .col-#{$class}-offset-#{$index}-#{$num-columns} {
            margin-left: percentage(($index / $num-columns));
        }
    }
}

@mixin loop-extended-grid-columns($num-columns, $class, $type) {
    @for $i from 1 through $num-columns {
        @include calc-extended-grid-column($i, $num-columns, $class, $type);
    }
}

@mixin make-extended-grid($class, $num-columns) {
    @include float-extended-grid-columns($class, $num-columns);
    @include loop-extended-grid-columns($num-columns, $class, width);
    @include loop-extended-grid-columns($num-columns, $class, pull);
    @include loop-extended-grid-columns($num-columns, $class, push);
    @include loop-extended-grid-columns($num-columns, $class, offset);
}

//Icon placeholder instead of image styling; Still icon needs fa-nofoto or other class
@mixin icon-placeholder($icon-placeholder-size: 90px){
    position: absolute;
    color: $color10;
    opacity: $pending-opacity;
    font-size: $icon-placeholder-size;
    top: calc(50% - #{$icon-placeholder-size/2});
    left: calc(50% - #{$icon-placeholder-size/2});
}

@mixin overlay($zindex: 1){
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex;
    cursor: pointer;
}

@mixin only_safari_no_ios($selector, $map){
    _:-webkit-full-screen:not(:root:root),#{$selector} {
        @each $property, $value in ($map) {
            #{$property}: $value;
        }
    }
}

@mixin slider-filter {
    .irs {
        height: 21px;
    }
    .irs-line {
        height: 12px;
        border-color: $color19;
    }
    .irs-slider {
        width: 21px;
        height: 21px;
        background: $color7;
        border-radius: 4px;

        &:hover,
        &:active {
            background-color: $color18;
        }
    }
    .irs-bar {
        height: 12px;
        border-top: 1px solid $color19;
        border-bottom: 1px solid $color19;
        background-image: none;
        background-color: $color8;
    }
    .irs-bar-edge {
        height: 12px;
        border: 1px solid $color19;
        border-right: 0;
        background-image: none;
        background-color: $color8;
        border-radius: 5px 0 0 5px;
    }
}

@mixin lighter-background($amount) {
    background-image: linear-gradient(bottom, rgba(255, 255, 255, $amount) 41%, rgba(255, 255, 255, $amount) 71%);
    background-image: -o-linear-gradient(bottom, rgba(255, 255, 255, $amount) 41%, rgba(255, 255, 255, $amount) 71%);
    background-image: -moz-linear-gradient(bottom, rgba(255, 255, 255, $amount) 41%, rgba(255, 255, 255, $amount) 71%);
    background-image: -webkit-linear-gradient(bottom, rgba(255, 255, 255, $amount) 41%, rgba(255, 255, 255, $amount) 71%);
    background-image: -ms-linear-gradient(bottom, rgba(255, 255, 255, $amount) 41%, rgba(255, 255, 255, $amount) 71%);
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.41, rgba(255, 255, 255, $amount)), color-stop(0.71, rgba(255, 255, 255, $amount)));
    background-blend-mode: lighten;
}
