﻿#tourop-images {
    display: flex;
    align-items: center;
    flex-wrap:wrap;

    .mg-image-container {
        overflow: hidden;
        max-height: 150px;
        margin-bottom: 5px;

        @include respond-from($screen-lg-min) {
            max-height: 120px;
        }

        img {
            width: 100%;
        }
    }
}

.reservation-step {
    &.merlin-gallery-container {
            min-height: 0;
        }
}

#merlin-gallery-fullscreen {
    .merlin-gallery-container {
        .merlin-gallery {
            min-height: 0;
            height: auto;
        }
    }
}

div#msd-hotel-gallery {
    &.merlin-gallery-container {
        .merlin-gallery {
            min-height: 0;
            height: auto;
        }
    }
}

.merlin-gallery-container {
    position: relative;
    height: 100%;

    .spinner {
        position: absolute;
        top: calc(50% - 35px);
        left: 50%;
        margin-left: -35px;
    }

    .mg-btn-container {
        height: calc(100% - 70px);
        position: absolute;
        top: 0;
        cursor: pointer;

        &:hover {
            .gallery-button {
                background: $color18;
            }
        }

        .gallery-button {
            position: absolute;
            top: 50%;
            margin-top: -28px;

            &.disabled {
                background-color: $color10;
                opacity: 1;
            }
        }

        &.prev-image {
            width: 35px;
            left: 0;

            .gallery-button {
                left: 0;
            }
        }

        &.next-image {
            width: 35px;
            right: 0;

            .gallery-button {
                right: 0;
            }
        }
    }
}

div.merlin-gallery-container {
    min-height: 390px;
}

.reservation-step {
    min-height: initial;
}

.merlin-gallery {
    text-align: center;
    margin-top: 10px;
    display: block;
    padding: 0;
    width: 100%;
    float: left;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    .spinner {
        text-align: center;
        margin-top: 20px;
    }

    .gallery-button {
        padding: 20px 4px 22px 5px;
        margin: 0;
        font-size: 22px;
        width: 25px;
        cursor: pointer;
        z-index: 2;
        display: none;

        &.disabled {
            background-color: $color10;
            opacity: 1;
        }
    }

    .gallery-scroll-pane {
        overflow: hidden;
        white-space: nowrap;
        margin: 4px 38px;
    }

    .gallery-content {
        &:before {
            content: '';
            vertical-align: middle;
            display: inline-block;
        }
        .thumbnail-box {
            position: relative;
            width: 65px;
            height: 65px;
            overflow: hidden;
            display: inline-block;
            margin-left: 3.5px;
            margin-right: 3.5px;

            &.active {
                border: 2px solid;
                border-radius: 2px;
                color: $color1;
            }

            > img {
                min-width: 40px;
                cursor: pointer;
                position: absolute;
                left: 50%;
                top: 49%;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                height: 100%;
            }

        }
    }
}

.qtip .mg-image-container.big-image {
    @media (min-width: $screen-md-min) {
        height: calc(100% - 70px);
        max-height: 800px;
        min-height: 300px;
        padding-left: 30px;
        padding-right: 30px;
    }
}

.qtip {
    .merlin-gallery {
        width: calc(100% - 30px);
        position: absolute;
        bottom: 0;
    }

    .tourop-list-label {
        font-size: 14px;
    }
}

.mg-image-container {
    position: relative;

    &.big-image {
        &:before {
            content: '';
            height: 100%;
            vertical-align: middle;
            display: inline-block;
        }
        height: calc(100% - 70px);
        max-height: 800px;
        min-height: 300px;
        position: relative;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        @media (min-width: $screen-md-min) {
            height: 300px;
        }
    }

    > .btn-zoom-in {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -.5em 0 0 -.5em;
        opacity: .7;
        font-size: 5em;
        color: #fff;
    }
}

#merlin-gallery-fullscreen {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, .7);
    top: 0;
    left: 0;
    z-index: 22222;

    .mg-fs-image-container {
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 4px;
        margin-top: -165px;
        margin-left: -250px;
        text-align: center;
        background: #fff;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        border: 1px solid #999;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 500px;
        height: 340px;
        overflow: hidden;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        max-height: 870px;

        > .spinner {
            position: absolute;
            top: 50%;
            margin-top: -28px;
            left: 50%;
            margin-left: -28px;
        }
    }

    .btn-gallery-close {
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;
        margin: 0;
        padding: 15px;
        z-index: 20;

        &:hover {
            color: $color18;
        }
    }

    .mg-btn-container {
        height: 100%;
        width: 50%;
        background: transparent;
        cursor: pointer;
        z-index: 10;

        &:hover .gallery-button {
            background-color: $color18;
        }

        .gallery-button {
            position: absolute;
            top: 50%;
            margin-top: -218px;

            &.disabled {
                background-color: $color10;
                opacity: 1;
            }
        }
    }
}

.fullscreen-content-gallery {
    .mg-fs-image-container {
        line-height: 0;
        z-index: -1;
    }
    .merlin-gallery {
        display: none;
        position: fixed;
        bottom: 0;
        padding: 4px 0;
        background-color: rgba(255, 255, 255, 1);

        .gallery-content {
            & > img {
                width: 160px;
                max-height: 100px;
                border: 1px solid rgba(0, 0, 0, .2);
                border-radius: 0;
            }
            .active {
                border: 5px solid;
            }
        }
    }
}

#merlin-gallery-fullscreen {
    .fullscreen-content-gallery {
        .gallery-button {
            position: absolute;
            top: 4px;
            bottom: 4px;
            margin-top: 0;
            padding: 0;
            line-height: 108px;
        }
    }
    .mg-btn-container {
        .gallery-button {
            margin-top: -108px;
        }
    }
    .img-title:not(:empty) {
        position: absolute;
        top: 0;
        min-height: 75px;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
        color: #fff;
        line-height: 75px;
        font-size: 26px;
    }
}

#merlin-gallery-fullscreen {
    .mg-fs-image-container {
        margin-left: 0 !important;
        left: 0 !important;
        width: auto !important;
        height: auto !important;
        position: static !important;
        padding: 0;

        img {
            width: auto !important;
            height: auto !important;
            border: 2px solid #fff;
            border-radius: 4px;
            position: fixed;
            top: 0;
            bottom: 116px;
            left: 0;
            right: 0;
            max-width: calc(100% - 116px);
            max-height: calc(100% - 166px);
            margin: auto;
            overflow: auto;

            &:not([src]) {
                display: none;
            }
        }

        .img-title:not(:empty) ~ img {
            max-height: calc(100% - 250px);
            top: 75px;
        }
    }
}

#merlin-gallery-fullscreen {
    .mg-btn-container {
        height: calc(100% - 116px);

        .gallery-button {
            top: calc(50% - 58px);
            margin-top: -28px;
        }
    }
}

@include respond-to($screen-xs-max) {
    #merlin-gallery-fullscreen {
        .mg-fs-image-container {
            img {
                max-width: 100%;
            }
        }

        .mg-btn-container {
            width: 40px !important;
            position: relative;
            margin-top: 50px;

            .fa-3x {
                font-size: 2em !important;
            }

            &.pull-right .btn {
                right: 5px !important;
            }
            &.pull-left .btn {
                left: 5px !important;
            }
        }
    }

}

.region-info-section.hotel-gallery {
    li.mg-image-container {
        img {
            height: 110px;
        }
    }
}

#tourop-gallery-box {
    .object-listing-elem {
        border-bottom: none;
    }
}
