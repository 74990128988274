/*
	section (boxes with header and content) styling
*/

section {
	margin: 0 0 15px;
	overflow: hidden;

	@include cf;
	@include respond-to($screen-xs-max) {
		margin: 0;
	}
	.sh-inner {
		position: relative;
		display: block;
		vertical-align: middle;
		width: 100%;

        h2 {
            line-height: inherit;
        }
	}
	.section-header, .section-header-2 {
		outline: none;
		padding: $quarter-gutter $half-gutter;
		white-space: nowrap;
		min-height: $widget-header-height;
		line-height: $widget-header-height - $half-gutter;

		@include respond-to($screen-xs-max) {
			margin-top: 1px;
		}
		&.section-header-space{
			padding:0;
			margin-left:17px;
			margin-right:17px;

			&.with-accordion .sh-inner {
				&:after {
					right: 15px;
					//top: 0;
				}
			}
		}
		&.height-auto{
			height: auto;
		}
		h1, h2, h3 {
			text-overflow: ellipsis;
			overflow: hidden;
		}
		h1 {
			margin: 0;
			font-size: 19px;
		}
		h2 {
			margin: 0;
			font-size: 19px;
		}
		h3 {
			margin: 0;
			font-size: 14px;
			font-weight:bold;
		}
		p.text-color-9 {
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.section-header-icon {
			width: 50px;
			height: 50px;
			text-align: center;
			background: white;
			border: 2px solid;
			padding: 3px 0;
			border-radius: 100%;
			& + h3 {
				font-size: 21px;
			}
		}
		.section-header-icon-2 {
			width: 25px;
			display: inline-block;
			text-align: center;
			margin-right: 5px;
		}
		.carousel-indicators {
			left: initial;
			right: 15px;
			bottom: initial;
			top: 50%;
			margin-top: -10px;
		}

		&.with-accordion {
			cursor: pointer;
			.sh-inner {
				padding-right: 30px;
				&:after {
					font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
					font-size: 1.33333em;
					line-height: 25px;
					content: unquote("\"#{ $fa-var-chevron-down }\"");
					font-weight: var(--#{$fa-css-prefix}-style);
					position: absolute;
					right: 0;
					top: 0;
					display: block;
					padding: ($widget-header-height - $half-gutter - 25px)/2 0;
				}
			}
			.fa-inner {
				&:after {
					content: unquote("\"#{ $fa-var-chevron-down }\"");
					font-weight: var(--#{$fa-css-prefix}-style);
				}
			}
		}
		&.with-accordion-active{
			.sh-inner {
				&:after {
					content: unquote("\"#{ $fa-var-chevron-up }\"");
					font-weight: var(--#{$fa-css-prefix}-style);
				}
			}
		}
		&.notTab{
			&:after {
				content: "";
			}
		}

		&.static-color {
			background: #1B4594;
			color: #fff;
		}
	}

	.section-header-2 {
		border-top-width: 1px;
		border-top-style: solid;
		border-bottom-width: 1px;
		border-bottom-style: solid;
	}

	.section-header-3 {
		outline: none;
		padding: $quarter-gutter $half-gutter;
		white-space: nowrap;
		min-height: $widget-header-height;
		line-height: $widget-header-height - $half-gutter;
		color: $color19;
		background-color: $color4;
		border-bottom-width: 1px;
		border-bottom-style: solid;

		@include respond-to($screen-xs-max) {
			margin-top: 1px;
		}
		&.section-header-space{
			padding:0;
			margin-left:17px;
			margin-right:17px;

			&.with-accordion .sh-inner {
				&:after {
					right: 15px;
					//top: 0;
				}
			}
		}

		&.with-accordion {
			cursor: pointer;
			.sh-inner {
				color: $color9;
				padding-right: 30px;
				font-weight: bold;
				min-height: 20px;
				white-space: nowrap;
				&:after {
					font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
					font-size: 1.33333em;
					line-height: 25px;
					content: unquote("\"#{ $fa-var-chevron-down }\"");
					font-weight: var(--#{$fa-css-prefix}-style);
					position: absolute;
					right: 0;
					top: 0;
					display: block;
					padding: ($widget-header-height - $half-gutter - 25px)/2 0;
					color: $color1;
				}
			}
		}
		&.with-accordion-active{
			.sh-inner {
				&:after {
					color: $color1;
					content: unquote("\"#{ $fa-var-chevron-up }\"");
					font-weight: var(--#{$fa-css-prefix}-style);
				}
			}
		}
	}

	.carousel-indicators {
		width: auto;
		margin: 0;
		//letter-spacing: -4px;
		li {
			margin: 0;
			width: 20px;
			height: 20px;
			border-width: 1px;
			border-style: solid;
			@include border-radius(5px);
			&:first-child {
				margin-left: 0;
			}
		}
		.active {
			width: 20px;
			height: 20px;
		}
	}

	.section-content {
		@include cf;
		padding: 15px;
		&.no-padding {
			padding: 0;
		}
		&.padding-top-bot {
			padding: 15px 0 6px;
		}
		&.hidden {
			display: none;
		}
		&.solid-bg {
			background: $color3;
		}
	}
	.section-footer {
		@include cf;
		min-height: 49px;
		padding-top: 5px;

		ul {
			margin: 0;
			@include cf;
			list-style: none;
			padding: 0 10px;

			@include respond-to($screen-xs-max) {
				padding: 0;
			}

			li {
				float: left;
				&.label {
					font-weight: bold;
					padding: 13px 5px;
					font-size: 14px;
				}
				&.option {
					float: right;
				}
				&.switch {
					padding: 10px 5px;
					span.fa{
						cursor:pointer;
					}
				}
				&.more{
					a{
						padding-right:0;
					}
				}
				&.active {
					a {
						//text-decoration: underline;
					}
				}
			}
			a {
				display: block;
				padding: 10px 5px 0 5px;
			}
		}

		@include respond-to($screen-xs-max) {
			padding: 0 15px 10px;
		}
	}

	.no-margin {
		margin: 0;
	}
	.description {
		.text-justify{
			padding:10px 17px;
			&.margin-bottom-0{
				margin-bottom:0;
			}
			&.padding-bottom-0{
				padding-bottom:0;
			}
		}
	}

	iframe {
		border: none;
	}
}
