.qtip-mixed-destinations {
  @include respond-to($screen-sm-min) {
    max-width: 800px;
  }
  border: none;
  box-shadow: 0 2px 8px rgba(50, 50, 50, 0.6);
  overflow: hidden;
  max-height: 1000px;
  background-color: white;
  min-width: 0;

  .btn-toolbar {
    width: 100%;
    padding: 10px;
    background-color: $color4;
    display: flex;
    justify-content: flex-end;
    margin: 0;

    .btn {
      min-width: 100px;
    }
  }

  .qtip-content {
    color: $color1;
    margin: 0;
    padding: 0;
    background-color: $color3;

    & > div > div:first-of-type {
      display: flex !important;
      flex-wrap: wrap;
      gap: 6px;
      >div {
        flex-grow: 1;
        @include respond-from($screen-xxs-min) {
          @include respond-to($screen-sm-max) {
            flex-shrink: 1;
            flex-basis: 20%; //any positive value to make columns equal and avoid calc()
          }
        }
      }
    }
  }

  .view-type-sections-list {
    & > div:first-of-type {
      @include respond-from($screen-md) {
        max-height: 500px;
      }
    }
  }

  .view-type-tree {
    ul{
      max-height: 500px;
      li {
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 3px 10px;
        width: 400px;
      }
    }
  }

  h3 {
    background-color: $color1;
    color: $color12;
    margin: 5px 0;
    font-size: 20px;
    padding: 7.5px 15px;
    min-height: 46px;
    line-height: 31px;
    white-space: nowrap;

    .fa {
      margin-right: 5px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;

    &.with-icon {
      max-height: 500px;
      li{
        padding: 3px 10px;
        width: 400px;
        @include respond-from($screen-xxs-max) {
          @include respond-to($screen-sm-max) {
            width: auto;
          }
        }
        &:before {
          font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
          margin-right: .3em;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        }
        &[data-type="regions"]:before {
          content: unquote("\"#{ $fa-var-map-location-dot }\"");
          font-size: 18px;
        }

        &[data-type="countries"]:before {
          content: unquote("\"#{ $fa-var-flag }\"");
        }

        &[data-type="hotels"]:before {
          content:  unquote("\"#{ $fa-var-hotel }\"");
        }

        &[data-type="cities"]:before {
          content:  unquote("\"#{ $fa-var-city }\"");
          margin-right: .15em;
        }

        &[data-type="roundTrips"]:before {
          content:  unquote("\"#{ $fa-var-suitcase-rolling }\"");
        }
      }
    }

    li {
      white-space: nowrap;
      border: 1px solid transparent;
      cursor: pointer;
      display: flex;
      width: 100%;
      position: relative;

      @include respond-from($screen-xxs-max) {
        @include respond-to($screen-sm-max) {
          width: auto;
        }
      }

      .show-regions-container {
        font-size: 0.8em;
        transition-duration: 500ms;
        transition-property: rotate;
        position: absolute;
        right: 0;
        top: 0;
        padding: 1em;
        z-index: 1;
      }

      .expanded .show-regions-container {
        rotate: 180deg;
      }

      &:hover .scaling-animate {
        scale: 1.2;
        width: 84%;
      }

      .scaling-animate {
        transition-duration: 100ms;
        transition-property: scale;
        transform-origin: 10px;
        padding: 0 5px 0 0;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        width: 100%;

        > * {
            padding: 5px 0;
        }

        .checkbox-container {
          padding: 5px 5px 5px 1px
        }

        & > span {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .scaling-animate label {
          margin: 0;
        }
      }

      & > span > span > span {
        width: 100%;
        display: block;
        margin-top: 3px;
        font-size: 0.8em;
        &:first-child{
          width: 100%;
        }
      }

      span > span + span:before {
        content: " - ";
      }

      &:hover {
        background-color: $color4;
      }

      &>span>.checkbox-container {
        .fa-square-o {
          display: unset
        }

        .fa-check-square-o {
          display: none
        }
      }


      &.selected>span>.checkbox-container {
        .fa-square-o {
          display: none
        }

        .fa-check-square-o {
          display: unset
        }
      }

      mark {
        color: $color18;
        padding: .2em 0;
        background-color: transparent;
      }
    }

    ul {
      flex-basis: 100%;
      li {
        min-width: auto;
      }
    }
  }

  .btn-toolbar {
    width: 100%;
    padding: 10px;
    background-color: $color4;
    display: flex;
    justify-content: flex-end;
    margin: 0;

    .btn {
      min-width: 100px;
    }

  }

  .spinner {
    margin: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    display: inline-block !important;
  }
}

.search-mixed {
  border: none;
  width: 100%;
  outline: none;
  padding: 0 25px 0 10px;

  &[disabled] {
    background-color: lightgray;
  }

  ~.fa {
    position: absolute;
    right: 4px;
    top: 3px;
    font-size: 1.8em;
    color: $color1;
    &.fa-search {
      pointer-events: none;
      user-select: none;
      top: 6px;
      right: 10px;
      font-size: 1.4em;
    }
    &.fa-times-circle {
      color: $color16;
    }
  }

  &:focus {
    border: 1px solid $color1;
  }
}

//special styling to allow search field to be visible on top qtip content
body.qtip-with-field-moved-top {
  padding-top: 48px;
  overflow: hidden;
  .mobile-nav-fixed {
    display: none !important;
  }
  [data-field-type="destinationMixed"] {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background: linear-gradient($color3, $color3), linear-gradient(white, white);
    z-index: 1000000;
  }
  .qtip-mixed-destinations {
    margin-top: 44px;
    box-shadow: none;
    .btn-toolbar {
      position: fixed;
      bottom: 0;
      height: 50px;
    }
    ul {
      max-height: unset;
      height: 100%;
    }
    .qtip-content{
      padding-bottom: 50px;
      >div {
        height: 100%;
        padding-bottom: 40px;
        overflow: hidden;
        >div:first-of-type {
          overflow: hidden;
          height: 100%;
          > div {
            overflow: hidden;
            height: 100%;
            @include respond-to($screen-xxs-min) {
              flex-basis: 100%;
              height: 50%;
              &:first-child{
                padding-bottom: 50px;
              }
              &:nth-child(2){
                padding-bottom: 112px;
              }
            }
          }
        }
      }
    }
  }
}

//additional styling for specific cases
.qtip-mixed-destinations .qtip-content .display-mode-base {
  ul li {
    width: auto;
  }
  &.view-type-sections ul li .scaling-animate .checkbox-container {
    padding: 5px;
  }
}

.qtip-mixed-destinations .qtip-content .display-mode-suggest {
  ul {
    margin: 0 6px;
  }
  & > div:first-of-type > div {
    flex-grow: 0;
    flex-shrink: 0;
    width: calc((100% - 12px)/3);
    height: auto !important;
    max-height: 50%;

    &:nth-child(1), &:nth-child(2) {
      flex-grow: 1;
    }
    @include respond-to($screen-lg-min) {
      flex-basis: calc((100% - 12px)/2);
      height: 33%;
    }
    @include respond-to($screen-md-min) {
       flex-basis: calc((100% - 12px)/3);
       height: 33%;
    }

    @include respond-to($screen-sm-min) {
      flex-basis: calc((100% - 12px)/2);
      height: 33%;
    }
    @include respond-to($screen-xs-min) {
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      max-height: initial;
    }
  }

  &.view-type-list {
    & > div:first-of-type > div {
      max-height: 100%;
      flex-basis: 100%;
    }
  }

  &.view-type-sections-list {
    & > div:first-of-type {
      flex-direction: column;
      flex-wrap: nowrap;
      overflow: auto;
      & > div {
        width: 100% !important;
        flex-shrink: 0;
        flex-basis: auto;
      }
    }
  }
}

.qtip-mixed-destinations .qtip-content .view-type-list {
  ul li {
    width: 100%;
    min-width: 340px;
  }
}

.qtip-mixed-destinations .qtip-content .display-mode-suggest.view-type-sections {
  ul {
    height: auto;
    @include respond-to($screen-xs-min) {
      overflow: hidden !important;
    }
  }

  & > div:first-of-type {
    @include respond-to($screen-xs-min) {
      flex-direction: column;
      flex-wrap: nowrap;
      overflow-y: auto !important;
      > div {
        flex-shrink: 0;
        flex-basis: auto;
      }
    }
  }
}

.qtip-mixed-destinations .qtip-content .display-mode-base.view-type-sections {
  ul {
    margin: 0 0 0 10px;
    height: calc(100% - 54px);
  }
  & > div:first-of-type > div {
    width: calc((100% - 12px)/3);
  }
}
