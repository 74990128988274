section.expert-box {
	position: relative;
	/* jCarousel */
	margin-bottom:15px;
	.expert-list {
		position: relative;
		overflow: hidden;

		ol {
			white-space: nowrap;
			position: relative;
			padding-left: 0;

			li {
				display: inline-block;
				margin: 0 1px 0 0;
				width: 540px;
				white-space: nowrap;

				td {
					vertical-align: top;
				}
			}
		}
	}
	.fa-chevron-circle-left:before{
	  display: block;
	  width: 16px;
	}
  .expertsVisible{
		padding-left:10px;
  }
  .expertsQuantity{
	padding-right:10px;
  }
	@include respond-to($screen-xs-max) {
		margin-bottom: 0;
	}
	
	//@extend .guide-box;
	h3 {
		display: inline-block;
		margin: 5px 0 0 0;
		@include respond-to($screen-sm-max) {
			margin-top:4px;
		}
		a{
			color: $color1;
			&:hover{
				color:$color9;
				text-decoration:none;
			}
		}
		font-size: 1.3em;
		font-weight: bold;

	}

	h4 {
		font-size: 1.1em;
		font-weight: bold;
		margin: 0 0 10px 0;
		height:33px;
		overflow:hidden;
		height:42px;
		@include respond-to(719px) {
			height: auto;
		}

	}

	p {
		margin: 0;
		word-break: break-all;
		color: $color9;
		 //  @media (min-width: $screen-xs-min) {
			// &.hideTo480{display:none !important;}// telefon poziomo
		 //  }
		 //  @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
			// &.showFrom480{display:block !important;}// telefon poziomo

		 //  }		
		&.text {
			font-size: 13px;
			line-height: 18px;
			text-align:justify;
			@include respond-to($screen-sm-max) {
				height:35px;
				padding-right: 50px;
			}
		}
	}
  	.phoneExpert{
	  .fa-phone{
		font-size: 18px;
		position: relative;
		top: 2px;
	  }
	}

	.section-footer.hidden-sm {
		border-top: 1px solid $color19;
		padding-top: 15px;
	}
	.section-content{
		@include respond-to(980px) {
			.col-sm-8{
				&.padding-left-sm{
					padding-left:15px;
				}
			}
		}
		height: 190px;
		overflow: hidden;

		@include respond-to(980px) {
		  height: 200px;
		}
		@include respond-to(801px) {
		  height:190px;
		}
		@include respond-to($screen-xs-max) {
			height: auto;
		}
	}

  	.imageExpert{
	  float:left;
	  width:135px;
	 //  @include respond-to($screen-xxs-max) {
		// margin-bottom: 5px;
		// width: 90px;
		// height: 105px;
	 //  }
	  margin-right: 15px;
	}
	img.scalable-x {
		margin: 0;
	}
	ol {
		list-style: none;
	}

	.expert-image-container {
		display: inline-block;
		vertical-align: top;
		white-space: normal;
		width: 135px;
		height: 160px;
		overflow: hidden;
		margin: 0 15px 0 0;

		@include respond-to($screen-xxs-max) {
			width: 90px;
			height: 105px;
			margin-bottom: 5px;
		}
	}

	.expert-data-container {
		display: inline-block;
		vertical-align: top;
		white-space: normal;
		width: calc(100% - 160px);

		a {
			&:hover {
				h3, h4 {
					text-decoration: underline;
				}
			}
		}

		@include respond-to($screen-xxs-max) {
			width: calc(100% - 115px);
		}
	}
	

}
