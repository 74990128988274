section.bestratedhotels {
    .rating--header {
        .rating--container {
            padding: 10px 15px 10px;
            height: auto;
        }
    }

    .brhotels-image--container {
        display: inline-block;
        float: left;
        vertical-align: top;
        white-space: normal;
        overflow: hidden;
        width: 105px;
        height: 65px;
        font-size: 18px !important;
        margin: 6px 3px 14px 15px;
        img {
            width: 100%;
            height: 100%;
        }

        .rating-box {
            position: relative;
            top: -65px;
            width: 53px;
            height: 31px;
            background-color: #F07132;
            font-size: 20px;
            text-align: center;
            color: #FFF;
            border-right: 2px white solid;
            border-bottom: 2px white solid;

            &.beach-box{
                width: 60px;
                line-height: 31px;
                font-size: 15px;
                background-color: $color7;
                border-width: 1px;

                .rating-n {
                    left: 0;
                }
            }
        }

        .no-hover:hover {
            -webkit-transition: none;
            -webkit-transition-delay: 0s;
            -moz-transition: none;
            -o-transition: none;
            transition: none;
        }

        .rating-box .rating-n {
            position: relative;
            top: 0;
            //transform: translateY(-50%);
            letter-spacing: 1px;
            z-index: 10;
        }
    }

    .with-img-price {
        margin-top: 46px!important;
        margin-right: 32px;
    }
    .with-pic{
        margin-top: 11px!important;
        padding: 0 10px;

        h4 {
            height: 15px;
        }
    }

    .with-pic-m {
        width: 167px;
        margin-top: -5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .logozoover {
        position: absolute;
        margin-top: 1px;
        top: 50%;
        right: 4px;
        color: #F07132;
        font-size: 2em;
    }

    .section-content {
        padding: 0;
    }
    .client-opinions {
        width: 92%;
        height: 52px;
        display: table-cell;
        vertical-align: middle;
        p {
            margin: 2px 0 10px;
            padding-left: 5px;
            width: 93%;
        }
    }

    .opinions-wrapper {
        padding: 4px 10px;
        height: 52px;
    }

    // No picture
    .no-image--hotels {
        display: inline-block;
        width: 63px;
        height: 46px;
        background-color: #f16a24;
        color: #ffffff;
        margin: 3.5px 0 12px 13px;
        padding: 0;
        text-align: center;
        line-height: 1.109;
        
        &.beach-box{
            background-color: $color7;
        }
        .no-pic-m-t {
            margin-top: 3px;
            color: #FFF;
            //height: 30px;
        }
        span.hotels-op {
            font-size: 11px;
            width: 35px;
            color: #FFF;
        }
        .big {
            font-size: 22px;
            color: #FFF;
        }
        .no-hover:hover {
            color: white !important;
            text-decoration: none !important;
        }
    }

    .no-pic {
        margin-top: 5px;
        padding: 0 10px;
        p {
            margin-bottom: 0;
        }
        h4 {
            display: inline-block;
        }
    }
    .ratelogo  {
        position: relative;
        top: -30px;
        right: -16px;
        width: 140px;
        height: 80px;
    }


    .brhotels-list {
        list-style: none;
        padding: 0;
        margin: 0;
        .price {
            position: absolute;
            display: inline-block;
            margin-top: 42px;
            float: left;
            font-size: 18px;
            right: 0;
            padding: 0;
            width: 85px;
            text-align: right;
        }
        .price strong {
            margin-top: 1px;
        }

        .no-pic-price {
            margin-top: 25px;
            margin-right: 31px;
        }

        li:hover .rating-box .rating-n {
            color: white !important;
            text-decoration: none !important;
        }

        .no-hover:hover {
            text-decoration: none !important;
        }
        .hotel-pointer {
            cursor: pointer;
        }
    }
    .br-hotels__content {
        margin: 7px 0;
        list-style: none;
        padding: 0;

        &.beachbox-with-pic{
            margin: 3px 0;
            .no-image--hotels{
                margin: 7px 0 8.5px 13px;

                .no-pic-m-t{
                    .big{
                        font-size: 20px;
                    }
                    .hotels-op{
                        text-transform: lowercase;
                    }
                }
            }
            .brhotels-data-container {
                margin-top: 9px;

            }
            &+.row .footer-padding {
                padding-top: 3px;
                padding-bottom: 11px;
                &.with-pic-no-image{
                    padding-top: 6px;
                }
            }
        }
    }

    .border-bottom {
        border-bottom: solid 1px $color19;
    }
    .row {
        margin: 0 ;
    }

    .brhotels-data-container {
        display: inline-block;
        vertical-align: top;
        float: left;
        margin-top: 7px;
        position: absolute;
        .hotels-with-pic {
            width: 195px;
        }
        .hotels-stars {
            position: absolute;
            left: 226px;
            top: 1px;
        }

        h3 {
            margin: 0 0 5px;
            overflow: hidden;
            font-size: 18px;
            font-weight: 700;
            white-space: nowrap;
            max-width: 95%;
            text-overflow: ellipsis;
        }

        .with-pic-stars {
            display: inline-block;
        }

        .region-widget{
            width: 78%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            p{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        h4 {
            margin: 0;
        }
        a {
            &:hover {
                h3, h4 {
                    text-decoration: underline;
                }
            }
        }
    }

    .b-hotels-stars {
        position: absolute;
        top: -27px;
        right: 0;
    }

    .widget-footer {
        padding-top: 3px;
    }

    .with-pic-m {
        margin-top: -5px;
        width: 60%;
    }

    .footer-padding {
        padding-bottom: 10.5px;
    }

    @media screen and (min-width: 420px) and (max-width: $screen-md-max) {
        .brhotels-data-container {
            .hotels-with-pic {
                width: 300px;
            }
            .region-widget {
                width: 70%;
            }
        }
    }

    &.beachbox{

        .client-opinions {
            p {
                margin: 5px 0 5px 10px;
            }
        }

        .opinions-wrapper {
            padding: 0;
            height: auto;
        }

        .img-container-wrapper {
            height: 200px;
            width: 100%;
            //overflow: hidden;
            position: relative;
        }
        @media (max-width: $screen-md-max) and (min-width: 750px) {
            .col-md-12 & .col-lg-12 > .img-container-wrapper {
                height: 400px;
            }
        }

        .img-container {
            overflow: hidden;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            left: 0;
            @include transition(all .5s ease);

            &.scale-x-1 {
                background-position: 0% 0%;
            }

            &.scale-x-2 {
                background-position: 50% 0%;
            }

            &.scale-x-3 {
                background-position: 100% 0%;
            }

            &:hover {
                &.with-zoom {
                    @include scale3d(1.1, 1.1, 1.1);
                }
            }
        }
    }
}

.weather-widget-list {
    .weather-widget-region {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 93%;
        overflow: hidden;
        margin-top: 6px;
    }
    .second-line {
        padding-top: 5px;
    }
    .weather-widget-dg {
        font-size: 16px;
        position: relative;
        padding-left: 5px;
        top: 4px;
    }
    .weather-widget-img {
        width: 50px;
        height: 52px;
        margin-top: 5px;
    }
    .weather-widget-price {
        font-size: 18px;
        margin-top: 26px;
    }
    .weather-widget-txt {
        padding-left: 15px;
        padding-right: 0;
    }
}

