section.subscriptionsbox {
    &.force-height-high {
        min-height: 406px;
        max-height: 406px;
    }
    &.force-height-average {
        min-height: 285px;
        max-height: 285px;
    }
    &.force-height-low {
        min-height: 102px;
        max-height: 102px;
    }
    &.force-height-default {
        min-height: initial;
        max-height: initial;
    }
}

.container-fluid-subscriptionsbox {
    .close {
        top: 0;
    }
    .newsletter {
        min-height: 200px;
        @include respond-to($screen-xs-max) {
            min-height: 0;
        }
        .icon-container {
            float: left;
            i {
                font-size: 43px;
            }
            margin-right: 10px;
        }
        .value-newsletter {
            float: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 21px;
            line-height: 50px;
            p {
                margin-bottom: 0;
            }
        }
        .newsletter-text {
            clear: both;
            min-height: 61px;
        }
        label {
            font-weight: normal;
        }
    }

    .newslleter-popup {
        @include respond-to(397) {
            left: 16px !important;
        }
    }
}

.newsletter.subscriptions {
    &.low, &.medium, &.high {
        .section-content {
            overflow-y: auto;
        }
    }
}

.newsletter {

    .section, .section-content{
        height: inherit;
    }

    .section {
        display: flex;
        flex-direction: column;
    }

    .section-content {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    min-height: 132px;

    &:not(.subscriptions).low {
        h4, .newsletter-text {
            display: none;
        }
    }

    &.medium {
        min-height: 285px;
    }

    &.high  {
        min-height: 408px;
    }

    .widget-distinction{
        position:absolute;
        top:15px;
        left:15px;
        padding:5px;
        background:rgba(255,0,0,0.7);
        color:#fff
    }

    .newsletter-form {
        .checkbox.conditions-block {
            margin: 10px auto;

            input {
                margin-left: 4px;
            }
        }

        input.invalid {
            margin-bottom: 20px;

            + label.invalid {
                position: absolute;
                top: 100%;
                left: 0;
                margin-top: -15px;
                margin-left: $quarter-gutter;

                + .btn[type="submit"] {
                    bottom: 20px;
                }
            }
        }
    }


    @include respond-to($screen-xs-max) {
        height: auto!important;
        .section-content {
            padding-bottom: 55px;
            height: inherit!important;
        }

        .newsletter-form {
            left: 15px;
            right: 15px;
            bottom: initial;
        }
    }

    .modern-layout{
        padding-top: 45px;
        padding-bottom: 90px;

        .checkbox.conditions-block {
            width: 50%;
        }

        .newsletter-form{
            bottom: 55px;
            @include respond-to($screen-xs-max){
                bottom: 40px;
            }
        }

        .value-newsletter {
            float: none;
            font-size: 24px;
            margin-bottom: 15px;
        }

        .newsletter-text{
            font-size: 16px;
            margin-bottom: 30px;
        }

        $temp-border-radius: "br-input";

        .input-container{
            position: relative;
            margin: 0 auto;
            width: 50%;
            @include respond-to($screen-xs-max){
                width: 100%;
            }

            input{
                height:48px;
                padding: 10px 84px 10px 24px;
                border: 2px solid $color7;
                @include border-radius($temp-border-radius);
                @include placeholder{
                    color: $color7;
                };
            }

            .btn[type="submit"]{
                position: absolute;
                top: 1px;
                right: 0;
                bottom: 1px;
                width: 70px;
                background: transparent;
                @include border-right-radius($temp-border-radius);
                @include border-left-radius(0);

                &:hover{
                    color: $color100;
                    background: $color7;
                }
            }
        }
    }
}
