.masgg-element {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	line-height: 1.4em;
	&:hover {
		background: $color18;
	}
	i {
		font-size: 1.4em;
		width: 17px;
	}
}

.masgg-content {
	background-color: #fff;
}

.masgg-info {
	color: $color9;
	background: "$bgcolor4";
}

.msagg-highlighted {
	background: $color18;
}