/*
	general buttons styling
*/

.btn-confirm, .btn-cancel {
	border: 1px solid $color7;

	&:hover {
		color: $color12;
		background: $color18;
		border: 1px solid $color18;
	}
}

.btn-confirm {
	color: $color15;
	background: $color7;
}

.btn-cancel {
	color: $color7;
	background: $color15;
}

.btn-mobile-nav, .btn-mobile-back {
	position: absolute;
	top: 8px;
	display: block;
	width: 36px;
	height: 36px;
	border: none;
	cursor: pointer;
	text-align: center;
	font-size: 24px;
	@include border-radius(4px);
  	z-index: 11;
	text-shadow: 0 0 1px #888;
}

.btn-mobile-back {
	left: 15px;
	line-height: 40px;
	padding-right: 2px;
}

.btn-mobile-nav {
	right: 15px;
	line-height: 36px;

	.fa-times {
		padding: 5px 9px;
		display: block;
	}

	.fa-navicon {
		padding: 6px;
		display: block;
	}
}

// TODO usunąć jeśli niepotrzebne
.section-header {
	.btn-link {
		line-height: 19px;
		padding: 0;
	}
}

.btn-primary {
	&.static-color {
		background: #699A21;
		color: #fff;

		&:hover {
			background: #F96420;
		}
	}
}

.btn-secondary {
	&.static-color {
		background: #428bca;
		color: #fff;
		border: 1px solid #B7B8B8;
	}

	&:hover {
		background: #F96420;
	}
}
.btn-error {
	&.static-color {
		background: #C11F27;
		color: #FFF;
		border: 1px solid #C11F27;
	}
}

.btn.text-color-19.background-color-4 {
	box-sizing: border-box;
	border: 1px solid;
}

.multi-radio-container {
	display: table;
	padding-bottom: 10px;
	width: 100%;
	table-layout: fixed;
}

.btn-back{
	font-size: 16px;
}

