.officemap-box {
	
	.text-container {
		p {
			font-size: 14px;
			line-height: 20px;
		}
	}

	.office-select-container {
		padding: 15px;
		margin: 5px 0 15px;
	}

	.officemap-select {}

	.map-container {
		margin:  0;
		overflow: hidden;
		position: relative;

		.map-type {
			position: absolute;
			top: 5px;
			right: 5px;
			z-index: 2;
		}

		.map {
			width: 100%;
			height: 800px;
			position: relative;
			z-index: 1;
		}
	}
}