@keyframes scale-150-proc {
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1.5);
    }
}

@keyframes anim-move-r {
    0%{
        -webkit-transform:translateX(-15px);
        -moz-transform:translateX(-15px);
        -ms-transform:translateX(-15px);
        -o-transform:translateX(-15px);
        transform: translateX(-15px);
    }
    50%{
        -webkit-transform:translateX(0);
        -moz-transform:translateX(0);
        -ms-transform:translateX(0);
        -o-transform:translateX(0);
        transform: translateX(0);
    }
}

@keyframes bounce {
    from, 10%, 26.5%, 40%, 50%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transform: translate3d(0,0,0);
    }

    20%, 21.5% {
        animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -30px, 0);
    }

    35% {
        animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -15px, 0);
    }

    45% {
        transform: translate3d(0,-4px,0);
    }
}
