$mxicon-font-family: "MerlinXFont" !default;
$mxicon-font-path: "/assets/fonts/awesome/" !default;

$mxicon-ac: "\f900";
$mxicon-activity: "\f901";
$mxicon-airport-group: "\f902";
$mxicon-attr-seniors2: "\f903";
$mxicon-attr_ac: "\f904";
$mxicon-attr_adult: "\f905";
$mxicon-attr_agdrtv: "\f906";
$mxicon-attr_airport: "\f907";
$mxicon-attr_animation: "\f908";
$mxicon-attr_beach: "\f909";
$mxicon-attr_beachvolleyball: "\f90a";
$mxicon-attr_bikes: "\f90b";
$mxicon-attr_businesshotel: "\f90c";
$mxicon-attr_citybreak: "\f90d";
$mxicon-attr_club: "\f90e";
$mxicon-attr_disco: "\f90f";
$mxicon-attr_diving: "\f910";
$mxicon-attr_exotic: "\f911";
$mxicon-attr_fireplace: "\f912";
$mxicon-attr_fitness: "\f913";
$mxicon-attr_gay: "\f914";
$mxicon-attr_golf: "\f915";
$mxicon-attr_grill-bbq: "\f916";
$mxicon-attr_handicaped: "\f917";
$mxicon-attr_horse: "\f918";
$mxicon-attr_insidepool: "\f919";
$mxicon-attr_internet: "\f91a";
$mxicon-attr_kids: "\f91b";
$mxicon-attr_kindergarten: "\f91c";
$mxicon-attr_lakes: "\f91d";
$mxicon-attr_massage: "\f91e";
$mxicon-attr_minigolf: "\f91f";
$mxicon-attr_nearcenter: "\f920";
$mxicon-attr_nearslopes: "\f921";
$mxicon-attr_nightclub: "\f922";
$mxicon-attr_outsidepool: "\f923";
$mxicon-attr_parking: "\f924";
$mxicon-attr_passport: "\f925";
$mxicon-attr_pets: "\f926";
$mxicon-attr_pilgrimage: "\f927";
$mxicon-attr_poolprivat: "\f928";
$mxicon-attr_quiet_location: "\f929";
$mxicon-attr_sailing: "\f92a";
$mxicon-attr_sandy_beach: "\f92b";
$mxicon-attr_sauna: "\f92c";
$mxicon-attr_seaside: "\f92d";
$mxicon-attr_seniors: "\f92e";
$mxicon-attr_ski: "\f92f";
$mxicon-attr_skiregion: "\f930";
$mxicon-attr_skirent: "\f931";
$mxicon-attr_spa: "\f932";
$mxicon-attr_sports: "\f933";
$mxicon-attr_squash: "\f934";
$mxicon-attr_surfing: "\f935";
$mxicon-attr_table_tennis: "\f936";
$mxicon-attr_tennis: "\f937";
$mxicon-attr_walking: "\f938";
$mxicon-attr_waterski: "\f939";
$mxicon-attr_waterslides: "\f93a";
$mxicon-attr_watersports: "\f93b";
$mxicon-attr_wintersport: "\f93c";
$mxicon-bagaz: "\f93d";
$mxicon-bagaz-circle: "\f93e";
$mxicon-bus: "\f93f";
$mxicon-bus3: "\f940";
$mxicon-campattr_art: "\f941";
$mxicon-campattr_bio: "\f942";
$mxicon-campattr_comp: "\f943";
$mxicon-campattr_game: "\f944";
$mxicon-campattr_health: "\f945";
$mxicon-campattr_lang: "\f946";
$mxicon-campattr_media: "\f947";
$mxicon-campattr_misc: "\f948";
$mxicon-campattr_music: "\f949";
$mxicon-campattr_theme: "\f94a";
$mxicon-car-door: "\f94b";
$mxicon-charter: "\f94c";
$mxicon-columns: "\f94d";
$mxicon-cruise: "\f94e";
$mxicon-cruise-circle: "\f94f";
$mxicon-fly0: "\f950";
$mxicon-fly1: "\f951";
$mxicon-fly2: "\f952";
$mxicon-holidaycheck: "\f953";
$mxicon-insurance: "\f954";
$mxicon-layout-tab-navigation: "\f955";
$mxicon-layout-tab-offerbox: "\f956";
$mxicon-layout-tab-search: "\f957";
$mxicon-merlin-activity: "\f958";
$mxicon-merlin-activity-circle: "\f959";
$mxicon-merlin-attractions: "\f95a";
$mxicon-merlin-attractions-circle: "\f95b";
$mxicon-merlin-hotel: "\f95c";
$mxicon-merlin-hotel-circle: "\f95d";
$mxicon-merlin-meeting: "\f95e";
$mxicon-merlin-meeting-circle: "\f95f";
$mxicon-merlin-plane: "\f960";
$mxicon-merlin-restaurant: "\f961";
$mxicon-merlin-restaurant-circle: "\f962";
$mxicon-merlin-train: "\f963";
$mxicon-merlin-transfer: "\f964";
$mxicon-merlinx-logo: "\f965";
$mxicon-meta: "\f966";
$mxicon-meta2: "\f967";
$mxicon-page-double-col-left: "\f968";
$mxicon-page-double-col-right: "\f969";
$mxicon-page-eguide: "\f96a";
$mxicon-page-experts: "\f96b";
$mxicon-page-single-col: "\f96c";
$mxicon-start2: "\f96d";
$mxicon-transfer: "\f96e";
$mxicon-attr_supermarket: "\f96f";
$mxicon-widget-beachboxwidget: "\f970";
$mxicon-widget-breadcrumbswidget: "\f971";
$mxicon-widget-breadcrumbswidget-2: "\f972";
$mxicon-widget-contactboxwidget: "\f973";
$mxicon-widget-eguidewidget: "\f974";
$mxicon-widget-expertboxwidget: "\f975";
$mxicon-widget-footercontactwidget: "\f976";
$mxicon-widget-footerwidget: "\f977";
$mxicon-widget-functionalheaderwidget: "\f978";
$mxicon-widget-gallerywidget: "\f979";
$mxicon-widget-headerwidget: "\f97a";
$mxicon-widget-iframewidget: "\f97b";
$mxicon-widget-imagelinksbox: "\f97c";
$mxicon-widget-locationwidget: "\f97d";
$mxicon-widget-megamenuwidget: "\f97e";
$mxicon-widget-meteowidget: "\f97f";
$mxicon-widget-modernheaderwidget: "\f980";
$mxicon-widget-navibarwidget: "\f981";
$mxicon-widget-newsletterwidget: "\f982";
$mxicon-widget-offerlistboxwidget: "\f983";
$mxicon-widget-offernavbarwidget: "\f984";
$mxicon-widget-offertriprestboxwidget: "\f985";
$mxicon-widget-offervcustomlistboxwidget: "\f986";
$mxicon-widget-offervlistboxwidget: "\f987";
$mxicon-widget-offervratingboxwidget: "\f988";
$mxicon-widget-officemap: "\f989";
$mxicon-widget-officeslider: "\f98a";
$mxicon-widget-operbarwidget: "\f98b";
$mxicon-widget-pagemap: "\f98c";
$mxicon-widget-pathwidget: "\f98d";
$mxicon-widget-promoboxwidget: "\f98e";
$mxicon-widget-searchboxwidget: "\f98f";
$mxicon-widget-simpleexpertwidget: "\f990";
$mxicon-widget-simpleheader: "\f991";
$mxicon-widget-subscriptionswidget: "\f992";
$mxicon-widget-testimonialswidget: "\f993";
$mxicon-widget-textwidget: "\f994";
$mxicon-widget-weathersearchboxwidget: "\f995";
$mxicon-widget-weathersimplewidget: "\f996";
$mxicon-www: "\f997";
$mxicon-zoover-logo: "\f998";
$mxicon-infant: "\f999";
$mxicon-dorosli: "\f99a";
$mxicon-clubhotel: "\f99b";
$mxicon-merlin-car: "\f99c";
$mxicon-attr_wifi: "\f99d";
$mxicon-zw: "\f99e";
$mxicon-check-seats: "\f99f";
$mxicon-check-beds: "\f9a0";
$mxicon-candy: "\f9a1";
$mxicon-check-commision: "\f9a2";
$mxicon-installment: "\f9a3";
$mxicon-best-price: "\f9a4";
$mxicon-round-trip: "\f9a5";
$mxicon-check-status: "\f9a6";
$mxicon-cart-add: "\f9a7";
$mxicon-cart-remove: "\f9a8";
$mxicon-attr_mountains: "\f9a9";
$mxicon-attr_tt_vacation_in_the_mountains: "\f9aa";
$mxicon-attr_tt_vacation_in_germany: "\f9ab";
$mxicon-attr_tt_vacation_in_austria: "\f9ac";
$mxicon-attr_tt_vacation_at_north_baltic_sea: "\f9ad";
$mxicon-attr_tt_vacation_at_mediterranean_sea: "\f9ae";
$mxicon-attr_tt_theme_parks: "\f9af";
$mxicon-attr_tt_thalasso_treatments: "\f9b0";
$mxicon-attr_tt_swim_school: "\f9b1";
$mxicon-attr_tt_surfing_close_to_hotel: "\f9b2";
$mxicon-attr_tt_sporting_activities_for_children: "\f9b3";
$mxicon-attr_tt_specials_for_newly_married: "\f9b4";
$mxicon-attr_tt_special_culinary_delights: "\f9b5";
$mxicon-attr_tt_special_atmosphere_design: "\f9b6";
$mxicon-attr_tt_soccer_school: "\f9b7";
$mxicon-attr_tt_sandy_beach: "\f9b8";
$mxicon-attr_tt_sailing_close_to_hotel: "\f9b9";
$mxicon-attr_tt_rewe_calimera_aktivhotels: "\f9ba";
$mxicon-attr_tt_restaurant: "\f9bb";
$mxicon-attr_tt_quiet_location: "\f9bc";
$mxicon-attr_tt_pool_for_children: "\f9bd";
$mxicon-attr_tt_playroom_for_children: "\f9be";
$mxicon-attr_tt_playground_for_children: "\f9bf";
$mxicon-attr_tt_phantasialand: "\f9c0";
$mxicon-attr_tt_petting_zoo: "\f9c1";
$mxicon-attr_tt_pets_allowed: "\f9c2";
$mxicon-attr_tt_parking_spaces_available: "\f9c3";
$mxicon-attr_tt_own_wellness_facilities: "\f9c4";
$mxicon-attr_tt_own_water_sports_facilities: "\f9c5";
$mxicon-attr_tt_own_water_slide: "\f9c6";
$mxicon-attr_tt_own_tennis_court: "\f9c7";
$mxicon-attr_tt_own_squash_court: "\f9c8";
$mxicon-attr_tt_own_sauna_bathing_facilities: "\f9c9";
$mxicon-attr_tt_own_outdoor_pool: "\f9ca";
$mxicon-attr_tt_own_indoor_pool: "\f9cb";
$mxicon-attr_tt_own_fitness_facilities: "\f9cc";
$mxicon-attr_tt_other_theme_parks: "\f9cd";
$mxicon-attr_tt_non_smoking_rooms: "\f9ce";
$mxicon-attr_tt_non_smoking_hotel: "\f9cf";
$mxicon-attr_tt_nice_small_family_run_hotel: "\f9d0";
$mxicon-attr_tt_moviepark: "\f9d1";
$mxicon-attr_tt_mini_club_3to6_years: "\f9d2";
$mxicon-attr_tt_medical_service: "\f9d3";
$mxicon-attr_tt_maxi_club_7to12_years: "\f9d4";
$mxicon-attr_tt_massages_and_body_treatments: "\f9d5";
$mxicon-attr_tt_long_haul_journey: "\f9d6";
$mxicon-attr_tt_legoland_d: "\f9d7";
$mxicon-attr_tt_laundry_service: "\f9d8";
$mxicon-attr_tt_kids_disco: "\f9d9";
$mxicon-attr_tt_hotel_near_beach: "\f9da";
$mxicon-attr_tt_hotel_in_hiking_region: "\f9db";
$mxicon-attr_tt_hotel_brand_common: "\f9dc";
$mxicon-attr_tt_horseback_riding_close_to_hotel: "\f9dd";
$mxicon-attr_tt_heidepark: "\f9de";
$mxicon-attr_tt_heated_pool: "\f9df";
$mxicon-attr_tt_handicapped_accessible: "\f9e0";
$mxicon-attr_tt_great_sports_offer: "\f9e1";
$mxicon-attr_tt_golf_close_to_hotel_2: "\f9e2";
$mxicon-attr_tt_golf_close_to_hotel: "\f9e3";
$mxicon-attr_tt_german_entertainment_for_kids: "\f9e4";
$mxicon-attr_tt_gently_sloping_sandy_beach: "\f9e5";
$mxicon-attr_tt_gay_hotels: "\f9e6";
$mxicon-attr_tt_free_wlan: "\f9e7";
$mxicon-attr_tt_free_parking: "\f9e8";
$mxicon-attr_tt_family_friendly_2: "\f9e9";
$mxicon-attr_tt_family_friendly: "\f9ea";
$mxicon-attr_tt_family_benefits: "\f9eb";
$mxicon-attr_tt_elegant_deluxe: "\f9ec";
$mxicon-attr_tt_diving_close_to_hotel: "\f9ed";
$mxicon-attr_tt_disneyland: "\f9ee";
$mxicon-attr_tt_direct_proximity_ski_lift: "\f9ef";
$mxicon-attr_tt_direct_beach_access: "\f9f0";
$mxicon-attr_tt_cosmetic_treatments: "\f9f1";
$mxicon-attr_tt_club_with_entertainment: "\f9f2";
$mxicon-attr_tt_city_breaks: "\f9f3";
$mxicon-attr_tt_central_location: "\f9f4";
$mxicon-attr_tt_buggy_rent: "\f9f5";
$mxicon-attr_tt_buffet_for_children: "\f9f6";
$mxicon-attr_tt_bike_mountainbike_rental: "\f9f7";
$mxicon-attr_tt_beauty_farm: "\f9f8";
$mxicon-attr_tt_babysitting: "\f9f9";
$mxicon-attr_tt_baby_equipment: "\f9fa";
$mxicon-attr_tt_baby_cot: "\f9fb";
$mxicon-attr_tt_ayurveda: "\f9fc";
$mxicon-attr_tt_attractive_for_singles_w_child: "\f9fd";
$mxicon-attr_tt_attractive_for_singles: "\f9fe";
$mxicon-attr_tt_attractive_for_couples: "\f9ff";
$mxicon-attr_tt_adults_only: "\fa00";
$mxicon-attr_tt_action_adventures_parties_fun: "\fa01";
$mxicon-attr_tt_winter_sports_ski_area: "\fa02";
$mxicon-covid-text-circle-o: "\fa03";
$mxicon-covid-text-circle: "\fa04";
$mxicon-covid: "\fa05";
$mxicon-covid-circle: "\fa06";
$mxicon-covid-o: "\fa07";
$mxicon-attr_children_friendly: "\fa08";
$mxicon-covid2: "\fa09";
$mxicon-assistant-male: "\fa0a";
$mxicon-assistant-male-circle: "\fa0b";
$mxicon-assistant-male-circle-o: "\fa0c";
$mxicon-assistant-female: "\fa0d";
$mxicon-assistant-female-circle: "\fa0e";
$mxicon-assistant-female-circle-o: "\fa0f";
$mxicon-assistant-profile: "\fa10";
$mxicon-assistant-profile-circle: "\fa11";
$mxicon-assistant-profile-circle-o: "\fa12";
$mxicon-assistant-team-small: "\fa13";
$mxicon-assistant-team-small-circle: "\fa14";
$mxicon-assistant-team-small-circle-o: "\fa15";
$mxicon-assistant-team: "\fa16";




