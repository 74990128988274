/*
    multidynamic/dynamicsearch step3
*/

.allianz-banner {
    background-color: #063984;
    color: #fff;
    margin-top: 15px;
    text-align: center;

    a {
        color: #fff;
        text-decoration: underline;
    }

    @media (min-width: $screen-sm) {
        text-align: left;

        > div {
            display: table-row;

            > span {
                white-space: nowrap;
                padding-right: 20px;
                display: table-cell;
                vertical-align: middle;
            }
        }
        br {
            display: none;
        }
    }
}

.msd-infomessage-flight-additional {

    @media (min-width: $screen-sm) {
        min-width: $screen-sm;
        width: 70%;
        max-width: 0.8 * 1200px - 15px * 2;
    }

    .qtip-content {
        padding: 0;

        $icon-placeholder-size: 40px;

        .icon-placeholder {
            position: absolute;
            font-size: $icon-placeholder-size;
            top: calc(50% - #{$icon-placeholder-size/2} + #{$half-gutter});
            left: calc(50% - #{$icon-placeholder-size/2});
        }

        .msd-ifa-loading {
            height: 100px;

            .icon-placeholder {
                display: block !important;
                visibility: visible !important;
            }

            .msd-ifa-loading-text {
                display: block !important;
                visibility: visible !important;
            }

            div {
                display: none;
                visibility: hidden;
            }
        }

        .msd-ifa-empty {
            line-height: 2em;

            .msd-ifa-empty-text {
                display: block !important;
                visibility: visible !important;
            }

            div {
                display: none;
                visibility: hidden;
            }
        }

        .msd-ifa-route {
            .btn.active {
                background-color: $color1;
                color: $color15;
            }
        }

        .msd-ifa-section {
            line-height: 34px;
        }

        .select2-chosen {
            text-align: left;
        }

        .tabs-container {
            .inactive {
                display: none;
                visibility: hidden;
            }
            .active {
                display: block !important;
                visibility: visible !important;
            }
        }

        .msd-ifa-acceptance {
            line-height: 46px;

            .price-main {
                vertical-align: bottom;
                display: inline-block;
                line-height: 2em;
            }

            .btn {
                min-width: 100px;
                border-color: $color7;

                &:hover {
                    border-color: $color18;
                }
            }
        }
    }
}

.msd-m-t-n {
    margin-top: 0 !important;
}

.msd-m-r-n {
    margin-right: 0 !important;
}

.msd-m-b-n {
    margin-bottom: 0 !important;
}

.msd-m-l-n {
    margin-left: 0 !important;
}

.msd-m-t-xs {
    margin-top: 5px !important;
}

.msd-m-r-xs {
    margin-right: 5px !important;
}

.msd-m-b-xs {
    margin-bottom: 5px !important;
}

.msd-m-l-xs {
    margin-left: 5px !important;
}

.msd-m-t-sm {
    margin-top: 10px !important;
}

.msd-m-r-sm {
    margin-right: 10px !important;
}

.msd-m-b-sm {
    margin-bottom: 10px !important;
}

.msd-m-l-sm {
    margin-left: 10px !important;
}

.msd-m-t-md {
    margin-top: 15px !important;
}

.msd-m-r-md {
    margin-right: 15px !important;
}

.msd-m-b-md {
    margin-bottom: 15px !important;
}

.msd-m-l-md {
    margin-left: 15px !important;
}

.msd-m-t-lg {
    margin-top: 20px !important;
}

.msd-m-r-lg {
    margin-right: 20px !important;
}

.msd-m-b-lg {
    margin-bottom: 20px !important;
}

.msd-m-l-lg {
    margin-left: 20px !important;
}

.msd-p-t-n {
    padding-top: 0 !important;
}

.msd-p-r-n {
    padding-right: 0 !important;
}

.msd-p-b-n {
    padding-bottom: 0 !important;
}

.msd-p-l-n {
    padding-left: 0 !important;
}

.msd-p-t-xs {
    padding-top: 5px !important;
}

.msd-p-r-xs {
    padding-right: 5px !important;
}

.msd-p-b-xs {
    padding-bottom: 5px !important;
}

.msd-p-l-xs {
    padding-left: 5px !important;
}

.msd-p-t-sm {
    padding-top: 10px !important;
}

.msd-p-r-sm {
    padding-right: 10px !important;
}

.msd-p-b-sm {
    padding-bottom: 10px !important;
}

.msd-p-l-sm {
    padding-left: 10px !important;
}

.msd-p-t-md {
    padding-top: 15px !important;
}

.msd-p-r-md {
    padding-right: 15px !important;
}

.msd-p-b-md {
    padding-bottom: 15px !important;
}

.msd-p-l-md {
    padding-left: 15px !important;
}

.msd-p-t-lg {
    padding-top: 20px !important;
}

.msd-p-r-lg {
    padding-right: 20px !important;
}

.msd-p-b-lg {
    padding-bottom: 20px !important;
}

.msd-p-l-lg {
    padding-left: 20px !important;
}

.msd-offer-unavailable-window {

    .qtip-content {
        padding: 0 !important;

        .msd-offer-unavailable {
            text-align: left;
            padding: 15px;
            border-bottom: 1px solid $color19;
        }

        .btn-container {
            padding: 0 15px 10px 15px;

            a {
                min-width: 100%;
                text-transform: capitalize;
            }
        }
    }
}

.msd-flight-terms,
.msd-car-terms {

    @media (min-width: $screen-sm) {
        min-width: $screen-sm;
    }

    .qtip-content {
        padding: 0;
    }

    .terms-before-response {
        margin: 0 auto;
        padding: $half-gutter;
        text-align: center;

        .msg {
            margin-bottom: $half-gutter;
            font-size: 1.3em;
            line-height: 21px;
        }

        .btn-retry {
            min-width: 200px;
            padding: 7px 15px;
            font-size: 18px;
        }
    }

    .modal-section-header {
        height: 36px;
        border-top: 1px solid $color19;
        background-color: $color4;
        color: $color1;
        cursor: pointer;

        h2 {
            cursor: pointer;
            margin-top: 10px;
            padding-left: $half-gutter;
            font-size: 14px;
            text-align: left;
            text-transform: uppercase;
        }
        .btn-collapse {
            cursor: pointer;
        }
    }

    .modal-section-content {
        width: 100%;
        padding: 10px $half-gutter;
        line-height: 130%;
        text-align: left;
        background-color: $color5;
    }
}

.msd-flight-infomessage {

    .msd-flight-group-indicator {
        padding: 11px $half-gutter;
        border-bottom: 1px solid $color19;
        font-size: 18px;
        text-align: left;

        .fa {
            margin-right: $half-gutter;
            font-size: 28px;
            vertical-align: -5px;
        }

        .heading-text {
            display: inline-block;
        }
    }

    .msd-f-elem {
        display: table;
        width: 100%;
        padding: 11px $half-gutter;
        border-bottom: 1px solid $color19;
        text-align: left;

        .letter-naming {
            display: table-cell;
            width: 28px + $half-gutter;
            padding-right: $half-gutter;
            font-weight: 700;
            text-transform: uppercase;
        }

        .airport {
            display: table-cell;
            color: $color10;
            line-height: 1.3;
        }

        .airport-city {
            color: $color9;
        }
    }
}

.multisearchdetails {
    //position: relative;

    #notifySend {
        margin-top: 15vh;
        margin-bottom: 25vh;
        margin-right: auto;
        margin-left: auto;
        border: 1px solid $color3;
        width: 35vw;
        min-width: 300px;
    }

    .main-content {
    }
    .fixed-box {
    }

    .msd-infomessage-hotelfacilities {

        @media (min-width: $screen-sm) {
            width: 40%;
            min-width: $screen-xs;
        }
        .qtip-content {
            vertical-align: middle;
        }
        .icon-facilities {
            text-align: center;
            vertical-align: middle;
            display: inline-block;
            width: 30px;
            height: 30px;
            border: 1px solid;
            margin: 5px 10px 5px 0;
        }
    }

    .msd-infomessage-pricedetails {
        @media (min-width: $screen-sm) {
            min-width: $screen-sm;
            width: 70%;
            max-width: 1200px - 15px * 2;
        }

        .qtip-content {
            padding: 0 $half-gutter;
            padding-top: 5px;
        }
    }

    .msd-your-services {

        margin-left: auto;

        .t-cell.msd-ysi-cell-1 {
            width: 46.5px;
            padding-top: 7px;
            padding-bottom: 7px;
        }
        .t-cell.msd-ysi-cell-2 {
            width: 100%;
            padding-bottom: 0;
        }
        .t-cell.msd-ysi-cell-3 {
            width: 37px;
            // no delete until we support it
            width: 0;
            padding-bottom: 0;
        }

        .item-pricing {
            margin: 0 $margin-padding-md;

            .msd-cell {
                padding: 0;
                padding-bottom: 5px;
                vertical-align: top;
            }

            .msd-cell.msd-ysi-pricing-cell-1 {
                text-align: left;
            }
            .msd-cell.msd-ysi-pricing-cell-2 {
                padding-left: $margin-padding-sm;
                text-align: right;
                white-space: nowrap;
            }
        }

        .pricing-price {
            color: $color11;
        }

        .section-content {
            padding: 0;
            .your-services-price {
                background: $color4;
                min-height: 100px;
                padding: 12px 15px 15px;
                text-align: right;
                //border-bottom: 1px solid $color19;

                .main-price-container {
                    color: $color11;
                    vertical-align: baseline;

                    .msd-cell-2 {
                        font-size: 24px;
                    }
                }

                .msd-border-solid {
                    margin-top: 5px;
                    border-top: 2px dotted $color19;
                    padding-top: 5px;
                    width: 100%;
                }

                .table-secondary-price {
                    //margin-top: 6px;
                    font-size: 14px;
                    vertical-align: top;
                }

                .msd-cell {
                    padding: 0;
                    padding-bottom: 5px;
                }
                .msd-cell-1 {
                    width: 100%;
                    text-align: left;
                }
                .msd-cell-2 {
                    padding-left: 10px;
                    text-align: right;
                    white-space: nowrap;
                }

                .btn-next-step {
                    margin-top: 10px;
                    width: 100%;
                    padding: 5px $margin-padding-sm 6px;
                    font-size: 18px;
                }
            }

            .your-services-term {
                background: $color8;
                padding: 8px $margin-padding-md;
                border-bottom: 1px solid $color19;
            }

            .your-services-list {
                //display: table;
                border-collapse: collapse;
                width: 100%;
                background: $color8;
                .your-services-item {
                    display: block;
                    position: relative;
                    border-bottom: 1px solid $color19;
                    //z-index: 1;

                    .table-service-services-list {
                        .msd-cell {
                            padding: 0;
                            padding-bottom: 5px;
                        }
                        .msd-cell-1 {
                            width: 100%;
                            text-align: left;
                        }
                        .msd-cell-2 {
                            padding-left: 10px;
                            text-align: right;
                            white-space: nowrap;
                        }
                    }

                    .msd-ys-price-description {
                        display: inline-block;
                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }

                    .your-services-item-overlay {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 2;
                        cursor: pointer;

                        &:hover {
                            /*& + .t-table .services-item-icon,
                            & + .t-table .services-item-name {
                                color: $color9;
                            }*/
                            & + .t-table .services-item-name {
                                text-decoration: underline;
                            }
                            + .t-table .services-item-delete {
                                //display: block;
                                opacity: 1;
                            }
                        }
                    }

                    .services-item-icon {
                        //display: table-cell;
                        vertical-align: middle;
                        padding-left: $margin-padding-md;
                        font-size: 24px;
                    }

                    .services-item-name {
                        //display: table-cell;
                        vertical-align: middle;
                        color: $color1;
                        padding: 7px 10px 7px 7px;
                    }
                    .services-item-delete {
                        //display: none;
                        //position: relative;
                        //top: 50%;
                        //margin-top: -10px;
                        vertical-align: middle;
                        opacity: 0;
                        @include transition(opacity 0.15s ease-in-out);
                        &:hover {
                            display: block;
                        }

                        .link-delete {
                            position: relative;
                            //margin-right: 13px;
                            top: 0;
                            padding-top: 0;
                            color: $color21;
                            cursor: pointer;
                            z-index: 3;

                            .fa {
                                //top: -3px;
                                margin-right: 0;
                                font-size: 24px;
                            }

                            &:hover {
                                color: $color18;

                                .fa {
                                    animation: scale-150-proc .2s ease-out 1 normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .msd-special-messages,
    .msd-error-messages {
        margin-bottom: 15px;
        @include respond-to($screen-xs-max) {
            margin-right: $margin-padding-md;
            margin-left: $margin-padding-md;
        }
        padding: 15px 15px;
        font-size: 18px;
        text-align: center;
    }

    .btn-collapse {
        position: relative;
        top: 0;
        left: 0;
        height: 36px;
        width: 36px;
        cursor: pointer;
        line-height: 36px;
    }

    .btn-collapse-details {
        position: relative;
        top: 1px;
        left: 15px;
        text-align: center;

        &:hover {
            background-color: $color18;
        }

        .fa {
            line-height: 36px;
            vertical-align: middle;
        }

        &.collapse-hotel-alt-room{
            left: 0;
        }
    }

    .link {
        display: inline-block;
        //padding-top: 11px;
        text-decoration: underline;
        color: $color16;
        cursor: pointer;

        &:hover {
            color: $color18;
        }
        .fa {
            color: inherit;
            position: relative;
            top: 2px;
            left: 0;
            margin-right: 5px;
        }
    }

    .link-clipboard {
        display: inline-block;
    }

    .send-buttons-container {

        .btn-reserve-booking {
            font-size: $font-size-large;
            width: 100%;
            padding: 3px 0 4px;
        }
    }

    .total-charge {
        line-height: 20px;

        .total-price {
            font-size: $font-size-h3;
            white-space: nowrap;
            font-weight: bold;
        }
    }

    .msd-hotel-occupancy {
        line-height: 12px;
    }

    // Alternatives & reservation pending

    .msd-pending-info {
        display: none;
        position: fixed;
        top: calc(50% - 75px);
        left: calc(50% - 200px);
        width: 400px;
        padding: 40px 30px; //0 $margin-padding-lg;
        text-align: center;
        z-index: 15;
        @include respond-to($screen-xs-max) {
            position: fixed;
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            width: inherit !important;
        }

        .loading-results-pending,
        .loading-checking-availability,
        .loading-reservation-pending {
            margin: 0 auto 10px;
            font-size: 14px;
        }
    }

    &.msd-pending .data-section-box-overlay,
    &.msd-pending .msd-pending-info {
        display: block;
    }

    .icon-spinner /*.spinner-5-items*/
    {
        padding: 10px;
        font-size: 12px;
        letter-spacing: 2px;

        .fa {
            margin: 0 3px;
            @include opacity(0.4);

            &.semi3 {
                @include opacity(0.55);
            }
            &.semi2 {
                @include opacity(0.7);
            }
            &.semi {
                @include opacity(0.85);
            }
            &.bright {
                @include opacity(1);
            }
        }
    }

    .sort-results {
        background: $color12;
    }

    .select-order-container {
        $select-order-container-top: ($widget-header-height - $half-gutter - 32) / 2;
        $select-order-container-top: if($select-order-container-top < 0, 0, $select-order-container-top);

        position: relative;
        top: $select-order-container-top;
        height: 32px;
        min-width: 220px;
        margin-left: $half-gutter;
        border: 1px solid $color19;
    }

    .sort-results .select2-choice {
        height: 32px;
        padding: 0 10px;
    }
    .sort-results .select2-choice .select2-chosen,
    .sort-results .select2-choice:after {
        padding-top: 2px;
        line-height: 27px;
        color: $color9 !important;
    }

    .select2-alt-mock {
        > a > div {
            margin-right: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .select2-alt-mock-disabled {

        background: #f4f4f4;

        .select2-choice {
            cursor: default;

            &:after {
                content: "";
            }
        }
    }

    &.msd-alt-overlay {
        max-height: 80%;
        max-width: 1200px - $half-gutter * 2;
        width: 80%;

        @media (max-width: $screen-md - 1) {
            max-height: 100%;
            max-width: 100%;
            height: 100%;
            width: 100%;
        }

        &.msd-alt-full-height {
            @media (min-width: $screen-md) {
                height: 80%;
            }
        }

        $icon-placeholder-size: 60px;

        .qtip-content > div {
            height: 100%;
        }

        .select2-container {
            top: 0;
        }

        .msd-alt-same-price {
            font-size: inherit !important;
            font-weight: normal !important;
        }
        @each $wd in (20, 30, 40, 45, 60, 80, 100, 130) {
            .msd-wd-#{$wd} {
                display: inline-block;
                min-width: #{$wd}px;
            }
        }
        @media (max-width: $screen-sm - 1) {
            overflow-y: auto;

            .qtip-titlebar {
                height: $tooltip-default-title-height;
                position: fixed;
                width: 100%;
                z-index: 1;
            }
            .qtip-content {
                padding-top: $tooltip-default-title-height !important;
                height: 100%;
            }
            .btn-change-alternative {
                margin-top: 7px;
                width: 100%;
                letter-spacing: 0 !important;
            }
            .msd-alt-elem-persons {
                font-size: inherit !important;
            }
        }
        .msd-alt-template-title {
            height: $tooltip-default-title-height;
        }
        .msd-alt-list-container {
            height: calc(100% - #{$tooltip-default-title-height} - 64px);

            > div {
                height: 100%;
            }
        }
        .msd-alt-list {
            max-height: 100%;
            overflow-y: auto;

            li:first-child {
                border-top: none !important;
            }
        }
        .msd-alt-template-sorting {
            .select2-container {
                opacity: $pending-opacity;
            }
            .msd-alt-additional-header, .js-show-more {
                display: none !important;
            }
            .msd-alt-list {
                visibility: hidden;
                height: 80px;
            }
            .msd-alt-sorting-placeholder.icon-placeholder {
                display: block !important;
                visibility: visible !important;
            }
        }
        .msd-alt-template-loading {
            height: 150px !important;
        }
        .msd-alt-template-loading, .msd-alt-template-noresults {

            div {
                visibility: hidden;
                display: none;
            }
        }
        .msd-alt-template-loading.msd-alt-template-noresults {

            .msd-alt-noresults {
                display: none !important;
                visibility: visible !important;
            }
        }
        .msd-alt-template-noresults {

            .msd-alt-noresults {
                display: block !important;
                visibility: visible !important;
            }
        }
        .icon-placeholder {
            @include icon-placeholder($icon-placeholder-size);
        }
        .msd-alt-template-loading, .element-loading {

            .msd-alt-loading-placeholder.icon-placeholder {
                display: block !important;
                visibility: visible !important;
            }
        }
        .element-loading {

            .img-container {
                min-height: 100px;
                background-color: $color4;
            }

            .offer-img {
                visibility: hidden;
            }

            .icon-placeholder {
                display: block !important;
                visibility: visible !important;
            }
        }

        .img-container img {
            max-height: 130px;
        }
        .qtip-titlebar {

            .qtip-title {
                font-weight: normal;

                .fa {
                    font-size: 1.5em;
                }
                span {
                    margin: 0 0.4em;
                }
            }
        }
        .qtip-content {
            padding: 0 $half-gutter;
        }
        .name {
            font-weight: bold;
            margin-bottom: 4px;

            &.name-bigger {
                font-size: 18px;
                line-height: 22px;
                margin-right: 4px;
            }
        }
        .stars {
            line-height: 24px;
            font-size: 14px;
            white-space: nowrap;
        }

        .msd-alt-additional-header {
            line-height: 45px;
            white-space: nowrap;

            .msd-alt-additional-header-name {
                padding-left: 70px;
            }
        }

        .msd-alt-elem {
            line-height: 36px;
            word-wrap: normal;
        }

        .total-charge {

            .total-price {
                font-size: 18px;
                font-weight: bold;
            }
        }
        .msd-alt-elem-middle {
            width: 100%;
            display: inline-block;
            line-height: 20px;
            vertical-align: middle;
        }
        .msd-alt-elem-details-status {
            font-size: $font-size-h4;
            width: calc(100% - 35px);
            float: left;
        }
        .msd-alt-elem-select {
            width: 35px;
            float: left;
        }
        .btn-change-alternative {
            font-size: 16px;
            letter-spacing: 0.1em;
            min-width: 90%;
            .fa {
                font-size: 20px;
            }
        }

        // Flight specific
        .msd-alt-flight {
            line-height: 24px;
        }
        .next-day {
            font: {
                size: 75%;
                weight: 700;
            }
            vertical-align: 15%;
            color: $color21;
        }

        // Hotel specific
        &.msd-alt-overlay-hotel .msd-alt-list-container {
            @media (min-width: $screen-sm) {
                height: calc(100% - 2 * #{$tooltip-default-title-height} - 64px);
            }
        }
        .msd-alt-elem-persons {
            font-size: 18px;
            letter-spacing: 1px;
            .fa {
                vertical-align: -5%;
            }
        }

        // Car specific
        &.msd-alt-overlay-car .msd-alt-elem {
            line-height: $base-line-height;
        }
        .elem-car-option {
            display: inline-block;
            margin-right: $margin-padding-sm;
            &:last-of-type {
                margin-right: 0;
            }
        }
        .car-tooltip-content {
            width: 230px;
            line-height: $base-line-height !important;
            overflow: hidden;
        }
    }

    .msd-service-ref {
        width: 60px;
    }

    .msd-hotel-room-elem-header {
        .msd-service-ref {
            width: 100% !important;
            padding: 0;
            line-height: 36px;
            @include respond-to($screen-sm-max) {
                max-width: 120px;
            }
        }

        .msd-service-basket {
            //padding: 4px 7px;
            font-size: 16px;
            text-align: center;

            .fa {
                vertical-align: -25%;
            }
        }
    }

    .msd-hotel-overview {

        .msd-srv-cell.msd-srv-cell-1 {
            @include respond-to($screen-xs-max) {
                padding-right: 0px;
            }
        }

        .msd-srv-cell-2 {
            padding-right: 0;
            @include respond-to($screen-xs-max) {
                padding-left: 0;
                position: static;
            }
        }

        [data-anchor] {
            cursor: pointer;
        }

        .msd-hotel-info {
            margin: 7px 0;
            .fa.loading-icon {
                margin: 0;
            }
        }

        .msd-service-image {
            //height: 180px;
            //width: 260px;
            max-width: 100%;
            height: 130px;
            cursor: pointer;
            @include respond-to($screen-xs-max) {
                height: auto;
                width: 100%;
            }
        }

        .msd-elem-rating {
            float: none;
        }

        .msd-hotel-rating-container {
            @include respond-to($screen-xs-max) {
                position: absolute;
                top: 30px;
                right: 30px;
            }
        }

        .msd-hotel-rating-details {
            float: right;
            background-color: #f16a24;
            height: 47px;
            width: 55px;
            color: #fff;
            cursor: pointer;
            text-align: center;

            .msd-hotel-rating-logo {
                margin: 0;
                padding-top: 5px;
                font-size: 10px;
            }

            .msd-hotel-rating-value {
                margin: 0;
                font-size: 26px;
                line-height: 22px;
            }
        }

        .msd-hotel-description {
            margin-top: 2px;
            position: relative;
            line-height: 20px;

            .msd-service-description {
                float: left;
                margin-right: 15px;
                padding-left: 17px;

                &.no-margin{
                    margin: 0;
                }

                i {
                    left: 0;
                    top: 3px;
                    position: absolute;
                }
            }
            .msd-hotel-distance {
                float: left;
                font-size: 14px;
                white-space: nowrap;
                margin-right: 20px;
            }

            .msd-hotel-beach-distance {
                float: left;
                font-size: 14px;
                .fa-attr_beach {
                    font-size: 21px;
                }
            }
        }

        h2.msd-service-title {
            font-size: 26px;
            font-weight: bold;

            .stars {
                font-size: .6em;
            }
            sup {
                top: -0.4em;
            }
        }

        h3 {
            font-size: 14px;
            margin: 0;
            line-height: 17px;
        }

        .msd-table-hotel-info {
            margin: 10px 0;
            td {
                padding: 2px 10px 2px 0;
                vertical-align: top;
            }
            .msd-elem-rating {
                .rating {
                    margin-left: 0;
                }
                .rating-value {
                    display: inline;
                }
                img {
                    display: inline;
                }
            }
        }

        .msd-elem-rating {
            margin-bottom: 15px;
        }

        .facilities {
            li {
                padding: 1px 2px;
                i {
                    font-size: 24px;
                }
            }
        }
    }

    .msd-hotel-details-info {
        line-height: 45px;
        //white-space: nowrap;
        @include respond-to($screen-sm-max) {
            line-height: 20px;
            padding: 5px 0;
        }

        .msd-hotel-details-data {
            padding-right: 30px;
            margin-right: 30px;
            border-right: 1px solid $color10;
            white-space: nowrap;
            @include respond-to($screen-sm-max) {
                border: 0;
                padding: 0;
                margin: 0;
                display: block;
            }
        }

        .msd-hotel-details-occupancy {
            white-space: nowrap;
            @include respond-to($screen-sm-max) {
                display: block;
            }
        }

        .msd-hotel-operator-label {
            padding-right: 10px;
        }
        .operator-select {
            margin-top: 6px;
        }
    }

    .msd-hotel-details-header {
        line-height: 45px;
        white-space: nowrap;

        &.header-pending{
            @include opacity(0.05+$pending-opacity);
        }

        .header-icon{
            position: relative;
            margin-right: $margin-padding-md;

            .fa {
                position: relative;
                top: 5px;
                display: inline-block;
                font-size: 24px;
            }

            .fa-refresh{
                padding: 10px;
                margin: -10px;
                // set sizes for spin
                width: 44px;
                height: 44px;
                font-size: 24px;

                &:hover{
                    color: $color18;
                }
            }
        }

        .toggle-caret {
            vertical-align: -15%;
            font-size: 18px;
            display: none;
        }

        .active-sort {
            display: block;
            background-color: rgba(255, 255, 255, .3);

            .toggle-caret {
                display: inline-block;
            }
        }

        a {
            text-decoration: underline;
        }
    }

    .msd-hotel-room-elem {
        //display: none;

        &:last-of-type {
            border-bottom-width: 0;
        }

        &.hidden {
            display: none;
        }

        &.visible {
            display: block;
        }
    }

    .msd-hotel-room-elem-header {
        line-height: 36px;
        position: relative;

        .small-padding {
            padding: 0 2px;
        }

        .small-padding-left {
            padding-left: 2px;
            @include respond-to($screen-xs-max) {
                padding-left: 15px;
            }
        }

        .msd-hotel-room-elem-header-text {
            text-align: center;

            .default-cursor {
                cursor: default;
            }

            .btn {
                overflow: hidden;
                width: 90%;
                height: 36px;

                &.btn-reserve-booking {
                    width: 100%;
                }
            }

            i.fa {
                font-size: 18px;
                vertical-align: sub;
            }
        }

        .total-charge {
            .total-price {
                font-size: $font-size-h4;
                line-height: 38px;
            }
        }
        .msd-hotel-room-title {
            width: 100%;
            vertical-align: middle;
        }

        .msd-hotel-room-name {
            display: inline-block;
            line-height: 16px;
            //padding: 0 15px;
            vertical-align: middle;
            @include respond-to($screen-sm-max) {
                padding-left: 0;
                padding-right: 15px;
            }
        }
        .msd-hotel-room-service {
            display: inline-block;
            line-height: 16px;
            vertical-align: middle;
            @include respond-to($screen-xs-max) {
                vertical-align: initial;
            }
        }
        .msd-hotel-room-payment {
            display: inline-block;
            line-height: 16px;
            vertical-align: middle;
            @include respond-to($screen-xs-max) {
                vertical-align: initial;
            }
        }
        .msd-hotel-room-details-status {
            font-size: $font-size-h4;

            @include respond-to($screen-xs-max) {
                width: auto;
            }

            .header-label {
                padding-left: 66px;
                font-size: 14px;
            }
        }
        .msd-hotel-room-elem-select {
            width: 35px;
            //float: left;
            padding-right: 4px;
            @include respond-to($screen-sm-max) {
                padding-right: 0;
            }
            @include respond-to($screen-xs-max) {
                padding-left: 30px;
                line-height: 0;
            }
        }

        .radio-stack {
            top: 0;
            right: -1px;
            float: right;
            line-height: inherit;
            @include respond-to($screen-sm-max) {
                top: 15px;
                right: 7px;
            }
            @include respond-to($screen-xs-max) {
                top: 30px;
            }
        }
    }

    .radio-container {
        position: absolute;
        right: 0;
        @include respond-to($screen-sm-max) {
            top: 0;
        }
    }

    .msd-hotel-room-details {
        padding: 15px 15px 15px 70px;
        @include respond-to($screen-xs-max) {
            padding-left: 20px;
        }

        .msd-hotel-room-elem-header-text {
            @include respond-to($screen-xs-max) {
                padding-left: 15px;
            }
        }

        .msd-hotel-room-elem-header-table {
            margin: 0 35px 0 15px;
            tr {
                vertical-align: top;
                padding: 5px;
            }
            td:first-child {
                text-align: left;
                white-space: nowrap;
            }
            td:last-child {
                text-align: left;
                padding-left: 25px;
            }
        }

        .extra-info-label {
            margin: 15px 0 5px;
            display: block;
        }
    }

    .msd-hotel-sidebar {
        position: relative;

        .msd-your-services {
            width: 165px !important;
            @include respond-to($screen-md-max) {
                width: 128px !important;
                .total-price {
                    font-size: 19px;
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            min-height: 165px;
            left: -1px;
            background-color: $color19;
            @include respond-to($screen-xs-max) {
                display: none;
            }
        }
    }

    .msd-hotel-details-reserve {
        line-height: 30px;

        .msd-hotel-expandable-table {
            width: 100%;
            padding: 10px 15px;

            a {
                text-decoration: underline;
            }

            .row {
                border-bottom: 2px dotted $color19;
                &:first-child {
                    border-top: 2px dotted $color19;
                }

                & > div {
                    padding: 0;
                }

                & > div:first-child {
                    text-align: left;
                }
                & > div:last-child {
                    text-align: right;
                }
            }
        }
    }

    .msd-hotel-additional {
        padding-top: 25px;

        .msd-hotel-additional-item {

            .msd-hotel-additional-item-header {
                h2 {
                    font-size: $font-size-large;
                    margin: 0;
                    a {
                        display: block;
                    }
                }
            }

            .msd-hotel-additional-item-content {
                display: none;

                .msd-hotel-gallery-image-container {
                    margin-bottom: 20px;
                    height: 180px;

                    .msd-hotel-gallery-image {
                        height: 180px;
                        width: 100%;
                        background-size: cover;
                        background-position: center;
                        cursor: pointer;
                    }
                }
                &.facilities > div {
                    padding: 5px 15px;

                    @include respond-from($screen-lg-min) {
                        &.facilities-clearfix:nth-of-type(12n) {
                            content: '';
                            display: table;
                            clear: both;
                        }
                    }

                    @include respond-to($screen-md-max) {
                        @include respond-from($screen-md-min) {
                            &.facilities-clearfix:nth-of-type(8n) {
                                content: '';
                                display: table;
                                clear: both;
                            }
                        }
                    }

                    @include respond-to($screen-sm-max) {
                        @include respond-from($screen-sm-min) {
                            &.facilities-clearfix:nth-of-type(6n) {
                                content: '';
                                display: table;
                                clear: both;
                            }
                        }
                    }

                    @include respond-to($screen-xs-max) {
                        &.facilities-clearfix:nth-of-type(4n) {
                            content: '';
                            display: table;
                            clear: both;
                        }
                    }

                    &.facilities-clearfix {
                        padding: 0;
                    }

                    i.fa {
                        top: inherit;
                        margin-top: 0;
                        line-height: 1;
                    }
                }
            }

            &:last-child {
                .msd-hotel-additional-item-content {
                    border-bottom: 1px solid $color1;
                }
            }
        }
    }

    .msd-hotel-final-price {
        .total-charge {
            margin: 0 20px;
        }
    }

    .msd-services-list {
        margin-bottom: 0;
    }
}

.msd-service-elem {
    &:not(:first-of-type) {
        border-top: 1px solid $color19;
    }

    &.flight-service {

    }

    &.hotel-service {

        .msd-hotel-distances-container {
            padding-top: 2px;
            @include respond-from($screen-sm-min) {
                padding-bottom: 10px;
            }
        }
        .msd-hotel-distance {
            display: inline-block;
            margin-right: $margin-padding-sm;
            font-weight: 700;
            color: $color1;

            &:last-child {
                margin-right: 0;
            }

            .fa {
                font-size: 1.7em;
                vertical-align: -27%;
            }
        }
    }

    &.insurance-service, &.parking-service, &.transfer-service {
        .msd-service-content-container {
            padding-top: 10px;
        }
    }

    .msd-service-header {
        position: relative;
        padding: $margin-padding-md;
        min-height: 70px;
        background: $color8;

        .msd-service-header-price {
            text-align: right;
            display: table-cell;
            vertical-align: middle;
            width: 35%;

            @include respond-to($screen-xs-max) {
                display: block;
                width: 100%;
                text-align: left;
            }

            .total-price-container {
                color: $color11;
            }

            span.total-price {
                font-size: 24px;
            }

            button {
                margin-top: 15px;
                width: 100%;
            }
        }

        @include respond-to($screen-xs-max) {
            padding-bottom: 3px;
        }
    }

    .msd-icon-pos {
        position: absolute;
        top: 50%;
        left: $margin-padding-md;
        margin-top: -20.5px;
        height: 41px;
        width: 41px;

        .fa {
            font-size: 40px;
        }
    }
    .msd-icon-pos-offset {
        display: table;
        padding-left: 55px;
        width: 100%;
        min-height: 41px;
        table-layout: fixed;

        .msd-service-head {
            display: table-cell;
            vertical-align: middle;
            @include respond-to($screen-xs-max) {
                display: block;
            }
        }
        .msd-service-ctrl {
            display: table-cell;
            padding-top: 2px;
            padding-left: $margin-padding-xs;
            vertical-align: middle;
            width: 35%;
            @include respond-to($screen-sm-max) {
                width: 45%;
            }
            @include respond-to($screen-xs-max) {
                display: block;
                width: auto;
                padding-top: 0;
                padding-left: 0;
            }
        }
    }

    .msd-service-title {
        margin: 0;
        font-size: 18px;
        color: $color1;

        .stars {
            display: inline-block;
            top: 0;
            font-size: 0.8em;
        }
    }
    .msd-service-description {
        margin-top: 2px;
    }

    .msd-srv-header-link {
        font-size: 16px;
        /*display: inline-block;
        padding-top: 11px;
        text-decoration: underline;
        color: rgb(112,169,3);
        cursor: pointer;*/

        .fa {
            margin-right: 0;
            margin-left: $margin-padding-xs;
        }

        .link {
            padding: 7px 0;
        }
    }

    .msd-service-ctrl-btn-container {
        border-top: 1px solid $color19;
        padding: 10px 0;
        width: 100%;
        height: 56px;
        background: $color8;

        .msd-srv-header-btn {
            float: right;
            width: 50%;
            padding: 0 15px;

            .btn {
                width: 100%;
            }
        }
    }
    /*.msd-footer-link {
        margin-top: 20px;

        @include respond-to($screen-xs-max) {
            margin-top: 0;
        }
    }*/

    .car-operator-info {
        margin-top: 7px;

        .car-operator-info-item {
            display: inline-block;

            @include respond-to($screen-xxs-max) {
                display: block;
            }
        }

        .car-operator-info-sep {
            display: inline-block;

            @include respond-to($screen-xxs-max) {
                display: none;
            }
        }

        @include respond-to($screen-xxs-max) {
            .car-operator-info-item:first-child {
                margin-bottom: 5px;
            }
        }
    }

    @include respond-to($screen-xs-max) {
        .service-image {
            width: 100%;
            margin-bottom: 15px;
            text-align: center;
        }

        .car-service-info {
            width: 100%;
        }
    }

    @include respond-to($screen-xxs-max) {
        .service-image {
            text-align: center;
        }
    }

    .cell-service-date {
        padding-left: 40px;
        white-space: pre;

        @include respond-to($screen-xxs-max) {
            padding-left: 0;
            display: block;
        }
    }

    .car-desc-item {
        margin-right: 10px;
        display: inline-block;
        margin-bottom: 4px;

        &:last-child {
            margin-right: 0;
            margin-bottom: 7px;
        }

        .fa {
            padding-right: 5px;
            color: $color1;
        }
    }

    .msd-srv-cell {
        display: table-cell;
        vertical-align: top;
        //padding: 0;
        @include respond-to($screen-xs-max) {
            display: block;
        }

        &.msd-srv-cell-1 {
            width: 275px;

            @include respond-to($screen-xs-max) {
                width: 100%;
                margin-right: 0;
                margin-bottom: $margin-padding-md;
            }

            /*&.msd-image-wrapper .img-container{
                width: 260px;
                min-height: 172px;
                background-color: $color4;
                @include respond-to($screen-xs-max) {
                    width: 100%;
                    //margin-right: 0;
                    //margin-bottom: $margin-padding-md;
                }
            }*/
        }
        &.msd-srv-cell-2 {
        }
    }

    .msd-service-image {
        width: 260px;
        cursor: pointer;

        @include respond-to($screen-xs-max) {
            width: 100%;
        }
    }

    .msd-service-delete-link {
        float: right;
        margin-left: $margin-padding-md;
        @include respond-to($screen-sm-max) {
            display: none;
        }

        .link-delete {
            color: $color21;

            &:hover {
                color: $color18;

                .fa {
                    animation: scale-150-proc .2s ease-out 1 normal;
                }
            }
        }
    }

    .msd-service-alternative-link {
        float: right;
        margin-right: -3px;
        @include respond-to($screen-xs-max) {
            display: none;
        }

        &:hover {
            color: $color18;

            .fa {
                animation: scale-150-proc .2s ease-out 1 normal;
            }
        }
    }

    .msd-service-term {
        background: $color4;
        padding: $margin-padding-md $margin-padding-md 11px 70px;
        @include respond-to($screen-xs-max) {
            padding-left: $margin-padding-md;
        }
        min-height: 52px;
        max-height: 80px;

        .sli-container,
        .msd-srv-beforeslider,
        .msd-srv-afterslider {
            display: inline-block;
        }
        .sli-container {
            @include respond-to($screen-xs-max) {
                display: block;
                margin-right: 5px;
                margin-left: 5px;
            }
        }
        .msd-srv-duration {
            font-weight: 700;
            color: $color1;
        }
        .next-day {
            position: relative;
            top: -5px;
            color: $color21;
        }
    }

    .msd-service-content-container {
        background: "$bgcolor3";
        padding: $margin-padding-md;
        padding-bottom: $margin-padding-sm;
        padding-left: 70px;
        @include respond-to($screen-xs-max) {
            padding-left: $margin-padding-md;
        }
        min-height: 123px;

        .msd-service-content {

            &.flight-service {
                margin-left: -70px;
                padding-left: 15px;
            }

            .service-content-desc {
                padding: 10px 0;
                border-bottom: 2px dotted $color19;
                text-align: justify;
            }

            .service-content-table {
                display: table;
                border-collapse: collapse;
                width: 100%;

                .service-content-row {
                    border-bottom: 2px dotted $color19;

                    .service-cell {
                        display: table-cell;
                        vertical-align: middle;
                        padding: 7px 0;

                        &.cell-01 {
                            width: 160px;

                            @include respond-to($screen-xs-max) {
                                width: 110px;
                            }
                        }

                        &.cell-02 {
                        }
                    }

                    /*&:first-child {
                        .service-cell {
                            padding-top: 0;
                        }
                    }*/
                }
            }

        }
        .msd-service-footer {

            .msd-link {
                padding: 7px 0 3px;
            }

            .msd-srv-prices-container {
                position: relative;
                //margin-top: 5px;

                .msd-srv-add-to-cart {
                    position: absolute;
                    top: 11px;

                    .fa {
                        position: relative;
                        top: 2px;
                        left: 0;
                        margin-right: 5px;
                    }
                }

                .price-main {
                    font-size: 24px;
                    color: $color11;
                    &.msd-price-main-both-prices {
                        @include respond-to($screen-xs-max) {
                            display: block;
                            position: relative;
                            top: 20px;
                            clear: both;
                        }
                    }

                    .description {
                        font-size: 14px;
                    }
                }
                .price-second {
                    margin-top: 12px;
                    margin-right: 15px;
                    font-size: 14px;
                    color: $color10;
                    &.msd-price-second-both-prices {
                        @include respond-to($screen-xs-max) {
                            display: block;
                            position: relative;
                            bottom: 32px;
                            margin-right: 0;
                            margin-top: 0;
                            clear: both;
                        }
                    }
                }
            }
        }
    }
}

.msd-service-details {
    padding: 5px 0;
    border-bottom: 2px dotted $color19;

    .fixed-first-col {
        float: left;
        @include respond-to($screen-xs-max) {
            width: 110px;
            float: left;
        }
        @include respond-from($screen-sm-min) {
            width: 160px;
            float: left;
        }
    }

    .transfer-content-row {
        float: left;
        @include respond-to($screen-xs-max) {
            width: calc(100% - 110px);
        }
        @include respond-from($screen-sm-min) {
            width: calc(100% - 160px);
        }
    }

}

.msd-srv-cell-2 {
    .msd-cell {
        padding-bottom: 7px;
    }
    .t-cell.msd-cell-1 {
        width: 120px;
        min-height: 50px;
        &.respond-sm {
            @include respond-to($screen-xs-max) {
                display: block;
                width: auto;
                min-height: auto;
            }
        }
        &.msd-cell-xs-inline-block {
            @include respond-to($screen-xs-max) {
                display: inline-block;
            }
        }
    }
    .t-cell.msd-cell-2 {
        &.respond-sm {
            @include respond-to($screen-xs-max) {
                display: block;
            }
        }
        &.msd-cell-xs-inline-block {
            @include respond-to($screen-xs-max) {
                display: inline-block;
            }
        }
    }
}

.msd-package-price-container {
    padding: 6px $margin-padding-md 6px;
    @include respond-to($screen-xs-max) {
        padding-top: $margin-padding-sm;
        padding-bottom: $margin-padding-sm;
    }

    &.msd-package-price-container-top {
        @include respond-from($screen-lg-min) {
            display: none;
        }
        background: "$bgcolor4";
        border-bottom: 1px solid $color19;
    }
    &.msd-package-price-container-bottom {
        border-top: 1px solid $color19;
    }
    &.msd-package-price-container-hotel-only {
        background: transparent;
        border-bottom: none;
        float: right;
        padding-top: 11px;
        @include respond-to($screen-xs-max) {
            float: none;
        }
        @include respond-from($screen-lg-min) {
            display: block;
        }
    }

    .price-main {
        font-size: 36px;
        color: $color11;
        @include respond-to($screen-xs-max) {
            display: block;
        }

        .description {
            font-size: 18px;
        }
    }
    .price-second {
        margin-top: 24px;
        margin-right: 15px;
        font-size: 14px;
        color: $color10;
        @include respond-to($screen-xs-max) {
            display: block;
        }
    }

    .msd-package-cart-xs-wrapper {
        text-align: center;
    }
    .msd-package-add-to-cart {
        margin-top: 24px;

        @include respond-to($screen-xs-max) {
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
        }

        .fa {
            position: relative;
            top: 2px;
            left: 0;
            margin-right: 5px;
        }
    }
}

.msd-ys-package-price-container {
    padding: 15px;
    text-align: center;
    background: "$bgcolor4";

    .msd-package-add-to-cart {
        margin-right: auto;
        margin-left: auto;

        .fa {
            position: relative;
            top: 2px;
            left: 0;
            margin-right: 5px;
        }
    }
}

.sli-label-main {
    font-weight: 700;
}

.sli-container {
    position: relative;
    margin: -12px $margin-padding-sm 0;
    padding-top: 22px;
    height: 29px;

    .msd-srv-duration {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        text-align: center;
    }

    .sli-handle {
        position: absolute;
        top: 10px;
        margin-left: -7px;
        padding: 7px;
        color: $color1;
        z-index: 1;

        &:hover {
            //color: $color7;
        }

        &.sli-handle-start {
            left: 0%;
        }

        &.sli-handle-end {
            left: 100%;
        }

        .fa {
            position: absolute;
            left: 0;
        }
    }
    .sli-path {
        position: relative;
        width: 360px;
        height: 4px;
        @include border-radius(2px);
        //background: $color5;
        background: $color4;

        @include respond-to($screen-xs-max) {
            width: 100%;
        }

        &.sli-path-active {
            margin-left: 0%;
            width: 100%;
            //background: $color1;
        }

        .sli-segment {
            position: absolute;
            top: 0;
            left: 0;
            height: 4px;

            &.sli-segment-active .sli-segment-core {
                background: $color1;
            }

            &:first-child .sli-segment-core {
                left: 0;
            }
            &:last-child .sli-segment-core {
                right: 0;
            }

            .sli-segment-core {
                position: absolute;
                top: 0;
                right: 1px;
                left: 1px;
                height: inherit;
                //background: transparent;
                background: $color5;
            }
        }
    }
}

.flight-service .flightresult {

    .icon-pos {
        left: 15px;
    }
}

.msd-service-elem .msd-service-content-container {
    padding-top: 0;

    .flightbooking .icon-direction-size {
        font-size: 24px;
        margin-left: 9px;
        margin-right: 16px;
        vertical-align: 13px;
    }

    .flight-schedule.flight-schedule-first {
        display: inline-block;
        margin-left: 2.5px;
    }

    .flightresult .flight-schedule .hour {
        font-size: 18px;
    }
    .flightresult .flight-schedule .next-day,
    .flightresult .flight-schedule .next-day-xs {
        vertical-align: 38%;
    }

    .flightresult .flight-insights .luggage {
        padding-top: 0;

        .fa-2x {
            font-size: 24px;
        }
    }

    /*.flightresult .flight-schedule .airport{
        display: inline;
    }

    .flight-schedule{
        display: inline;
    }

    .flight-insights{
        display: inline;
    }

    .flightresult .flight-insights .stops{
        display: inline;
    }

    .flightresult .flight-insights .flight-number{
        display: inline;
    }*/

    .flightresult .flight-insights .duration, .flightresult .flight-insights .airline {
        padding-top: 1px;
    }
}

.f-list-elem.msd-f-list-elem {
    padding-bottom: 10px;
    border-bottom: none;
    border-top: 1px solid $color19;
}

.flightbooking .link.msd-link {
    padding: 5px 0;
    &:not(:last-child) {
        margin-right: $margin-padding-sm;
    }
}

.msd-elem-rating-container {
    padding-top: 4px;
}

.elem-rating.msd-elem-rating .rating-container.msd-rating-container {
    @include respond-to($screen-xs-max) {
        width: auto;
        margin-right: 10px;
    }

    .progress {
        //display: inline-block;
        //float: none;
        //margin-top: 2px;
        //vertical-align: -29%;
        @include respond-to($screen-xs-max) {
            display: none;
            margin-top: 7px;
        }
    }
}

.msd-rating-container .progress-bar {
    @include opacity($pending-opacity);
}

.msd-m-l-55 { /* is still needed*/
    margin-left: 55px;
}

.flight-details.msd-flight-details {
    @extend .msd-m-l-55;
    @include respond-to($screen-xs-max) {
        padding-bottom: 10px;
    }

    .msd-leg-attributes { /* TODO: placeholders & includes instead of extends */
        @extend .text-color-10;
        @extend .msd-m-t-xs;

        &.msd-leg-attributes-last {
            @include respond-from($screen-sm-min) {
                margin-bottom: 10px;
            }
        }
    }
}

.flightbooking .flight-restrictions.msd-flight-restrictions {
    //margin-top: 10px;
    padding: $margin-padding-sm $margin-padding-md;
    @include respond-to($screen-xs-max) {
        margin-top: 0;
        margin-left: 31px;
    }
}

.msd-restrictions-icon {
    position: absolute;
    top: 0;
    left: $margin-padding-md;
    margin-left: 9px;
    width: 24px;
    height: 28px;
    font-size: 24px;
}

.flightbooking .flight-restrictions .restrictions-txt.msd-restrictions-txt.msd-restrictions-icon-pos-offset {
    position: relative;
    top: 0;

    display: inline-block;
    padding-top: 7px;
    padding-left: 55px;
    width: 100%;
    @include respond-to($screen-xs-max) {
        padding-left: 48px;
    }
}

.flightbooking .flight-restrictions .restrictions-list.msd-restrictions-list {
    margin-top: 5px;
    margin-left: 50px;
    @include respond-to($screen-xs-max) {
        margin-top: 10px;
        margin-left: 12px;
    }
}

.msd-flight-extra-block {
    position: relative;
    //margin-top: 10px;
    border-top: 1px solid $color19;
    padding-top: 10px;
    /*margin-bottom: 10px;
    border-bottom: 1px solid $color19;*/
    padding-bottom: 10px;
    //padding-left: 24px;
    color: $color1;
    /*@include respond-to($screen-xs-max){
        padding-bottom: 10px;
    }*/
    @include respond-to($screen-xs-max) {
        margin-left: 46px;
    }

    &.msd-flight-addon {
        //  font-size: 16px;
    }

    .msd-extra-block-icon {
        display: inline-block;
        position: relative;
        left: 28px;
        width: 70px;
        font-size: 18px; //24px;
        @include respond-to($screen-xs-max) {
            left: 25px;
            width: 66px;
        }

        &.fa-plus {
            left: 30px;
            @include respond-to($screen-xs-max) {
                left: 27px;
            }
        }
    }
    /*.msd-extra-block-icon-pos {
        position: absolute;
        top: 11px;
        left: $margin-padding-md;
        margin-left: 10px;
    }*/

    .msd-extra-block-table-wrapper {
        //display: block;
        //width: 100%;
        /*padding-left: 46px;
        @include respond-to($screen-xs-max) {
            padding-left: 39px;
        }*/

        .msd-last-ticket-date-txt {

        }
        .msd-last-ticket-date-val {
            font: {
                size: 18px;
                weight: 700;
            }
        }

        .msd-cell {
            padding: 0;
            text-align: left;
        }

        .msd-flight-addon-cell-0 {
        }
        .msd-flight-addon-cell-1 {
            width: 100%;
            text-align: left;
        }
        .msd-flight-addon-cell-2 {
            padding: 0 $margin-padding-md;
            text-align: right;
            white-space: nowrap;
        }

        .msd-flight-addon-delete {
            display: inline-block;
            margin-right: -5px;
            padding: 5px;
            color: $color21;
            cursor: pointer;

            &:hover {
                color: $color18;

                .fa {
                    //position: absolute;
                    animation: scale-150-proc .2s ease-out 1 normal;
                    //z-index:1;
                }
            }
        }
    }
}

.f-ending-border {
    margin-bottom: 0;
    //@include respond-to($screen-xs-max){
    margin-top: 0;
    //}

    &.f-ending-border-solid {
        //margin-top: 10px;
        border-top: 1px solid $color19;
        padding-top: 5px;
    }
}

.elem-wrapped-xs.mds-elem-wrapped-xs {
    @include respond-to($screen-xs-max) {
        padding-left: 70px;
        padding-bottom: 10px !important;
        border-top: 1px solid $color19;
    }
}

.no-pull-500 {
    @include respond-to(500px) {
        float: none !important;
    }
}

.btn-go-step4 {
    white-space: normal;
    padding: 5px $margin-padding-sm 6px;
    min-width: 120px;
    font-size: 18px;
    @include respond-to($screen-xs-max) {
        width: 100%;
    }
    @include respond-from($screen-sm-min) {
        float: right;
        margin-top: 6px;
        margin-left: 15px;
    }

    .xs-price-second {
        white-space: nowrap;
        font-size: 16px;
        @include respond-to(500px) {
            display: block;
        }
    }
}

.multisearchreserve .msd-service-content-container.car-only {
    padding-bottom: 0;
}

.msd-service-content.car-service {

    .elem-info {
        line-height: 10px;
    }
    .name {
        margin-right: 4px;
        font-weight: bold;

        &.name-bigger {
            font-size: 24px;
        }
    }

    .car-name-ext {
        font-size: 14px;
    }

    .car-border-bottom {
        border-bottom: 1px solid $color19;
    }

    .img-container {
        width: 100%;
        @include respond-from($screen-sm-min) {
            width: calc(100% - 15px);
        }
        min-height: 130px;
        text-align: center;
        background-color: #fff;

        .msd-service-image {
            display: inline-block;
            width: 100%;
            border: $margin-padding-md solid #fff;
            background-color: #fff;
        }
    }

    .car-desc {
        margin-top: 15px;
        .car-desc-item {
            margin-right: 10px;
            display: inline-block;
            margin-bottom: 4px;

            &:last-child {
                margin-right: 0;
                margin-bottom: 7px;
            }

            .fa {
                padding-right: 5px;
                color: $color1;
            }
        }
    }

    .car-operator-info {
        margin-top: 4px;
    }

    .msd-car-second-table {
        margin: 10px 0;
        @include respond-to($screen-xs-max) {
            margin-top: 7px;
        }
        th {
            padding: 5px;
            font-weight: normal;
            text-align: left;
            &:first-child {
                padding-left: 0;
            }
        }
    }

    .msd-booking-total-price-container {
        padding: 2px 15px;
    }

    //END of car service
}

.msd-hotel-details-content {
    //min-height: 165px;
    .border-right {
        @include respond-to($screen-xs-max) {
            border-right-width: 0;
        }
    }
    .hidden-group {
        display: none;
        visibility: hidden;

        & + .msd-hotel-room-more {
            display: none;
            visibility: hidden;
        }
    }
}

.msd-hotel-button-list {

    .btn {
        background-color: $color15;
        color: $color1;
        &.active-hotel-btn {
            background-color: $color1;
            color: $color15;
            cursor: default;
        }

        .fa {
            vertical-align: -15%;
            padding-left: 2px;
        }
    }
}

.msd-service-content.parking-service {
    padding-bottom: 5px;
    .msd-parking-info-label {
        padding: 7px 0;
    }
}

.msd-bottom-border {
    border-bottom: 2px dotted $color19;
}

.msd-row-padding {
    padding: 7px 0;
}

.msd-mask-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.capitalized-text {
    text-transform: capitalize;
}

.msd-btn-back {
    float: left;
    line-height: 40px;
    display: block;
    width: 39px;
    height: 39px;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 24px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    z-index: 10;

    &.btn-reserve-back {
        margin: 15px 0 15px 15px;
    }

    &.btn-details-back {
        margin: 6px 0 0px 0px;

        @include respond-to($screen-xs-max) {
            margin-top: 0;
        }
    }
}

.no-wrap {
    white-space: nowrap;
}

$icon-placeholder-size: 60px;
[data-type=hotel-only], .hotel-service {

    .msd-image-wrapper {
        height: 130px;
        position: relative;
        @include respond-to($screen-xs-max) {
            height: auto;
            margin-bottom: 15px;
        }

        &.msd-no-photo {
            vertical-align: middle;
            text-align: center;
        }

        $icon-placeholder-size: 90px;
        @include respond-to($screen-xs-max) {
            min-height: $icon-placeholder-size;
        }
        .icon-placeholder {
            @include icon-placeholder($icon-placeholder-size);
        }
    }

    .element-loading {
        position: relative;
        min-height: 130px;
        .icon-placeholder {
            @include icon-placeholder($icon-placeholder-size);
        }

        .img-container {
            min-height: 230px;

            img {
                visibility: hidden;
            }
        }

    }

    .loading-icon {
        display: table;
        margin: 30px auto;
    }
}

.hotel-service {
    .element-loading {
        .icon-placeholder {
            top: calc(50% - #{$icon-placeholder-size});
        }
        .img-container {
            position: relative;
            width: 260px;
            min-height: 172px;
            background-color: $color4;
            @include respond-to($screen-xs-max) {
                width: 100%;
            }
        }
    }
}