*::-ms-clear {
    display: none;
}

.select2-country-drop {
    .select2-results {
        overflow-y: hidden;
    }
}

.booking-wait-screen {
    .wait-status {
        min-height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
    }
}

.msd-section-payment {
    background: $color5;
}

.multisearchreserve {

    .msd-selected-package {
        border-bottom: 1px solid $color19;
    }

    .msd-service-content-container {
        padding: 15px;
    }

    #msd-booking-agreements {
        .msd-form-panel-elem {
            display: block;
            margin-top: -1px;
            position: relative;

            .msd-form-panel-elem-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                margin: 0;
            }

            &.error {
                background: $input-err-col-transp;
                border: 1px solid $color21 !important;

                .last-plan:before {
                    color: $color21;
                }
            }
        }
    }

    .msd-selected-package {
        margin-bottom: 0;
    }

    .msd-booking-total-price-container {
        padding: 10px 15px;
        text-align: right;

        .msd-total-charge {
            font-size: 18px;
            color: $color11;

            .total-price {
                font-size: 36px;
                white-space: nowrap;
            }
        }

        &.top-price-container {
            background: "$bgcolor3";
            margin-bottom: 15px;
        }
    }

    .msd-booking-elem {
        .section-content {
            background: "$bgcolor3";
        }

        .msd-booking-person-heading {
            background: "$bgcolor4";
            color: $color19;
            margin: 0;

            .msd-booking-person-heading-title {
                color: $color9;
                display: table;
                height: 40px;
                line-height: 18px;

                span {
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            .msd-head-payer {
                height: 40px;
                display: table;
                float: right;

                .msd-head-payer-title {
                    padding-right: 10px;
                    display: table-cell;
                    vertical-align: middle;
                    color: $color9;
                    line-height: 18px;
                }

                .msd-payer-checkbox {
                    display: table-cell;
                    vertical-align: middle;

                    .checkbox-stack {
                        top: 0px;
                        vertical-align: middle;
                        margin: 0px;
                        height: 22px;
                        width: 20px;
                        display: inline-block;

                        .last-plan, .first-plan {
                            &:before {
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
        .msd-link {
            text-decoration: underline;
            cursor: pointer;
            color: $color16;

            &:hover {
                color: $color18;
            }
            i {
                display: inline-block;
                position: relative;
                top: 1px;
                margin-left: 5px;
                color: $color1;
            }
        }

        .msd-booking-inputs-container {
            padding: 15px 15px 0px 15px;

            .msd-fields-separator {
                margin-bottom: 20px;
            }

            .input-container {
                padding-bottom: 25px;
            }
        }

        .msd-booking-buttons {
            padding: 15px;

            .btn-reserve-back {
                margin: 0;
            }

            .btn {
                min-width: 190px;
                font-size: 18px;
                padding: 5px 10px 6px;

                &.msd-btn-reserve {
                    float: right;
                    @include respond-to($screen-xs-max) {
                        float: none;
                        margin-top: 15px;
                        width: 100%;
                    }
                }
            }

            .msd-booking-buttons-right {
                text-align: right;

                .msd-booking-total-price-container {
                    padding: 0;
                    display: inline-block;
                    text-align: right;
                    position: relative;
                    margin-right: 15px;

                    .msd-total-charge {
                        line-height: 1.1;
                    }

                    @include respond-to($screen-xs-max) {
                        margin-right: 0px;
                        display: block;

                    }
                }
            }
        }

        .msd-form-panel {
            position: relative;
            display: table;
            min-height: 45px;
            border-collapse: collapse;
            width: 100%;

            &.msd-form-panel-agreements {
                a {
                    position: relative;
                    z-index: 1;
                }
            }

            .radio-stack, .checkbox-stack {
                top: 0px;
                vertical-align: middle;
                margin: 0px;
                height: 25px;
                width: 24px;
                display: inline-block;
                left: 20px;
            }

            .checkbox-stack {
                input[type=checkbox]:checked ~ .first-plan {
                    &:before {
                        left: 2px;
                    }
                }
            }

            .msd-form-panel-elem {
                display: table-row;
                border-top: 1px solid $color19;
                border-bottom: 1px solid $color19;
                border-left: 1px solid $color4;
                border-right: 1px solid $color4;

                &.copy-payment-container{
                    border-top: none;

                    .msd-panel-cell {
                        position: relative;
                        left: 37px;
                    }

                    .checkbox-stack {
                        top: 0;
                        left: 1px;
                        margin: 0;
                        line-height: 28px !important;

                        input[type=checkbox] + label.last-plan:before,
                        input[type=checkbox] ~ label.first-plan:before {
                            top: 0;
                        }
                    }

                    .stack-label {
                        display: inline-block;
                        margin: 3px $margin-padding-md 4px 0;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: normal;
                        font-family: "Trebuchet MS", Trebuchet, Arial, Helvetica, sans-serif;
                    }
                }

                .msd-form-panel-elem-overlay {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                }

                .msd-panel-cell {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 10px 15px;

                    &.cell-input {
                        padding-right: 0px;
                        vertical-align: top;
                    }

                    &.cell-1 {
                        width: 50%;

                        &.cell-payment-trigger-name {
                            @include respond-from($screen-sm-min) {
                                width: 75%;
                            }
                            @include respond-from($screen-md-min) {
                                width: 80%;
                            }
                        }
                    }

                    &.cell-2 {
                        width: 50%;
                    }

                    &.cell-100 {
                        width: 100%;
                    }

                    &.cell-price {
                        font-size: 18px;
                        color: $color11;
                        text-align: right;
                    }
                }
            }

            &.msd-form-panel-prices {
                background: $color8;

                .msd-form-panel-elem {
                    border-left: 1px solid $color8;
                    border-right: 1px solid $color8;
                }
            }
        }

        .msd-payment-fields {
            padding: $margin-padding-xs $margin-padding-md $margin-padding-md;

            .input-container{
                margin-top: $margin-padding-sm;
            }
        }

        .input-title-label {
            height: 22px;
        }

        span.error {
            position: absolute;
            bottom: 5px;
            left: 15px;
            right: 15px;
            color: $color21;
            width: 100%;
        }

        .msd-room-header {
            border-bottom: 2px solid $color19;
            padding: 10px 15px;
            font-size: 18px;
            //margin-bottom: 1px;
        }

    }

    // Additional services
    #msd-booking-extservices {

        .msd-extservices-price-checkbox-inner {
            float: right;
        }

        .section-header.section-header-with-icon {

            h2 {
                margin-top: 7px;
                padding-right: 15px;
            }

            .section-header-icon-small {
                display: inline-block;
                width: 34px;
                height: 34px;
                padding-top: 3px;
                text-align: center;
                background: white;
                border-radius: 100%;
            }
        }

        .msd-extservices-row {
            margin: 0 15px 0 66px;
            padding: 15px 0;
            position: relative;

            .cell-02 {
                text-align: center;
                img {
                    max-height: 35px;
                }
            }

            @include respond-to($screen-xs-max) {
                .t-cell {
                    width: 100% !important;
                    display: block;

                    .msd-extservices-price-checkbox-inner {
                        float: none;
                    }
                }
            }
        }

        .msd-extservices-desc {
            padding: 12px 15px;
            background: "$bgcolor4";
            border-bottom: 1px solid $color19;
        }

        .msd-extservices-elem-opening-btn {
            position: absolute;
            width: 66px;
            height: 66px;
        }

        .msd-extservices-elem-details {
            display: none;
            margin-top: 10px;
        }

        .msd-extservices-btn-collapse-details {
            top: 0;
            height: 36px;
            width: 36px;
            margin: 15px;
            text-align: center;
            cursor: pointer;

            .fa {
                line-height: 36px;
                vertical-align: middle;
            }
        }

        .msd-extservices-elem {
            background: "$bgcolor3";
            position: relative;
            min-height: 56px;
            border-bottom: 1px solid $color19;

            .checkbox-stack {
                right: 9px;
            }
            .msd-extservice-price {
                display: inline-block;
                position: relative;
                top: -3px;
                left: 0;
                margin-right: 15px;
                white-space: nowrap;
                font-size: 18px;
                color: $color11;
            }

            .t-cell {
                padding-bottom: 0;
            }

            .cell-01 {
                width: 35%;
                padding-right: 15px;
                vertical-align: baseline;
            }
        }
    }

    #msd-booking-services-list {

        .msd-booking-services-select-container {
            margin: 5px 15px 0 0;
        }

        .error {
            .checkbox-stack input[type=checkbox] + label.last-plan:before {
                color: $color21;
            }
        }
        .fa-spinner {
            margin: 10px auto;
            display: table;
        }
    }
}

.hotel-booking-step {
    [data-anchor]:not(.msd-elem-rating) {
        cursor: default;
        pointer-events: none;
    }

    section.msd-service-elem {
        margin-bottom: 0;

        /*.msd-hotel-overview .msd-hotel-description .msd-service-description {
            padding-left: 0;
        }*/

        .msd-srv-cell {
            padding-bottom: 7px;
        }
    }
}

.hotel-only-price-summary {
    margin-bottom: 0;

    .msd-hotel-details-content {
        min-height: unset;
    }

    .msd-hotel-room-elem-header {

        border-bottom: 1px solid $color19;

        .msd-hotel-room-name {
            padding-left: 0;
            width: 100%;
        }
        .msd-hotel-room-elem-header-text {
            text-align: left;

            @include respond-to($screen-sm-max) {
                padding-left: 0;
            }
        }

        .msd-hotel-room-elem-header-text {
            &:first-child {
                max-height: 36px;
                text-align: left;
            }

            &:last-child {
                text-align: right;
                font-size: 16px;
                @include respond-to($screen-sm-max) {
                    text-align: left;
                }
            }
        }

        .total-charge .total-price {
            font-size: 22px;
        }

        .msd-hotel-room-elem-header-text i.fa {
            vertical-align: inherit;
        }

        .msd-hotel-room-payment {
            vertical-align: inherit;
        }

        .msd-service-ref {
            margin-left: 0;
        }
    }

    & ~ .msd-booking-total-price-container {
        border-bottom: 1px solid $color19;
        margin-bottom: 15px;
        padding: 5px 15px;
        background-color: $color3;
    }
}

.hotel-conditions-content {
    padding: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: $color3;

    i.fa {
        display: block;
        margin: 10px auto;
        text-align: center;
    }
}

.price-details-table {
    width: 100%;

    tr:hover {
        cursor: default;
    }

    td:first-child {
        padding-left: 15px;
    }

    td:last-child {
        padding-right: 15px;
    }
}
.qtip-interchangeable .price-details-table tbody tr {
    cursor: default;
}
