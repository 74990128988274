.price-block {
	font-weight: bold;
	font-size: 36px;
	line-height: 21px;
	.price-label {
		font-size: 18px;
	}
	.btn {
		vertical-align: bottom;
	}
	.btn-reserve {
		min-width: 182px;
	}
}