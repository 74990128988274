.pagemap-box {

	.map-container {
		margin:  0;
		overflow: hidden;
		position: relative;
		text-align: center;

		.map-type {
			position: absolute;
			top: 5px;
			right: 5px;
			z-index: 2;
		}

		.map {
			width: 100%;
			height: 540px;
			position: relative;
			z-index: 1;
		}

		.map-img {
			width: 100%;
			height: auto;
		}
	}

} // pagemapbox 