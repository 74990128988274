.offerlist-box {
	overflow: visible;
	@include respond-to($screen-xs-max) {
		overflow: hidden;
	}

	a.inner {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}

	.img-container-wrapper {
		height: 200px;
		width: 100%;
		//overflow: hidden;
		position: relative;
	}
	@media (max-width: $screen-md-max) and (min-width: 750px) {
		.col-md-12 & .col-lg-12 > .img-container-wrapper {
			height: 400px;
		}
	}

	.img-container {
		overflow: hidden;
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		left: 0;
		@include transition(all .5s ease);

		&.scale-x-1 {
			background-position: 0% 0%;
		}

		&.scale-x-2 {
			background-position: 50% 0%;
		}

		&.scale-x-3 {
			background-position: 100% 0%;
		}

		&:hover {
			&.with-zoom {
				@include scale3d(1.1, 1.1, 1.1);
			}
		}
	}

	.img-mobile-container{
		text-align:center;
		overflow: hidden;
		position: relative;

		@include respond-to($screen-sm-max) {
			height: auto;
		}
		@include respond-to($screen-xs-max) {
			height: auto;
		}

		img {}

		&:hover {
			.with-zoom {
				img {
					@include scale3d(1.1, 1.1, 1.1);
				}
			}
		}
	}
	.offers {
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			font-size: $font-size-base;
			line-height: 42px;
			a {
				text-decoration: none;
				font-size: 18px;
				display: table;

				&.more {
					padding: 0 15px;
					display: block;
					font-size: 14px;
				}
				.name {
					padding: 0 5px 0 15px;
					display: table-cell;
					width: 100%;
					vertical-align: middle;
				}
				.price {
					padding: 0 15px 0 5px;
					display: table-cell;
					vertical-align: middle;
					.history-price{
						font-size: 0.7em;
						vertical-align: middle;
					}
					strong {
						vertical-align: middle;
						min-width: 60px;
						display: inline-block;
						text-align: right;
					}
				}
				&:hover {
					&.more {
						text-decoration: underline;
					}
				}
			}
			&:last-child {
				border: none;
			}
		}

		.fixed-table {
			display: table;
			width: 100%;
			table-layout: fixed;

			& > div {
				display: table-cell;
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&.offerlist-box-big {
		.name-with-stars {
			.fixed-table:not(:first-child){
				font-size: $font-size-base;
				font-weight: bold;
			}
			sup{
				top: 0;
			}
			a {
				line-height: 62px;
			}

			.name {
				line-height: 1.5;
				font-size: $font-size-large;
			}

			.fixed-table .star-container {
				line-height: 18px;
                display: inline;
				top: 0;
				.stars {
					font-size: $font-size-base;
				}
			}
		}
        .omnibus {
            bottom: 5px;
            right: 15px;
        }
	}

	&.offerlist-box-rich {
		.name-with-stars {
			padding: 10px 0;
			line-height: 24px;
		}

		.price {
			text-align: right;
			.history-price{
				clear: both;
				display: block;
			}
			strong {
				vertical-align: middle;
			}
		}
        .omnibus {
            bottom: -7px;
            right: 15px;
        }

	}

	.name-with-additional-text {
        line-height: 26px!important;
		padding: 9px 0;
		.fixed-table {
			span {
				&:first-child {
					line-height: 28px;
				}
				&:last-child {
					line-height: 13px;
				}
			}
		}

        .omnibus {
            bottom: -7px;
            right: 15px;
        }

	}

	.offer-additional-text {
		font-size: 14px;
		line-height: 18px!important;
		display: block;
	}

    .omnibus {
        bottom: 2;
        right: 15px;
    }
}

[data-widgetname=OfferVListBoxWidget][data-header=hide],
[data-widgetname=OfferVCustomListBoxWidget][data-header=hide] {
    .img-container{
		@include border-top-radius("$block-br");
    }
}
