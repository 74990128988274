.facebook-join-elem {
	z-index: 2;
}

// eguide footer
.social {
	clear: both;
	margin: 14px 17px;
	a {
		display: inline-block;
		padding-bottom: 5px;
		padding-right: 5px;
		position: relative;
		margin-right: 4px;
		&:first-child {
			margin-left: 0;
		}
	}
	.shareOffer {
		display: inline-block;
		padding-right: 10px;

	}
	&.social-print {
		text-align: right;
	}

	&.social-trip-details{
        margin: $margin-padding-md;
        @include respond-from($screen-sm-min){
            margin-top: 0;
        }
	}
}

// eguide footer
.social.printTellFriend {
	text-align: right;
	border-bottom: 1px solid $color19;
	padding-bottom: 10px;
	a.visible-sm {
		display: inline-block !important;
	}
}

.btn-group-social {
	.btn {
		background: none;
	}
}
.btn-twitter {
	color: #00acee;
}

.btn-facebook {
	color: #3b5998;
}

.btn-youtube {
	color: #E12B28;
}

.btn-google-plus {
	color: #D84735;
}

.btn-pinterest {
	color: #CB2028;
}