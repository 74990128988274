.trip-filters#controls {
    .section-content {
        padding: 0;
    }

    #filters {
        padding: 15px;
    }
}

#controls {
    .section-content {
        border-bottom: 1px solid;
        padding-bottom: 0;
        @include respond-to($screen-xs-max) {
            border: none;
        }
    }
}

#filters {
    position: relative;
    padding: 0 60px 0 0;
    @include transition(all .2s ease);
    @include respond-to($screen-xs-max) {
        @include transition(none);
        border-top: none;
        padding: 0;
    }
    #filters-container {
        overflow: hidden;
        max-height: 49px;
        @include respond-to($screen-xs-max) {
            max-height: 1000px;
        }
    }
    &.expanded {
        #filters-container {
            max-height: 1000px;
            overflow: auto;
        }
    }
    #toggle-filters {
        position: absolute;
        right: 0;
        top: 0;
        height: 34px;
        overflow: hidden;
        i.fa {
            line-height: 12px;
            font-size: 21px;
            font-weight: bold;
            position: relative;
            top: 3px;
        }
    }
    .filter {
        position: relative;
        margin-bottom: 15px;
        height: 34px;

        > * {
            height: 100%;
        }
        &:hover .clear-filter {
            display: block;
        }
    }
    .select2-container-disabled {
        pointer-events: none;
        cursor: default;
        & ~ i {
            opacity: 0;
        }
    }
    .select2-search-choice {
        padding-right: 20px;
        margin-bottom: 0;
    }
    .select2-search-choice-close {
        right: 2px;
    }
    .select2-search-choice-close::after {
        color: inherit;
        top: 50%;
        font-size: 16px;
    }
    .select2-choices {
        padding: 4px 25px 4px 5px;

        li {
            max-width: 100%;
            div {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
    .select2-search-field {
        white-space: normal;
        max-width: 100%;
    }
    .select2-dropdown-open .select2-input {
        display: block !important;
        width: 100% !important;

        & ~ .select2-search-choice {
            margin-top: 4px;
        }
    }
    .category-select {
        a.select2-choice:not(.select2-default) {
            height: auto;
            min-height: 32px;
            padding: 0 20px 0 5px;
            line-height: 10px;
            .select2-chosen {
                white-space: normal;
            }
        }
    }
    .input-container-disabled, &.disabled-form .filter {
        pointer-events: none;
        cursor: default;
        opacity: .5;
        &.mc-input-container:after {
            color: $color19;
        }
    }
    .mc-input-container:not(.mc-selected):after {
        content: unquote("\"#{ $fa-var-angle-down }\"");
        font-weight: var(--#{$fa-css-prefix}-style);
    }
    .mc-input-container:after {
        font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        width: 32px;
        line-height: 32px;
        font-size: 21px;
        font-weight: bold;
        color: $color1;
        cursor: pointer;
        -webkit-font-smoothing: antialiased;
        text-shadow: 0 1px 0 $color1;
        @include respond-to($screen-xs-max) {
            content: "";
        }
    }
    .mc-selected > .mc-btn-clear {
        display: block;
        font-size: 25px;
    }
    .mc-selected {
        padding: 5px;
        background-color: #ffffff;
        height: 34px;

        .mc-input {
            max-height: none !important;
            border-radius: 3px !important;
            padding: 2px 19px 2px 3px;
            cursor: default;
            display: inline-block;
            width: auto;
            position: relative;

            .mc-icon {
                display: block;
                color: inherit;
                font-size: 16px;
                top: -5px;
                padding-left: 15px;
            }
        }
    }
    .qtip-open:after {
        content: " ";
        top: auto;
        bottom: -16px;
    }
    &.input-container input.mc-input {
        border: none;
    }

    .calendar-filter {
        .mc-input-container {

            &.mc-selected {
                padding-right: 30px;
                .mc-icon {
                    display: block !important;
                }
                .mc-input {
                    padding-bottom: 3px;
                    padding-top: 3px;
                }
            }
            .mc-input {
                width: 100%;

            }

        }
    }

    .btn-clear-selected {
        display: none !important;
    }

    .slider-filter {
        height: 70px;
        @include slider-filter;
        .irs-slider{
            top: 28px;
        }
        .title{
            position: relative;
            bottom: 8px;
        }
    }

    .filter {
        input.checkbox[type=checkbox]{
            display: none;
            &~ label{
                width: 100%;
                display: flex;
                align-items: center;
                .check-marker{
                    width: 2em;
                }
                .check-marker:before{
                    content: unquote("\"#{ $fa-var-square }\"");
                    font-weight: var(--#{$fa-css-prefix}-style);
                    font-size: 2em;
                }
            }
            &:checked ~ label .check-marker:before{
                content: $fa-var-square-check
            }
        }
    }
}

.sort-results {
    display: none;
    border: 1px solid $color19;
    display: inline-block;
    overflow: hidden;
    background: rgba(255, 255, 255, .3);

    &.trip-list-ord {
        position: relative;
        top: -1px;
        min-width: 250px;
        z-index: 1;
    }

    .select2-choice {
        height: 30px;
        line-height: 30px;
        min-height: 30px;
        border: none;

        .select2-chosen, &:after {
            color: $color12 !important;
        }
    }
}

.clear-filter {
    display: none;
    position: absolute;
    top: 1px;
    right: 16px;
    font-size: 26px;
    background: white;
    padding: 3px 0;
    @include respond-to($screen-xs-max) {
        top: 50%;
        right: 21px;
        margin-top: -10px;
        font-size: 23px;
    }
}

.sub-col-aside {
    #filters {
        #filters-form {
            & > div {
                width: 100%;
            }
        }
    }
}


