/* qTip2 v2.2.0 css3 | qtip2.com | Licensed MIT, GPL | Sun Mar 16 2014 17:53:30 */
.qtip {
    position: absolute;
    left: -28000px;
    top: -28000px;
    display: none;
    max-width: 280px;
    min-width: 50px;
    font-size: 14px;
    line-height: 14px;
    direction: ltr;
    box-shadow: none;
    padding: 0;
    -ms-touch-action: none;
    background-color: $color3;
    max-height: 80%;
    z-index: 16000 !important;

    &.qtip-height-full {
        height: 80%;

        .qtip-content {
            overflow-y: auto;
        }
    }
}

.qtip-content {
    height: calc(100% - #{$tooltip-default-title-height});
    padding: 10px;
    overflow: hidden;
    text-align: left;
    word-wrap: break-word;
    @include border-bottom-radius("$block-br");
}

.qtip-titlebar {
    position: relative;
    padding: 0 120px 0 15px;
    overflow: hidden;
    border-width: 0 0 1px;
    font-weight: 700;
    @include respond-to($screen-xxs-max) {
        padding-right: 46px;
    }

    &.ui-draggable-handle {
        cursor: move;
    }
}

.qtip-titlebar + .qtip-content {
    border-top-width: 0 !important;
}

.qtip-default {
    @include border-radius("$block-br");

    .qtip-titlebar {
        @include border-top-radius("$block-br");
        font-size: 19px;
        line-height: $tooltip-default-title-height;
        min-height: $tooltip-default-title-height;
        white-space: nowrap;

        &.side-admin-panel-header {
            font-size: 16px;
            line-height: 36px;
            min-height: 36px;

            .btn-close {
                line-height: 36px;
            }
        }

        .qtip-title {
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
        }

        .btn-close {
            background: none !important;
            font-size: inherit;
            font-weight: normal;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            cursor: pointer;
            padding: 0 15px;

            span {
                position: relative;
                top: -2px;
                vertical-align: middle;

                @include respond-to($screen-xxs-max) {
                    display: none;
                }
            }
        }

        .fa {
            font-size: (4em / 3);
            position: relative;
            top: -2px;
            vertical-align: middle;
        }
    }
}

.qtip-calendar {
    border: 1px solid $color1;
    max-width: 395px;
    @include border-radius("$block-br");
    min-width: 320px;
}

@include respond-from($screen-md-min) {
    .qtip-open {
        &::after {
            content: '';
            position: absolute;
            bottom: -16px;
            left: 30px;
            width: 0;
            height: 0;
            z-index: 9;
            border-style: solid;
            border-width: 0 15px 15px 15px;
            border-color: transparent transparent $color1 transparent;
        }
    }
}

@include respond-from($screen-md-min) {
    .qtip-open {
        &::after {
            content: '';
            position: absolute;
            bottom: -16px;
            left: 30px;
            width: 0;
            height: 0;
            z-index: 9;
            border-style: solid;
            border-width: 0 15px 15px 15px;
            border-color: transparent transparent $color1 transparent;
        }
    }
}

.qtip-freebie {
    p {
        text-align: center;
        font-size: 12px;
        line-height: 18px;
        margin: 0;
    }
}

.qtip-infolabel {
    background: #F4F3F8;
    color: #000;

    p {
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        margin: 0 !important;
        text-align: left;
    }
}

.qtip-infolabel-page {
    background: $color1;
    color: $color15;
    border: 1px solid $color1;
    @include border-radius("$block-br");

    p {
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        margin: 0 !important;
        text-align: left;
    }
}

.qtip-classic-window {
    min-width: 320px;
    max-width: 50%;
    max-height: 80%;
    border: 1px solid $color1;
    @include border-radius("$block-br");
}

.qtip-image-host {
    max-width: 80%;
}

.qtip-modern-window {
    max-width: 1200px;
}

.qtip-select-list {
    min-width: initial;
    max-width: initial;
    min-height: initial;
    max-height: initial;
    @include border-radius("$block-br");

    .qtip-titlebar {
        @include border-right-radius("$block-br");

        @include respond-to($screen-xs-max) {
            @include border-radius(0);
        }
    }

    .qtip-content {
        padding: 0;
    }

    @include respond-to($screen-xs-max) {
        @include border-radius(0);
    }
}

.qtip-shadow {
    /*-webkit-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, .15);
    -moz-box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, .15);
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, .15);*/
    @include box-shadow(0px 2px 9px -1px rgba(0, 0, 0, 0.75));

    @include respond-to($screen-xs-max) {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}

.qtip:not(.ie9haxors) div.qtip-content, .qtip:not(.ie9haxors) div.qtip-titlebar {
    filter: none;
    -ms-filter: none;
}

.qtip {
    .qtip-tip {
        margin: 0 auto;
        overflow: hidden;

        z-index: 10;
        canvas {
            top: 0;
            left: 0;
        }

        .qtip-vml {
            behavior: url(#default#VML);
            display: inline-block;
            visibility: visible;
        }
    }
}

.qtip {
    .qtip-tip,
    .qtip-tip .qtip-vml,
    .qtip-tip canvas {
        position: absolute;
        color: #123456;
        background: transparent;
        border: 0 dashed transparent;
    }

    &.qtip-alert-color {
        color: red;
        background-color: red;

        .qtip-content {
            color: white;
        }
    }

    &.qtip-info {
        max-width: 190px;
        color: $color1;
        background-color: $color1;

        .qtip-content {
            color: $color12;
        }
    }

    &.qtip-bounce {
        animation-duration: 2s;
        animation-fill-mode: both;
        animation-name: bounce;
        animation-delay: -1s;
        animation-iteration-count: infinite;
        transform-origin: center bottom;
    }
}

x:-o-prefocus, .qtip .qtip-tip {
    visibility: hidden;
}

.qtip-turn-calendar-min-width {
    min-width: 360px;
}

@media (max-height: 519px), (max-width: $screen-sm-max) {
    .qtip-fullscreen {
        position: fixed;
        left: 0 !important;
        top: 0 !important;
        max-width: none;
        max-height: none;
        width: 100% !important;
        height: 100% !important;
        border-width: 1px;
        border: 0;
        @include border-radius(0);

        .qtip-search {
            position: relative;
            display: inline-block;
            padding-left: 10px;
            width: 100%;
            padding-right: 60px;
        }

        .qtip-titlebar.qtip-with-autosuggest {
            .qtip-close {
                padding-right: 10px;
            }
        }

        .qtip-content {
            border: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            padding-top: $tooltip-default-title-height;
            z-index: -1;
            padding-left: 0;
            padding-right: 0;

            .calendar {
                min-height: 300px;
                padding-left: 10px;
                padding-right: 10px;

                .header-inline {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }

            .qtip-scrollpane {
                width: 100%;
                height: 100%;
                max-height: none;
            }
        }

        &.qtip-default .qtip-titlebar {
            @include border-radius(0);
        }
    }
}

.qtip-map-body-overflow {
    overflow: hidden;

    // not sure if it should be set for screen sizes or modal full screen
    @include respond-to($screen-sm-max) {
        position: fixed;
    }
}

@media (min-width: $screen-md-min) and (min-height: 520px) {
    .qtip-popup {
        box-shadow: 0 3px 5px rgba(50, 50, 50, 0.6);
        max-width: none;
        @include border-radius("$block-br");
    }

    .qtip-popup-double {
        box-shadow: 0 3px 5px rgba(50, 50, 50, 0.6);
        max-width: none;
        @include border-radius("$block-br");
    }
}

@include respond-from($screen-sm-min) {
    .quick-search-popup {
        max-width: 395px;
    }
}

@media (max-height: 349px) {
    .qtip-content {
        overflow-y: auto;
    }
}

.qtip-popup-qs {
    border: 1px solid $color1;
    max-width: 400px;
}

.qtip-popup-qs .qtip-content {
    padding: 0;
    @include border-bottom-radius(0);
}

.qtip-weather-forecast {
    //width:80%;
    width: 1001px;
    height: 80%;
    max-width: none;
    overflow: hidden;

    .qtip-content {
        position: absolute;
        background: #F4F3F8;
        max-width: 100%;
        height: 100%;
        width: 100%;
        top: 0;
        border: 0;
        padding: 0;
        padding-bottom: 32px;
        z-index: -1;
    }
}

.qtip-agent-select {
    width: 1000px;
    max-width: 100%;
    height: 800px;
    max-height: 100%;
    .qtip-content {
        padding: 0 !important;
    }
}

/* wyci�gni�te z default do obs�ugi innych okien ni� qtipy */
.qtip-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    height: 100%;
    background: none;
    border: none;
    padding: 0 15px;
    font-size: 19px;
    line-height: $tooltip-default-title-height;

    span, .fa {
        vertical-align: middle;
        top: 0;
    }

    .ui-icon-close {
        overflow: visible;
        text-indent: 0;
        background: none;

        &:before {
            font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
            color: $color12;
            content: unquote("\"#{ $fa-var-times-circle }\"");
            font-weight: var(--#{$fa-css-prefix}-style);
        }
    }

    .btn-close {
        padding: 7px 10px;

        .fa {
            top: -2px;
        }
    }
}

#qtip-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

}

.qtip-overlay-dark {
    #qtip-overlay {
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
    }
}

.qtip-overlay-dark-2 {
    #qtip-overlay {
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
        z-index: 16000!important;
    }

    .qtip-close {
        color: #fff;
    }
}

/* Applied to modals with show.modal.blur set to true */
/*
#qtip-overlay.blurs {
    cursor: pointer;
}
*/

/* Change opacity of overlay here */
#qtip-overlay div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /*background-color: black;

        opacity: 0.7;
        filter:alpha(opacity=70);
        -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";*/
    @include opacity($pending-opacity);
}

.qtip-simple {
    background-color: rgb(199, 199, 199);
}

.infomessage {
    max-width: 400px;
    z-index: 17000 !important;

    .qtip-content {
        text-align: center;
        padding: 20px 20px 15px;

        p {
            font-size: 14px;
            line-height: 20px;
            margin: 0;
        }

        .btn-container {
            text-align: center;
            margin: 20px 0 0;
        }

        @include respond-to($screen-sm-max) {
            position: relative;
        }
    }

    &.align-center {
        .qtip-content {
            text-align: center;
        }
    }

    &.align-left {
        .qtip-content {
            text-align: left;
        }
    }

    &.modal-custom {
        background: $color100;
    }
}

.extended-infomessage {
    max-width: calc(0.9 * 1200px);
    z-index: 17000 !important;

    .qtip-content {
        text-align: center;
        padding: $half-gutter;
        font-size: 14px;
        line-height: 20px;

        @include respond-to($screen-sm-max) {
            position: relative;
        }
    }
    .qtip-titlebar {

        .qtip-title {
            margin-right: 40px;

            @media (min-width: $screen-xs) {
                margin-right: 120px;
            }
        }
        .btn-close {
            span {
                font-size: 18px;
            }
        }
    }
    .qtip-titlebar .qtip-title {
        font-weight: normal;
        font-size: 18px;
        vertical-align: middle;
    }
    @media (max-width: $screen-sm - 1) {
        max-width: 100%;

        .qtip-titlebar {
            height: 50px;
            position: fixed;
            width: 100%;
            z-index: 1;
        }
        .qtip-content {
            overflow-y: auto;
            margin-top: $tooltip-default-title-height !important;
        }
    }
}

.confirm-infomessage {

    max-width: 400px;

    .qtip-content {
        text-align: center;
        padding: 15px;
    }
    .question {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 15px;
    }
    .input-container{
        input.confirm-input {
            border-color: $color19;
            @include border-radius(6px);
            margin-bottom: 10px;
        }
    }
    .confirm, .cancel {
        min-width: 100px;
        border: 1px solid $color7;

        &:hover {
            color: $color12;
            background-color: $color18;
            border: 1px solid $color18;
        }
    }
    .confirm{
        margin-right: 5px;
        color: $color12;
        background-color: $color7;
    }
    .cancel {
        color: $color7;
        background-color: $color12;
    }
    &.no-cancel .cancel {
        display: none;
    }
}

.formbox-infomessage {

    min-width: 600px;

    .model-template.btn {
        width: 100%;
    }

    .btn-remove-model {
        position: absolute;
        right: 10px;
        height: 34px;
        line-height: 34px;

        background: $color21;
        text-align: center;
        color: $color12;
        width: 18px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        .fa {
            position: absolute;
            top: 50%;
            right: 2px;
            margin: -6px 2px 0;
            font-size: 12px;
        }

        &:hover {
            background: $color18;
        }
    }

    .model-row {
        margin-bottom: 10px;

        .model-row-title {
            text-align: left;
            line-height: 25px;
        }
        &.removable {
            .model-row-field {
                padding-right: 2 * $half-gutter;
            }
        }
        .model-row-field {
            text-align: left;
            line-height: 25px;

            input, .select2-container {
                height: 34px;
                border: 1px solid;
            }
            input {
                padding: 0 8px;
            }
            .model-row-field-val {
                width: 100%;
            }
            .formbox-options {
                width: 100%;

                .formbox-option:first-child:nth-last-child(1) {
                    width: 100%;
                }

                @for $optSize from 2 to 6 {
                    .formbox-option:first-child:nth-last-child(#{$optSize}),
                    .formbox-option:first-child:nth-last-child(#{$optSize}) ~ .formbox-option {
                        width: calc(100% / #{$optSize} - 1px);
                    }
                }

                .formbox-option {
                    margin-right: 1px;
                    color: $color19;
                    border: 1px solid $color19;
                    background: $color4;

                    &:hover {
                        background-color: $color18;
                    }

                    &.active {
                        color: $color12;
                        background: $color7;
                    }
                }
            }
        }
    }
}

.multitab-infomessage {
    width: 80%;
    max-width: 1330px;
    height: 80%;
    max-height: 1012px;
    background: #fff;

    .qtip-content {
        padding-top: 0;
        background: $color3;
    }

    .multitab-content {
        height: 100%;
    }

    .multitab-header {
        padding: 10px;
        position: absolute;
        right: 0;
        z-index: 3;
        height: 50px;
    }

    .multitab-container {
        width: calc(100% + 20px);
        margin: 0 -10px;
        padding: 10px;
        height: calc(100% + 10px);

        .multitab-nav {
            width: calc(100% + 20px);
            margin: -10px;
            height: 50px;
        }

        .tab-content {
            height: calc(100% - 44px);
            margin: 10px -10px -10px -10px;
            padding: 0 10px;
            overflow: auto;
            position: relative;

            .tab-pane {
                padding: $half-gutter;
            }
        }
    }

    .nav-tabs {
        display: inline-block;
        width: 100%;
        border: none;
        white-space: nowrap;
        overflow: hidden;

        > li {
            background: $color4;

            a {
                line-height: 30px;
                color: $color9;
            }

            &.active {
                background: none;
            }

            &:last-child:after {
                content: " ";
                background: $color4;
                width: 100vw;
                position: absolute;
                left: 100%;
                top: 0;
                bottom: 0;
            }
        }
    }
}

@include respond-from($screen-md-min) {
    .route-map-height {
        height: 100%;
    }
}

.qtip-map {
    max-width: 100%;
    max-height: 100%;
    top: 15px !important;
    left: 15px !important;
    bottom: 15px !important;
    right: 15px !important;
    position: fixed;

    @include respond-to($screen-xxs-max) {
        top: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
    }

    .qtip-content {
        position: absolute;
        width: 100%;
        bottom: 0;
    }

    &.qtip-map-flight {

        @include respond-from($screen-md-min) {
            min-height: 300px;
            max-height: 520px;
            max-width: 991px;
            position: fixed;
            left: 50% !important;
            top: 50% !important;
            margin: -280px 0 0 -495px;
            #js-map-window {
                display: block;
                height: 100%;
            }
        }
        @include respond-to($screen-sm-max) {
            min-height: 100% !important;
            min-width: 100% !important;
            left: 0 !important;
            right: 0 !important;
            top: 0 !important;
            bottom: 0 !important;
            position: fixed;
            box-shadow: none !important;

            .qtip-content {
                overflow-y: auto;
            }
        }
    }
    &.location-map {
        z-index: 15200 !important;
        .map {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            bottom: 0;
            z-index: 9;

            @include respond-to($screen-xxs-max) {
                display: none;
            }
        }
        .image-map {
            overflow: hidden;
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 63px;
            img {
                width: 100%;
                height: auto;
            }

            @include respond-to($screen-xxs-max) {
                display: block;
            }
        }
        .actions-container {
            padding: 15px;
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;

            @include respond-to($screen-xxs-max) {
                display: block;
            }
        }
    }

    &.search-map {

        .map-header {
            p {
                margin: 5px 0 10px;
            }
        }
        .map {
            position: absolute;
            top: 85px;
            left: 0;
            width: 100%;
            bottom: 0;
            z-index: 9;
        }
        .map-type {
            padding: 0;
            top: 100px;
            right: 10px;
        }
    }

    .map-type {
        position: absolute;
        padding: 10px 0 0 13px;
        z-index: 10;
        width: auto;
        top: 0;
        display: block;
        .btn:first-child {
            @include border-right-radius(0);
        }
        .btn:last-child {
            @include border-left-radius(0);
        }
    }
    .map-type-plane {
        top: 20px !important;
        left: 10px;
    }

    @include respond-to($screen-xs-max) {
        top: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        @include box-shadow(0);
    }
}

// qtip map

.qtip-popup-searchbar {
    //    min-width: 342px;
    border: none;
    max-width: none;
    @include respond-to($screen-sm-max) {
        background: $color100;
    }

    .qtip-content {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        border: 0;
        padding-top: 0;
        overflow: visible;
        z-index: -1;

        .mc-scroll-wrapper {
            ul > ul {
                padding-left: 0;

                > li {
                    padding-left: 40px;
                }
            }
        }
    }
}

%qtip-overlay-child {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    @include opacity($pending-opacity);
    cursor: pointer;
}

.qtip-airport-map {
    & + #qtip-overlay div {
        @extend %qtip-overlay-child;
        //background:none;
    }
    .map-type {
        display: none;
    }
}

.dis-blk-h-100 {
    display: block;
    height: 100%;
}

.qtip-map.qtip-airport-list {
    height: 570px !important;
    max-height: 570px !important;
    top: 15%;
    //.qtip-titlebar {
    //  height: 52px;
    //  padding: 0;
    //  display: table;
    //  bottom: 15px;
    //  width: 100%;
    //  .btn-close-s {
    //    padding: 5px 15px;
    //    font-size: 18px;
    //    font-weight: 400;
    //  }
    //  .btn-close {
    //    padding: 11px 15px;
    //    font-size: 18px;
    //    width: 100%;
    //    text-align: right;
    //  }
    //}
    .map {
        @include respond-to($screen-xxs-max) {
            display: block;
        }
    }
    //.qtip-title {
    //  padding: 3px 0 0 15px;
    //}
}

.js-airport-map-qtip-content {

    .airport-search-margin {
        margin: 1px 4px 15px 20px;
        padding-left: 10px;
        @include respond-to($screen-sm-max) {
            margin-right: 28px;
        }
    }
    .js-airport-select {
        @include respond-to($screen-sm-max) {
            padding-right: 28px;
        }
    }

    .airport-select {
        @include respond-to($screen-sm-max) {
            margin: 0 10px 15px 13px;
        }
    }

    .airport-select-margin {
        margin: 15px 0 0 -5px;
        padding-left: 4px;
        padding-right: 9px;
        @include respond-to($screen-sm-max) {
            padding-left: 28px;
            margin: 0 0 15px 0;
            padding-right: 15px;
        }
    }
    .air-port-border {
        //border: solid #000000 1px;
        margin-top: -12px;
        margin-right: -2px;
        @include respond-to($screen-sm-max) {
            margin-top: -20px;
            top: 7px;
            width: auto;
        }
    }

    .airport-link {
        margin-top: 19px;
        padding-right: 1px;
    }
    .airport-text-sel {
        padding: 20px 6px 10px 15px;
        font-size: 15px;
    }

    .radio-stack {
        top: 11px;
        left: 68px;
        position: absolute;

    }
    .airport-btn {
        text-align: center;
        height: 36px;
        p {
            padding-top: 11px;
            font-size: 18px;
        }
    }

    .list-marker {
        color: $color13;
        border-radius: 50%;
        background: $color1;
        height: 25px;
        width: 25px;
        display: table-cell;
        position: relative;
        top: 22px;
        left: 28px;
        float: left;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        p {
            position: relative;
            top: 6px;
            text-align: center;
            font-size: 14px;
            z-index: 1;
        }
        &:after {
            position: relative;
            top: 9.5px;
            left: 12.4px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            pointer-events: none;
            border-top-color: $color1;
            border-width: 8px;
            margin-left: -8px;

            @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
            only screen and (-o-min-device-pixel-ratio: 13/10),
            only screen and (min-resolution: 120dpi) {
                top: -3.5px;
                left: 12.551px;
            }
        }
        .air-p-label {
            height: 44px;
        }
    }
    .map-hight {
        @include respond-to($screen-sm-max) {
            height: 30%;
            @media (max-height: 460px) {
                height: 28%;
            }
            @media (max-height: 450px) {
                height: 26%;
            }
            @media (max-height: 430px) {
                height: 24%;
            }
            @media (max-height: 420px) {
                height: 22%;
            }
            @media (max-height: 410px) {
                height: 20%;
            }
            @media (max-height: 400px) {
                height: 18%;
            }
            @media (max-height: 390px) {
                height: 16%;
            }
            @media (max-height: 380px) {
                height: 14%;
            }
        }
    }

    .airport-countries-list {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 348px;
        top: 0;
        overflow-y: auto;
        overflow-x: hidden;
        @include respond-to($screen-sm-max) {
            max-height: 51%;
            @media (max-height: 770px) {
                height: 49%;
            }
            @media (max-height: 690px) {
                height: 47%;
            }

            @media (max-height: 630px) {
                height: 45%;
            }

            @media (max-height: 590px) {
                height: 43%;
            }
            @media (max-height: 560px) {
                height: 41%;
            }

            @media (max-height: 535px) {
                height: 35%;
            }
            @media (max-height: 519px) {
                height: 28%;
            }
            @media (max-height: 495px) {
                height: 25%;
            }
            @media (max-height: 475px) {
                height: 22%;
            }
            @media (max-height: 45px) {
                height: 20%;
            }
        }

        li {
            list-style: none;
        }
        .air-p-list {
            padding-left: 40px;
            padding-right: 15px;
            height: 48px;
            display: table;
            width: 100%;
            .airport-list-text {
                display: inline-block;
                top: 35%;
            }
            .list-content {
                display: table-cell !important;
                line-height: 1.3;
                position: relative;
                left: -75px;
                vertical-align: middle;
                width: 235px;
                @include respond-to($screen-sm-max) {
                    width: 100%;
                    //left: 25px;
                }
            }
            .secound-a-map-step {
                left: 41px;
            }
        }
        .air-c-list {
            padding-left: 40px;
            padding-right: 15px;
            height: 25px;

        }
        .air-city-list {
            margin: 0 0 0 30px;
        }
        .airport-distance {
            @include respond-from($screen-md-min) {
                position: absolute;
                right: -70px;
                width: 50px;
                text-align: right;
            }
            @include respond-to($screen-sm-max) {
                position: absolute;
                right: -75px;
                margin-right: 15px;
            }
        }
    }

    .map-btn-padding {
        padding: 5px 0 0 15px;
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
    .airport-map-button {
        position: relative;
        top: 0 !important;
        height: 100px;
        @include respond-to($screen-sm-max) {
            //top: 50%;
            //border: none !important;
        }
    }
}

.list-elem-offset {
    padding-left: 18px;
}

.vis-hidden {
    visibility: hidden;
}

.airport-labels {
    color: $color13;
    border-radius: 50%;
    background: $color1;
    overflow: visible !important;
    height: 25px;
    width: 25px;
    display: inline-block;
    position: relative;
    top: 50%;
    left: 32px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    p {
        position: relative;
        top: 6px;
        text-align: center;
        font-size: 14px;
    }
    &:after {
        position: relative;
        top: 9.5px;
        left: 12.61px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        pointer-events: none;
        border-top-color: $color1;
        border-width: 8px;
        margin-left: -8px;
        z-index: 1;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
            top: 9.5px;
            left: 12.551px;
        }
    }
}

.airport-list-hover {
    background-color: $color18;
    color: $color12;
}

.map-element-hover-18 {
    &:hover {
        color: $color12;
        background: $color18;
    }
}

.overlay {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 11;
}

.js-airport-city {
    width: 102%;
}

.all-airports-label {
    position: absolute;
    bottom: -10px;
    @include respond-to($screen-sm-max) {
        top: 56%;
    }
}

.select2-drop {
    z-index: 21472;
}

.m-l-40px {
    margin-left: 40px !important;
}

.max-list-height-l {
    max-height: 287px;
}

.max-list-height-c {
    max-height: 462px !important;
    @include respond-to($screen-sm-max) {
        height: 100% !important;

    }
}

.city-country-list {
    position: absolute;
    top: 19px;
    left: 47px;
}

.city-country-list-div {
    position: relative;
    bottom: 47px;
    @include respond-to($screen-sm-max) {
        //bottom: 37px;
    }
}

.radio-stack-small {
    top: 5px !important;
}

.airport-map-links {
    a {
        color: $color16;
    }
}

.airport-distance-elem {
    display: none;
    margin-top: 19px;
    padding-right: 1px;
    width: 100%;
}

.airport-map-input-border {
    border-color: #FFF !important;
}

.airport-labelshover {
    color: $color3;
    border-radius: 50%;
    background: $color18;
    overflow: visible !important;
    height: 25px;
    width: 25px;
    display: inline-block;
    position: relative;
    top: 50%;
    left: 32px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    p {
        position: relative;
        top: 6px;
        text-align: center;
        font-size: 14px;
        z-index: 1;
    }
    &:after {
        position: relative;
        top: 9.5px;
        left: 12.51px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        pointer-events: none;
        border-top-color: $color18;
        border-width: 8px;
        margin-left: -8px;

        @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
            top: 9.5px;
            left: 12.551px;
        }
    }
}

.select-limit {
    margin: 10px !important;
    width: 100%;
}

.list-content-s {
    display: table-cell !important;
    line-height: 1.3;
    position: relative;
    left: -80px !important;
    vertical-align: middle;
    width: 235px;
    @include respond-to($screen-sm-max) {
        width: 100%;
        left: 25px;
    }
}

.js-distance-select-el {
    width: 100%;
}

.airport-limit-box {
    @include respond-to($screen-sm-max) {
        top: 0 !important;
    }
}

.left-m-80 {
    @include respond-from($screen-md-min) {
        left: -80px !important;
    }
}

.left-m-70 {
    @include respond-from($screen-md-min) {
        left: -70px !important;
    }
}

.left-m-65 {
    @include respond-from($screen-md-min) {
        left: -65px !important;
    }
}

.right-m-80 {
    @include respond-from($screen-md-min) {
        right: -80px !important;
    }
}

.padding-l-18 {
    @include respond-from($screen-md-min) {
        padding-left: 18px;
    }
}

.m-top-9 {
    margin-top: 9px;
}

.airport-not-found {
    text-align: center;
    margin: 15px;
}

.margin-r-110 {
    margin-right: 110px;
}

// QTIP - Select seats

.qtip-select-seats {

    background: rgb(247, 247, 247) !important;
    width: 980px !important;

    .height100 {
        height: 100%;
    }

    .codes-bar {
        button {
            .fa {
                margin-left: 5px;
                vertical-align: middle;

                &.fa-minus {
                    font-size: 8px;
                    width: 14px;
                    height: 14px;
                    line-height: 14px;
                }
            }
        }
    }
    .segments-list-seats {
        overflow: hidden;
        border-bottom: 1px solid $color19;
        border-top: 1px solid $color19;
        margin-bottom: 55px;

        .no-segment {
            height: 80px;

            .no-segment-text {
                color: $color21;
                font-size: 16px;
                text-align: center;
                line-height: 22px;
                padding: 0px 25px;
            }
        }

        .number-in-circle {
            height: 27px;
            width: 27px;
            display: inline-block;
            border-radius: 100%;
            background-color: $color1;
            color: #fff;
            line-height: 27px;
            text-align: center;
            font-size: 13px;
        }

        .select-seats-right {
            overflow: auto;
            overflow-x: hidden;
            border-left: 1px solid $color19;

            .deck-title {
                text-align: center;
                margin: 15px 0px;
            }
        }

        .select-seats-left {
            padding: 15px;
            overflow: hidden;

            .seat-price {
                color: $color7;
                font-weight: bold;
                white-space: pre;
            }

            .seats-caption {
                margin-top: 15px;

                .seats-caption-title {
                    font-size: 13px;
                    line-height: 16px;
                    padding: 5px 0px;
                }

                .seat-table {
                    border-spacing: 0px 5px;
                    width: 100%;
                    padding: 0;

                    td {
                        padding-left: 10px;
                        cursor: default;

                        &.seat-unavailable-text {
                            color: #888888;
                        }
                    }
                }

                @include respond-to($screen-xs-max) {
                    display: none;
                }
            }

            .passenger {
                padding: 3px;
                border: 1px solid $color19;
                margin-bottom: 6px;
                //cursor: pointer;

                .number-in-circle {
                    margin-right: 6px;
                }

                &.highlight {
                    border-color: $color21;

                    .number-in-circle {
                        background-color: $color21 !important;
                    }
                }

                .t-cell {
                    padding-bottom: 0px;

                    &.cell-01 {
                        width: 35%;

                        @include respond-to($screen-xxs-max) {
                            display: block;
                            width: 100% !important;
                        }
                    }
                    &.cell-02 {
                        width: 25%;
                        @include respond-to($screen-xxs-max) {
                            display: block;
                            width: 100% !important;
                            padding-left: 37px;
                            margin-top: 1px;
                        }
                    }
                    &.cell-03 {
                        width: 40%;
                        @include respond-to($screen-xxs-max) {
                            display: block;
                            width: 100% !important;
                            padding-left: 37px;
                            margin-top: 8px;
                        }
                    }
                }

                .seat-code {
                    font-weight: bold;
                }

                @include respond-to($screen-xxs-max) {
                    padding-bottom: 10px;
                }
            }
        }
    }

    .seat-table {
        border-collapse: separate;
        border-spacing: 5px;
        padding: 10px 0px;

        .seat-td {
            width: 33px;
            height: 33px;
            text-align: center;
            cursor: pointer;

            &.seat-td-hover:hover:not(.seat-unavailable) {
                @extend .background-hover-18;
            }

            &.no-seat {
                cursor: default !important;
            }

            span {
                @extend .number-in-circle;
            }
        }
        .seat-td-number {
            width: 18px;
            height: 33px;
            text-align: center
        }
        .seat-table-head td {
            height: 14px !important;
            text-align: center;
        }

        $PHI: 1.6180339885;
        $colorsNum: 20;

        @for $i from 0 through $colorsNum - 1 {
            .seat-price-#{$i} {
                $n: $i * $PHI - floor($i - $PHI);
                $h: floor($n * 360 + 180) % 360;
                $s: 70 + (20 / $colorsNum) * $i;
                $l: 45 + (25 / $colorsNum) * $i;
                background-color: hsl($h, $s, $l);
            }
        }
        .seat-unavailable {
            background-color: #D6D6D6;
            cursor: not-allowed;
            text-align: center;
            padding: 0 !important;
            font-size: 20px;
            color: #8E8E8E;
        }
        .seat-price-noprice {
            background-color: #8E8E8E !important;
        }
    }
    .elem-price-container-footer {
        padding: 10px 15px;
        background: #F7F7F7 !important;
        text-align: right;
        position: absolute;
        bottom: 0;
        border-top: 1px solid $color19;

        .btn {
            margin-top: 0px !important;
            min-width: unset !important;
            display: inline-block;
        }

        .seats-total-price {
            color: $color11;
            font-weight: bold;
            white-space: pre;
            display: inline-block;
            position: relative;
            top: 5px;
            line-height: 24px;

            span {
                font-size: 24px;
            }

            @include respond-to($screen-xs-max) {
                position: static;
                padding-bottom: 10px;
                top: 0;
                line-height: 14px;

                span {
                    font-size: 16px;
                }
            }
        }
    }

    @include respond-to($screen-sm-max) {
        min-height: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: 0 !important;
        position: fixed;
        box-shadow: none !important;

        .elem-price-container-footer {
            position: fixed;
            bottom: 0;
            .btn {
                margin-bottom: 0 !important;
            }
        }

        .seats-content-scroll {
            left: 0;
            right: 0;
            bottom: 0;
            overflow: auto;
            height: 85%;
            height: calc(100% - 110px);
            border-bottom: 1px solid rgb(219, 213, 213);
        }

        .select-seats-right {
            height: auto !important;
            overflow: auto !important;
        }

        .select-seats-left {
            height: auto !important;
            overflow: auto !important;
            border-right: none !important;
            padding: 8px !important;
        }

        .segments-list-seats {
            margin-bottom: 81px;
            height: auto !important;
            border-bottom: none !important;

            .no-segment {
                height: 170px;
            }
        }
    }

    @include respond-to($screen-xs-max) {
        .seats-content-scroll {
            height: calc(100% - 132px) !important;
        }
    }

    @include respond-to($screen-xxs-max) {
        .btn-codes {
            width: 100%;
            display: block;
            margin: 5px 0px !important;
        }
        .seat-table.more-than-six-seats {
            .seat-td {
                width: 20px;
                height: 20px;

                i {
                    font-size: 12px;
                }

                span {
                    height: 18px;
                    width: 18px;
                    line-height: 18px;
                    font-size: 11px;
                }
            }
            .seat-td-number {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.map-type {
    display: none !important;
}

.g-map-controls {
    margin: 10px 0;
    height: 32px;
    border: 1px solid !important;
}

.dyn-search-butt {
    width: 100%;
    margin-top: 10px;
}

.qtip-map-black-overlay {
    background-color: black;
    //@include opacity($pending-opacity !important);
    cursor: pointer;
    opacity: 0.65 !important;
}

.hotels-map-location-map-icon {
    display: inline-block;
    position: absolute !important;
    top: 0;
    right: 0;
    padding: 7px;
    bottom: 0.1px;
    @include border-left-radius(0);
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
}

.temporary-icon-width {
    min-width: 19px;
    visibility: visible;
}

.qtip-background-100,
.qtip-calendar,
.qtip-timepicker {
    background-color: $color100;
}

.qtip-info-tooltip {
    background-color: $color20;
    color: $color12;
    border: 1px solid $color20;
    border-radius: 5px;
    margin-top: 7px;
}
