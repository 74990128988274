#summary{
  text-align: left;
  height: calc(100% - 56px);
  overflow: hidden;

  .wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 50px);
    margin-top: 50px;
  }

  .object-name {
    h1 {
      margin-bottom: 6px;
      margin-top: 10px;
    }
    .object-region {
      display: block;
      h2, h3, h4 {
        display: inline;
        font-size: 1em;
        font-weight: bold;
      }
    }

    h1 {
      font-size: 1.3em;
      font-weight: bold;
      margin-bottom: 2px;
    }
  }

  h6 {
    font-size: 1.4em;
  }

  h2 {
    font-size: 1.3em;
    margin: 0;
  }

  [data-section] span:first-child {
    vertical-align: sub;
  }
  .back-to-form {
    cursor: pointer;
    display: inline-block;
    width: 1.5em;
    overflow: hidden;
    height: 1.5em;
    .fa-pencil{
      font-size: 1.5em;
    }
  }

  .table {
    display: table;
    width: auto;
    white-space: nowrap;

    &>div {
      display: table-row;
      &>span {
        display: table-cell;
      }
    }

    &.table-stripped, &.table-services{
      &>div {
        &:after {
          display: table-cell;
          width: 100%;
          content: '';
        }
        &:nth-child(odd){
          background-color: $darkerBackground;
        }
      }
      &.table-stripped-even>div {
        &:nth-child(even){
          background-color: $darkerBackground;
        }
        &:nth-child(odd){
          background-color: initial;
        }
      }
    }

    &.table-services{
      width: 100%;
      &>div {
        &:after {
          width: 0;
        }
      }
    }

    &.table-save-space
    {
      &>div>span:first-of-type{
        @media (max-width: $screen-sm-min) {
          display: none;
        }
      }
      &>div>span:nth-child(2){
        @media (max-width: $screen-sm-min) {
          padding-left: 15px;
          max-width: 200px; //to prevent long descriptions (rooms for ex.) destroying layout
        }
      }
    }

    &.table-cell-padded-horizontal {
      &>div {
        &>span {
          padding-left: 7.5px;
          padding-right: 7.5px;
          &:last-child{
            padding-right: 15px;
          }
          &:first-child{
            padding-left: 15px;
          }
        }
      }

      .cell-collapse-left {
        padding-left: 0;
      }

      .cell-collapse-right {
        padding-right: 0;
      }

    }
    &.table-cell-padded-vertical{
      &>div {
        &>span {
          padding-top: 7.5px;
          padding-bottom: 7.5px;
        }
      }
    }
    &.table-cell-padded-horizontal-small {
      &>div {
        &>span {
          padding-left: 3px;
          padding-right: 3px;
          &:last-child {
            padding-right: 6px;
          }
          &:first-child {
            padding-left: 6px;
          }
        }
      }

      .cell-collapse-left {
        padding-left: 0;
      }

      .cell-collapse-right {
        padding-right: 0;
      }
    }
    &.table-cell-padded-vertical-small{
      &>div {
        &>span {
          padding-top: 3px;
          padding-bottom: 3px;
        }
      }
    }

    .text-wrap {
      white-space: normal;
    }
  }

  .fa-stack{
    bottom: 5px;
  }

  .fa-stack-1x{
      padding-right: 3px;
      padding-top: 1px;
  }
}

.booking-form-services .price{
  vertical-align: middle;
}
