// Compass
@import '../compass-mixins/compass';
//@import '../compass-mixins/compass/css3';

// Colors
@import '_colors';
@include generateColorClasses();
// Bootstrap
@import '_bootstrap';
//@import 'bootstrap/variables';

// Mixins
@import '_mixins';
// Variables
@import '_variables';
// Fonts
@import "../base/font-awesome/_functions";
@import "../base/font-awesome/_variables";
@import "../base/font-awesome/fontawesome.scss";
@import "../base/font-awesome/solid.scss";
@import "../base/font-awesome/regular.scss";
@import "../base/font-awesome/thin.scss";
@import "../base/font-awesome/light.scss";
@import "../base/font-awesome/duotone.scss";
@import "../base/font-awesome/_duotone-icons.scss";
@import "../base/font-awesome/brands.scss";
@import "../base/font-awesome/sharp-solid.scss";
@import "../base/font-awesome/v4-shims.scss";
@import "../base/font-awesome/merlin-icons";
@import "../base/font-awesome/v4-shims-merlin.scss";
// Extended grid system
@import '_extended-grid';

// Core
@import 'common/_common';

@import 'common/_buttons';
@import 'common/_social-buttons';
@import 'common/inputs/_inputs';
@import 'jquery-qtip';
@import 'cookies-alert';
@import 'common/_rating';
@import 'common/_error';
@import 'common/_animate';
@import 'common/price-block';
@import 'common/inputs/_calendar';
@import '_map-elems';
@import 'common/inputs/_range-slider';
@import 'common/inputs/_radio-checkbox-stack';
@import '_cart';

@import 'animation-keyframes';

// Widgets
@import 'widgets/_widget';
@import 'widgets/_header';
@import 'widgets/_simple-header';
@import 'widgets/_footer';
@import 'widgets/_breadcrumbs';
@import 'widgets/_reservationpath';
@import 'widgets/_testimonials';
@import 'widgets/_popup';
@import 'widgets/_searchbox';
@import 'widgets/_offerbox';
@import 'widgets/_promobox';
@import 'widgets/_guidebox';
@import 'widgets/_expertbox';
@import 'widgets/_weatherbox';
@import 'widgets/_contactbox';
@import 'widgets/_compare';
@import 'widgets/_operbox';
@import 'widgets/_verticalofferlistbox';
@import 'widgets/_textbox';
@import 'widgets/_offerbar';
@import 'widgets/_simpleexpertbox';
@import 'widgets/_imagelinksbox';
@import 'widgets/_officemapbox';
@import 'widgets/_officesliderbox';
@import 'widgets/_pagemapbox';
@import 'widgets/_navibar';
@import 'widgets/_bestratedhotels';
@import 'widgets/_offertriprestbox';
//@import 'widgets/_subscriptionbox';
@import 'widgets/_flightresult';
@import 'widgets/_flightbooking';
@import 'widgets/_dynamicresult';
@import 'widgets/_template';
@import 'widgets/_gallerywidget';
@import 'widgets/_details-no-offer';

@import 'merlin-utils/_merlin-common';
@import 'merlin-utils/_mobile-header';
@import 'merlin-utils/_merlin-calendar';
@import 'merlin-utils/_merlin-timepicker';
@import 'merlin-utils/_merlin-autosuggest';
@import 'merlin-utils/_merlin-gallery';
@import 'merlin-utils/gallery-slider';
// Pages
//@import 'pages/details/_lightbox';
@import 'pages/details/_occupancy-editor';
@import 'pages/details/_chart';
@import 'pages/details/_facilities';        // facilities step 2 and 3
@import 'pages/details/_hotel-alternatives';
@import 'pages/details/_link-share';
@import 'pages/details/_visa-info';
@import 'pages/results/_filters';           // results step 2
@import 'pages/results/_region-listing';
@import 'pages/results/_region-group-listing';
@import 'pages/results/_objectlisting';
@import 'pages/_eguide';
@import 'pages/_expert-advice';
@import 'pages/_page-login';
@import 'pages/reserve/_booking';           // reserve step 4
@import 'pages/reserve/_externals';
@import 'pages/summary/_summary';           // summary step 5
@import 'common/flash';
@import 'pages/_multisearchdetails'; // multidynamic step 3
@import 'pages/_multisearchreserve'; // multidynamic step 4
// Newsletter
@import 'newsletter';

// Gridstack
@import '_gridstack';

// Skeleton
@import 'skeleton';

@import 'pages/_easymeet';
