section.contact-box { 

	.single-elem {
		margin: 15px 0 0;

		&:first-child {
			margin: 0;
		}
	}
	
	h3 {
		display: inline-block;
		margin: 5px 0 16px;
		color: $color1;
		font-size: 1.3em;
		font-weight: bold;
	}
	h4 {
		font-size: 1.1em;
		font-weight: bold;
		margin: 0 0 10px 0;
	}
	p {
		margin: 0;
		max-height: 65px;
		font-size: 14px;
		line-height: 20px;
		color: inherit;
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		@include respond-to($screen-xs-max) {
			max-height: 90px;
		}
	}

	.direct-contact {
		margin: 15px 0 0;
	}

	.img-container {
		overflow: hidden;
		text-align: center;

		img {
			max-width: 100%;
		}
	}

	.map-container {
		margin:  20px 0 0;
		overflow: hidden;
		position: relative;

		.map-type {
			position: absolute;
			top: 5px;
			right: 5px;
			z-index: 2;
		}

		.map {
			width: 100%;
			height: 400px;
			position: relative;
			z-index: 1;
		}
	}
}

.sub-col-aside .contact-box .section-content .single-elem>.row>div{
    &[class*=' col-'],
    &[class^='col-'] {
        width: 100%;
    }
}

section.contactform-box{
	.contact-text-arena {
		height: 90px;
	}
	.contact-checkbox {
		min-height: 95px;
	}

	.conditions-block {
		margin-top: 10px;

		input {
			margin:4px 0 0
		}
	}
}

.contact-form-disabled {
	width: 60%;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white !important;
    color: black !important;
	z-index: 1000;
	border: 1px solid black;
	text-align: center;
	max-height: calc(100% - 15px);
	min-height: 46px;
	overflow: hidden;
	overflow-y: auto;

	div {
        position: relative;
        padding: 10px;

		p {
			margin: 5px 0;
		}
	}
}

.section .contact-form-disabled {
	top: calc(50% - 15px/2);
}

.element-disabled {
    form {
        -moz-filter: grayscale(90%) brightness(90%);
        -webkit-filter: grayscale(90%) brightness(90%);
        filter: gray; /* IE6-9 */
        filter: grayscale(90%) brightness(90%);
    }
}
