.mcal-dotw {
    height: 20px;
    text-align: center;
}

.mcal-top {
    display: table;
    width: 100%;
    z-index: 2;
    position: relative;

    &.header-inline {
        position: absolute;
        left: 0;
        right: 0;
    }
}

.mcal-single-table {
    &[data-year][data-month] {
        margin-top: -42px;
    }

    width: 100%;
    //margin-bottom: 20px;

    tr {
        text-align: center;
    }

    td {
        display: table-cell;
        width: (100% / 7);
    }

    th {
        .mcal-top-btn {
            margin: 0;
            width: 25%;
            text-align: center;
            &.mcal-top-btn-year {
                padding: 6px 2px;
            }
        }
    }

    @media (max-width: 335px) {
        .mc-btn {
            padding: 6px 9px;
        }
    }

}

$mcal-double-min-width: 600px; // Can't change - set by merlin-calendar plugin
$mcal-under-min-height: 555px;

.mcal-content {

    &.mcal-dual {
        @include respond-from($screen-md-min) {
            min-width: $mcal-btn-width * 16;
        }
    }

    .mcal-dual-table {
        display: inline-table;
        position: relative;

        &:last-child {
            &:before {
                display: none;
                content: "";
                position: absolute;
                left: (100% / 48% * -2%);
                bottom: 0;
                height: calc(100% - #{18px * 2} - 1em);
                width: 1px;
                border-left: 1px solid $color19;
            }
        }

        @media (min-width: $mcal-double-min-width) and (max-height: $mcal-under-min-height - 1px) {
            border-collapse: initial;
            width: 48%;
            margin-top: -47px;

            &:first-child {
            }

            &:last-child {
                margin-left: 4%;

                &:before {
                    display: block;
                }
            }
        }

        @media (max-width: $screen-sm-max) and (min-height: $mcal-under-min-height) {
            width: 100%;
            padding-bottom: 20px;

            &:first-child {
                margin-top: -47px;
            }
        }

        @media (min-width: $screen-md-min) and (min-height: $mcal-under-min-height) {
            width: 48%;
            margin-top: -42px;

            &:first-child {
            }

            &:last-child {
                margin-left: 4%;

                &:before {
                    display: block;
                }
            }
        }

        tr {
            text-align: center;
        }

        td {
            display: table-cell;
            width: (100% / 7);
        }
    }
}

.mcal-top-btn {
    width: 40px;
    margin: 0px;
    display: inline-block;

    &.year-range {
        width: 120px;
    }

    i {
        display: inline-block;
        font-size: 14px;
        padding: 3px;
    }
}

.mcal-btn-prev {
    float: left;
    text-align: right;

    i {
        float: left;
    }
}

.mcal-btn-next {
    float: right;
    text-align: left;

    i {
        float: right
    }
}

//color classes

.mcal-cell {
    @include border-radius("$btn-br");
    color: $color15;
    background-color: $color7;

    &:hover {
        background: $color18;
    }
}

.is-today {
    color: $color9 !important;
}

.mcal-btn {
    @include border-radius("$btn-br");
    color: $color15;
    background: "$bgcolor1";

    &:hover {
        background: $color18;
    }
}

.mcal-disabled {
    @include border-radius("$btn-br");
    color: $color15;
    background-color: $color2;
    opacity: 0.5;
    cursor: default;
}

.mcal-selected {
    @include border-radius("$btn-br");
    color: $color15;
    background: $color1;

    &:hover {
        background: $color18;
    }
    &.mc-btn-disabled {
        opacity: 0.5;
    }
}

.mc-btn-dis-between {
    @include border-radius("$btn-br");
    color: $color15;
    background-color: $color1;
    opacity: .3;
}

.mc-btn-between {
    @include border-radius(0);
    color: $color15;
    background-color: $color1;
    opacity: .9;

    &:hover {
        background: $color18;
        opacity: 1;
    }
    &.mc-btn-disabled {
        opacity: 0.3;
    }
}

.mc-rightChev, .mc-leftChev {
    position: relative;
    @include border-radius(0);
}

.mc-rightChev {
    @include border-top-left-radius($mcal-btn-width/2);
}

.mc-leftChev {
    @include border-bottom-right-radius($mcal-btn-width/2);
}

.hover-range {
    color: $color15;
    background-color: $color1;
    opacity: .7;

    &.mcal-selected {
        opacity: 1;
    }
}

.calendar-alert {
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 46px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color3;
    text-align: center;
    color: $color21;

    .calendar-alert-container {
        display: table;
        width: 100%;
        height: 100%;
    }

    .calendar-alert-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 24px;
    }

    p {
        line-height: 22px;
        font-size: 18px;
    }

    a.btn {
        min-width: 100px;
        margin-top: 5px;
    }
}
