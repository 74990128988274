/*
    serachbox section styling
*/
@import '../common/inputs/_destination-picker';
@import '_suggest.scss';
section.search-box, section.subscriptionsbox {
    select {
        display: block;
        width: 100%;
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid $color19;
        background: $fields-background-color;
        height: 36px;
        padding: 8px 5px;

        &[multiple] {
            background: none;
            color: $color10;
        }
    }

    .nav-tabs {
        border: none;
        > li {
            display: block;
            float: left;
            min-width: 50px;
            //min-height: 50px;
            overflow: hidden;
            border-color: $color19;
            border-style: solid;
            border-width: 0;
            border-left-width: 1px;
            border-bottom-width: 1px;
            @include border-radius(0);

            &.tab-with-text-and-icon {
                span {
                    display: block;
                }
            }

            a {
                background: transparent !important;
                position: relative;
                width: 100%;
                display: block;
                vertical-align: middle;
                text-decoration: none;
                padding: 15px 5px;
                font-weight: 400;
                text-align: center;
                margin: 0 !important;
                border: none;

                .fa {
                    font-size: 1.8em;
                    vertical-align: -20%;
                    &.fa-plus {
                        font-size: 1.2em;
                        margin: 0 3px;
                    }
                }
            }

            &:not(.active) a:hover {
                color: $color18 !important;
            }

            &.active {
                background: transparent !important;
                border: none;
                border-left: 1px solid $color19;
                border-bottom: 1px solid transparent;
            }

            &:first-child {
                border-left-width: 0px;
            }
        }
    }

    .section-content.br-block-top {
        .nav-tabs {
            > li:last-of-type {
                @include border-top-right-radius("$block-br");
            }
            > li:first-of-type {
                @include border-top-left-radius("$block-br");
            }
        }
    }

    .tab-content {
        padding: 2px 15px 0;
        position: static;

        @include respond-to($screen-sm-max) {
            height: auto;
        }

        &.single {
            .inputs {
                //min-height: 289px;

                @include respond-to($screen-sm-max) {
                    min-height: 0;
                }
            }
        }
    }

    .inputs {
        //min-height: 241px;
        margin: 15px 0 0;
        padding-bottom: 7px;

        @include respond-to($screen-sm-max) {
            min-height: 0;
        }

    }

    .additional-container {
        width: 100%;
    }
    .fields-additional-button {
        ~ .fields-additional {
            display: none;
        }

        &.active ~ .fields-additional {
            display: block;
        }
    }

    .fields-additional-button:not(.grid-stack-item) {
        height: $searchbox-default-field-height !important;
    }

    div {
        &[data-template-type="searchbtn"]:not(.grid-stack-item) {
            height: $searchbox-default-field-height !important;
        }
    }


}

// fix for participants - gate
section.search-box {
    position: relative;
    overflow: visible;

    .section-content {
        opacity: 1;
        transition: opacity 0.2s;
        @include border-bottom-radius("$block-br");
    }
}

// Fix for search-box in narrow aside column
.layout-col.sub-col-aside .section.search-box {
    // radio instead of tabs
    .nav-tabs-many {
        li {
            width: 100% !important;
            margin: 0;
            border-bottom: 1px solid;
            min-height: 41px;
            border-left: 0;

            &.tab-with-icon {
                a {
                    padding: 8px 10px 4px;
                    &:before {
                        margin-top: 3px;
                        display: block;
                        float: left;
                    }

                    i:first-child {
                        margin-left: 3px;
                    }
                }
            }

            &.tab-with-text-and-icon {
                span {
                    display: inline;
                    vertical-align: super;
                }
                a {
                    padding-top: 9px;
                    padding-bottom: 3px;
                    white-space: nowrap;
                    -ms-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    &:before {
                        margin-top: 2px;
                        display: block;
                        float: left;
                    }
                    &:after {
                        top: 17px !important;
                    }
                }

                i:first-child {
                    margin-left: 3px;
                }
            }

            a {
                border: none;
                padding: 10px;
                text-align: left;
                position: relative;
                display: block;
                &:before {
                    content: "";
                    border: 2px solid $color19;
                    background: $fields-background-color;
                    border-radius: 100%;
                    display: inline-block;
                    vertical-align: bottom;
                    margin-right: 8px;
                    width: 21px;
                    height: 21px;
                }

            }

            &.active {
                border-left: 0;

                a {
                    &:before {
                        border-color: $color7;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        background: $color7;
                        width: 9px;
                        height: 9px;
                        border-radius: 100%;
                        display: block;
                        top: 16px;
                        left: 16px;
                    }
                }

                &.tab-with-icon a:after {
                    top: 17px;
                }
            }
        }
    }
    // double fields split for whole width
    .col-xs-6.searchbox-element {
        width: 100%;
    }

    // Fields containers width
    .sb-elem-halfsize, .sb-elem-fullsize {
        @media (min-width: $screen-lg-min) {
            width: 100%;
        }
    }
}

.searchbox-group {
    padding: 0;
    &.multiflight {
        padding-bottom: 5px;
        border-bottom: 1px solid;
        margin-bottom: 5px;
    }
}

.searchbox-group {
    &[data-field-type="htldoubleroom-2"] {
        display: none;
    }
}

.searchbox-element {
    padding: 7px 7px;

    .input-container input {
        cursor: pointer;

        &.select2-focused {
            cursor: text;
        }
    }

    .select2-container-multi {
        .mc-input {
            cursor: pointer;
        }
        ul.select2-choices {

            cursor: pointer;

            input {

                cursor: pointer;

                &.select2-focused {
                    cursor: text;
                }
            }
        }
    }

}

.searchbox-label {
    padding: 8px 0;
    margin-bottom: 0;
}

.searchbox-checkbox {
    width: 100%;
    text-align: left;
    cursor: pointer;
    border: 1px solid;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > span {
        width: calc(100% - 26px);
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.searchbox-radio {
    width: 100%;
    position: relative;
    padding: 7px 7px 6px 32px;
    text-align: left;
    cursor: pointer;
    border: 1px solid;

    label {
        width: 100%;
        margin: 0;
        cursor: pointer;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    input:checked + .mc-radio:before {
        content: unquote("\"#{ $fa-var-circle-dot }\"");
        font-weight: var(--#{$fa-css-prefix}-style);
    }
    .mc-radio {
        position: absolute;
        left: $quarter-gutter;
        top: 50%;
        margin-top: -6px;
    }
}

.searchbox-rating {
    display: inline-block;
    width: 24px;
    height: 24px;
    color: #ffffff;
    padding: 0;
    margin: auto 10px auto auto;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
}

// Flexible searchbox fields, not fixed width
$possible-column-numbers: 4, 6, 8, 12;

@each $i in $possible-column-numbers {
    [data-widgetname=SearchBoxWidget][merlin-grid="#{$i}"],
    [data-widgetname=SearchBoxWidget].col-lg-#{$i} {
        @media (min-width: $screen-lg) {
            .fields-container {

                > .sb-elem-halfsize {
                    width: percentage(12/$i * (2/12));
                }
                > .sb-elem-fullsize {
                    width: percentage(12/$i * (4/12));
                }
            }
        }
    }
}

[data-widgetname=SearchBoxWidget].step2 {
    .sb-elem-halfsize {
        width: 50%;
    }
    .sb-elem-fullsize {
        width: 100%;
    }
}

.sb-flight-num {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 22px;
    height: 100%;
    padding: 5px;
    border-radius: "$input-br" 0 0 "$input-br";
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

// Searchbox logo
[data-widgetname=SearchBoxWidget][merlin-grid="12"][data-side-logo]:not([data-side-logo=""]):not(.step2),
[data-widgetname=SearchBoxWidget].col-lg-12[data-side-logo]:not([data-side-logo=""]):not(.step2) {
    @media (min-width: $screen-lg) {

        .sb-elem-halfsize {
            width: percentage(12/8 * (2/12));
        }
        .sb-elem-fullsize {
            width: percentage(12/8 * (4/12));
        }

        section {
            .section-content {
                padding-right: percentage(1/3) !important;
            }

            &.search-box {
                .searchbox-logo {
                    display: inline-block !important;
                    visibility: visible !important;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: #{percentage(1/3)};
                    overflow: hidden;
                    transition: opacity 0.5s;
                    opacity: 1;

                    & > img {
                        max-width: 100%;
                        width: 100%;
                        height: auto;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                    }
                }
                .section-header.with-accordion:not(.with-accordion-active) ~ .searchbox-logo {
                    top: $widget-header-height;
                    opacity: 0;
                }
            }
        }
    }
}

//

.btn-check {
    border: 1px solid;
    i.fa {
        position: relative;
        top: 2px;
        width: 20px;
    }
}

.btn-reset {
    padding: 7px 10px 6px;

    .fa-lg {
        vertical-align: -25%;
    }
}

.btn-send .btn-send-only-icon {
    font-size: 1.4em;
}

.tab-content.show-field-labels [data-gs-width] {
    .btn-send .btn-send-only-icon {
        font-size: 2.4em;
    }
}

.tab-content:not(.show-field-labels) [data-gs-width] {
    .btn-send .btn-send-only-icon {
        font-size: 3em;
    }
}

.btn-send > .fa {
    pointer-events: none;
}

.multiflight-buttons {
    overflow: hidden;
    border: 1px solid;

    button {
        height: 100%;
        border-radius: 0;
    }

    .btn-group {
        height: 100%;

        &:first-child {
            border-right: 1px solid;
        }
        &:last-child {
            border-left: 1px solid;
        }
    }

}

.icon-date {
    & * {
        cursor: pointer;
    }

    position: relative;

    .fa-calendar {
        pointer-events: none;
        position: absolute;
        font-size: 21px;
        top: 50%;
        right: 7px;
    }
}

.pac-container {
    z-index: 16000;
}

.historyBox {
    position: absolute;
    width: 350px;
    bottom: 0px;
    padding: 0px;
    padding-top: 0;
    display: none;
    z-index: 20000;
    height: 100%;
    margin-right: 0px;
    margin-left: 0px;

    @include respond-to($screen-sm-max) {
        display: none !important;
        height: 0 !important;
    }

    &.leftHistoryBox {
        left: 100%;
    }
    &.rightHistoryBox {
        right: 100%;
    }
    &.fullHistoryBox {
        right: 0;
    }

    .historyBoxTitle {
        padding: 0 $half-gutter;

        h3 {
            margin: 0;
            line-height: $widget-header-height;
            border-bottom: 1px solid $color19;
            font-size: 18px;
        }
    }
    .historyContent {
        padding: 0 $half-gutter;
        height: calc(100% - #{$widget-header-height} - 1px);
        overflow-y: auto;
    }
    .historyElem {
        border-bottom: 1px solid $color19;
        margin: 0;
        padding: 7px;
        &:hover {
            background-color: $color18;
            color: $color15;
            cursor: pointer;
        }
        p {
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.sb-val-error {
    background-color: $color21;
    color: $color12;
    border: 1px solid $color21;
    border-radius: 5px;
    margin-top: 14px;
    .qtip-content {
        padding: 3px 7px;
        line-height: 16px;
        text-align: center;
    }
}

.sb-info {
    background-color: $color20;
    color: $color12;
    border: 1px solid $color20;
    border-radius: 5px;
    .qtip-content {
        padding: 5px 7px;
        text-align: center;
        line-height: 14px;
    }
}

.sb-side-panel {
    position: absolute;
    width: 393px;
    top: 0;
    padding: 15px;
    padding-top: 0;
    display: none;
    z-index: 99;
    min-height: 200px;
    background: $color100;
    -webkit-box-shadow: 0 3px 5px rgba(50, 50, 50, .6);
    box-shadow: 0 3px 5px rgba(50, 50, 50, .6);

    &.sb-side-panel-grid {
        .sb-side-panel-title {
            margin-left: -$half-gutter;
            margin-right: -$half-gutter;
            padding-left: $half-gutter;
            padding-right: $half-gutter;

            h3 {
                border-bottom: none;
            }
        }

        .sb-side-panel-content {
            padding-top: $quarter-gutter;
        }
    }

    &.sb-side-panel-ins {

        .input-container {

            .mc-btn-clear {
                display: none;
            }

            &.mc-selected:hover {
                .mc-btn-clear {
                    display: block;
                }

                .fa-calendar {
                    display: none;
                }
            }
        }
    }

    .sb-side-panel-confirmation {
        margin-top: 7px;

        @include respond-to($screen-sm-max) {
            position: absolute;
            bottom: 15px;
            width: calc(100% - 30px);
        }

        .confirm-button-left {
            padding-left: 0;
            padding-right: 7px;
        }
        .confirm-button-right {
            padding-left: 7px;
            padding-right: 0;
        }
        .sb-side-panel-confirm {
            width: 100%;
            //padding-left: 0;
        }
        .sb-side-panel-abort {
            width: 100%;
            //padding-left: 0;
        }
    }

    &.left-side-panel {
        left: 100%;
    }
    &.right-side-panel {
        right: 100%;
    }
    &.full-side-panel {
        right: 0;
    }
    .sb-side-panel-content {
        margin-right: -15px;
        padding-right: 15px;

        @include respond-to($screen-sm-max) {
            overflow: auto;
        }
    }

    .sb-side-panel-title {
        h3 {
            line-height: $widget-header-height;
            margin: 0;
            border-bottom: 1px solid $color19;
            font-size: 18px;
            text-transform: lowercase;
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
    .sb-side-panel-element {
        border-bottom: 1px solid $color19;
        margin: 0;
        padding: 7px 0;

        &.error {
            background: $input-err-col-transp;
            border-color: $color21 !important;
            outline-color: $color21 !important;
        }
        p {
            line-height: 32px;
            margin: 0;
            padding: 0;
        }
        .pass-counter {
            height: 32px;
            width: 100%;
            text-align: center;
            border: 1px solid $color1;
        }
        .counter-container {
            position: relative;

            .counter-container-overlay {
                display: none;
            }

            .increment, .decrement {
                height: 32px;
                width: 32px;
                line-height: 34px;
                text-align: center;
                cursor: pointer;
                position: absolute;
                top: 0;
            }
            .increment {
                right: 0;
            }
            .decrement {
                left: 0;
            }

            &-disabled {
                .counter-container-overlay {
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    background-color: rgb(183, 182, 188);
                    @include opacity(.5);
                }

                .decrement, .increment {
                    background-color: rgb(183, 182, 188);
                }

                .pass-counter {
                    border-color: rgb(183, 182, 188);
                }
            }
        }

        .checkbox {
            display: block;
            margin: 2px 0 0 0;

            label {
                padding-left: 20px;
                line-height: 15px;
            }

            input[type=checkbox] {
                display: none;
            }

            input[type=checkbox] + label:before {
                font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
                color: #ffffff;
                margin-right: 7px;
                display: inline-block;
                content: unquote("\"#{ $fa-var-square }\"");
                font-weight: var(--#{$fa-css-prefix}-style);
                float: left;
                font-size: 23px;
                top: 5px;
                left: 2px;
                position: absolute;
                z-index: 1;
            }
            /* unchecked icon */

            input[type=checkbox] + label:after {
                font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
                color: $color19;
                display: inline-block;
                content: unquote("\"#{ $fa-var-square }\"");
                font-weight: var(--#{$fa-css-prefix}-style);
                position: absolute;
                left: 2px;
                font-size: 23px;
                top: 5px;
            }

            input[type=checkbox]:checked + label:before {
                content: unquote("\"#{ $fa-var-check }\"");
                font-weight: var(--#{$fa-css-prefix}-style);
                color: #ffffff;
                left: 6px;
                margin-right: 16px;
                font-size: 14px;
            }
            /* checked icon */

            input[type=checkbox]:checked + label:after {
                color: $color7;
            }
            /* checked icon */
        }
    }

    .sb-side-child-num {
        &:nth-of-type(odd) {
            padding-right: 7px;
        }
        &:nth-of-type(even) {
            padding-left: 7px;
        }

        .searchbox-label {
            padding: 8px 0 2px;
        }

        .searchbox-element {
            display: block;
            padding: 0;
            min-height: 0;
        }
    }

    .searchbox-element {
        display: none;
    }

    .sb-side-panel-data {
        display: none;
    }

    &.mobile-sb-side-panel {
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 66px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0;
        background: $color100;
    }
}

.participants .mc-icon.text-color-1 {
    display: block !important;
}

.participants .mc-input span:last-child {
    span {
        display: none;
    }
}

.participants {

    &.double-room-container {
        background: $color100;
        height: 34px;
        cursor: pointer;

        .sb-room-num {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 5px;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
            text-transform: capitalize;
            float: left;
        }

        .mc-input {
            float: left;
            width: auto;
            max-width: calc(100% - 100px);
        }
    }
}

.sb-elem-xs-clear {
    @include respond-to($screen-xs-max) {
        clear: left;
    }
}

.sb-elem-sm-clear {
    @include respond-from($screen-sm-min) {
        @include respond-to($screen-sm-max) {
            clear: left;
        }
    }
}

.duration-container {
    .mc-input {
        .mc-icon {
            display: none !important;
        }
        &::after {
            content: unquote("\"#{ $fa-var-angle-down }\"");
            font-weight: var(--#{$fa-css-prefix}-style);
            font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
            position: absolute;
            top: calc(50% - 16px);
            right: 0;
            text-align: center;
            width: 32px;
            line-height: 32px;
            font-size: 21px;
            color: $color1;
            cursor: pointer;
            -webkit-font-smoothing: antialiased;
            @include respond-to($screen-xs-max) {
                content: "";
            }
        }
    }
    .mc-selected .mc-input::after {
        content: '';
    }
    .mc-selected i ~ .mc-input::after {
        content: '';
    }
}

.fullscreen-popup .duration-container .mc-input::after {
    content: unquote("\"#{ $fa-var-angle-up }\"");
    font-weight: var(--#{$fa-css-prefix}-style);
}

.dropdown-inactive-option {
    pointer-events: none;
    opacity: .3;
    cursor: default;
}

.searchbox-field-label {
    margin: 0;
    padding-left: $quarter-gutter;
    white-space: nowrap;
}

.step2 .show-field-labels {
    .searchbox-element {
        padding: 3px 7px;
    }
}

.searchbox-loading, .widget-loading {
    .section-content {
        opacity: 0 !important;
    }
    .searchbox-loading-spinner, .widget-loading-spinner {
        display: block !important;
        position: absolute;
        width: 35px;
        height: 40px;
        top: calc(50% - 17px);
        left: calc(50% - 17px);
        font-size: 40px;
        opacity: $pending-opacity;
    }
}

.searchbox-loading-spinner, .widget-loading-spinner {
    display: none !important;
}

.tab-content[data-layout="1"] {
    .without-label {
        margin-top: 15px;
    }
}

.tab-content[data-layout="2"], .tab-content[data-layout="0"] {
    .without-label {
        margin-top: 25px;
    }
}

.tab-content[data-layout="3"] {
    .without-label {
        margin-top: 25px;
        &.field-moved {
            margin-top: 0;
        }
    }
}

.tab-content[data-layout="4"] {
    .without-label:not([data-template-type="searchbtn"]) {
        height: auto;
        .show-additional {
            margin-top: 25px;
            min-height: 32px !important;
        }
    }
}

.time-separator {
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 13px;
}

.qtip-pos-tc {
    .qtip-content {
        min-width: 200px;
    }
}

.sb-elem-md-clear {
    @include respond-from($screen-md-min) {
        @include respond-to($screen-md-max) {
            clear: left;
        }
    }
}

.sb-elem-lg-clear {
    @include respond-from($screen-lg-min) {
        clear: left;
    }
}

.hide-first-option li:first-child {
    display: none;
}

[data-header="hide"] {
    .section-header {
        display: none;
    }

    section.search-box {
        .inputs {
            margin-top: 7px;
        }
    }

}

//Wide Searchbox

.wide-searchbox-field {
    position: relative;
    float: left;
    @include respond-to($screen-sm-max) {
        width: 100%;
    }

    .searchbox-checkbox {
        position: relative;
        .mc-checkbox {
            left: 3px;
            top: 50%;
        }
    }

    &[data-template-type="searchbtn"] {
        display: block !important;
    }
}

.hidden-date-input, .hidden-time-input {
    visibility: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 0 !important;
    position: absolute;

    .searchbox-element {
        margin: 0 !important;
        padding: 0 !important;
        width: 0 !important;
    }
}

.hidden-time-input.searchbox-element {
    padding: 7px 0 !important;
    margin-left: 7px !important;
}

.searchbox-group {
    .hidden-time-input.searchbox-element {
        padding: 7px !important;
        margin: 0 !important;
    }
}

[data-header="hide"] {
    .wide-searchbox {
        .sb-side-panel {
            padding: 0;
        }
    }
    .tab-content[data-layout="3"] {
        .without-label {
            margin-top: 25px;
        }
    }
}

.wide-searchbox {

    .sb-side-panel {
        padding: 0;
        //top: 157px;
        border: 1px solid $color1;
        @include border-radius("$block-br");
        box-shadow: 0 5px 8px rgba(50, 50, 50, 0.6);

        &.full-side-panel {
            right: initial;
        }
    }

    .sb-side-panel-title {
        padding: 0 15px;
        max-width: 400px;
        margin: auto;
        h3 {
            border-bottom: 0;
        }
    }

    .sb-side-panel-content {
        padding: 8px 15px 0 15px;
        width: 100%;
        max-width: 400px;
        margin: auto;
    }

    .sb-side-panel-confirmation {
        padding: 0 15px 15px 15px;
    }

    .qtip-open {
        position: relative;
        &::after {
            //bottom: -7px;
        }

        &.mc-input {
            //overflow: auto;
        }
    }

    @include respond-to($screen-sm-max) {
        .sb-side-panel-title {
            background: transparent;
            color: $color1;
        }
    }

    .select2-container {
        overflow: initial;
    }
    .select2-dropdown-open {
        @include respond-from($screen-md-min) {
            &::after {
                content: '';
                position: absolute;
                bottom: -16px;
                left: 30px;
                width: 0;
                height: 0;
                z-index: 9;
                border-style: solid;
                border-width: 0 15px 15px 15px;
                border-color: transparent transparent $color1 transparent;
            }
        }
    }
}

.searchbox-element {
    .select2-search-choice {
        max-width: 100%;

        div {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .select2-container-multi .select2-choices .select2-search-field {
        max-width: 100%;
    }
}

.qtip-wide-searchbox {
    @include respond-from($screen-md-min) {
        background-color: transparent;
        .dp-scroll-pane {
            border: 1px solid $color1;
            top: 47px;
        }
    }
    .qtip-shadow {
        box-shadow: 0 5px 8px rgba(50, 50, 50, 0.6);
        -webkit-box-shadow: 0 5px 8px rgba(50, 50, 50, 0.6);
    }
    .mc-scroll-wrapper {
        @include respond-from($screen-md-min) {
            top: 47px;
            border: 1px solid $color1;
            .masgg-info {
                //margin-top: -20px;
                background: $color1;
                color: $color12;
            }
        }
        @include respond-to($screen-sm-max) {
            padding-bottom: 0;
        }
    }
}

@include respond-from($screen-md-min) {
    .select-wide-searchbox {
        margin-top: 14px;
        border: 0;
        box-shadow: 0 5px 8px rgba(50, 50, 50, 0.6);
        -webkit-box-shadow: 0 5px 8px rgba(50, 50, 50, 0.6);
    }
}

section .wide-searchbox .inputs {
    margin-top: 5px;
    .select2-dropdown-open {
        @include respond-from($screen-md-min) {
            &::after {
                content: '';
                position: absolute;
                bottom: -16px;
                left: 30px;
                width: 0;
                height: 0;
                z-index: 9;
                border-style: solid;
                border-width: 0 15px 15px 15px;
                border-color: transparent transparent $color1 transparent;
            }
        }
    }
}

@include respond-from($screen-md-min) {

    .field-moved {
        position: absolute;
        top: 5px;
        width: auto !important;
        padding: 0 7px;

        .searchbox-element {
            width: auto !important;
            padding: 0 7px;
        }

        .searchbox-checkbox, .searchbox-radio {
            background: transparent;
            border-color: transparent;
            span, i.fa, label {
                color: $color12;
            }
        }
    }

    [data-header="hide"] {
        .wide-searchbox.with-checkbox {
            .tab-pane.active {
                padding-bottom: 45px;
            }
        }

        .field-moved {
            top: auto;
            bottom: 0;

            .searchbox-checkbox, .searchbox-radio {
                background: $color4;
                border-color: $color1;
                span, i.fa, label {
                    color: $color1;
                }
            }
        }
    }
}

section .wide-searchbox .inputs {
    margin-top: 5px;
}

//Wide Searchbox End

.participants-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 98;
    div {
        @extend %qtip-overlay-child;
        cursor: default;
    }
}

.with-wrapper {
    .participants-overlay {
        z-index: -1;
    }
}

.camp-child-cal {
    .mc-input-container {
        border: 0;
    }
}

// Special rule for allianz
.hideChronicallyIllChk > .sb-side-panel-content > .sb-side-panel-element {
    > .input-container {
        width: 75%;
    }
    > .checkbox {
        display: none;
    }
}

// Wide searchbox background
[merlin-grid="12"] .search-box-width-fill {
    box-shadow: none;

    .section-content {
        margin-left: calc((100vw - 100%) / -2) !important;
        padding-left: calc((100vw - 100%) / 2) !important;
        margin-right: calc((100vw - 100%) / -2) !important;
        padding-right: calc((100vw - 100%) / 2) !important;

        .nav-tabs {
            > li:not(.active) {
                &:first-child {
                    border-left-width: 1px;
                }
                &:last-child {
                    border-right-width: 1px;
                }
            }
        }
    }

    .sb-side-panel.full-side-panel {
        @media(min-width: $screen-lg) {
            right: calc((100vw - 1200px) / 2) !important;
        }
    }
}

// Grid layout styles
@mixin searchbox-fields-1lvl {
    > .mc-input-container,
    > .input-container,
    > .btn-send,
    > .btn-reset,
    > .btn-group,
    > [data-field-type="multiflight"] > .searchbox-element,
    > .searchbox-element,
    > .searchbox-checkbox,
    > .searchbox-radio,
    > .select2-container,
    > .show-additional,
    > .fields-additional {
        @content;
    }
}

@mixin searchbox-fields-2lvl {
    .show-additional-text,
    .mc-input-container,
    .mc-input,
    input,
    .searchbox-checkbox,
    .searchbox-radio,
    .select2-container,
    .select2-choices,
    .select2-choice,
    .select2-chosen,
    .sb-room-num,
    .dp-init-element,
    .hotels-map-location-map-icon {
        @content;
    }
}

/*
 * Searchbox grid and moderno styles - in the future it will replace other styles
 */
section.search-box {
    // --------------------------------------------- //
    // Searchbox fields default height
    // --------------------------------------------- //
    .fields-container-wrapper {
        padding: $quarter-gutter;
    }

    @include respond-to(995px) { // - fix for iOS
        .fields-container {
            > .searchbox-dd-item {
                height: $searchbox-default-field-height !important;
            }
        }
    }

    @include respond-from(995px) {
        @include respond-to($screen-sm-max + 19px) { // - fix for iOS
            .fields-container {
                > .searchbox-dd-item {
                    height: $searchbox-default-field-height !important;
                }

                @include only_safari_no_ios( '> .searchbox-dd-item', (height: 100% !important) );

            }
        }
    }


    @include respond-to($screen-sm-max + 19px) { // - fix for iOS
        .fields-container {
            > .searchbox-dd-item.multiflight {
                height: 3 * $searchbox-default-field-height !important;
                max-height: 3 * $searchbox-default-field-height !important;

                > .searchbox-dd-item {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    width: 100% !important;
                    height: 33.3% !important;

                    &[data-field-type="multiflight"] {
                        > .searchbox-dd-item:first-child {
                            padding-right: $quarter-gutter !important;
                        }
                        > .searchbox-dd-item:last-child {
                            padding-left: $quarter-gutter !important;
                        }
                    }
                }
            }

            &.fields-labels {
                > .searchbox-dd-item:not([data-template-type="searchbtn"]):not([data-template-type="resetbtn"]):not(.multiflight) {
                    height: $searchbox-default-field-height + 24px !important;
                }

                > .searchbox-dd-item.multiflight {
                    height: 3 * ($searchbox-default-field-height + 24px) !important;
                    max-height: 3 * ($searchbox-default-field-height + 24px) !important;
                }
            }
        }
    }

    .fields-container:not(.grid-stack) {
        > .searchbox-dd-item {
            height: $searchbox-default-field-height;
        }

        > .searchbox-dd-item.multiflight {
            > .searchbox-dd-item:not(.searchbox-group) {
                width: 25%;
            }

            > .searchbox-group {
                width: 50%;
                height: 100%;
            }
        }

        &.fields-labels {
            > .searchbox-dd-item:not(.fields-additional-button, [data-template-type="searchbtn"]) {
                height: $searchbox-default-field-height + 24px;
            }
        }
    }

    // --------------------------------------------- //
    // Searchbox new unified styles
    // --------------------------------------------- //

    .tab-content {
        padding: 0px !important;

        .fields-container-wrapper {
            padding: $quarter-gutter;
            margin-top: 0;
            @include border-bottom-left-radius("$block-br");
            @include border-bottom-right-radius("$block-br");
        }

        .fields-container {
            margin: 0;

            &.grid-stack-one-column-mode {
                display: flex;
                flex-direction: column;
            }

            > .searchbox-dd-item {
                padding: $quarter-gutter;

                &.ui-draggable-dragging, &.ui-resizable-resizing {
                    box-shadow: 1px 1px 3px 0 black;
                }

                @include searchbox-fields-1lvl {
                    height: 100%;
                    // TODO: Disable script that set field height
                    max-height: 100% !important;
                    min-height: 28px;
                    padding: 0;

                    @include searchbox-fields-2lvl {
                        height: 100%;
                        // TODO: Disable script that set field height
                        max-height: 100% !important;
                        min-height: 28px;
                        display: flex;
                        align-items: center;
                        position: relative;
                    }

                    .select2-choices,
                    .select2-choice {
                        width: 100%;
                    }
                }

                // Labels
                &.searchform-label {
                    display: flex;
                    align-items: center;
                }

                div {
                    > .searchbox-field-label {
                        margin-top: -$quarter-gutter;
                    }

                    &:first-of-type {
                        > .searchbox-field-label {
                            margin-top: -$quarter-gutter;
                            left: 0;
                        }
                    }
                }

                .searchbox-field-label {
                    white-space: nowrap;
                    position: absolute;
                    z-index: 1;
                    top: $quarter-gutter;
                    left: $quarter-gutter;
                    max-width: calc(100% - #{$grid-gutter-width});
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .searchbox-field-label + div {
                    margin-top: 25px;
                    height: calc(100% - 25px) !important;
                }

                // Multiflight special styles
                &.searchbox-group.multiflight {

                    > div {
                        > .searchbox-field-label {
                            left: 2 * $half-gutter;
                        }

                        &:first-of-type {
                            > .searchbox-field-label {
                                left: 3 * $quarter-gutter;
                            }
                        }

                        @include respond-to($screen-sm-max) {
                            > .searchbox-field-label {
                                margin-top: 0;
                            }
                        }
                    }

                    @include respond-from($screen-sm-min) {
                        [data-field-type="multiflight"] {
                            width: 50%;
                            height: 100%;
                        }
                    }
                }

                // Other fields
                .searchbox-checkbox {
                    display: flex;
                    align-items: center;
                    padding: 0 $quarter-gutter;
                    // Spacing for .mc-checkbox
                    padding-left: $quarter-gutter - 5px;
                }

                > .btn-send,
                > .btn-reset {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                // For the future field options
                .searchbox-field-options {
                    display: none;
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    padding: 2px;
                    text-align: center;
                    width: 20px;
                    height: 20px;
                    color: white;
                    z-index: 2;
                    cursor: pointer;
                    opacity: .8;
                    @include border-radius(2px);
                }

                &.editing {
                    outline: 1px solid orangered;
                }

                &:hover {
                    .searchbox-field-options {
                        display: block;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }

            &.grid-stack:not(.grid-stack-static) {
                > .searchbox-dd-item {
                    display: block !important;
                }
            }

            // Joined date styles
            &.grid-stack {
                > .searchbox-dd-item[data-template-type="date"].joined-date-field {
                    > .searchbox-element:first-child {
                        padding-right: 0;
                    }
                }
            }

            // Multiflight special styles
            &.grid-stack {
                @include respond-from($screen-md-min) {
                    .searchbox-group.multiflight {
                        border-bottom: 0;
                    }
                }
            }

            // Searchbox group paddings

            > .searchbox-dd-item.searchbox-group > .searchbox-dd-item.searchbox-group,
            > .searchbox-dd-item.searchbox-group {
                > .searchbox-dd-item {
                    padding-right: inherit;
                }
                > .searchbox-dd-item ~ .searchbox-dd-item {
                    padding-left: inherit;
                }
            }

            &:not(.grid-disabled) {
                > .searchbox-dd-item.searchbox-group > .searchbox-dd-item.searchbox-group {
                    > .searchbox-dd-item:nth-last-of-type(1) {
                        padding-right: 0;
                    }
                }
                > .searchbox-dd-item.searchbox-group {
                    > .searchbox-dd-item:nth-last-of-type(3) {
                        padding-right: 0;
                    }
                }
            }

            &.grid-disabled, &:not(.grid-stack) {
                > .searchbox-dd-item.searchbox-group {
                    > .searchbox-dd-item:nth-last-of-type(1) {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    // --------------------------------------------- //
    // Additional field styles
    // --------------------------------------------- //
    &[data-fields-appearance="traditionalPlaceholders"],
    &:not([data-fields-appearance]) {
        .fields-container {
            > .searchbox-dd-item {
                &.multiflight {
                    @include respond-to($screen-sm-max) {
                        > .searchbox-dd-item {
                            padding-top: $quarter-gutter !important;
                        }
                        .searchbox-field-label {
                            left: 0 !important;
                        }
                    }

                    > .searchbox-dd-item {
                        &:nth-child(1) {
                            width: calc(25% - #{$eighth-gutter});
                        }

                        &:nth-child(2) {
                            width: calc(25% + #{$eighth-gutter});
                        }

                        &:nth-child(3) {
                            > .searchbox-dd-item:first-child {
                                padding-right: $quarter-gutter;
                            }
                        }
                    }
                }
            }
        }

    }

    &[data-fields-appearance="lite"] {
        .fields-container-wrapper {
            padding: 0;
        }

        .fields-container {
            > .searchbox-dd-item {
                padding: 0;

                @include searchbox-fields-1lvl {
                    border: none;

                    @include searchbox-fields-2lvl {
                        border: none;
                    }
                }

                // Disable selects border when changing
                .select2-container-active {
                    border-width: 0px !important;
                }

                // Fields right border
                &:not(.searchbox-group):not(:last-of-type):before,
                &.searchbox-group > *:before,
                &.searchbox-group > .searchbox-group > *:before {
                    content: ' ';
                    z-index: 3;
                    position: absolute;

                    @media (min-width: $screen-sm + 1) {
                        width: 0;
                        height: 100%;
                        top: 0;
                        right: 0;
                        border-right: 1px solid $color19 !important;
                    }

                    @include respond-to($screen-sm-max + 19px) { // - fix for iOS
                        width: 100%;
                        height: 0;
                        bottom: 0;
                        left: 0;
                        border-bottom: 1px solid $color19 !important;
                    }
                }

                // Fields labels styles
                .searchbox-field-label {
                    top: $quarter-gutter * 2;
                    text-transform: uppercase;
                    font-size: $font-size-small;
                    font-weight: bold;
                }

                .searchbox-field-label + div:not(.input-container),
                .searchbox-field-label + div.input-container > * {
                    padding: 30px $quarter-gutter 10px $quarter-gutter;
                }

                .searchbox-field-label + div {
                    margin-top: 0;
                    height: 100% !important;

                    > .mc-icon {
                        top: calc(50% - 16px + 10px);
                        right: $quarter-gutter;
                    }

                    > .mc-radio {
                        margin-top: 5px;

                        + label {
                            margin-left: $quarter-gutter + 15px;
                        }
                    }
                }

                div {
                    .searchbox-field-label {
                        top: $quarter-gutter * 3;
                    }
                    &:first-child {
                        > .searchbox-field-label {
                            left: $quarter-gutter;
                        }
                    }
                }

                &.multiflight {
                    > div:first-of-type {
                        > .searchbox-field-label {
                            left: 2 * $half-gutter !important;
                        }
                    }
                }

                &[data-field-type="fields-additional-button"] {
                    padding: 0 !important;
                }

                @include respond-to($screen-sm-max) {
                    &[data-field-type="searchbtn"],
                    &[data-template-type="resetbtn"] {
                        width: calc(100% - #{$half-gutter}) !important;
                        margin: $quarter-gutter !important;
                    }
                }
            }
        }
    }

    &[data-fields-appearance="rich"] {
        .fields-container {
            > .searchbox-dd-item {
                // Fields labels styles
                .searchbox-field-label {
                    top: $quarter-gutter * 1.5;
                    max-width: calc(100% - #{$half-gutter});
                }
                .searchbox-field-label + div:not(.input-container) {
                    padding-top: 20px;
                }
                .searchbox-field-label + div.input-container {
                    > * {
                        padding-top: 20px;
                    }
                }
                .searchbox-field-label + div {
                    margin-top: 0;
                    height: 100% !important;

                    > .mc-icon {
                        top: calc(50% - 16px + 10px);
                    }

                    &.searchbox-radio {
                        padding-bottom: 0px;
                    }

                    > .mc-radio {
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    // --------------------------------------------- //
    // Slim tabs for searchbox
    // --------------------------------------------- //
    $slim-tabs-height: 38px;

    @media (min-width: $screen-sm + 1) {
        &.slim-tabs:not(.step2) {
            .section-content {
                background: transparent !important;

                .nav-tabs {
                    height: $slim-tabs-height;
                    white-space: nowrap;
                    overflow: hidden;

                    > li {
                        float: initial;
                        position: relative;
                        display: inline-block;
                        width: auto !important;
                        height: auto !important;
                        border: none !important;
                        overflow: hidden;
                        margin-right: 1px;
                        margin-bottom: 0;
                        background-color: "$bgcolor4";
                        @include border-top-radius("$block-br");

                        &:not(.active) {
                            box-shadow: inset 0px -4px 1px -1px rgba(0, 0, 0, 0.2);
                        }

                        &.active {
                            background-color: "$bgcolor3" !important;
                        }

                        a {
                            padding: 0 15px;
                            top: auto !important;
                            transform: none !important;
                            background-color: transparent !important;
                            line-height: $slim-tabs-height;
                            height: $slim-tabs-height;

                            > * {
                                display: inline-block;
                                vertical-align: middle;
                                width: auto;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                > i {
                                    vertical-align: -20%;
                                }
                            }
                        }
                    }

                    > li:first-child:nth-last-child(1) {
                        width: 100%;
                    }

                    @for $tabNum from 2 through 6 {
                        > li:first-child:nth-last-child(#{$tabNum}),
                        > li:first-child:nth-last-child(#{$tabNum}) ~ li {
                            max-width: (100% / $tabNum);
                        }
                    }
                }
            }

            &.slim-tabs-left {
                .section-content .nav-tabs {
                    text-align: left;
                }
                .tab-content form .fields-container-wrapper {
                    @include border-top-right-radius("$block-br");
                }
            }
            &.slim-tabs-center {
                .section-content .nav-tabs {
                    text-align: center;
                }
                .tab-content form .fields-container-wrapper {
                    @include border-top-right-radius("$block-br");
                    @include border-top-left-radius("$block-br");
                }
            }
            &.slim-tabs-right {
                .section-content .nav-tabs {
                    text-align: right;
                }
                .tab-content form .fields-container-wrapper {
                    @include border-top-left-radius("$block-br");
                }
            }
        }
    }
}

section.search-box {
    .tab-content {
        .fields-container {
            &.grid-stack-one-column-mode {
                > .searchbox-dd-item {
                    min-height: 45%;
                }
            }
        }
    }
}

// Multiflight special styles
[merlin-grid="4"],
[merlin-grid="6"] {
    > section.search-box {
        .fields-container {
            &.fields-labels {
                > .searchbox-dd-item.multiflight {
                    height: 3 * ($searchbox-default-field-height + 24px) !important;
                    max-height: 3 * ($searchbox-default-field-height + 24px) !important;

                    > .searchbox-dd-item {
                        height: $searchbox-default-field-height - $half-gutter + 24px !important;
                    }
                }
            }

            > .searchbox-dd-item {
                &.multiflight {
                    height: 3 * ($searchbox-default-field-height) !important;
                    max-height: 3 * ($searchbox-default-field-height) !important;

                    > .searchbox-dd-item {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        width: 100% !important;
                        height: $searchbox-default-field-height - $half-gutter !important;
                        margin-top: $half-gutter;

                        &:first-child {
                            margin-top: 0;
                        }

                        &[data-field-type="multiflight"] {
                            width: 100%;

                            > .searchbox-dd-item {
                                width: 50%;

                                &:first-child {
                                    padding-right: $quarter-gutter !important;
                                }
                                &:last-child {
                                    padding-left: $quarter-gutter !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Second step styles
section.search-box.step2 {
    .fields-container:not(.grid-stack) {
        &.fields-labels {
            //
            // For now fields labels are disabled on second step but I leave this just in case
            //
            //> .searchbox-dd-item[data-field-type="searchbtn"],
            //> .searchbox-dd-item[data-template-type="resetbtn"] {
            //    height: $searchbox-default-field-height;
            //}
            //
            //> .searchbox-dd-item {
            //    &[data-template-type="date"], &[data-field-type="participants"].children-contain {
            //        margin-bottom: ($searchbox-default-field-height + 24px) !important;
            //    }
            //}

            > .searchbox-dd-item.multiflight {
                height: 3 * ($searchbox-default-field-height + $half-gutter + 24px) !important;
                max-height: 3 * ($searchbox-default-field-height + $half-gutter + 24px) !important;

                > .searchbox-dd-item {
                    height: $searchbox-default-field-height - $half-gutter + 24px;
                }
            }
        }
    }
}

.layout-col.sub-col-aside .section.search-box {
    .fields-container > .searchbox-dd-item {

        &[data-template-type="date"], &[data-field-type="participants"].children-contain, &[data-field-type="class"] {
            height: auto;
        }

        > .searchbox-dd-item ~ .searchbox-dd-item {
            margin-top: $half-gutter;
        }

        @include searchbox-fields-1lvl {
            padding-left: 0 !important;
            padding-right: 0 !important;

            .searchbox-field-label {
                left: 0 !important;
            }
        }

        &.multiflight {
            height: 3 * ($searchbox-default-field-height) !important;
            max-height: 3 * ($searchbox-default-field-height) !important;

            > .searchbox-dd-item {
                padding-left: 0 !important;
                padding-right: 0 !important;
                width: 100% !important;
                height: $searchbox-default-field-height - $half-gutter !important;

                &[data-field-type="multiflight"] {
                    width: 100%;

                    > .searchbox-dd-item {
                        width: 50%;

                        &:first-child {
                            padding-right: $quarter-gutter !important;
                        }
                        &:last-child {
                            padding-left: $quarter-gutter !important;
                        }
                    }
                }
            }
        }
    }
}

.children-container {
    padding: 0 0 10px 0;
    display: inline;

    .added-child-row {
        .searchbox-element {
            padding: 0;

            .searchbox-label {
                color: $color10;
                padding: 0;
                padding-left: $quarter-gutter + 7px;
                white-space: nowrap;
                font-weight: normal;
                margin-top: $quarter-gutter;
            }

            .mc-input-container {
                margin: 7.5px;
                .mc-input {
                    max-height: 31px;
                }

            }

            .searchbox-label~.mc-input-container {
                margin-top:6px;
            }

        }
    }
}

.tab-pane-mobile-short-form {
    .without-label {
        margin-top: 0 !important;
    }
}
