@import "../../mixin-simple-carousel";

.object-listing-elem.elem-booking {
    padding-top: 15px;
    padding-bottom: 15px;

    &.full-width-gallery {
        .simple-carousel {
            @include simple-carousel();
        }
    }

    .img-container-s-3-5 {
        float: left;
        overflow: hidden;
        max-height: 301px;

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            max-height: 202px;
        }

        @media (max-width: $screen-xs-max) {
            max-height: none;
        }

        &.img-container-lmplus{
            max-height: 175px;
        }
    }

    .elem-meta {
        .meta-date {
            min-width: 55px;
            display: inline-block;
        }
    }

    .elem-rating {
        float: none;
        margin: 10px 0;
    }

    .facilities {
        margin-top: 15px;
        margin-bottom: 10px;
        border: 0 none;
        @include respond-to($screen-sm-max) {
            margin-bottom: 0;
        }
    }

    .elem-name {
        .map-icon-wrapper i.fa-map-marker{
            font-size: 24px;
        }

        h2,h3,h4{
            display: inline;
        }

        .fa-inverse {
            left: -1px;
            font-weight:bold;
            font-style:normal;
        }
    }

    .price-container {
        text-align: right;
        display: inline-block;
        padding-top: 10px;

        hr {
            box-sizing: border-box;
        }

        &.mobile-footer-available {
            @media (max-width: $screen-md) {
                //mini footer with price details for mobile screens
                position: fixed;
                bottom: 0;
                background-image: linear-gradient(to right, $color3, $color3),linear-gradient(to right, white, white);
                opacity: 1;
                z-index: 10000;
                display: block;
                left: 0;
                padding: 0 0 0 0;

                .button-mobile-close-summary {
                    width: 50%;
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                }

                .price-details-header {
                    padding:10px;
                    margin-top: 50px;
                    span{
                        float:left;
                        font-size: 19px;
                    }
                }

                .button-container, .price-label-container {
                    background: $color6;
                }
                &.mobile-details-shown {
                    top: 0;
                    .button-mobile-close-summary,
                    .tfg-info,
                    .price-row,
                    .price-details-header{
                        display: inherit;
                    }
                }

                &.mobile-details-shown {
                    .button-container{
                        display: none;
                    }
                }

                .price-label {
                    font-size: 30px;
                }

                &.mobile-details-shown {
                    .strip-even{
                        background-color: $darkerBackground;
                    }

                    .price-label-container {
                        display: block;
                        width: 100%;
                        text-align: right;
                        background-color: transparent;
                        color: $color9;
                        .price-label{
                            font-size: 20px !important;
                            font-weight: normal;
                            color: $color11;
                        }
                    }

                    .captcha-wrap .captcha-container {
                        display: none
                    }
                }

                .tfg-info,
                .price-row{
                    padding: 5px 15px;
                    .price-desc {
                        line-height: 1.6;
                    }
                }

                .tfg-info, .price-row, .price-details-header, .button-mobile-close-summary {
                    display: none;
                }

                .tfg-info {
                    font-size: 14px;
                    float: left;
                    width: 100%;
                    margin: 0;
                    line-height: 1.8;
                }
                .price-separator, details, hr {
                    display: none;
                }

                details {
                    height: 0;
                    width: 0;
                }

                .price-label-container {
                    color: $color12;
                    padding-bottom: 4px;
                    text-align: center;
                }

                .captcha-wrap {
                    width: 100%;
                    display: none;
                    .captcha-container {
                        float: left;
                        padding: 10px;
                        background: $color6;
                        display: flex;
                        width: 100%;
                        .captcha-field-container {
                            flex-grow: 1;
                        }
                        .captcha-description {
                            text-align: end;
                            padding-right: 10px;
                            p{
                                color: $color14;
                            }
                        }
                    }
                }

                .price-component {
                    border: none;
                    font-size: 18px;
                    .price-amount {
                        font-weight: normal;
                    }
                    .price-desc {
                        font-size: 14px;
                        color: $color9;
                    }
                }

                .price-curr {
                    display: none;
                }

                .button-container {
                    padding: 10px;
                    margin-top:0;
                    font-size: 16px;
                    font-weight: 800;

                    .btn-back, .btn-send {
                        font-size: inherit;
                    }

                }

                .price-load-cover {
                    display: none;
                }
            }


            @media (max-width: $screen-sm) {
                .btn-back{
                    max-width: 38px;
                }
            }
        }

        @include respond-from($screen-md-min){ //step 4 when .elem-details has full (high) table
            float: right;
        }

        .price-separator {
            display: none;
        }

        .price-person {
            font-size: 18px;
        }

        .price-label-container {
            margin: 0;
            padding: 10px 15px 0 0;
            white-space: nowrap;

            .price-label {
                display: inline;
            }
        }
    }

    .price-extended-info-container{
        padding: $margin-padding-md $margin-padding-md 0;
        border-top: 1px solid;
        @include respond-to($screen-xs-max){
            margin-top: $margin-padding-md;
        }

        .price-extended-info{
        }
        .info-label{
            font-weight: 700;
        }
        .info-text{

        }
    }

    .button-container {
        margin: 10px 0 0;
        padding: 0;

        .btn {
            min-width: 175px;
        }

        .btn-send {
            visibility: hidden;
            min-height: 36px;
        }

        @include respond-to($screen-sm-max) {
            padding-top: 0;
            margin-top: 5px;

            .btn {
                min-width: 0;
                display: block;
                width: 100%;
            }
        }
    }

    .candy-list {
        p {
            margin: 0;
        }

        @include respond-to($screen-xs-max) {
            border-top: 1px solid;
            border-bottom: 1px solid;
            padding: 5px 0;
            margin: 10px 15px 0 0;
        }
    }

    .btn-function-group {
        @include respond-to($screen-xs-max) {
            margin-top: 15px;
            padding-bottom: 15px;
        }
    }
    &.de-country{
        .elem-name{
            h4{
                display: none;
            }
        }
    }
}

#top-price-container .price-label{
    font-size: 36px;

    @media (max-width: $screen-md) {
        font-size: 30px;
    }
}

input[name="client_birthdate"]{
    cursor: pointer;
    position: relative;
    & ~ i{
        position: absolute;
        right: 22px;
        top: 8px;
        font-size: 21px;
    }
}

/* Alternatives step 3 */
.alternatives-list {
    .alternative {
        display: block;
    }
}

.alert-paragraph{
    margin: 0;
    padding: $margin-padding-md;
    padding-bottom: 0;
    font-weight: 700;
}

.payment,.termsStatements{
    .form-group{
        border-bottom:1px solid $color19;
        border-top:1px solid $color19;
        position:relative;
        min-height: 37px;
        line-height: 38px;
        margin-bottom: 0;
        padding-left: 15px;
        margin-top: -1px;

        input {
            margin-right: 10px;
            float: left;
        }

        label {
            font-weight: normal;
            line-height: 20px;

            span {
                display: block;
                padding-left: 23px;
                padding-right: 15px;
            }
        }

        &.form-group-price-payment {
            text-align: right;
            font-size: 18px;

            span {
                padding: 0 15px;
            }
        }
    }

    .col-lg-12 {
        &.last-child {
            .form-group {
                border-bottom: 0;
            }
        }
    }

    table {
        .form-group {
            padding-left: 0;
        }
    }
}

.msd-car-payment-choice{
    user-select: none;

    .panel-overlay{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }
    .cell-payment-choice-text{
        width: 100% !important;
    }
}

.termsStatements {
    .form-group {
        padding-top: 8px;
        padding-bottom: 4px;
        line-height: 10px;

        input[type="checkbox"] {
            width: auto;
        }
    }

    a {
        color: $color16;
    }

    .box-container {
        display: flex;

        .box-item {
            align-self: flex-start;

            &.item-1 {
                flex: 0 0 36px;
            }
            &.item-2 {
                flex: 1 1;
            }
        }
    }

    .form-group {

        .checkbox-stack {
            top: 0;
            margin: 0;
            line-height: 28px !important;

            input[type=checkbox] + label.last-plan:before,
            input[type=checkbox] ~ label.first-plan:before {
                top: 0;
            }
        }

        .stack-label {
            display: inline-block;
            margin: 3px $margin-padding-md 4px 0;
            font-weight: normal;
            font-size: 14px;
            line-height: normal;
            font-family: "$font-family", sans-serif;
        }

        .description {
            display: block;
            margin: 3px $margin-padding-md 4px 0;
            font-weight: normal;
            font-size: 14px;
            line-height: normal;
            font-family: "$font-family", sans-serif;
        }
    }

    .border-bottom-none {
        border-bottom: none !important;
    }

    .border-top-none {
        border-top: none !important;
    }
}

.elem-price-container {
    text-align: right;

    h3 {
        line-height: 25px;
    }

    .price-label {
        font-size: 18px;
    }
}

.reserve-back-wrapper{
    margin-top: -64px;
    margin-bottom: 15px;
}

.btn-payment {
    .choosen {
        display: none;
    }
    &.selected {
        .choosen {
            display: block;
        }
        .choose {
            display: none;
        }
    }
}

.elem-price-container-footer {
    .price-value {
        font-size: 36px;
        display: inline-block;
    }
    &.controls-empty{
        @media (min-width: $screen-md-min) {
            display: none;
        }
    }
    .reserve-controls{
        display: flex;
        @media (max-width: $screen-xs-max) {
            flex-direction: column;
        }
        .btn {
            margin: 0;
        }
        #captcha-alternative{
            @media (max-width: $screen-xs-max) {
                order:-1;
                margin-bottom: 10px;
            }
        }
    }

    .btn {
        margin-top: -15px;
        min-width: 175px;

        @media (max-width: $screen-xs-max) {
            margin-top: 15px;
            margin-bottom: 15px;
            width: 100%;
        }
    }

    .non-flight-button {
        font-size: 14px;
        padding-top: 7px;
        padding-bottom: 7px;
        margin-bottom: 0;
    }

    .price-label {
        font-size: 21px;
    }
}

#offer-unavailable-error {
    height: 80px;
}

.form-control, .required {
    &.error {
        background-color: $input-err-col-transp;

        &:focus {
            outline-color: inherit;
        }
    }
}

input.error[type="radio"], input.error[type="checkbox"] {
    outline: thin solid;
    outline-offset: -1px;
    outline-color: $color21;
}

input.error[type="radio"] {
    webkit-outline-radius: 50%;
    outline-radius: 50%;
    -moz-outline-radius: 50%;
}

input.error[type="checkbox"] {
    outline: thin solid;
    outline-offset: 0px;
}


.ask-about-offer {
    width: 567px;
    max-height: none;

    label {
        font-weight: normal;

        input#askAgree {
            margin-right: 5px;
              margin-top:0;
              position:absolute;
            top: 3px;
            left:0;
            width: auto;
        }
    }

    input.btn {
        max-width: 195px;
        float: right;
    }

    textarea {
        height: 104px;
    }

    .form-element-btn {
        display: table;
        width: 100%;
    }

    .qtip-4-content {
        overflow: visible;
    }

    .qtip-content {
        overflow-y: visible;
    }

    .labelAskAgree {
        position: relative;
          font-size:11px;
        display:inline-block;
          padding-left: 20px;
    }

    .preloader {
      display: none;
      max-width: none;
    }
}

@include respond-to($screen-xs-max) {
  .qtip-fullscreen-smartphone {
    border: 0 none;
    border-radius: 0;
    height: 100% !important;
    left: 0 !important;
    max-height: none;
    max-width: none;
    position: fixed;
    top: 0 !important;
    width: 100% !important;
  }

  .qtip-fullscreen-smartfon{
    .pageFormQtip2{
        padding-bottom: 50px;
    }
    input.btn{
      max-width: none;
      float:none;
    }
  }
}
section.pricing-table {

    .luggage-row-padding {
        padding: 15px 15px 0;
        @include respond-to($screen-sm-max){
            //padding-top: 7px;
        }
    }

    .pricing-row-padding{
        padding: $margin-padding-xs $margin-padding-md;
    }

    .luggage-margin {
        margin: 10px 0;
    }

    $pricing-table-span-line-height: 34px;
    .list-item-name{
        @include respond-from($screen-md-min){
            line-height: $pricing-table-span-line-height;
        }
    }

    .input-container {
        padding-bottom: 15px;
    }

    .l-margin {
        @include respond-to($screen-sm-max){
            margin-bottom: 13px;
        }
    }

    .price{
        font-size: 18px;
        @include respond-from($screen-md-min){
            line-height: $pricing-table-span-line-height;
        }
    }

    .elem-price-container {
        padding-right: 10px;
    }

    h3 {
        margin-top: 10px;
    }

    /*.border-bottom:last-child {
        border: none;
    }*/
}

.captcha-container{
    padding: 0;
    text-align: left;
    p{
        margin: 6px 0;
    }
}

.captchaField{
    img{
        max-width: none;
    }
}

.js-not-required-fields {
    width: 100%;
    display: none;
}

.width-auto {
    width: auto;
}

.js-expand-not-required-fields {
    display: block;
    margin-bottom: 11px;
}

.trip-booking.additional-services {

    .services-type:not(:first-child){
        border-top: 1px solid $color19;
    }

    $icon-wrapper-height: 56px;
    .service-heading{
        position: relative;
        display: flex;
        padding: $margin-padding-md;
        padding-right: 0;
        min-height: calc(#{$icon-wrapper-height} + (2 * #{$margin-padding-md}));

        .service-icon-wrapper{
            flex: 0 0 calc(#{$icon-wrapper-height});
            align-self: flex-start;
            margin-right: $margin-padding-md;
            width: $icon-wrapper-height;
            height: $icon-wrapper-height;
            box-sizing: border-box;
            border: 2px solid $color1;
            overflow: hidden;
            font-size: calc(#{$icon-wrapper-height} - 10px);
            line-height: $icon-wrapper-height + 2px;
            text-align: center;
            @include border-radius($icon-wrapper-height);
        }

        .service-heading-overlay{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            cursor: pointer;
        }

        .service-heading-text{
            align-self: flex-start;
            flex: 1 1;

            .service-name{
                line-height: inherit;
            }
            .service-incentive{
            }
        }

        .service-logo{
            flex: 0 0;
            align-self: flex-start;
            margin-left: $margin-padding-md;
            max-width: 250px;
            height: 56px;
        }

        .btn-collapse{
            flex: 0 0;
            align-self: flex-start;
            padding: $margin-padding-md;
            font-size: 18px;
        }
    }

    .row-info{
        padding: $margin-padding-md;
        border-bottom: 1px solid $color19;
    }

    .service-row{
        position: relative;
        padding: $margin-padding-md;
        border-top: 1px solid $color19;
        font-weight: normal;

        @include respond-to(419px) {
            flex-direction: column;
        }

        &-car{
            flex-direction: column;
        }

        & div {
            @include respond-to(419px) {
                padding-right: 0;
            }
        }

        &:first-child{
            border-top: none;
        }

        .box-container{
            display: flex;
            align-items: center;

            .box-item{
                align-self: center;

                &.item-1{
                    flex: 0 0 36px;
                }
                &.item-2{
                    flex: 1 1;
                }
                &.item-3{
                    flex: 0 0 auto;
                    padding-left: $margin-padding-md;
                }
            }

            .radio-item{
                flex: 0 0 40px;

                .radio-stack{
                    top: 1px;
                    right: -22px;

                    .first-plan, .last-plan{
                        left: auto;
                        top: auto;
                    }
                }
            }

            .item-price{
                text-align: right;
                min-width: 145px;
                @include respond-to($screen-xs-max) {
                    flex: 0 0;
                    min-width: unset;
                }
            }

            .service-link {
                float: right;
                @include respond-to($screen-xs-max) {
                    float: left;
                }
            }
        }

        &.flex-column {
            flex-direction: column;
        }

        .service-content.box-container{
            display: flex;
            flex-wrap: wrap;

            .box-item{
                align-self: center;
                order: 1;

                &.item-1{
                    flex: 0 0 120px;
                    margin-right: $margin-padding-md;
                }
                &.item-2{
                    flex: 1 1;
                    align-self: flex-start;
                    padding-right: $margin-padding-md;
                    min-width: 200px;
                    @include respond-to($screen-xs-max){
                        order: 2;
                    }
                    &.item-group{
                        align-self: center;
                    }
                }
                &.item-3{
                    flex: 0 0;
                    padding: 0;
                    max-width: 100px;
                }
            }
        }

        .overlay{
            position: absolute;
            top: 0;
            right: -$margin-padding-md;
            bottom: 0;
            left: -$margin-padding-md;
            max-width: none;
            width: auto;
        }

        .service-img{
            float: left;
            max-width: 120px;
            max-height: 80px;
        }
        .service-name{
            @include respond-to(419px){
                word-break: break-all;
            }

            &.name-car{
                margin-bottom: $margin-padding-xs;
                font-weight: 700;
                @include respond-to($screen-xs-max){
                    margin-top: $margin-padding-xs;
                }
            }
        }
        .car-attributes{
            display: contents;
        }
        .service-link{
            position: relative;
            z-index: 15;
        }

        .price-wrapper{
            font-size: 18px;
            white-space: nowrap;

            .price-val,
            .price-curr{
                @include respond-to(419px) {
                    float: right;
                }
            }
        }
    }

    .hidden-agreement-row{
        position: relative;
        padding: $margin-padding-md;
        padding-left: 32px;// center with panel icon
        border-top: 1px solid $color19;
        display: flex;
        align-items: flex-start;

        .overlay{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            cursor: pointer;
        }

        .checkbox-stack{
            flex: 0 0 39px;
            margin-bottom: 0;
        }

        .agreement-text{
            flex: 1 1;
            margin: $margin-padding-xs 0 0;
            font-weight: 500;

            .link{
                position: relative;
                padding: $margin-padding-md 0;
                margin: -$margin-padding-md 0;
                z-index: 1;
            }
        }
    }

    .uncollapse-list{
        position: relative;
        padding: $margin-padding-md;
        padding-left: 34px;// center with panel icon
        border-top: 1px solid $color19;

        .overlay{
            @include overlay;

            &:hover + .link{
                color: $color18;
            }
        }

        .fa{
            vertical-align: -27%;
        }
    }
}

.panel-heading-participant.box-container{
    display: flex;
    flex-wrap: wrap;

    .box-item{
        align-self: flex-start;

        &.item-1{
            flex: 1 1;
        }
        &.item-2{
            flex: 0 0 auto;
        }
    }

    .copy-person-fields-checkbox{
        position: relative;
        white-space: nowrap;

        label{
            margin: 0;
            font-weight: 500;
        }
    }
}

.confirmation-block{
    padding: 30px 0 50px;

    .confirmation-number{
        margin-top: 30px;
        font-size: 16px;

        strong{
            font-size: 24px;
        }
    }

    .confirmation-info{
        max-width: 768px;
        margin: 30px auto 0;
    }
}

.confirmation-bottom{
    border-top: 1px solid $color19;
    padding: $margin-padding-md;

    .btn{
        font-size: 16px;
    }
}


.back-btn-wrapper-trip-details {
    display: inline-block;
    padding-left: $margin-padding-md;
    vertical-align: 3px;
    margin-bottom: 12px;

    & + .social-trip-details {
        clear: none;
        display: inline-block;
        padding-top: 10px;
    }
}

#booking-form .input-container {
    .floating-label {
        margin: 0;
        font-size: 15px;
        font-weight: normal;
        position: absolute;
        top: 5px;
        left: 23px;
        opacity: 0;
        visibility: hidden;
        transition: font-size .3s, top .3s, left .3s;
        color: $color9;

        &.floating-label-active {
            top: -15px;
            left: 15px;
            visibility: visible;
            font-size: 12px;
            opacity: 1;
        }

        & + input[type="text"], & + input[type="email"], & + textarea {
            &:focus::-webkit-input-placeholder { color:transparent; }
            &:focus:-moz-placeholder { color:transparent; }
            &:focus::-moz-placeholder { color:transparent; }
            &:focus:-ms-input-placeholder { color:transparent; }
        }
    }
    label {
        @media (max-width:$screen-sm-max) {
            display: none;
        }
    }

    @media (min-width: $screen-md-min) {
        & :-moz-placeholder{
            color: transparent;
            opacity: 0;
        }
        & ::-moz-placeholder{
            color: transparent;
            opacity: 0;
        }
        & ::-webkit-input-placeholder {
            color: transparent;
            opacity: 0;
        }
        & :-ms-input-placeholder{
            color: transparent;
            opacity: 0;
        }
        & textarea::-webkit-input-placeholder{
            color: transparent;
            opacity: 0;
        }
        & textarea:-moz-placeholder{
            color: transparent;
            opacity: 0;
        }
        & textarea::-moz-placeholder{
            color: transparent;
            opacity: 0;
        }
        & textarea:-ms-input-placeholder{
            color: transparent;
            opacity: 0;
        }
    }

}

.form-booking {
    .select2-container .select2-choice {
        height: 30px;
        min-height: inherit;
    }
    .form-booking-person .row:not(.js-not-required-fields) label::after, #client .row:not(.js-not-required-fields) label::after{
        content: '*';
    }
}

.services-group .section-content{
    padding-top: 0;
    padding-bottom: 0;
}

.service-container{
    padding-top: 10px;
    padding-bottom: 5px;
    &:not(:last-child){
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }
}

.service-participant-container, .service-title-container{
  min-height: 24px;
}

.reservation-disabled {
    width: 100%;
    height: 300px;
    border: 1px solid black;
    text-align: center;

    div {
        top: 25%;
        position: relative;
        padding: 20px
    }
}
.main-col {
    float:none;
}

.reserve-col-aside {
    @media (min-width: $screen-md-min) {
        float:right;
        position: sticky;
        top: 0;
    }

    .elem-meta {
        td.meta-label {
            @include respond-from($screen-xs-max) {
                padding: 3px 5px 3px 15px;
            }
        }
    }

    .price-component {
        border-bottom: dotted 1px $color19;

        .price-desc {
            padding: 1px 2px;
        }

        .price-amount {
            font-weight: 800;
            padding: 1px 2px;
        }

        +div .tfg-info{padding-top:8px;}
    }

    #price-details-box{
        text-align: left;
        ul{
            padding-left: 15px;
        }

    }

    .tfg-info {
        font-size: 0.9em;
        text-align: left;
        white-space: normal;
    }
    .price-load-cover{
        padding-top: 5%;
        width: 100%;
        bottom: 100px;
        position: absolute;
        .price-loader{
            font-size: 1.3em;
            margin: auto;
           /* margin: 10% auto 0 auto;*/
            float: none;
            max-height: 2em;
            position: relative;
        }
        .price-load-label{
            font-size: 1.3em;
            text-align: center;
            float: none;
            display: block;
            position: relative;
        }
        .cover{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.95;
            //background-color: white;
        }
    }

}

.price-loader{
    width:5em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    line-height: 34px;
    height: 100%;
    margin-top: 10px;
    font-size: 10px;
    float: right;
}
.loader-component{
    animation-duration: 1s;
    animation-name: wait-loader;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.loader-component:nth-child(1){
    animation-delay:-1.2s
}
.loader-component:nth-child(2){
    animation-delay:-0.8s
}
.loader-component:nth-child(3){
    animation-delay:-0.4s
}
.loader-component:nth-child(4){
    animation-delay:0s
}


@keyframes wait-loader {
    0% {
        opacity: 0;
        //transform: scale(1.3);
    }
    100% {
        opacity: 1;
    }
}

.service-in-conflict {
    opacity: 0.5
}

.additionalServices {
    overflow: hidden;
    position: relative;
}

.cover {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 98;
    text-align: center;
    padding-bottom: 200px;
}


#booking-form .prompt {
    min-width: 200px;
    max-width: 80%;
    min-height: 250px;
    max-height: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 80%;
    position: sticky;
    top: 40vh;
    @media (max-width: $screen-xs-max)  {
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        height: 100%;
        max-height: 100%;
    }
    &-header{
        width: 100%;
        height: 30px;
        font-weight: bold;
        font-size: 1.3em;
        padding-top: 10px;
        .title{
            width: 100%;
            height: 30px;
            font-weight: bold;
            font-size: 1.3em;
            padding: 0 10px;
        }
    }
    &-body{
        width: 100%;
        padding: 20px 0 10px 0;
        .description {
            width: 100%;
        }
        .conflicts-list {
            text-align: left;
            width: 80%;
            margin-left: 10%;
            padding-top: 20px;
        }
    }
    &-footer{
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 30px;
        bottom: 0;
        flex-wrap: wrap;
        .btn {
            border-radius: 0
        }
        .description {
            width: 100%;
            margin: 10px 0;
        }
        .controls {
            width: 100%;
            margin: 10px 0;
            button {
                margin: 0 5px;
            }
        }

    }
    &-content-wrapper{
        width: 100%;
    }
}

.js-expand-not-required-fields, .js-expand-loyalty-card{
    .fa-chevron-down{
        transition: transform .2s linear;
    }

    &.js-expanded{
        .fa-chevron-down {
            transform: rotate(180deg);
        }
    }
}

.js-expand-not-required-fields{
    .expand-text{
        &:after{
            content: attr(data-text-shrinked);
        }
    }
    &.js-expanded .expand-text{
        &:after{
            content: attr(data-text-expanded);
        }
    }
}

.payment-selection ul li{
    @media (min-width: $screen-xs-max)  {
        display: flex;
        align-items: center;
    }
}

#booking-form{
    margin-bottom: 10px;
}

.row-payment{
    overflow: hidden;
    padding: 8px 0;
    &:first-child .payment-description{
        font-weight: bold;
    }
}

.full-body-popup-opened header#main-header {
    @media (max-width: $screen-md-min) {
        display: none;
    }
}

#booking-container-fluid .layout-col {
    @media (max-width: $screen-xs-max) {
        padding: 0;
    }
}

.qtip-srv-client-desc {
    border: 1px solid $color1;
    max-width: none;
    max-height: none;
}
