$gridstack-columns: 60 !default;
$horizontal_padding: 20px !default;
$vertical_padding: 20px !default;
$animation_speed: .3s !default;

@mixin vendor($property, $value...) {
    -webkit-#{$property}: $value;
    -moz-#{$property}: $value;
    -ms-#{$property}: $value;
    -o-#{$property}: $value;
    #{$property}: $value;
}

:root .grid-stack-item > .ui-resizable-handle {
    filter: none;
}

.grid-stack {
    position: relative;

    &.grid-stack-rtl {
        direction: ltr;

        > .grid-stack-item {
            direction: rtl;
        }
    }

    .grid-stack-placeholder > .placeholder-content {
        margin: 0;
        position: absolute;
        top: 0;
        left: $horizontal_padding / 2;
        right: $horizontal_padding / 2;
        bottom: 0;
        width: auto;
        z-index: 0 !important;
        text-align: center;
    }

    > .grid-stack-item {
        min-width: 100% / $gridstack-columns;
        position: absolute;
        //padding: 0;

        > .grid-stack-item-content {
            margin: 0;
            position: absolute;
            top: 0;
            left: $horizontal_padding / 2;
            right: $horizontal_padding / 2;
            bottom: 0;
            width: auto;
            z-index: 0 !important;
            overflow-x: hidden;
            overflow-y: auto;
        }

        > .ui-resizable-handle {
            position: absolute;
            display: block;
            -ms-touch-action: none;
            touch-action: none;
            color: $color18;
            text-indent: 0;
            font-size: initial;
            font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
            text-align: center;
            text-shadow: 0 0 1px black;

            &:after {
                content: unquote("\"#{ $fa-var-expand }\"");
              font-weight: var(--#{$fa-css-prefix}-style);
            }
        }

        &.ui-resizable-disabled > .ui-resizable-handle,
        &.ui-resizable-autohide > .ui-resizable-handle {
            display: none;
        }

        &.ui-draggable-dragging,
        &.ui-resizable-resizing {
            z-index: 100;

            > .grid-stack-item-content,
            > .grid-stack-item-content {
                box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
                opacity: 0.8;
            }
        }

        > .ui-resizable-se,
        > .ui-resizable-nw {
            @include vendor(transform, rotate(90deg));
        }

        > .ui-resizable-n
        > .ui-resizable-s {
            @include vendor(transform, rotate(-45deg));
        }

        > .ui-resizable-e,
        > .ui-resizable-w {
            @include vendor(transform, rotate(45deg));
        }

        > .ui-resizable-nw {
            cursor: nw-resize;
            width: 20px;
            height: 20px;
            left: 10px;
            top: 0;
        }
        > .ui-resizable-n {
            cursor: n-resize;
            height: 10px;
            top: 0;
            left: 25px;
            right: 25px;
        }
        > .ui-resizable-ne {
            cursor: ne-resize;
            width: 20px;
            height: 20px;
            right: 10px;
            top: 0;
        }
        > .ui-resizable-e {
            cursor: e-resize;
            width: 10px;
            right: $horizontal_padding / 2;
            top: 15px;
            bottom: 15px;
        }
        > .ui-resizable-se {
            cursor: se-resize;
            width: 20px;
            height: 20px;
            right: 0px;
            bottom: 0;
        }
        > .ui-resizable-s {
            cursor: s-resize;
            height: 10px;
            left: 25px;
            bottom: 0;
            right: 25px;
        }
        > .ui-resizable-sw {
            cursor: sw-resize;
            width: 20px;
            height: 20px;
            left: 10px;
            bottom: 0;
        }
        > .ui-resizable-w {
            cursor: w-resize;
            width: 10px;
            left: $horizontal_padding / 2;
            top: 15px;
            bottom: 15px;
        }

        &.ui-draggable-dragging {
            & > .ui-resizable-handle {
                display: none !important;
            }
        }

        @for $i from 0 through $gridstack-columns {
            &[data-gs-width='#{$i}'] {
                width: ($i / $gridstack-columns) * 100.1%;
            }
            &[data-gs-x='#{$i}'] {
                left: ($i / $gridstack-columns) * 100.1%;
            }
            &[data-gs-min-width='#{$i}'] {
                min-width: ($i / $gridstack-columns) * 100.1%;
            }
            &[data-gs-max-width='#{$i}'] {
                max-width: ($i / $gridstack-columns) * 100.1%;
            }
        }
    }

    &.grid-stack-animate,
    &.grid-stack-animate .grid-stack-item {
        @include vendor(transition, left $animation_speed, top $animation_speed, height $animation_speed, width $animation_speed);
    }

    &.grid-stack-animate .grid-stack-item.ui-draggable-dragging,
    &.grid-stack-animate .grid-stack-item.ui-resizable-resizing,
    &.grid-stack-animate .grid-stack-item.grid-stack-placeholder {
        @include vendor(transition, left .0s, top .0s, height .0s, width .0s);
    }

    &.grid-stack-one-column-mode {
        height: auto !important;
        & > .grid-stack-item {
            position: relative !important;
            width: 100% !important;
            left: 0 !important;
            top: auto !important;
            margin-bottom: $vertical_padding;
            max-width: none !important;

            & > .ui-resizable-handle {
                display: none;
            }
        }
    }
}


.grid-stack:not(.grid-stack-static) {
    // Grid background
    // grid-background-image-columns
    $gbic: 100% / 6;
    // grid-background-image-columns-width
    $gbicw: 1%;
    // grid-background-image-columns-color
    $gbicc: rgba(0, 0, 0, .1);
    background-image: linear-gradient(0deg,
            $gbicc 0%, $gbicc 10%, transparent 10%,
            transparent 90%, $gbicc 90%, $gbicc 100%
    ),
    linear-gradient(90deg,
                    transparent $gbic * 0, $gbicc $gbic * 0, $gbicc $gbic * 0 + $gbicw, transparent $gbic * 0 + $gbicw,
                    transparent $gbic * 1 - $gbicw, $gbicc $gbic * 1 - $gbicw, $gbicc $gbic * 1 + $gbicw, transparent $gbic * 1 + $gbicw,
                    transparent $gbic * 2 - $gbicw, $gbicc $gbic * 2 - $gbicw, $gbicc $gbic * 2 + $gbicw, transparent $gbic * 2 + $gbicw,
                    transparent $gbic * 3 - $gbicw, $gbicc $gbic * 3 - $gbicw, $gbicc $gbic * 3 + $gbicw, transparent $gbic * 3 + $gbicw,
                    transparent $gbic * 4 - $gbicw, $gbicc $gbic * 4 - $gbicw, $gbicc $gbic * 4 + $gbicw, transparent $gbic * 4 + $gbicw,
                    transparent $gbic * 5 - $gbicw, $gbicc $gbic * 5 - $gbicw, $gbicc $gbic * 5 + $gbicw, transparent $gbic * 5 + $gbicw,
                    transparent $gbic * 6 - $gbicw, $gbicc $gbic * 6 - $gbicw, $gbicc $gbic * 6, transparent $gbic * 6
    );
}

@mixin grid-stack-items($gridstack-columns) {
    .grid-stack.grid-stack-#{$gridstack-columns} {

        > .grid-stack-item {
            min-width: 100% / $gridstack-columns;

            @for $i from 1 through $gridstack-columns {
                &[data-gs-width='#{$i}'] { width: (100.1% / $gridstack-columns) * $i; }
                &[data-gs-x='#{$i}'] { left: (100.1% / $gridstack-columns) * $i; }
                &[data-gs-min-width='#{$i}'] { min-width: (100.1% / $gridstack-columns) * $i; }
                &[data-gs-max-width='#{$i}'] { max-width: (100.1% / $gridstack-columns) * $i; }
            }
        }

        // Grid background
        &:not(.grid-stack-static) {
            background-size: (100% / ($gridstack-columns / 6)) 12px;
        }
    }
}

@each $j in ($gridstack-columns*1/3, $gridstack-columns*1/2, $gridstack-columns*2/3, $gridstack-columns){
    @include grid-stack-items($j);
}
