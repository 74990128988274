.operbar-box {
    padding: 5px 0;
}

.operbar-list-container {
    width: 100%;
    height: 35px;
    position: relative;
    overflow: hidden;

    .carousel {
        display: inline-block;
        font-size: 0;
        padding: 0;
        white-space: nowrap;
        will-change: transform;
    }

    .oper-item {
        display: inline-block;
        position: relative;
        float: none;
        width: 100px;
        height: 35px;
        margin: 0;
        padding: 0;
        list-style-type: none;

        a {
            display: block;
            width: 70px;
            height: 35px;
            margin: 0 auto;
            text-indent: -9999px;
            background-position-y: 0px;
        }
    }

    &[data-mode="color"] {
        .oper-item a {
            background-position-y: -35px;
        }
    }

    &[data-mode="multi"] {
        .oper-item:hover a {
            background-position-y: -35px;
        }
    }
}

@-webkit-keyframes operbar {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes operbar {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}
