.region-listing {
    .section-header {
        margin: 1px 0 0;

        .sh-inner {
            h2, > .region-price {
                height: inherit;
                font-size: 19px;

                .fa {
                    vertical-align: -15%;
                }
            }
        }
    }

    .history-price.history-diff{
        position: absolute;
        top: 15px;
        right: 0;
        display: block;
        .diff {
            font-size: 1.3em;
            color: $color12;
            background-color: "$bgcolor1";
        }
    }

    .history-price.history-amount{
       opacity: .5;
    }

    .img-container {
        position: relative;
        .eguide-icon, .region-icon {
            display: block;
            position: absolute;
            top: 10px;
            background-color: "$bgcolor1";
            color: $color12;
            opacity: 0.9;
            transition: all .5s;
            padding: 5px 5px 2px 5px;
            border-radius: 5px;
            cursor: pointer;
            left: 10px;
            &:hover {
                opacity: 1;
                transform: scale(1.2);
            }
        }
        .eguide-icon-pull-right {
            left: 45px;
        }
    }
    .img {
        height: 165px;
        overflow: hidden;
        @include respond-to($screen-xs-max) {
            height: auto;
        }
        img {
            width: 100%;
        }
    }
    .region-name {
        position: relative;
        a, h3 {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            &:focus {
                text-decoration: none;
            }
        }
    }
    &.cluster {
        .region-name {
            display: flex;

            a, h3 {
                margin: 5px 0;
                white-space: nowrap;
                font-size: 18px;
                line-height: 1.3em;
            }
            a {
                /*padding-right: 25px;*/
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .fa {
                position: absolute;
                top: 9px;
                right: 0;
            }
            .region-icon {
                .fa {
                    right: 30px;
                    top: 11px;
                }
            }
        }
    }
    &.list {
        .section-header {
            .special-label {
                position: relative;
                float: left;
                margin: -7.5px 10px -7px -16px;
                line-height: 46px;
                padding: 0px 10px;
            }
        }
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0px 1px;
            white-space: nowrap;
        }
        .region-name {
            .region-name-label {
                &:first-child {
                    width: 50%;
                }
                a{
                    @include respond-to($screen-xs-max){
                        text-overflow: ellipsis;
                        max-width: 300px;
                    }
                }
            }

            a {
                padding: 10px 15px 7px 30px;
                margin-bottom: 1px;
                @include respond-to($screen-xs-max){
                    padding: 10px 5px 7px 15px;
                }
            }
        }
    }
    .region-container {
        .price-cell{
            min-width: 70px;
            @include respond-to($screen-xs-max){
                min-width: unset;
            }
            display: inline-block;
            text-align: right;
        }

        .history-price {
            font-size: .8em;
            .diff {
                margin-left: 3px;
            }
        }
    }
    .country-container{
         .price-cell{
             min-width: 100px;
             display: inline-block;
             text-align: right;
         }

        .history-price {
            font-size: .8em;
            .diff {
                margin-left: 3px;
            }
        }
     }
}

.de-country {
    .region-name {
        i.fa {
            display: none;
        }
    }
    .object-listing-elem {
        .info {
            h4 {
                span:last-child {
                    display: none;
                }
            }
        }
    }
}
