/*
 * Promobox carousel styling
 */
.promo-box {
	margin-bottom: $half-gutter;

	&.full-height .carousel {
		height: 100vh;
	}
	&.high .carousel {
		height: 408px;
	}
	&.medium .carousel {
		height: 285px;
	}
	&.low .carousel {
		height: 102px;
	}

	.carousel-inner {
		height: 100%;
		> .item {
			height: 100%;
			width: 100%;
			transition: none !important;
			left: 0 !important;
			transform: none !important;

			a {
				position: absolute;
				width: 100%;
				height: 100%;
				text-decoration: none;

				&[href="javascript:void(0)"] {
					cursor: default;
				}
			}
		}
		.animated {
			animation-duration: .6s;
		}
	}

	h2 {
		margin: 0;
	}

	// .carousel-control {
	// 	top: 50%;
	// 	margin: -35px 0 0;
	// 	width: 70px;
	// 	height: 70px;
	// 	font-size: 50px;
	// 	line-height: 70px;
	// 	@include opacity(.6);

	// 	&.left {
	// 		text-align: left;
	// 		padding-left: 15px;
	// 	}

	// 	&.right {
	// 		text-align: right;
	// 		padding-right: 15px;
	// 	}
	// }

	.carousel-indicators {
		&.pos-ul {
			top: 15px;
			left: 15px;
			bottom: initial;
		}
		&.pos-um {
			top: 15px;
			left: 0;
			right: 0;
			margin: auto;
			bottom: initial;
		}
		&.pos-ur {
			top: 15px;
			left: auto;
			right: 15px;
			bottom: initial;
		}
		&.pos-ll {
			bottom: 9px;
			left: 15px;
		}
		&.pos-lm {
			bottom: 9px;
			left: 0;
			right: 0;
			margin: auto;
		}
		&.pos-lr {
			bottom: 9px;
			right: 15px;
			left: auto;
		}
		&.pos-nd {
			display: none;
		}
	}

	.img-container {
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&.scale-x-1 {
			background-position: 0% 0%;
		}

		&.scale-x-2 {
			background-position: 50% 0%;
		}

		&.scale-x-3 {
			background-position: 100% 0%;
		}
	}

	.text-container {
		position: absolute;
		width: 100%;
		z-index: 10;
		color: #fff;

		h2 {
			margin: 15px 30px 5px;
			color: inherit;
		}
		h3 {
			margin: 15px 30px 5px;
			color: inherit;
		}

		&.textpos-vertical-top{
			top: 0;
		}

		&.textpos-vertical-middle{
			top: 50%;
			transform: translate(0, -50%);
		}

		&.textpos-vertical-bottom{
			bottom: 0;
		}

		&.textpos-horizontal-left{
			left: 0;
			text-align: left;
		}

		&.textpos-horizontal-center{
			text-align: center;
		}

		&.textpos-horizontal-right{
			right: 0;
			text-align: right;
		}

		&.textsize-s {
			h2 {
				font-size: 28px;
				line-height: 30px;
			}
			h3 {
				font-size: 16px;
				line-height: 18px;
			}
			@include respond-to(500px) {
				h2 {
					font-size: 18px;
					line-height: 18px;
				}
				h3 {
					font-size: 12px;
					line-height: 12px;
				}
			}
		}
		&.textsize-m {
			h2 {
				font-size: 36px;
				line-height: 38px;
			}
			h3 {
				font-size: 18px;
				line-height: 20px;
			}
			@include respond-to(500px) {
				h2 {
					font-size: 21px;
					line-height: 22px;
				}
				h3 {
					font-size: 12px;
					line-height: 13px;
				}
			}
		}
		&.textsize-l {
			h2 {
				font-size: 40px;
				line-height: 42px;
			}
			h3 {
				font-size: 20px;
				line-height: 22px;
			}
			@include respond-to(500px) {
				h2 {
					font-size: 25px;
					line-height: 26px;
				}
				h3 {
					font-size: 15px;
					line-height: 17px;
				}
			}
		}
		&.bgstyle-ls {
			background: rgba(255, 255, 255, 0.7);
		}
		&.bgstyle-ds {
			background: rgba(0, 0, 0, 0.3);
		}
		&.bgstyle-lg {
			@include text-shadow(0px 0px 30px rgba(255, 255, 255, 1), 0px 0px 25px rgba(255, 255, 255, 1), 0px 0px 20px rgba(255, 255, 255, 1), 0px 0px 15px rgba(255, 255, 255, 1), 0px 0px 10px rgba(255, 255, 255, 1), 0px 0px 5px rgba(255, 255, 255, 1));
			&.textpos-ul, &.textpos-uc, &.textpos-ur {
				padding-bottom: 50px;
				@include background(linear-gradient(top, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 100%));
			}
			&.textpos-ml, &.textpos-mc, &.textpos-mr {
			}
			&.textpos-ll, &.textpos-cl, &.textpos-lr {
				padding-top: 50px;
				@include background(linear-gradient(bottom, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 100%));
			}
		}
		&.bgstyle-dg {
			@include text-shadow(0px 0px 30px rgba(0, 0, 0, 1), 0px 0px 25px rgba(0, 0, 0, 1), 0px 0px 20px rgba(0, 0, 0, 1), 0px 0px 15px rgba(0, 0, 0, 1), 0px 0px 10px rgba(0, 0, 0, 1), 0px 0px 5px rgba(0, 0, 0, 1));
			&.textpos-ul, &.textpos-uc, &.textpos-ur {
				padding-bottom: 50px;
				@include background(linear-gradient(top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0) 100%));
			}
			&.textpos-ml, &.textpos-mc, &.textpos-mr {
			}
			&.textpos-ll, &.textpos-cl, &.textpos-lr {
				padding-top: 50px;
				@include background(linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0) 100%));
			}
		}
	}

    .omnibus {
        position: relative;
        color: inherit;
        font-size: inherit;
        margin: 0 30px 15px;
        right: 0;
        bottom: 0;
        line-height: inherit;
        filter: contrast(70%);
    }

    &.textsize-s {
        .omnibus-label {
            font-size: 20px;
            line-height: 20px;
        }
        @include respond-to(500px) {
            .omnibus-label {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
    &.textsize-m {
        .omnibus-label {
            font-size: 26px;
            line-height: 26px;
        }
        @include respond-to(500px) {
            .omnibus-label {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
    &.textsize-l {
        .omnibus-label {
            font-size: 28px;
            line-height: 28px;
        }
        @include respond-to(500px) {
            .omnibus-label {
                font-size: 18px;
                line-height: 18px;
            }
        }
    }
}
