

.flash {
	@include vendor-prefix(animation, flash 0.5s ease-out);
	@include vendor-prefix(animation-iteration-count, 1);
}

@include keyframes(flash) {
	0% { background-color:transparent;}
	50% { background-color:#fbf8b2;}
	100% {background-color:transparent;}
}
