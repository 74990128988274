/* cart page styling */

.cartActive {
    position: fixed;
    overflow: hidden;
    width: 100%;
}

.cart {
    .object-listing-box {
        .add-to-cart {
            display: none;
        }
        .controls {
            display: none;
        }

        #sort-results {
            display: none;
        }

        .actions-container {
            .add-to-comparison, .remove-from-cart {
                display: inherit;
            }
        }

        .elem-footer {
            ul {
                width: auto;
            }
        }
        .cart-options {
            margin: 0;
            padding: 0;
            float: left;
            @include respond-to($screen-xs-max) {
                float: none;
            }
            li {
                list-style: none;
                margin: 0;
                padding: 0;
                a {
                    .value {
                        text-decoration: underline;
                        color: inherit;
                    }

                }
            }
            .icon {
                display: inline-block;
                width: 16px;
                height: 15px;
                position: relative;
                background: $color1;
                top: 2px;
            }
            .cart-print {

            }
        }
        .section-footer {
            padding: 15px;
            .btn {
                float: right;
                @include respond-to($screen-xs-max) {
                    float: none;
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }

}

#containerFluidCart, .container-fluidCompare, .container-fluid-subscriptionsbox {
    width: auto !important;
    height: auto !important;
    position: fixed;
    margin: auto;
    z-index: 1301;
    max-width: 1200px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 80vh;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 0;

    @include respond-to($screen-xs-max) {
        max-height: initial;
    }

    section {
        margin: 0;
        height: 100%;

        .section {
            height: calc(100% - 64px);
        }

        .section-footer {
            height: 64px;

            .compare-options {
                padding: 5px 10px;
            }
        }
    }

    h2 {
        position: relative;
        padding-right: 0;
        float: left;
        line-height: 31px;
        @include respond-to($screen-xs-max) {
            float: none;
        }
    }

    .section-header {
        .list-options {
            margin: 2px 15px 0 0;
        }
    }

    .section-content {
        height: calc(100% - 46px);
        overflow-x: hidden;
        overflow-y: auto;
    }

    .close {
        float: right;
        position: relative;
        cursor: pointer;
        font-size: 16px;

        @include respond-to($screen-xs-max) {
            position: absolute;
            top: 12px;
            right: 15px;
        }
        i {
            font-size: 20px;
            position: relative;
            top: 1px;
        }
    }
}

#container-fluidCompare {
    .stars {
        i {
            font-size: 13px;
        }
    }
    .section-header {
        h2 {
            float: left;
        }
    }
}

.bgCart {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1300;
}

.cartPopup {
    max-width: 100%;
}

.send-offer-email {
    .form-element-btn {
        clear: both;
    }
    .preloader {
        display: none;
        left: 50%;
        position: absolute;
        top: 84px;
        z-index: 1;
        &.active {
            display: block;
        }
    }
    .hasCaptcha {
        .captchaField {
            label {
                display: none;
            }
            .codeCaptcha {
                height: auto;
                padding: 0;
                img {
                    padding: 0;
                    height: 32px;
                }
            }
            .inputCaptcha {
                padding: 0;
                width: auto;
                width: 143px;
                float: right;
            }
            margin-bottom: 15px;
            display: table;
            width: 100%;
        }
    }

}
