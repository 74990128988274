//flights - opacity for pending elements, popup overlay etc. (e.g.: header-pending, view-buttons-pending)
$pending-opacity: 0.35;
$input-err-col-transp: #fcf0f0;

$inputRadius: 5px;
$dropdownRadius: 5px;

// Extended grid system, definition for possible number of col per row.
// Usage: col-(xs, sm, md, lg)-x-num, e.g.: `col-xs-3-24` for 3/24 width of a row
$possible-extended-grid-columns-number: 7, 8, 9, 10, 20, 24;

//** QTips default titlebar height
$tooltip-default-title-height:        46px !default;

//** Panels default titlebar height
$widget-header-height:        46px !default;

// Searchbox

// Searchbox field default height
$searchbox-default-field-height:    48px;

// Fields default background color, TODO: WhyTF is it permanently white?
$fields-background-color:         #fff;


.br-block {
    @include border-radius("$block-br");
}
.br-input {
    @include border-radius("$input-br");

    .input-container {
        input {
            @include border-radius("$input-br");
        }
    }
}
.br-btn {
	@include border-radius("$btn-br");
}

.br-block, .br-input, .br-btn {
    & > .btn {
        @include border-radius(0);
    }
}
.br-block-top {
    @include border-top-radius("$block-br");
    &.section-header.with-accordion-for-phone {
        @include respond-to($screen-xs-max) {
            @include border-bottom-radius("$block-br");
            &.with-accordion-active {
                @include border-bottom-radius(0);
            }
        }
    }
}
.br-block-bottom {
    @include border-bottom-radius("$block-br");
}

.br-block {
    .section-header {
        @include border-top-radius("$block-br");

        &.with-accordion {
            @include border-bottom-radius("$block-br");

            &.with-accordion-active {
                @include border-bottom-radius(0);
            }
        }
    }

    .section-content {
        @include border-bottom-radius("$block-br");
    }
}