.mc-container {
    @include no-selection;
    position: relative;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    width: 100%;

    @media (max-width: $screen-sm-max) {
        padding: 10px;
    }

    @media (max-height: 519px) {
        padding: 10px;
    }
}

.mcal-content {
    .mc-header {
        text-align: center;
    }
}

.mc-header {
    padding: 8px 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    overflow: auto;
}

.mc-footer {
    padding: 10px;
    border-spacing: 10px 0px;
}

// To ensure every icon is aligned horizontaly and same width
.mc-icon {
    width: 32px;
    text-align: center;
}

.mc-input-container {
    position: relative;
    border: 1px solid;
    background: $fields-background-color;

    &:not(.in-camp) .with-age {
        padding-left: 45px;
    }

    .ch-number{
        width: 32px;
        height: 31px;
        background-color: $color1;
        color: $color12;
        position: absolute;
        left: 0px;
        top: 0px;
        line-height: 30px;
        text-align: center;
    }

    &:not(.in-camp).age-field {
        border-color: $color1;
        border: 0;
        outline: 1px solid $color1;
    }

    &.mc-inline {
        position: relative;
        float: left;
        padding: 0 5px;

        .mc-icon {
            right: 12px;
        }

        .mc-btn-clear.with-icon {
            right: 35px
        }
    }

    &.mc-focused {
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        border: 1px solid #5897fb;
    }

    .mc-icon {
        cursor: pointer;
    }
    .mc-btn-clear {
        display: none;
    }
    .mc-btn-clear-hidden {
        display: none !important;
    }

    &.error {
        border: 1px solid $color21;
        .mc-input {
            background: $input-err-col-transp;
        }
    }
    &.mc-selected:hover {
        .mc-icon.text-color-1 {
            display: none;
        }

    }

    &.mc-selected {
        .mc-btn-clear {
            display: block;
            font-size: 25px;
        }

        @include respond-to($screen-sm-max) {
            .mc-icon.text-color-1 {
                display: none;
            }
        }
    }
    &.mc-disabled {
        @include opacity(0.5);
    }
}
[data-field-type="date"], [data-field-type="htldate"] {
    .searchbox-element {
        .mc-input-container {
            &.mc-selected {
                .is-ignored {
                    display: none;
                }
            }
        }
    }
}

[data-field-type="dyndeparture"], [data-field-type="dyndestination"], .child-birthdate-input, .input-container {
    .mc-input-container {
        &.mc-selected {
            .is-ignored {
                display: none;
            }
        }
    }
}

.added-child-row{
    .searchbox-element {
        .mc-input-container {
            &.mc-selected {
                .is-ignored {
                    display: none;
                }
            }
        }
    }
}

.sb-err-msg {
    color: $color21;
    white-space: nowrap;
    margin: 1px 0;
}

.mc-icon.#{$fa-css-prefix} {
    position: absolute;
    font-size: 21px;
    line-height: 32px;
    top: calc(50% - 16px);
    right: 0;

    &.#{$fa-css-prefix}-angle-down {
        right: 0;
    }
}

.mc-icon.#{$fa-css-prefix} ~ .mc-input {
    padding-right: 32px;
}

.with-icon ~ .mc-input {
    padding-right: 54px;
}

.mc-disabled {
    pointer-events: none;
    cursor: default;
}

.mc-btn-clear {
    color: $color16;
    cursor: pointer;
    font-size: 24px;
    z-index: 1;

    &:hover {
        color: $color18;
    }
}

.is-ignored {
    pointer-events: none;
}

.mc-checkbox {
    padding: 0 5px;
    line-height: 1.4em;
    width: 1.5em;
}

.mc-input, .mc-text-input {
    border: none;
    outline: none;
    box-shadow: none;
    padding: $quarter-gutter;
    line-height: 18px;
    cursor: pointer;
    width: 100%;
    background: $fields-background-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sb-flight-num ~ .mc-input {
    margin-left: $half-gutter + $quarter-gutter;
}

ul.mc-input:after {
    content: unquote("\"#{ $fa-var-angle-down }\"");
    font-weight: var(--#{$fa-css-prefix}-style);
    font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
    position: absolute;
    top: calc(50% - 16px);
    right: 0;
    text-align: center;
    width: 32px;
    line-height: 32px;
    font-size: 21px;
    color: $color1;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}

// Solution for fields in 'field_departure.volt' and 'field_transport.volt'
// TODO: Separate logic for fields to prevent those kind of problems in select2 multiselect
.select2-container-multi {
    ul.mc-input:not(.select2-choices-empty):after {
        display: none;
    }
    .mc-btn-clear {
        display: block;
    }
}

.mc-btn {
    padding: 6px 12px;
    line-height: 1.42857;
    font-size: 14px;
    text-align: center;
    cursor: pointer;

    &.mc-btn-disabled {
        pointer-events: none;
        cursor: default;
    }
}

$mcal-btn-width: 38px;
$mcal-btn-height: 32px;

.mc-cell {
    min-width: $mcal-btn-width;
    height: $mcal-btn-height;
    padding: 0;
    white-space: nowrap;
    vertical-align: middle;
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0);
}

.mc-searchbar {
    margin-top: -$widget-header-height;
    padding: 10px;

    .input-container {
        width: auto;
        overflow: hidden;
        position: relative;
    }

    .btn-close {
        float: right;
        padding: 5px 0 5px 10px;
        cursor: pointer;
        vertical-align: middle;

        i {
            vertical-align: middle;
            font-size: 1.7em;
        }
    }
    @media (max-height: 519px), (max-width: $screen-sm-max) {
        margin-top: 0;
        border-radius: 0;
    }
    @media (min-height: 520px) and (min-width: $screen-md-min) {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        padding: 0;

        .sb-input {
            border: 1px solid #159ee5;

            &::-ms-clear {
                display: none;
            }
        }
    }
}

.mc-placeholder {
    margin: 0;
    line-height: 16px;
}

.mc-scroll-wrapper {
    position: absolute;
    top: 36px;
    left: 0;
    min-width: 100%;
    overflow: hidden;
    background-color: $fields-background-color;

    @media (max-height: 519px), (max-width: $screen-sm-max) {
        top: 54px;
        padding-bottom: 54px;
        height: 100%;

        .mc-content {
            height: 100%;
            box-shadow: none;
            max-height: none;
        }
    }
}

.mc-content {
    padding: 0;
    margin: 0;
    overflow-y: auto;
    height: auto;
    max-height: 270px;
    list-style: none;

    .mc-element {
        cursor: pointer;
        list-style: none;
        padding: 10px;
        border-bottom: 1px solid $color19;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
    }

    .mc-info {
        cursor: default;
        text-align: center;
        padding: 10px;
    }

    .mc-btn-more {
        padding: 10px;
        text-align: right;
        cursor: pointer;

        i {
            vertical-align: middle;
        }
    }

    .mc-selected {
        display: block;
        position: relative;
        .select2-result-label {
            padding-right: 30px;
        }
        &:after {
            content: unquote("\"#{ $fa-var-square-check }\"");
            font-weight: var(--#{$fa-css-prefix}-style);
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -9px;
            font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
            font-size: 1.3em;
        }
    }
}

#age-info {
    margin: 0;
    position: relative;
    line-height: 26px;
    top: 5px;
    z-index: -1;
}
