.destination-picker {
	height: 100%;

	.qtip-close {
		background: none !important;
		padding: 0 10px;
		font-size: inherit;
		margin-right: -200px;
		position: relative;
		display: inline-block;
		line-height: initial;

		.fa {
			position: relative;
			font-size: 24px;
		}
	}

	.dp-search {
		position: absolute;
		left: -1px;
		right: -1px;
		margin: auto;
		padding: 10px 0;
		z-index: 1;

		.input-container {
			position: relative;
			display: inline-block;
			width: 100%;
			padding-left: 10px;
			padding-right: 90px;

			input::-ms-clear {
				display: none;
			}
		}
	}

	.dp-btn-map {
		display: none;
		cursor: pointer;
	}


	.dp-scroll-pane {
		position: absolute;
		min-width: 100%;
		top: 0;
		left: 0;
		padding-top: 54px;
		padding-bottom: 50px;
		z-index: 0;

		&.dp-bottom {
			padding-bottom: 0;
		}
	}

	.dp-scroll-wrapper {
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		-webkit-overflow-scrolling: touch;
		max-height: 450px;
		background-color: $color3;
	}

	.dp-bottom-btns {
		position: absolute;
		width: 100%;
		background-color: $color4;
		bottom: 0;
		left: 0;
		margin-left: 0;
		padding: 10px 5px;
		text-align: center;
		z-index: 1;

		.btn {
			margin-left: 0;
			width: 49%;
		}

		.disabled {
			background-color: #E0DFE5;
		}
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin-bottom: 0;
		&.picker-country-list {
			background: #fff;
		}

		li {
			width: 100%;
		}
	}

	.dp-element-header, .dp-element-content {
		@include no-selection;
		line-height: 26px;

		&.popular {
			> .header-name {
				margin-left: 30px;
			}

			> .dp-checkbox {
				margin-left: 28px;
			}
		}
	}

	.header-name {
		display: inline-block;
		//overflow: hidden;
		//width: 55%;
		//text-overflow: ellipsis;
		white-space: nowrap;
	}

	&.dp-single .header-name{
		margin-left: -20px;
	}

	.dp-region {
		padding: 12px;
		border-top: none;
		border-bottom: 1px solid $color19;
	}

	.dp-element-header {
		position: relative;
		cursor: pointer;
		min-height: 0;
		height: 42px;
		padding: 8px 25px 8px 35px;
		white-space: nowrap;
		border-bottom: 1px solid $color19;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
	}

	.dp-element-content > .dp-element-header {
		padding-left: 63px;
		white-space: nowrap;
		//margin-left: 28px;

		.dp-checkbox {
			left: 28px;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}

	&.dp-single .dp-element-content > .dp-element-header{
		padding-left: 10px;
	}

	.dp-element-content {
		border-bottom: 1px solid $color19;
	}

	.clear-search {
		position: absolute;
		right: 0;
		top: 0;
		color: grey;
		margin-right: 15px;
		margin-top: 0;
		padding: 5px;
		padding-right: 80px;
		font-size: 25px;
		line-height: 25px;
		cursor: pointer;
	}

	@media (max-width: $screen-sm-max) {
		.dp-search, .dp-bottom-btns {
			border-radius: 0;
		}
	}

	@media (min-width: $screen-md-min) and (min-height: 520px) {
		.dp-search {
			display: inline-block;
			overflow: hidden;
			background-color: #fff;
			padding-top: 0;
			padding-bottom: 0;
			top: -1px;
			border: 1px solid rgb(21,158,229);
			//border: 1px solid $color1;

			.input-container {
				display: block;
				padding: 0 8px;
				overflow: hidden;
				background-color: #fff;
				width: auto;
				height: 100%;

				input {
					background-color: #fff;
					border: none;
					width: 100%;
					height: 100%;
					padding: 0;
					outline: none;
				}
			}
		}

		.dp-btn-map {
			display: block;
			padding: 7px;
			text-align: right;
			float: right;

			span {
				font-size: 16px;
				line-height: 18px;
				display: inline;
				vertical-align: top;
			}

			i {
				font-size: 26px;
				padding-left: 5px;
				display: inline;
			}
		}

		.clear-search {
			padding-right: 5px;
			margin-right: 0;
		}

		.dp-scroll-pane {
			padding-top: 0;
			//border: 1px solid rgb(21,158,229);
			box-shadow: 0 2px 8px rgba(50, 50, 50, 0.6);
			overflow: hidden;
			top: 33px;
		}

		.dp-bottom-btns {
			//border: 1px solid rgb(21,158,229);
			overflow: hidden;
			border-top: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			bottom: 0;
		}
	}
}

.mc-input-container {
	&.error{
		.dp-init-element, .mc-text-input {
			background: $input-err-col-transp !important;

			&:focus {
				border-color:#fff;
			}
		}
	}
}

.dp-init-element {
	position: relative;
	overflow: hidden;
	height: auto;
	background-color: #fff;
	cursor: pointer;
	white-space: nowrap;
	text-overflow: ellipsis;

	&:focus {
		outline: none;
	}

	&.focused {
		//border: 1px solid $color1;
		outline: none;
		box-shadow: 0 0 5px rgba(0, 0, 0, .3);
	}

	input {
		position: absolute;
		height: 0;
		width: 0;
		border: 0;
		padding: 0;
	}

	ul {
		width: 100%;
		margin: 0;
		padding: 0 35px 0 $quarter-gutter;

		li {
			list-style: none;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;

			&.placeholder {
				padding: 0 10px 0 0;
				position: relative;
				height: 28px;
				line-height: 28px;
				color: $color10;
			}
		}

		&:after {
			content: unquote("\"#{ $fa-var-angle-down }\"");
			font-weight: var(--#{$fa-css-prefix}-style);
			font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
			position: absolute;
			top: calc(50% - 16px);
			right: 0;
			text-align: center;
			width: 32px;
			line-height: 32px;
			font-size: 21px;
			color: $color1;
			cursor: pointer;
		}
	}
}

// Hide expand arrow if field is not empty
.mc-input-container > input[value]:not([value=""]) + .dp-init-element ul:after {
	display: none;
}

.dp-search-choice, .dp-grouped-choice {
	color: $color9;
	//outline: none;
	//padding: 3px 16px 3px 3px;
	//margin: 0 5px 4px 0;
	//position: relative;
	//line-height: 16px;
	//cursor: default;
	//border: 1px solid;
	//border-radius: 3px;
	//background-clip: padding-box;
	//white-space: nowrap;
	//max-width: 100%;
	//overflow: hidden;
	//text-overflow: ellipsis;
	//@include no-selection;


	a {
		//display: block;
		//position: absolute;
		//right: 0;
		//top: 0;
		//width: 30px;
		//height: 100%;

		//&:after {
		//	content: "\f00d";
		//	font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
		//	position: absolute;
		//	right: 3px;
		//	top: 50%;
		//	margin-top: -8px;
		//	font-size: 14px;
		//	color: $color15;
		//}
        //
        //&:hover:after {
			//color: $color18;
		//}
	}
}


.dp-chevron {
	line-height: inherit;
	font-size: 21px;
	font-weight: bold;
	right: 8px;
	position: absolute;
}

.dp-checkbox {
	font-size: 22px;
	position: absolute;
	padding: 10px;
	padding-top: 12px;
	left: 0;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

.dp-star {
	font-size: 22px;
	position: absolute;
	margin: 10px;
	margin-top: 15px;
	top: 0;
	left: 0;
}

.dp-selected-info {
	right: 28px;
	position: absolute;
}


.qtip-popup-dp {
	max-width: 100%;
	border: none;
	min-width: initial;
	@include respond-to($screen-sm-max) {
		background: $color100;
	}

	.qtip-titlebar {
		display: none;
	}

	.qtip-content {
		border: 0;
		height: 100%;
		width: 100%;
		position: relative;
		top: 0;
		padding-top: 0;
		z-index: -1;
		overflow: visible;
	}
}

@media (max-height:349px) {
	.qtip-popup-dp .qtip-content {
		overflow-y: hidden;
	}
}

.select2-container-disabled .btn-clear-selected:hover{
	color: $color16;
}

.btn-clear-selected {
	position: absolute;
	right: 2px;
	top: calc(50% - 14px);
	color: $color16;
	font-size: 25px;
	line-height: 25px;
	padding: 2px;
	cursor: pointer;

	&:hover {
		color: $color18;
	}

	&.with-icon {
		margin-right: 30px;
	}
}

.default-no-results {
	height:100px;
	overflow: hidden;
	.dp-bottom-btns {
		display: none;
	}
	.picker-country-list{
		li{
			padding: 10px;
			font-size: 1.3em;
			line-height: 1.2em;
		}
	}
}

