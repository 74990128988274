section.offer-trip-rest-box{

    .img-container{
        overflow: hidden;
        max-height: 200px;
        min-height: 100px;

        img {
            width: 100%;
            height: auto;
        }
    }
    .#{$fa-css-prefix}-circle-plus {
        position: absolute;
        font-size: 4em;
        top: 50%;
        left: 0;
        margin-top: -25px;
        transform: translateX(-50%);
        background: rgba(255, 255, 255, 0.6);
        padding: 5px;
        border-radius: 50%;
        color: $color1
    }

    .info{
        margin-bottom: -3px;

        h3,h4{
            margin: 10px 0 5px;
            overflow: hidden;
            font:{
                size: 18px;
                weight: 700;
            }
            white-space: nowrap;
            text-overflow: ellipsis;

            p{
                margin-bottom: 5px;
            }
        }

        i{
            margin-right: 5px;

            &.fa-star,
            &.fa-star-half-empty{
                margin-right: -3px;
            }

            &.fa-calendar{
                margin-left: 10px;
            }
        }
    }


    .border-top{
        border-top: 1px solid;
    }

    .section-content {
        padding: 15px 15px 12px ;
    }

    .omnibus {
        position: relative;
        width: 100%;
        text-align: right;
        font-size: 1em;
        padding: 10px 2px;
        .omnibus-loader:after {
            animation-name: ellipsis-l;
        }
    }

}
