.cookies-overlay {
    @include respond-to(($screen-md-min - 0.1)){
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
        background: #000000;
        opacity: $pending-opacity;
    }
}

.cookies-alert {
    position: fixed;
    z-index: 100000;
    @include box-shadow(0px -2px 15px 0px rgba(50, 50, 50, .5));
}

.cookies-alert.cookies-alert-old {
    width: 100%;
    bottom: 0;
    left: 0;

    .wrapper {
        margin: 0 auto;
    }
}

.cookies-alert:not(.cookies-alert-old) {

    @include respond-from($screen-md-min){
        width: 100%;
        bottom: 0;
        left: 0;
        position: fixed;
        z-index: 100000;
        @include box-shadow(0px -2px 15px 0px rgba(50, 50, 50, .5));
    }

    @include respond-to(($screen-md-min - 0.1)){
        width: calc(100% - 30px);
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
    }


    section {
        height: 100%;
        max-width: 1200px;
        position: relative;
        margin: 0 auto;

        .section-header {
            margin: 0;
        }

        .section-content {

            .text-content {
                height: auto;
                max-height: 300px;
                overflow-y: auto;
                padding-right: 10px;

                * {
                    color: inherit;
                }

            }

            @include respond-from($screen-md-min) {
                .cookies-close-wrapper {
                    display: none;
                }

                .text-content {
                    padding: 10px;
                    width: 85%;
                    height: auto;
                    max-height: 20vh;
                    font-size: 10pt !important;

                    * {
                        font-size: 10pt !important;
                    }

                }

                height: auto;
            }

            @include respond-to(($screen-md-min - 0.1)){
                height: calc(100% - #{$widget-header-height} - 55px);
                padding-bottom: 60px;

                .cookies-close-wrapper {
                    display: block;
                    height: 30px;
                    width: auto;
                    background-color: transparent;

                    .qtip-close {
                        height: 30px;
                    }
                }
            }
        }

        .cookies-consent-container {
            position: absolute;

            @include respond-to(($screen-md-min - 0.1)) {
                bottom: 0;
                width: 100%;

                > .btn {
                    width: 100%;
                }
            }

            @include respond-from($screen-md-min) {
                right: 1%;
                top: 33%;
                width: 15%;
                text-align: center;

                > .btn {
                    min-width: 81px;
                    width: auto;
                    max-width: 100%;
                }
            }
        }
    }
}
