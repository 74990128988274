.infobox-actions {
	.btn {
		text-transform: uppercase;
		margin: 0 auto;
	}
}

.hotel-infobox {
	width: 300px;
	@include box-shadow(0 0 3px rgba(0, 0, 0, 0.5));

	@include respond-to($screen-xs-max) {
		width: 220px;
	}

	.qtip-titlebar {
		height: 26px;
		@include border-radius(0);

		.qtip-close {
			padding: 0 5px;
			line-height: 26px;
		}

		.fa {
			font-size: 22px;
			top: 0;
		}
	}

	.infobox-content {
		padding: 10px 15px 15px;
		@include cf;
		position: relative;
	}

	h2 {
		font-size: 20px;
		line-height: 22px;
		margin: 0 0 3px;
		float: none !important;

		@include respond-to($screen-xs-max) {
			font-size: 16px;
			line-height: 28px;
		}
	}

	p {
		font-size: 12px;
		line-height: 20px;
		margin: 0 0 5px;
	}

	.media-container {
		overflow: hidden;
		margin: 0 0 10px;
		width: 100%;
		img {
			width: 100%;
			height: auto;
		}

		&.pull-left {
			width: 170px;
		}
	}

	.price-container {
		text-align: center;
		font-size: 12px;
		line-height: 16px;
		margin: 0 0 10px;

		strong {
			font-size: 16px;
		}
	}

	.infobox-actions {
		// position: absolute;
		// bottom: 20px;
		// right: 15px;
		// width: 185px;
	}

	.infobox-loading {
		padding: 15px;
		text-align: center;
		font-size: 18px;
		line-height: 18px;
	}
}

.location-infobox {
	width: 300px;
	@include box-shadow(0 0 3px rgba(0, 0, 0, 0.5));
	@include border-radius(4px);
	overflow: hidden;

	.qtip-titlebar {
		height: 26px;
		@include border-radius(0);

		.fa {
			font-size: 22px;
			top: 0;
		}
	}

	.infobox-content {
		padding: 10px 15px 15px;
	}

	p {
		margin: 0 0 15px;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
	}
}

.location-map-container {
	position: relative;

	.map-type {
		position: absolute;
		z-index: 3;
		top: 5px;
		right: 5px;
		display: block;
		width: auto;
	}

	#location-map {
		position: relative;
		z-index: 2;
		width: 100%;
		height: 700px;

		@include respond-to($screen-xs-max) {
			height: 400px;
			width: auto;
			margin: 15px;
		}
	}
}

.office-infobox {
	width: 300px;
	@include box-shadow(0 0 3px rgba(0, 0, 0, 0.5));

	@include respond-to($screen-xs-max) {
		width: 220px;
	}

	.qtip-titlebar {

		.fa {
			font-size: 22px;
		}
	}

	.infobox-content {
		padding: 10px 15px 15px;
		@include cf;
		position: relative;
	}

	h2 {
		font-size: 20px;
		line-height: 22px;
		margin: 0 0 15px;
		float: none !important;

		@include respond-to($screen-xs-max) {
			font-size: 16px;
			line-height: 28px;
		}
	}

	p {
		font-size: 12px;
		line-height: 20px;
		margin: 0 0 10px;
	}
}
