.facilities {
	list-style: none;
	padding: 0;
	margin: 0;
	&.inline li {
		display: inline-block;
		border: 1px solid;
		padding: 4px 6px;
		margin-bottom: 4px;
		width: 36px;
		height: 36px;
		text-align: center;
		i.mxicon {
			position: relative;
			font-size: 26px;
		}
	}
	li {
		position: relative;
		max-width: 180px;
		width: 36px;
		height: 36px;
		text-align: center;
	}
	i.mxicon {
		position: absolute;
		width: 26px;
		text-align: center;
	}
	.attr_description {
		margin-left: 30px;
		font-size: 13px;
		text-transform: capitalize;
		display: inline-block;
		padding: 6px 0;
	}
}

.compareFacilitiesRaiting{
	.facilities {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: start;
	}
}

.qtip-facilities .facilities li {
	width: auto;
	white-space: nowrap;
	text-align: unset;
}
