/*.layout-col.sub-col-aside > .row {
    > .col-xs-6{
        @include respond-from($screen-sm-min){
            width: 100%;
        }
    }
}*/

.layout-col.sub-col-aside{
    z-index: 1000;
}

.link-underline {
    text-decoration: underline;
}

.v-invisible-xs-sm {
    @include respond-to($screen-xs-max) {
        visibility: hidden;
    }
}

.v-visible-xs-sm {
    @include respond-to($screen-xs-max) {
        visibility: visible;
    }
}

// icon-filters mode
/*.sh-inner.v-invisibile-xs{

    &:before {
        display: none;

        @include respond-to($screen-xs-max) {
            display: inline-block !important;
            width: 46px;
            height: 46px;
            text-align: center;
            line-height: 46px;
            font-size: 40px;
            font-family: $icons-font-family;
            content: "\e610"
        }
    }
}
@include respond-to($screen-xs-max) {
    .ver-icons {
        &.filterOptions {
            position: absolute;
            right: 0;
            left: 0;
            z-index: 5;
        }
    }
}*/

.border-dotted {
    margin: 10px 0;
    border-top: 2px dotted $color19;
    border-radius: 0 !important;
}

.flightresult {
    .select-order-container {
        min-width: 220px;
        margin-left: $half-gutter;
    }

    .sort-results .select2-choice {
        height: 34px;
        padding: 0 10px;
    }
    .sort-results .select2-choice .select2-chosen,
    .sort-results .select2-choice:after {
        padding-top: 4px;
        line-height: 27px;
    }

    /*
        .select-order-dropdown{

    }

    /* bootestrap debugging styles */

    /*
    div.row {
        outline: 1px solid #c00 !important;
    }
    div[class*="col"] {
        outline: 1px solid #0c0 !important;
    }
    */

    .data-section-box {
        position: relative;
    }
    .data-section-box-overlay {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #ffffff;
        z-index: 5;
        @include opacity(0.5);
        @include respond-from($screen-sm-min) {
            bottom: 15px;
        }
    }
    .flightlist-pending-info {
        display: none;
        position: absolute;
        top: 180px;
        left: 0;
        width: 400px;
        padding: 40px 30px; //0 $margin-padding-lg;
        text-align: center;
        z-index: 15;
        @include respond-to($screen-xs-max) {
            position: fixed;
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            width: inherit !important;
        }

        .loading-results-pending,
        .loading-reservation-pending {
            margin: 0 auto;
            font-size: 18px;
        }
        .airplane-spinner {
            margin-top: 20px;
            padding: 0;
        }
    }
    &.flightlist-pending .data-section-box-overlay,
    &.flightlist-pending .flightlist-pending-info {
        display: block;
    }

    .table-bordered {
        border-color: $color19;

        & > thead > tr > th,
        & > tbody > tr > td {
            border-bottom-width: 1px;
            border-color: $color19;
        }
    }

    .section-header {

        &.filter-header,
        &.quickfinder-header {
            margin-top: 1px;
            cursor: pointer;
        }
        // icon-filters mode
        /*&.filter-header{
            @include respond-to($screen-xs-max){
                display: inline-block;
                margin: $quarter-gutter;
                width: 46px;
                padding: 0;
                @include border-radius("$block-br");

                > .button-icon{
                    height: 46px;
                    width: 46px;
                    padding: 2px 0px 0 7px;
                }
            }
        }*/
        &.top-offers-header,
        &.flight-grid-header {
            cursor: pointer;
        }
        &.header-pending {
            @include opacity($pending-opacity);

            .fa-spinner:before {
                font-size: 150%;
            }
        }
        &.section-header-has-seats-avail {

            .header-seats-avail {
                line-height: 20px;
            }
        }
        h2 {
            font-size: 18px;
        }
        .h2-xs {
            @include respond-to($screen-xs-max) {
                margin-top: -1px;
                line-height: 19px;

                &.one-line-xs {
                    margin-top: 5px;
                }
            }
            strong {
                font-size: 24px;
                font-weight: 500;
            }
        }

        .description {
            font-size: 14px;
            strong {
                font-size: 14px;
                font-weight: bold;
            }
        }
        .description-xs {
            @include respond-to($screen-xs-max) {
                display: block;
                font-size: 12px;
            }
        }

        .fare-type {
            line-height: 28px;
        }
        .fare-type-icon {
            margin-left: 5px;
            vertical-align: sub; //middle;
            font-size: 16px;
            line-height: 18px;
        }

        &-has-branded-fares {
            background: none;
            padding: 0;
            min-height: inherit;
        }
    }

    .btn-collapse {
        line-height: 21px;
        cursor: pointer;
    }
    .btn-collapse-section-content {
        text-align: right;

        i {
            display: inline-block;

            &:before {
                line-height: 20px;
            }
        }
    }
    .btn-collapse-price-details {
        width: auto;
        height: auto;

        .link {
            margin-right: $margin-padding-sm;
            color: $color16;
            text-decoration: underline;
        }

        i {
            display: inline-block;
            color: $color1;
        }
    }
    .section-price-details-overlay:hover + .row {
        .btn-collapse-price-details {
            > .link,
            > .fa {
                color: $color18;
            }
        }
    }
    .btn-collapse-details {
        position: relative;
        top: 2px;
        left: 0;
        text-align: center;
        height: 36px;
        width: 36px;

        &:hover {
            background-color: $color18;
        }

        .fa {
            line-height: 36px;
            vertical-align: middle;
        }
    }

    table {

        th {
            text-align: center;
        }
        .v-align-middle {
            vertical-align: middle;
        }
        .airline-logo {
            min-width: 64px;
            max-width: 100px;
        }

        &.table-compare {
            th {
                padding: 7px;
                vertical-align: middle;
                min-height: 30px;
                //text-align: right;
            }

            .th-caption {
                padding: 8px 15px;
                font-size: 12px;
                color: $color9;

                &.th-diagonal {
                    position: relative;
                    padding: 0;
                    background-color: $color8;
                }

                /*$diagonalcolor: '#C9C9CA';*/
                .diagonal-line-container {
                    padding: 8px 15px 2px;
                    /*background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg'><line x1='0' y1='0' x2='100%' y2='100%' stroke='" + $diagonalcolor + "' stroke-width='1'/></svg>") no-repeat;
                    width: 100%;
                    height: 100%;
                    */
                }
                .diagonal-svg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                .diagonal-line {
                    stroke: $color19;
                }
                .th-outbound {
                    text-align: right;
                }
                .th-return {
                    text-align: left;

                    .fa {
                        position: relative;
                        top: 3px;
                    }
                }
                .th-inner {
                    display: inline-block;
                    width: 50%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        &.hover-matrix { /* needs js to highlight row and col */
            th.highlighted,
            tr.highlighted td,
            td.highlighted {
                color: $color12;
                background: none;
                background-color: $color18;

                .desc {
                    color: $color12;
                    background: none;
                    background-color: $color18;
                }
            }

            td.cell-hover:hover {
                color: $color12;
                background: none;
                background-color: $color1;
                cursor: pointer;
            }
        }

        .desc {
            font-size: 11px;
            font-weight: bold;
        }
        .head-strong {
            font-size: 14px;
            font-weight: bold;
        }
        td {
            padding: 7px;
            text-align: center;
            &.align-left {
                text-align: left;
            }
            &.align-center {
                text-align: center;
            }
            &.align-right {
                text-align: right;
            }

            &.td-heading {
                padding-left: 15px;
                text-align: left;
            }

            &.cell-hover.compare-center {
                color: $color12 !important;
                background: none !important;
                background-color: $color1 !important;
            }
        }
    }

    #flight-list-header {
        padding: 0 0 15px 0;
        @include respond-to($screen-xs-max) {
            padding: 5px $half-gutter 15px;

            /* only for ungrouped xs-screen */
            & + div[data-section=fl] > .section-content {
                border-top: 1px solid $color19;
            }
        }
    }

    .y-flight-search {
        display: inline-block;
        margin-top: 5px;

        font: {
            size: 18px;
        }
    }

    .view-buttons {
        border: none;
        @include respond-to($screen-xs-max) {
            width: 100%;

            &.view-buttons-2 {
                .btn {
                    width: (100%/2);
                }
            }
            &.view-buttons-3 {
                .btn {
                    width: (100%/3);
                }
            }
        }

        &.view-buttons-pending {
            @include opacity($pending-opacity);
        }

        .btn {
            float: left;
            height: 36px;
            min-width: 95px;
            border: 1px solid $color1;
            color: $color1;
            background-color: $color12;
            cursor: pointer;

            &:first-of-type {
                @include border-left-radius("$btn-br");
                @include border-right-radius(0);
            }
            &:last-of-type {
                @include border-left-radius(0);
                @include border-right-radius("$btn-br");
            }

            &.btn-active {
                color: $color12;
                background-color: $color1;
                cursor: auto;
            }

            .fa {
                @include vendor-prefix(transform, translate3d(0, 0, 0));
            }
            &:not(.btn-active):hover .fa {
                animation: scale-150-proc .2s ease-out 1 normal;
            }
        }

        i {
            margin-right: 10px;
            position: relative;
            top: 1.5px;
            left: 0;
        }
    }

    .trp-xs-info {
        padding: 6px 0 10px;
        line-height: 24px;
    }

    .active-filter-bar {
        padding-top: 11px;
        padding-bottom: 1px;
        border: 1px solid $color19;
        border-left: none;
        border-right: none;
        font-size: 16px;

        .label-filters {
            display: inline-block;
            margin-right: 9px;
            color: $color1;
        }

        .label-filter {
            display: inline-block;
            height: 28px;
            padding: 6px 9px 5px 8px;
            margin: 0 10px 10px 0;
            font-size: 14px;
            background-color: $color1;
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: $color21;
            }
        }

        .filter-close {
            position: relative;
            display: inline-block;
            margin-left: 12px;

            .filter-label-divider {
                position: absolute;
                left: -9px;
                top: -6px;
                height: 28px;
                width: 1px;
                background-color: $color4;
            }

            .fa-close {
                position: relative;
                top: 0.5px;
                left: 0;
            }
        }

        .clear-filters {
            display: inline-block;
            position: relative;
            padding: 3px;
            text-decoration: underline;
            cursor: pointer;

            .clear-filter-close {
                display: inline-block;
                position: relative;
                top: 1px;
                left: 5px;
                color: $color1;
            }
        }
    }

    div[data-section=fl]:nth-of-type(even) .section-content {
        @include respond-to($screen-xs-max) {
            background-image: none;
            background-color: $color4;
        }
    }
    div[data-section=fl]:nth-of-type(odd) .section-content {
        @include respond-to($screen-xs-max) {
            background-image: none;
            background-color: $color3;
        }
    }

    .f-list-elem {
        padding: 15px $half-gutter;
        margin: 0 (-$half-gutter);
        border-bottom: 1px solid $color19;

        &.active {
            background: none;
            background-color: $color4;
        }

        .radio-stack {
            float: right;
            top: 6px;
            right: -2px;
        }

        .pos-rel {
            position: relative;

            .radio-stack {
                position: absolute;
                top: 50%;
                margin-top: -13px;
                width: 0;
                padding-right: 10px;
            }
        }

        &__data {
            padding-left: 74px;
        }

        &.elem-wrapped-xs {
            @include respond-to($screen-xs-max) {
                padding-bottom: 0;
                border-bottom: none;
                background: none;
                background-color: transparent;
                //background-color: $color3 $color4;
            }
        }
    }

    %elemlist-nonflightelem {
        margin-left: -$half-gutter;
        margin-right: -$half-gutter;
        padding: 9px $half-gutter 5px;
        border-bottom: 1px solid $color19;

        .heading-text {
            font-size: 18px;
            @include respond-to($screen-xs-max) {
                margin-top: -3px;
                font-size: 14px;
            }
        }
    }

    .flight-group-indicator {
        @extend %elemlist-nonflightelem;

        .fa {
            @include respond-from($screen-sm-min) {
                margin-left: 4px;
            }
        }

        .fare-type {
            padding-top: 4px;
            @include respond-to($screen-xs-max) {
                padding-top: 0;
                font-size: 12px;
            }
        }
        .fare-type-icon {
            display: inline-block;
            margin-left: 2px;
            vertical-align: sub; //middle;
            font-size: 20px;
            line-height: 20px;
            color: $color1;
            @include respond-to($screen-xs-max) {
                font-size: 16px;
                line-height: 16px;
            }
        }

        .two-lines-xs {
            @include respond-to($screen-xs-max) {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }

    .section-price-details {
        @extend %elemlist-nonflightelem;
        position: relative;
        padding-top: 8px;
        padding-bottom: 7px;

        @include respond-to($screen-xs-max) {
            .heading-text {
                margin-top: 0;
            }

            .btn-collapse-price-details i {
                margin: 0 auto;
            }
        }
    }
    .section-price-details-overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 40px;
        z-index: 1;
        cursor: pointer;
    }

    .price-details {
        margin-top: 5px;
        margin-bottom: 3px;
        text-align: right;
        color: $color1;

        @include respond-to($screen-xs-max) {
            text-align: left;
        }

        .price-details-separator {
            border-top: 1px solid $color15;

            &.separator-dotted {
                border-top-style: dotted;
            }
        }

        .header-txt {
            width: 50%;
        }

        .float-right-xs {
            @include respond-to($screen-xs-max) {
                float: right;
            }
        }
        .p-d-total-price {
            margin-right: $margin-padding-xs;
            /* retarded max-width control for Ania */
            @include respond-to($screen-xs-max) {
                float: left;
                max-width: 300px;
                text-align: left;
            }
            @include respond-to(419px) {
                max-width: 280px;
            }
            @include respond-to(399px) {
                max-width: 230px;
            }
            @include respond-to(349px) {
                max-width: 200px;
            }
        }
    }

    .flight-schedule {
        .hour {
            font: {
                size: 24px;
                weight: 700;
            }
            line-height: 20px;
            color: $color1;

            @include respond-to($screen-xs-max) {
                font-size: 14px;
                line-height: normal;
            }
        }
        .dash-separator {
            //font-size: 20px;
        }
        .next-day {
            font: {
                size: 75%;
                weight: 700;
            }
            vertical-align: 63%;
            color: $color21;
        }
        .next-day-xs {
            @include respond-to($screen-xs-max) {
                vertical-align: 20%;
            }
        }
        .date {
            font-size: 14px;
            color: $color9;
            white-space: nowrap;
        }
        .airport {
            color: $color10;

            .airport-city {
                color: $color9;
            }
        }
        .code-xs {
            @include respond-to($screen-xs-max) {
                font-size: 14px;
            }
        }
    }

    .flight-insights {
        @include respond-to($screen-xs-max) {
            color: $color10;
        }

        .duration {
            display: inline-block;
            padding-top: 4px;
            color: $color9;
            width: 100%;

            @include respond-to($screen-xs-max) {
                padding-top: 0;
                color: $color10;
            }

            .duration-label {
                color: $color10;
            }
        }

        .stop-over-night, .airport-change {
            display: inline-block;
            padding-left: 6px;
            color: $color21;
            cursor: pointer;
        }

        .stops {
            display: inline-block;
            color: $color9;
            width: 100%;

            &.is-airport-change {
                padding-top: 3px;
            }

            i {
                position: relative;
                top: 1px;
                font-size: 15px;
            }

            @include respond-to($screen-xs-max) {
                color: $color10;

                &:after {
                    content: " |";
                }
            }
        }
        .airline {
            display: inline-block;
            padding-top: 4px;
            color: $color9;

            @include respond-to($screen-xs-max) {
                padding-top: 0;
                color: $color10;
            }
        }
        .mark-top-airline {
            color: $color21;
            text-transform: uppercase;
        }
        .flight-number {
            color: $color10;
        }
        .airline-logo-container {
            margin: 0 auto;
            width: 85px;
            height: 43px;
            text-align: center;
            white-space: nowrap; /* prevent white spaces from pushing inline elements */
            @include respond-to($screen-xs-max) {
                display: inline-block;
                height: 20px;
                width: auto;
            }
            @include respond-from($screen-lg-min) {
                width: 110px;
            }

            .v-a-helper {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }
            .airline-logo {
                max-width: 85px;
                max-height: 43px;
                border: 1px solid transparent;

                @include respond-to($screen-xs-max) {
                    max-height: 20px;
                    max-width: none;
                }

                &.top-airline {
                    border-color: $color1;
                }

                &.booking-airline-logo {
                    max-width: 100px;
                }
            }
        }
        .luggage {
            display: inline-block;
            padding-top: 5px;
            margin-left: -$quarter-gutter;
            color: $color1;
            cursor: pointer;

            .fa-bezbagazu {
                @include opacity(0.5);
            }

            @include respond-to($screen-xs-max) {
                padding-top: 0;
                margin-left: 0;
                color: $color10;
                cursor: auto;

                &:after {
                    content: " |";
                }

                .fa-bagaz, .fa-bezbagazu {
                    @include opacity(1.0);

                    &:before {
                        display: none;
                    }
                }
            }
        }

        .over-night-info-xs, .airport-change-info-xs {
            @include respond-to($screen-xs-max) {
                margin-top: 3px;
                color: $color21;

                i {
                    width: 15px;
                }
            }
        }

        .airport-change-info-xs {
            @include respond-to($screen-xs-max) {
                margin-top: 3px;
                i {
                    font-size: 15px;
                }
            }
        }
    }

    .flight-details {

        .border-dotted {
            margin: 10px 0;
            border-top: 2px dotted $color19;
            border-radius: 0 !important;
        }

        .dur-pos {
            float: right;
        }

        .icon-pos {
            position: absolute;
            left: 7.5px;
            top: 0;

            .fa:before {
                margin-left: 1.5px;
                margin-right: 10px;
            }
        }
        .icon-pos-offset {
            margin-left: 25px;
        }

        .over-night-info, .airport-change-info {
            display: inline-block;
            color: $color21;

            i {
                width: 15px;
            }
        }

        .airport-change-info {
            padding-top: 3px;

            i {
                font-size: 15px;
            }
        }
    }

    .mark-alternative-airport-off {
    }
    .mark-alternative-airport-on {
        color: $color21 !important;
    }

    .flight-elems-footer {
        min-height: 65px;
        padding: 15px $half-gutter;
        margin: 0 (-$half-gutter) 15px;

        &.footer-wrapped-xs {
            @include respond-to($screen-xs-max) {
                margin-bottom: 0;
                border-bottom: 1px solid $color19;
                background: none;
                background-color: transparent;
            }
        }

        .link {
            display: inline-block;
            padding-top: 11px;
            text-decoration: underline;
            color: $color16;
            cursor: pointer;
            @include respond-to(419px) {
                padding-top: 0;
                margin-bottom: 15px;
            }

            &:not(:last-child) {
                margin-right: $margin-padding-md;
            }

            &:hover {
                color: $color18;

                i:before {
                    color: $color18;
                }
            }

            i {
                position: relative;
                top: 2px;
                left: 0;
                margin-right: 5px;
            }
        }

        .btn-book {
            font-size: 18px;
            padding: 5px $margin-padding-sm 6px;
        }

        &__change-ticket-btns {
            display: flex;

            .btn-check-price {
                min-width: 0;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .btn-select-flight {
                margin-top:0;
                margin-left: 15px;
                max-width: 150px;
            }
        }
    }

    .btn-load-more {
        display: block;
        height: 36px;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-top: 9px;
        padding-bottom: 5px;
        color: $color12;
        background-color: $color7;

        &:hover {
            background-color: $color18;
        }
    }
}

#section-flight-filters {

    .filter-elem {
        position: relative;
        top: 0;
        left: 0;
        padding: 10px $half-gutter;
        border-bottom: 1px solid $color19;
        background-color: $color4;
        color: $color1;

        &.check-all {
            background-color: $color8;
            font-weight: 700;
            text-transform: uppercase;

            .cur-pri-wrapper {
                display: none;
            }
        }

        &:last-child {
            border: none;
        }

        .chbx-elem {
            display: table;
            table-layout: fixed;
            width: 100%;
        }
        .chbx-wrapper {
            display: table-cell;
            width: 30px;
        }
        .filter-name {
            display: table-cell;
            vertical-align: middle;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .cur-pri-wrapper {
            display: table-cell;
            width: 75px;
            vertical-align: middle;
            text-align: right;
        }
        .price, .currency {
        }
        .price {
            font-weight: 700;
        }

        &.filter-elem-checkbox {

            &:hover {
                background-color: $color18;
                color: $color12;
            }
        }

        &.filter-elem-slider {
            position: relative;

            .slider-row-normal.hide + .slider-row-slider {
                margin-top: 3px;
            }

            &.grouped-first {
                padding-bottom: 0;
                border-bottom: none;
            }
            &.grouped-second {
                padding-top: 0;
                padding-bottom: 13px;
            }

            //slider styling background instead of icon - may be an issue
            @include slider-filter;
            .irs-line {
                top: 5px;
            }
            .irs-slider {
                top: 0;
            }
            .irs-bar {
                top: 5px;
            }
            .irs-bar-edge {
                top: 5px;
            }
            //

            .heading {
                float: left;
                font-weight: 700;
                text-transform: uppercase;

                + .heading {
                    float: right;
                    text-transform: none;
                }

                .units {
                    font-weight: 500;
                }
            }

            .slider-label {
                margin: 6px 0 7px;
                text-transform: capitalize;
            }

            .slider {
                position: relative;
                height: 20px;
                margin: 10px 25px 0 -1px; // left & right -1px for better look

                %handle {
                    position: absolute;
                    top: -7px;
                    left: 0;
                    display: inline-block;
                    font-size: 26px;
                    line-height: 26px;
                    color: $color7;
                    cursor: pointer;
                    z-index: 1;

                    &.hover {
                        color: $color18;
                    }
                }
                .handle1, .handle2 {
                    @extend %handle;
                }
                .handle2 {
                    left: 83%;

                    &:before {
                        position: relative;
                        left: 1px;
                        top: 0;
                    }
                }

                .track {
                    position: relative;
                    height: 12px;
                    margin-left: 1px;
                    margin-right: -24px; // - (handle width - 2px for better look)
                    border: 1px solid $color19;
                    border-radius: 5px;
                    background-color: #fff;

                    &.active {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 17%;
                        background-color: $color8;
                    }
                }
            }
        }
    }

    .filters-xs-sm-header-buttons {
        cursor: pointer;
    }
    .filters-xs-sm-content-buttons {
        padding: $half-gutter;

        .button-list {
            margin: 0;
            padding: 0;
            list-style: none;

            .button-list-elem {
                margin-top: $half-gutter;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .btn {
            font-size: 18px;
            padding: 5px 15px 6px;
            text-align: left;
        }
    }

    .filters-h2-label {
        display: none;
        font-size: 18px;
        color: $color1;
    }

    .sub-col-aside & .js-filterContainer:first-of-type .filter-header {
        @include border-top-radius("$block-br");
    }

    .sub-col-main & {
        @include respond-from($screen-md-min) {
            //border-radius: 0;

            .filters-h2-label {
                display: block;
                margin-top: 0;
                margin-bottom: 2.5px;
            }

            & .js-filterContainer .filter-header {
                @include border-radius("$block-br");

                &.br-b-none {
                    @include border-bottom-radius(0);
                }
            }

            .filter-header {
                min-width: 218px;
                border: 1px solid $color1;
                background-color: $color12; // will not always work?
                color: $color1;

                /*&:first-of-type {
                    border-radius: 0;
                }*/
            }
        }

        .filterOptions {
            @include respond-from($screen-md-min) {
                position: absolute;
            }
            min-width: 218px;
            z-index: 5;

            .filter-elem:first-child {
                border-top: 1px solid $color19;
            }
        }
    }

    .filter-xs-sm-modal {
        position: fixed;
        top: -1px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color5;
        z-index: 100;

        .filter-xs-sm-modal-section-content {
            @include respond-to($screen-sm-max) {
                padding: 0;
            }
        }
        .section-bottom {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding: $margin-padding-md $half-gutter;
            background-color: $color5;
            z-index: 5;

            .btn {
                border: 1px solid $color7;
                // ugly button texts
                //text-transform: uppercase;

                &:hover {
                    border-color: $color18;
                }
            }
        }

        .filterOptions {
            position: fixed;
            top: $widget-header-height;
            right: 0;
            left: 0;
            bottom: 65px;
            overflow-y: auto;
        }
    }
}

.disabled-filters {
    .chbx-elem, .select2-container, .filter-elem-slider .irs {
        @include opacity($pending-opacity);
        pointer-events: none;
        cursor: default;
    }
}

.btn:focus,
.btn:active,
.btn:active:focus {
    -webkit-appearance: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-sort {
    border: 1px solid $color1;
    color: $color1;
}

.btn-search {
    font-size: 18px;
}

.col-xxs-12 {
    @include respond-to(419px) {
        width: 100%;
    }
}

.checkbox-overlay {
    display: block;
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: 0;
    right: 0;
    margin-bottom: 0;
    cursor: pointer;
    z-index: 1;
}

#section-progress-bar.flightresult {
    margin: 1px 0 $margin-padding-md;

    .progress {
        margin-bottom: 0;
        height: 6px;
        background-color: $color8;
    }
}

#section-progress-bar.flightresult {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    margin: 0;
    // admin black top strip has z-index = 15000
    z-index: 16000;

    .progress {
        margin-bottom: 0;
        height: 5px;
        background-color: transparent;
    }
}

/*
.filters-collapsed{
    width: 200px;

    .fa-attr_handicaped.visible-xs-block +.sh-inner{
        width: 150px;
    }
}*/

.waiting-box {
    max-width: 714px;
    margin: $margin-padding-lg auto 40px; //top margin like main
    padding: 40px;
    text-align: center;
    background-color: $color1;
    color: $color12;

    &.in-flight-list {
        max-width: none;
        margin-top: 0;
    }

    .awaiting-response,
    .no-results {
        .route {
            margin-top: 0;
            font-size: 24px;
        }
        .route-date {
            margin-top: 0;
            font-size: 14px;
        }
        .legible {
            font-size: 18px;
        }

        .no-results-replacer { // html from mdsws
            font-size: 18px;
            p {
                margin: 5px auto 0;

                &.no-results-text {
                    margin: 20px 0;
                    text-transform: uppercase;
                }

                &.legible {
                    margin-bottom: 0;

                    &:last-of-type {
                        margin-bottom: 20px;
                    }
                }

                &.no-results-suggestions {
                    font-size: 14px;
                    margin-bottom: 0;
                }
            }
        }

        .btn-search-again {
            margin: 20px 0;
            padding: 8px 15px;
            border: 3px solid $color12;
            font-size: 18px;
            background-color: $color7;
            color: $color12;

            &:hover {
                background-color: $color18;
            }
        }
        .btn-clear-all-filters {
            @extend .btn-search-again;
            margin-bottom: 0;
        }
    }
}

.airplane-spinner {
    padding: 10px;
    font-size: 18px;

    .fa {
        margin: 0 $quarter-gutter;
        @include opacity(0.4);

        &.semi {
            @include opacity(0.7);
        }
        &.bright {
            @include opacity(1);
        }
    }
}

.bottom-wrapper {
    margin: 0 auto 40px;
    text-align: center;

    .loader-img {
        max-width: 100%;
        height: auto;
    }
}

.flight-infomessage {
    &.qtip-terms, &.qtip-select-seats, &.qtip-fare-info, &.qtip-passengers-data {
        @media (min-width: $screen-sm) {
            width: $screen-sm;
        }

        max-width: 0.9 * 1200px;

        .segments-list {
            overflow: auto;
            overflow-x: hidden;
        }

        .car-address {
            text-align: left;

            .segment-car-address {
                display: flex;
            }

            &-left {
                width: 40%;
                padding: 15px;
            }

            &-data {
                margin-bottom: 15px;

                &__title {
                    margin-bottom: 10px;
                    font-weight: bold;
                }
            }

            &-map {
                min-height: 500px;
                width: 60%;
            }
        }

        .terms-before-response, .fare-info__content--waiting, .passengers-data--waiting {
            margin: 0 auto;
            padding: $half-gutter;
            text-align: center;

            .msg {
                margin-bottom: $half-gutter;
                font-size: 1.3em;
                line-height: 2em;
            }

            .btn-retry {
                min-width: 200px;
                padding: 7px 15px;
                font-size: 18px;
            }
        }

        .no-seats-response {
            margin: 0 auto;
            padding: $half-gutter;
            text-align: center;

            .msg {
                font-size: 1.3em;
                line-height: 2em;
            }

            .btn-retry {
                min-width: 200px;
                padding: 7px 15px;
                font-size: 18px;
            }
        }
    }

    & + #qtip-overlay div {
        @extend %qtip-overlay-child;
    }

    &.reserve-waiting-window + #qtip-overlay div {
        @extend %qtip-overlay-child;
        cursor: pointer;
    }

    .qtip-content {
        padding: 0;
    }

    .flight-map {
        display: block;
        height: 100%;

        @media (min-width: $screen-md) {
            padding-right: 0;
        }

        .gm-style {
            left: -20px !important;
        }
    }

    .flight-map-right {
        height: 100%;

        @media (min-width: $screen-md) {
            padding-left: 0;
        }
    }

    .codes-bar {
        padding: $margin-padding-xs $quarter-gutter;
        background-color: $color8;
        text-align: left;
    }

    .btn-codes {
        margin: $margin-padding-xs $quarter-gutter;
        border: 1px solid $color1;
        background-color: $color12;
        font-weight: 700;
        color: $color1;
        text-transform: uppercase;

        &:hover,
        &.btn-active {
            color: $color12;
            background-color: $color1;
        }

    }

    .modal-section-header {
        border-top: 1px solid $color19;
        background-color: $color4;
        color: $color1;

        h2 {
            margin-top: 10px;
            padding-left: $half-gutter;
            font-size: 14px;
            text-align: left;
            text-transform: uppercase;
        }
    }

    .modal-section-content {
        width: 100%;
        padding: 10px $half-gutter;
        line-height: 130%;
        text-align: left;
        background-color: $color5;
    }

    .flight-group-indicator {
        padding: 11px $half-gutter;
        border-bottom: 1px solid $color19;
        font-size: 18px;
        text-align: left;

        .fa {
            margin-right: $half-gutter;
            font-size: 28px;
            vertical-align: -5px;
        }

        .heading-text {
            display: inline-block;
        }
    }

    .f-elem {
        display: table;
        width: 100%;
        padding: 11px $half-gutter;
        border-bottom: 1px solid $color19;
        text-align: left;

        .letter-naming {
            display: table-cell;
            width: 28px + $half-gutter;
            padding-right: $half-gutter;
            font-weight: 700;
            text-transform: uppercase;
        }

        .airport {
            display: table-cell;
            color: $color10;
            line-height: 1.3;
        }

        .airport-city {
            color: $color9;
        }
    }
}

// override non.flightresult .layout-col
.layout-col:first-child {
    padding-left: $half-gutter;
}

.layout-col:last-child {
    padding-right: $half-gutter;
}

//.main-col,
//.main-col .layout-col:not(.sub-col-aside),
.sub-col-main {
    #section-flight-filters {
        overflow: visible;
        @include respond-from($screen-md-min) {
            margin-bottom: 7.5px;
        }
    }

    .filters .js-filterContainer {
        margin-top: 7.5px;
        margin-bottom: 7.5px;

        .filter-header {
            display: block;
        }
        .filterOptions {
            top: $widget-header-height;
            left: $half-gutter;
            right: $half-gutter;
            border: 1px solid $color1;
            border-top: none;
        }
    }
}

.sub-col-aside {
    .search-box {
        .col-md-6.searchbox-element {
            @include respond-from($screen-sm-min) {
                width: 100%;
            }
        }
        .col-md-6.searchbox-group {
            .col-xs-6.searchbox-element {
                @include respond-from($screen-sm-min) {
                    width: 50%;
                }
            }
            @include respond-from($screen-sm-min) {
                width: 100%;
            }
        }
    }
}

.overflow-hidden-xs-sm {
    @include respond-to($screen-sm-max) {
        overflow: hidden;
    }
}

.tooltip-inner-bg-color-2 {
    background-color: $color2;
}

.tooltip-inner-text-left {
    text-align: left;
}

.tooltip-inner-padding-8 {
    padding: 8px;
}

.tooltip {
    &.top .tooltip-arrow.tooltip-arrow-br-color-2 {
        border-top-color: $color2;
    }
    &.right .tooltip-arrow.tooltip-arrow-br-color-2 {
        border-right-color: $color2;
    }
    &.left .tooltip-arrow.tooltip-arrow-br-color-2 {
        border-left-color: $color2;
    }
    &.bottom .tooltip-arrow.tooltip-arrow-br-color-2 {
        border-bottom-color: $color2;
    }
}

.no-pointer-events {
    pointer-events: none;
}

.has-reserve-overlay {
    position: relative;
    overflow: hidden;

    &.preview-mode {
        height: auto;
    }
}

.reserve-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    @include opacity($pending-opacity);
    z-index: 1000;
}

.simple-window,
.timeout-window,
.invalid-data-window {
    max-width: none;
    width: 400px;
    @include respond-to(420px) {
        width: 320px;
    }
    @include border-radius("$block-br");

    &.tw-bg {
        background: $color100;

        .qtip-content {
            background: $color3;
        }
    }

    .qtip-titlebar {
        @include border-top-radius("$block-br");
    }

    .qtip-content {
        padding: $margin-padding-md;
        @include border-bottom-radius("$block-br");
    }
    .timeout-text,
    .flightinfomessage-text {
        font-size: 16px;
        line-height: 21px;
        text-align: center;
    }
    .timeout-btn-refresh-container,
    .flightinfomessage-btn-container {
        margin-top: $margin-padding-md;

        .timeout-btn-refresh,
        .flightinfomessage-btn {
            font-size: 18px;
        }
    }
}

.ticketchange-price-window {
    width: 100%;
    max-width: 760px;

    .qtip-content {
        padding: 0;

        .pdetails-table {
            border-collapse: collapse !important;
            border-spacing: 0 !important;

            td {
                padding: 12px 15px;
                border-bottom: 1px solid #cdcdcd;
            }

            thead {
                color: #8d8d8d;
                background: #fff;
            }

            .show-price-details {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                white-space: nowrap;
                text-decoration: none;

                span {
                    text-decoration: underline;
                    margin-right: 5px;
                }

                i {
                    position: relative;
                    bottom: -1px;
                }
            }

            &__taxes {
                background: rgba(201, 233, 255, 1);

                td {
                    border-bottom: 1px solid #a1cae6;
                }

                &__desc {
                    background: #d7efff;
                }
            }

            tfoot {
                td {
                    background: #e2e2e2;
                }
            }
        }
    }

    .btn-container {
        padding: 15px;
        margin-top: 0;
        text-align: right;
    }
}

.invalid-data-window {
    .qtip-titlebar {
        background: $color21;
    }
}

.reserve-waiting-window {
    @extend .timeout-window;

    &.tw-bg {
        background: $color100;

        .qtip-content {
            padding: 40px 30px;
            text-align: center;
            @include border-radius("$block-br");
            background: $color1;
            color: $color12;
        }
    }

    .reserve-wait-text {
        margin: 0 auto 20px;
        font-size: 18px;
        line-height: 25px;
    }
}

.section-cheapest-alternative {
    position: relative;
    @include respond-to($screen-xs-max) {
        margin-top: $margin-padding-md;
    }

    .cheapest-alternative-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: 1;

        &:hover + .section-header {
            background: $color18;
        }
    }

    .h2-small-m-t {
        margin-top: 3px;
    }

    .alternative-price {
        font-size: 24px;
    }

    .btn-alternative-go {
        position: relative;
        top: 0;
        left: 0;
        height: 36px;
        width: 36px;
        margin-right: 6px;
        padding-top: 3px;
        text-align: center;
        cursor: pointer;

        > .fa {
            font-size: 1.85em;
        }
    }
    .cheapest-alternative-overlay:hover {
        & + .section-header .btn-alternative-go .fa-chevron-circle-right {
            animation: anim-move-r .8s ease-out 0s infinite normal;
        }
    }
}

.promote-seats-avail {
    position: absolute;
    top: 7px;
    right: 0;
    left: 0;
    padding: 0 $margin-padding-md;
    text-align: right;
    color: $color21;
    &.promote-seats-avail-hidden {
        @include respond-to($screen-xs-max) {
            display: none !important;
        }
    }
}

.has-promote-seats-avail {
    @include respond-to($screen-xs-max) {
        padding-top: 15px !important;
    }
}

#section-flight-list {
    .btn-collapse-details {
        position: absolute;
        top: 15px;
        left: 10px;
    }
}

.flight-extra-desc {
    margin-left: -15px;
    margin-right: -15px;

    &__item {
        display: flex;
        padding: 10px 15px;
        align-items: center;
        background-color: $color3;
        border-bottom: 1px solid $color19;

        @include respond-to(767) {
            display: block;
            padding: 15px;
            text-align: center;
        }
    }

    &__text {
        p {
            margin: 0;
        }
    }

    &__banner {
        max-width: 300px;
        width: 100%;
        flex-grow: 1;
        padding-left: 15px;

        img {
            max-width: 100%;
            max-height: 90px;
        }

        @include respond-to(767) {
            padding: 0;
            max-width: 100%;
            margin-top: 10px;
        }
    }

    &__item--only-banner {
        .flight-extra-desc__banner {
            max-width: 100%;
            padding: 0;

            @include respond-to(767) {
                margin: 0px;
            }
        }
    }

    @include respond-to(767) {
        [data-section="fl"] & {
            margin-top: 15px;
            border-top: 1px solid $color19;
        }
    }
}

.offer-attributes {
    display: flex;
    flex-wrap: wrap;

    &__item {
        display: inline-flex;
        align-items: center;

        &:not(:only-child):not(:last-child) {
            &:after {
                content: "/";
                margin: 0 5px;
            }
        }

        &__label {
            padding-right: 5px;
        }

        &__value {
            font-weight: bold;
        }
    }

    @include respond-to($screen-xs-max) {
        margin: 5px 0;
        padding: 0 30px;
    }
}

.fare-types {
    line-height: initial;

    &__fare {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 8.5px 15px;
        min-height: 46px;

        &__data {
            display: flex;
            flex-grow: 1;
            align-items: center;
            flex-wrap: wrap;
        }

        &__out,
        &__ret {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &__desc {
                .fare-types__fare--common-desc & {
                    display: none;
                }
            }
        }

        &__radio {
            .radio-stack {
                top: 0;
                right: 0;
                display: block;
                width: 20px;
                height: 20px;
                margin-left: 10px;
                margin-bottom: 0;

                input[type=radio] {
                    & + label.last-plan, & ~ label.first-plan {
                        &:before {
                            font-size: 20px;
                            line-height: 20px;
                            left: 0;
                        }
                    }
                    &:checked {
                        & ~ label.first-plan:before {
                            left: -0.25px;
                        }
                    }
                }

                @include respond-to($screen-xxs-max) {
                    margin-left: 0;
                }
            }

            .fare-types--single & {
                display: none;
            }
        }

        &__name-container {
            width: 55%;
            display: flex;
            line-height: 20px;
            flex-wrap: wrap;

            .fare-types__fare--common-desc &,
            .fare-types--single .fare-types__fare--common-desc & {
                width: 205px;
            }

            @include respond-to($screen-xs-max) {
                flex-direction: column;
            }

            @include respond-to($screen-xxs-max) {
                width: 100% !important;
            }

            @include respond-to(1140) {
                width: 70%;
            }
        }

        &__desc {
            display: none;

            .fare-types__fare--common-desc & {
                display: flex;
            }

            &-mobile {
                display: none;
            }

            @include respond-to($screen-xs-max) {
                display: none !important;

                &-mobile {
                    .fare-types__fare--common-desc & {
                        display: flex;
                    }
                }
            }
        }

        &__out__name,
        &__ret__name {
            font-weight: bold;
            font-size: 14px;
            width: 205px;
            display: flex;
            align-items: center;

            span {
                font-weight: normal;
                margin-right: 2px;

                .fare-types--one-way &,
                .fare-types__fare--same-brands &,
                .fare-types__fare--common-mfl & {
                    display: none;
                }
            }

            &__icon {
                margin-left: 5px;
                cursor: pointer;
            }

            .fare-types__fare--common-desc & {
                width: 100%;
            }

            @include respond-to($screen-xs-max) {
                width: 100% !important;
            }
        }
        &__price-label {
            display: inline-block;
            margin-right: 4px;
            font-size: 14px;
        }

        &__price-container {
            display: flex;
            margin-left: auto;
            align-items: center;

            @include respond-to(1140) {
                flex-direction: column-reverse;
                margin-top: 0px;
                align-items: flex-end;

                .fare-types--airlinemix & {
                    margin-top: 0;
                }
            }

            @include respond-to($screen-xs-max) {
                flex-direction: column-reverse;
                margin-left: 0;
                width: 100%;
                margin-top: 10px;
                align-items: flex-start;

                .fare-types--airlinemix & {
                    margin-top: 0;
                }
            }
        }

        &__price {
            display: inline-flex;
            align-items: center;

            &--primary {
                font-size: 24px;
            }

            &--secondary {
                font-size: 14px;
                margin-right: 10px;

                @include respond-to(1140) {
                    margin-right: 0px;
                }
            }
        }

        &__curr {
            padding-left: 5px;
        }

        .fare-types:not(.fare-types--single) &:not(:last-child) {
            border-bottom: 1px solid #fff;
        }

        &--same-brands {
            .fare-types__fare__ret {
                display: none;
            }
        }

        &.is-hidden-fare {
            display: none;
        }
    }
}

.fare-types-reduced {
    padding: 0 15px;
    border-bottom: 1px solid $color19;

    .link {
        text-decoration: underline;
        span {
            margin-right: 10px;
        }

        i {
            position: relative;
            top: 1px;
        }
    }
}

.fare-info {
    &__item:not(:first-child) {
        margin-top: 20px;
    }

    &__content {
        &__title {
            font-size: 24px;
            margin: 5px 0 15px;
        }
    }

    &__table {
        display: table;
        width: 100%;
        border-collapse: collapse;

        &__cell {
            display: table-cell;
            padding: 5px 0;
            border-top: 1px solid $color19;
            border-bottom: 1px solid $color19;
            vertical-align: middle;

            &:first-child {
                width: 65%;
            }
        }

        &__row {
            display: table-row;

            &--head {
                .fare-info__table__cell {
                    border: 0;
                    font-weight: bold;
                }
            }

            &:last-child {
                .fare-info__table__cell {
                    border: 0;
                }
            }
        }
    }

    &__list {
        &__item:not(:last-child) {
            padding-bottom: 5px;
        }
    }
}

.passenger-data-link {
    float: right;
}

.flight-infomessage.qtip-passengers-data {
    width: 860px;

    .qtip-content {
        position: static !important;
        overflow-y: hidden;
    }

    #passengers-data-container {
        height: 100%;
    }

    .passengers-data {
        overflow: auto;
        height: calc(100% - 57px);
        max-height: 700px;
        margin-bottom: 57px;

        &__container {
            display: flex;
            align-items: center;
            flex-grow: 1;
            flex-wrap: wrap;

            @media (max-width: $screen-md) {
                padding-left: 15px;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            border-top: 1px solid $color19;
            min-height: 57px;
            cursor: pointer;
            background-color: $color3;

            &:hover {
                background: $color8;
            }

            &:first-child {
                border-top: 0;
            }
        }

        &__first-line {
            font-weight: bold;
        }

        &__second-line {
            span {
                &:after {
                    content: ",";
                    display: inline-block;
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }

                @media (max-width: $screen-md) {
                    display: block;
                }
            }
        }

        &__person {
            flex-grow: 1;
            padding-left: 15px;

            & > div {
                line-height: 18px;
            }

            @media (max-width: $screen-md) {
                padding-left: 0;
            }
        }

        &__ff-select {
            width: 25%;

            @media (max-width: $screen-md) {
                width: 100%;
                margin-top: 15px;
            }
        }

        &__buttons {
            padding: 10px 15px;
            text-align: right;
            border-top: 1px solid $color19;
            background-color: $color4;
            position: absolute;
            height: 56px;
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10;
        }

        &__radio {
            width: 20px;
            height: 20px;

            .radio-stack {
                display: block;
                top: 0;
                left: 0;
                line-height: 20px;

                input[type=radio] + label.last-plan:before,
                input[type=radio] ~ label.first-plan:before {
                    left: 0;
                    top: 0;
                }

                input[type=radio]:checked ~ label.first-plan:before {
                    left: 0;
                    top: 0;
                }
            }

            @media (max-width: $screen-md) {
                width: 40px;
            }
        }

        @media (max-width: $screen-md) {
            max-height: unset;
        }
    }

    @media (max-width: $screen-md) {
        min-height: 100% !important;
        min-width: 100% !important;
        width: 100%;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: 0 !important;
        position: fixed;
        box-shadow: none !important;
    }
}
