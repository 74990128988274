.infobox, .details-no-offer {
	margin-top: 3%;
	margin-bottom: 5%;
	padding: 10px;

	section {
		margin: 0;

		.section-content {
			p {
				text-align: center;
				margin: 0 0 10px;

				&.info-header {
					font-weight: bold;
					font-size:2em;
					line-height: 1.4em;
					margin-bottom: 30px;
				}

				&:first-of-type {
					padding-top: 15px;
				}

				&:last-of-type {
					margin-bottom: 0;
				}

				&.info-content{
					font-size:1.2em;
					line-height: 1.4em;
				}
			}


			.buttons {
				text-align: center;
				a {
					margin-bottom: 20px;
					margin-top: 30px;
				}
			}
		}
	}
}
