$color1: "$color1";
$color2: "$color2";
$color3: "$color3";
$color4: "$color4";
$color5: "$color5";
$color6: "$color6";
$color7: "$color7";
$color8: "$color8";
$color9: "$color9";
$color10: "$color10";
$color11: "$color11";
$color12: "$color12";
$color13: "$color13";
$color14: "$color14";
$color15: "$color15";
$color16: "$color16";
$color17: "$color17";
$color18: "$color18";
$color19: "$color19";
$color20: "$color20";
$color21: "$color21";

// force color /* to overwrite (overwhite :D ) transparent color */
$color100: white;

$color-rating-6: #398439;
$color-rating-5: #64a82e;
$color-rating-4: #aeda5b;
$color-rating-3: #dad55e;
$color-rating-2: #d58512;
$color-rating-1: #c11f27;
$color-rating-0: #3db9ec;

// $color1: #1B4594;		/* listwy nagłówkowe lvl 1, małe ikonki pomocnicze, najważniejsze teksty główne */
// $color2: #132D56;		/* listwa menu główne */
// $color3: #F4F3F8;		/* tło pomocnicze (ibe, boksy) */
// $color4: #E0DFE5;		/* tło pomocznicze (okienka, boksy) */
// $color5: #FFFFFF;		/* tło główne, otaczające */
// $color6: #132D56;		/* tło stopki */
// $color7: #699A21;		/* tło przycisków */
// $color8: "$color8";		/* tło przycisków (dół, gry gradient) */
// $color9: #000;			/* teksty: główny */
// $color10: #797979;		/* teksty: pomocniczy */
// $color11: #699A21;		/* teksty: ceny */
// $color12: #fff;			/* teksty: na listwach nagłówkowych lvl 1 */
// $color13: #fff;			/* teksty: stopka (telefon, email, znajdź nas) */
// $color14: #7492BD;		/* teksty: stopka (pomocnicze) */
// $color15: #FFF;			/* teksty: na przyciskach + menu główne */
// $color16: #699A21;		/* teksty: kolor linków */
// $color17: #699A21;		/* teksty: kolor linków w stopce */
// $color18: #F96420;		/* teksty: podświetlenie linków i przycisków */
// $color19: #B7B8B8;		/* ramki selectów, linie podziału */
// $color20: "$color20";	/* komunikaty informacyjne */
// $color21: #c11f27;		/* komunikaty ostrzegawcze i błędy */

$colors: $color1, $color2, $color3, $color4, $color5, $color6, $color7, $color8, $color9, $color10, $color11, $color12, $color13, $color14, $color15, $color16, $color17, $color18, $color19, $color20, $color21;

$sectionRadius: "$sectionRadius";
$inputRadius: "$inputRadius";
$buttonRadius: "$buttonRadius";
$hintRadius: "$hintRadius";
// Separate loops for JavaScript parser iterations
@mixin generateColorClasses() {
	@for $i from 1 through 21 {
		.text-color-#{$i} {
			//color: nth($colors, $i);
			color: "$color#{$i}";
		}
	}
	@for $i from 1 through 21 {
		.background-color-#{$i} {
			//background: nth($colors, $i);
			background: "$bgcolor#{$i}";
		}
	}
	@media (hover: hover) and (pointer: fine) {
		@for $i from 1 through 21 {
			.background-hover-#{$i}:hover {
				//background: nth($colors, $i);
				background: "$bgcolor#{$i}";
			}
		}
	}
	@for $i from 1 through 21 {
		.border-color-#{$i} {
			border-color: "$color#{$i}" !important;
		}
	}
}

.text-hover-12:hover, .text-hover-12:hover * {
	color: $color12;
}
.text-hover-15:hover, .text-hover-15:hover * {
	color: $color15;
}
.text-hover-18:hover, .text-hover-18:hover * {
	color: $color18;
}
.text-hover-inherit, .text-hover-inherit * {
	color: inherit;
}
[class^="text-hover"] {
	@include transition(color ease 0.2s);
}

// force color /* to overwrite transparent color */
.background-color-100{
	background: $color100;
}

$darkerBackground: rgba(0, 0, 0, 0.05);
.darker-background{
	background-color: rgba(0, 0, 0, 0.05);
}

.qtip-background-8 {
	background-color: "$bgcolor8" !important;
}
