.skeleton {

  .skeleton-body{
    background-color: rgba(0, 0, 0, 0.05);
    gap: 10px;
    display: inline-block;
    width: 100%;
    height: 100%;

    .skeleton-body-part {
      padding: 0;
    }

    .skeleton-flex {
      display: flex;
      align-items: stretch;
      flex-shrink: 0;
      flex-wrap: wrap;
      gap: 0;
      &.flex-justify-center {
        justify-content: center;
      }
    }

    .skeleton-element-container {
      padding: 10px;
    }

    .skeleton-shimmer, .skeleton-element  {
      display: inline-block;
      position: relative;
      overflow: hidden;
      background-color: #b9b5b5;
      width: 100%;
      animation: fade 2s infinite linear;

      &.text-row {
        height:20px;
        &.half-width{
          width: 50%;
        }
      }

      &.text-header {
        height:30px;
        &.half-width{
          width: 60%;
        }
      }

      &.input {
        height:36px;
        &.half-width{
          width: 50%;
        }
      }

      &.image {
        height:100%;
        color: $color1;
        .fa:first-of-type::before{
          color: $color5;
          font-size: 100px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .fa:nth-of-type(2)::before{
          color: rgba(0, 0, 0, 0.05);
          font-size: 100px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      @keyframes fade {

        0% {
          opacity: 100%;
        }
        50% {
          opacity: 70%;
        }
        100% {
          opacity: 100%;
        }
      }
    }
  }

}
/** triplist additional rules */

#offer-list-section .skeleton {
  .skeleton-element-container {
    padding: 15px;
  }
  .image{
      .fa:first-of-type::before{
        color: $color5;
        font-size: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .fa:nth-of-type(2)::before{
        color: rgba(0, 0, 0, 0.05);
        font-size: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
  }

  .listing .skeleton-body-part {
    border-bottom: 1px solid #c2c2c2;
    .skeleton-element-container:first-of-type {
      display: none;
    }
    .skeleton-element-container:nth-of-type(2) {
      flex-basis: 70%
    }
    .skeleton-element-container:nth-of-type(3) {
      flex-basis: 30%;
      flex-direction: row-reverse;
      .skeleton-element:first-of-type {
        flex-basis: 80px; order: 2; margin-right: 10px;
      }
      .skeleton-element:nth-of-type(2) {
        flex-basis: 20px; order: 1;
      }
    }

  }

  .cluster .skeleton-body-part {
    padding: 5px;
    .skeleton-element-container:first-of-type {
      flex-basis: 100%;
      .image {
          height: 140px;
      }
    }
    .skeleton-element-container:nth-of-type(2) {
      flex-basis: 100%;
      padding: 5px 15px 0 15px;
    }
    .skeleton-element-container:nth-of-type(3) {
      flex-basis: 100%;
      padding: 5px 15px 10px 15px;
      .skeleton-element:first-of-type {
        flex-basis: 100%;
        height:33px;
      }
      .skeleton-element:nth-of-type(2) {
        display: none;
      }
    }
  }
}

.object-listing-elem.elem-booking .skeleton {
  padding: 0;
  .skeleton-body {
    padding: 0px;
  }
  .skeleton-element-container {
    padding: 0 1px;
  }

  .skeleton-body-part {
    padding: 0 1px;
  }
}

.sub-col-main .trip-filters .skeleton-body-part {
  display: flex;
  flex-wrap: wrap;
  .skeleton-element-container {
    width: 25%
  }
}

/** tripfilters additional rules */

.trip-filters .skeleton {
  .skeleton-element-container {
    padding: 10px 15px;
  }
}