//
// Utility classes
// --------------------------------------------------


// Floats
// -------------------------

.clearfix {
  @include clearfix;
}
.center-block {
  @include center-block;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.text-hide {
  @include text-hide;
}
.no-border{
    border: none !important;
}

.no-border-bottom{
    border-bottom: none !important;
}

// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.template {
    display: none;
}

// For Affix plugin
// -------------------------

.affix {
    position: fixed;
}

// condensed grid for flightresult & flightbooking
.grid-condensed {
    .row{
        margin-left: -$quarter-gutter;
        margin-right: -$quarter-gutter;
    }
    div[class*="col-"]{
        padding-left: $quarter-gutter;
        padding-right: $quarter-gutter;
    }
}

// Padding & margin additional classes
.p-r-half{
    padding-right: $quarter-gutter !important;
}

.p-l-half{
    padding-left: $quarter-gutter !important;
}

.m-r-half{
    margin-right: $quarter-gutter !important;
}

.m-l-half{
    margin-left: $quarter-gutter !important;
}

@mixin make-positions($rule_long, $rule_short, $prefix_with_separator, $positions, $size_code, $size){
    @each $position, $short_position in $positions {
        @if $position != null {.#{$prefix_with_separator}#{$rule_short}#{$rule_name_separator}#{$short_position}#{$rule_name_separator}#{$size_code}{ #{$rule_long}-#{$position}: $size !important; }}
    }
}

@mixin make-margin-padding-sizes($size_map, $rule_name_separator, $rule_name_prefix){
    $attributes_map: (margin: m, padding: p);
    $m-p-positions: (top: t, right: r, bottom: b, left: l);

    $prefix_with_separator: if($rule_name_prefix == '', null, if($rule_name_prefix != null, $rule_name_prefix+if($rule_name_separator == null, '', $rule_name_separator), ''));

    @each $size_code, $size_val in $size_map {
        @each $key, $val in $attributes_map {
            @include make-positions($key, $val, $prefix_with_separator, $m-p-positions, $size_code, $size_val);
        }
    }
}

// $sizes_map maps size name to rule value
$global_sizes_map: ('n': 0, 'xs': $margin-padding-xs, 'sm': $margin-padding-sm, 'md': $margin-padding-md, 'lg': $margin-padding-lg);
// $rule_prefix can be string or null
$rule_name_prefix: null;
// $rule_name_separator can be string or null
$rule_name_separator: '-';

@include make-margin-padding-sizes($global_sizes_map, $rule_name_separator, $rule_name_prefix);
