.mxicon-widget-easymeetwidget {
  &:before {
    content: $mxicon-assistant-team;
  }
}

.mxicon-attr_partycity {
  &:before {
    content: $mxicon-attr_nearcenter;
  }
}

.mxicon-attr_adultonly {
  &:before {
    font-weight: 900;
    font-family: '#{$fa-style-family}' !important;
    content: unquote("\"#{ $fa-var-male }\"");
    font-size: 0.7em;
    bottom: 5px;
    position: relative;
  }
}
