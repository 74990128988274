.qtip-popup-widget {
    max-height: 90%;
    margin: 0;
    border: none;
    background: transparent;
    @include border-radius("$block-br");

    @include respond-to($screen-sm-max) {
        box-shadow: none;
    }

    .qtip-content {
        padding: 0;
        border-radius: 0;
        max-height: 90vh;
        overflow-y: auto;
        overflow-x: hidden;

        @include respond-to($screen-sm-max) {
            padding: 46px 15px 15px;
        }

        p {
            margin-top: 5px;
            margin-bottom: 10px;
        }
    }

    @include respond-to($screen-sm-max) {
        .qtip-titlebar {
            margin: 0 15px;
        }
    }
    //.qtip-titlebar {
    //    font-size: 19px;
    //    line-height: 46px;
    //    position: relative;
    //    padding: 0 35px 0 15px;
    //    font-weight: normal;
    //
    //    .qtip-close {
    //        line-height: 46px;
    //        top: 0;
    //    }
    //}
}

.qtip-hidden-header {
    .qtip-close {
	    top: -35px;
	    right: -38px;
        color: #fff;
        height: auto;

        span {
            font-size: 16px;
        }

        i.fa {
            vertical-align: -15%;
            font-size: 24px;
        }
        @include respond-to($screen-sm-max) {
            top: 0;
            right: 0;
        }
    }
}

.popup-widget {
    display: none;
    height: 100%;
    @include respond-to($screen-sm-max) {
        height: auto;
    }

    hr {
        margin: 0;
        padding: 0;
    }

    .btn{
        &.cancel {
            border: 1px solid $color7;
            &:hover {
                background-color: $color7;
                color: $color5;
            }
        }
        &.confirm {
            border: 1px solid transparent;
        }
    }

    .input-container.input-container-left {
        padding-right: 7px;
    }
    .input-container.input-container-right {
        padding-left: 7px;
    }
    .input-container input {
        border-color: inherit;
    }
    .popup-btn-container {
        padding-left: 7px;
        padding-right: 0;
        a {
            width: 100%;
        }
    }
}

.popup-widget-background-image {
    background-size: cover;
    background-position: center;

    .popup-widget-content {
        background-color: transparent!important;
    }
}

.popup-widget-wrapper {

    &:last-child {
        .popup-separator {
            display: none;
        }
    }

    .popup-widget-content {
        position: relative;

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        h2 {
            margin-bottom: 0;
            margin-top: 0;
            font-size: 20px;
        }

        p {
            padding-right: $half-gutter;
            padding-left: $half-gutter;
        }

        .quickcontact-box, .newsletter-form {
            padding: 0;
            margin-bottom: 0;
            form {
                .row {
                    margin: 0;
                }
                .row:last-child {
                    padding-bottom: 10px;
                }
                .input-row{
                    .row {
                        margin: 0;
                    }
                }
            }
        }
    }

    .popup-widget-footer {
        text-align: right;
        padding: 0 15px 15px 15px;
        button {
            width: 25%;
            text-transform: uppercase;
        }
    }

    .quickcontact-form {
        .checkbox-section {
            margin-top: 0;
        }

        button {
            height: 32px;
        }
    }

    .checkbox-section{
        margin: 10px 0 0;

        .checkbox{
            display: block;
            margin: 0;


            label{
                padding-left: 30px;
                vertical-align: sub;
                font-size: 12px;
                position: relative;
            }

            input[type=checkbox] {
                //display:none;
                visibility: hidden;
                width: 0;
            }

            input[type=checkbox] + label:before {
                font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
                color: $color7;
                margin-right: 7px;
                display: inline-block;
                content: unquote("\"#{ $fa-var-square }\"");
                font-weight: 400;
                float: left;
                font-size: 23px;
                top: 5px;
                left: 0;
                position: absolute;
                z-index: 1;
            } /* unchecked icon */

            input[type=checkbox].invalid + label:before {
                color: #E8C3C5 ;
            }

            input[type=checkbox].invalid + label:after {
                color: $color21 ;
            }

            input[type=checkbox] + label:after {
                font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
                color: $color15;
                display: inline-block;
                content: unquote("\"#{ $fa-var-square }\"");
                font-weight: 900;
                position: absolute;
                left: 0;
                font-size: 23px;
                top: 5px;
            }

            input[type=checkbox]:checked + label:before {
                content: unquote("\"#{ $fa-var-check }\"");
                font-weight: 900;
                color: $color15;
                left: 2px;
                font-size: 16px;
                position: absolute;
            } /* checked icon */

            input[type=checkbox]:checked + label:after {
                color: $color7;
            } /* checked icon */
        }
    }
}

.contact-content {
    font-size: 22px;
    padding-left: 30px;

    a {
        color: inherit;
    }
    .fa {
        margin-right: 5px;
    }

    .fa-phone {
        font-size: 38px;
        vertical-align: -24%;
    }

    .fa-envelope-o {
        font-size: 28px;
        vertical-align: -15%;
    }
}

.popup-widget-content {
    .popup-image-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 50px;
        right: 50px;
        text-align: center;
        letter-spacing: 1.5px;
        max-height: 100%;
        overflow: hidden;
        color: inherit;
        text-shadow: 0 0 5px #000;

        h2 {
            font-size: 32px;
            margin-bottom: 10px;
            font-weight: bold;
        }

        p {
            font-size: 20px;
        }
    }
}

.popup-widget-type-header {
    .popup-widget-content {
        padding-bottom: 0!important;
    }
}

.popup-widget-type-image {
    position: relative;
    img {
        margin: 0 auto;
    }
    a {
        color: inherit;
    }
}

.popup-widget-type-community {
    a {
        display: inline-block;
        margin-right: 3px;
        height: 46px;
        text-align: center;
        transition: opacity .3s ease-in-out;

        &:hover {
            opacity: .7;
        }
        .fa {
            margin-top: 3px;
        }
    }
}

.popup-separator {
    height: 1px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
}

.big_80 {
    width: 80%;
}

.med_60 {
    width: 60%;
}

.sml_40 {
    width: 40%;
}

.facebook-btn {
    color: #fff;
    background-color: #3b5998;
}

.twitter-btn {
    color: #fff;
    background-color: #55acee;
}

.googleplus-btn {
    color: #fff;
    background-color: #dd4b39;
}

.pinterest-btn {
    color: #fff;
    background-color: #cb2027;
}

.linkedin-btn {
    color: #fff;
    background-color: #007bb5;
}
