.simple-header {
	padding: 10px 0;
	margin-bottom: 5px;

	@include respond-to($screen-sm-max) {
		margin: 0;
		padding: 0;
	}

	.logo-container-wrapper {
		margin: 0;

		img {
			max-width: 100%;
		}
	}

	h2 {
		display: inline-block;
		font-size: 19px;
		line-height: 21px;
		margin: 0;
		padding: 0;
		font-weight: 400;
		vertical-align: middle;
	}

	h3 {
		margin: 0;
		padding: 0;
		font-size: 22px;
		line-height: 22px;
		font-weight: 600;
		white-space: nowrap;
		@include respond-to($screen-sm-max) {
			font-size: 16px;
			text-align: center;
		}
	}

	h4 {
		margin-bottom: 0;
	}


	p {
		font-size: 12px;
		line-height: 16px;
		margin: 0;
	}

	.simpleheader-aside {
		@include cf;
		margin: 30px 0 0;

		.fa {
			vertical-align: -25%;
		}

		@include respond-to($screen-xs-max) {
			text-align: center;
			margin: 15px 0 0;

			&.no-padding-xs > .row {
				margin-left: -15px;
				margin-right: -15px;
			}
		}
	}

	.simpleheader-center {
		height: 100%;
	}

    // it's here until h4 descriptions are present
    .padding-8{
        padding-top: 8px;
    }
}
.sim-header-links {
	margin-top: 30px;
	display: table;
	&:hover {
		color: $color18;
	}
	.current-language, .yourCart, .current-curr {
		color: inherit;
		display: table-cell;
	}
	i.fa {
		padding-right: 5px;
	}

}

