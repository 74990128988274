/*
	footer specific styling
*/
$default-padding-top-bottom: 14px;

footer {
    //background: $color6;
    //padding: 10px 0 0px;
    position: relative;

    a {
        color: $color17;
    }

    h2 {
        font-size: 18px;
        line-height: 18px;
        margin: 0 0 7px;
        color: inherit;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .container, .container-fluid {
        position: relative;
    }

    .row.sortable-row {
        border: none;
    }

    .page-footer {
        padding: 10px 0 20px;

        div.row {
            padding: 0;

            .input-container {
                padding-bottom: $default-padding-top-bottom;
            }
        }

        .padding-top-15 {
            padding-top: $default-padding-top-bottom;
        }
    }

    div.row {
        border-bottom: 1px solid;
        @media (max-width: $screen-xs-max) {
            &.row-xs {
                border: 0px;
                padding-bottom: 0;
            }
        }
    }

    section {
        margin-bottom: 0;
    }

    @include respond-to($screen-xs-max) {
        position: relative;
        margin-top: 1px;
    }
}

.footer-contact-container {
    $container-min-h: 74px;
    $container-min-h-sm: $container-min-h - 14px;

    height: auto;
    min-height: $container-min-h;

    @include respond-to($screen-sm-max) {
        min-height: $container-min-h-sm;
    }

}

.footer-phone-container {
    position: relative;
    margin: 10px 0 0;
    padding: 3px 0 $default-padding-top-bottom 60px;

    .icon-container {
        position: absolute;
        top: 0;
        left: 0;

		.fa-phone {
			position: absolute;

			top: 10px;
			@include rotate(0);
			font-size: 50px;

            @include respond-to($screen-xs-max) {
                font-size: 50px;
            }
        }
        .fa-circle-o {
            position: absolute;
            top: 5px;
            left: 5px;
        }
    }

    .main-label {
        font-size: 16px;
        line-height: 18px;
        display: block;
        letter-spacing: 1px;
    }

    .value {
        font-size: 29px;
        line-height: 32px;
        display: block;
        a {
            color: inherit;
            text-decoration: none;
        }

        &.secondary {
            $block-h: 14px;
            font-size: $font-size-base;
            line-height: $block-h;
            display: block;
            height: $block-h;
            width: auto;
        }
    }

    @include respond-to($screen-xs-max) {
        width: 320px;
        margin: 10px auto 0;
        padding: 0px 0 0 50px;
        @include scale(0.7);
    }
}

.footer-email-container {
    position: relative;
    margin: 10px 0 0;
    padding: 3px 0 0;

    .main-label {
        font-size: 16px;
        line-height: 18px;
        display: block;
        letter-spacing: 1px;
    }

    .value {
        font-size: 29px;
        line-height: 32px;
        display: block;

        a {
            color: inherit;
            text-decoration: none;
        }
    }

}

.quickcontact-box {
    padding: $default-padding-top-bottom 0 0;

    h2, p {
        text-align: center;
    }

    p {
        font-size: 14px;
        line-height: 18px;
    }

    form {
        .input-group-addon, .form-control {
            border-color: $color14 !important;
        }
        .row {
            padding: 0 0 15px 0;
            border: 0;
            margin: 0;

            .input-container {
                @include respond-from($screen-sm-min) {
                    padding-right: 7px;
                    padding-left: 7px;

                    &.input-container-left {
                        padding-right: 7px;
                    }
                    &.input-container-right {
                        padding-left: 7px;
                    }
                }

                input, button {
                    border-color: inherit;
                    height: 32px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .agree-checkbox {
                padding-left: 0px;
                padding-bottom: $default-padding-top-bottom;
                margin: 0 auto;
                width: 90%;
            }
        }
    }
}

.newsletter-box {
    padding: $default-padding-top-bottom 0 0;

    p {
        font-size: 14px;
        line-height: 18px;
    }

    .input-container {
        width: 200px;
        margin: 0 0 15px 0;

        input {
            color: $color14;
            border-color: $color14;
        }

    }

    .btn {
        margin: 0 0 0 5px;
        padding: 8px 30px 7px;
        border: none;
    }
}

.footer-link-section {
    text-align: center;
}

.nav-in-footer {
    color: #000000;
}

.footer-nav-box {
    padding: $default-padding-top-bottom 0 $default-padding-top-bottom 0;
    overflow: hidden;
    .header {
        display: inline;
        font-weight: bold;
    }
    .el-block {
        a {
            display: inline;
            padding: 0;
            margin: 0 0 10px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        &:after {
            content: '|';
            color: $color14;
        }

        &:last-of-type {
            &:after { content: ''; }
        }
    }

    .link-in-footer {
        &.custom-link-color, &.non-functional-link {
            margin-bottom: 0;
            color: $color17;

            &:hover {
                color: $color18;
            }
        }
    }

    ul {
        margin: 20px 0 0;
        padding: 0;
        @include cf;

        li {
            list-style: none;
            display: block;
            float: left;
            min-width: 50%;
            padding: 0 10px 0 0;
            margin: 0 0 10px;
            @include box-sizing(border-box);

            @include respond-to($screen-md-max) {
                min-width: 50%;
            }
            @include respond-to($screen-sm-max) {
                min-width: 50%;
            }
            @include respond-to($screen-xs-max) {
                float: none;
                display: inline-block;
                width: auto;
            }
        }
        @include respond-to($screen-xs-max) {
            margin: 0;
            text-align: center;
        }
    }
    @include respond-to($screen-xs-max) {
        text-align: center;
        padding: 20px 0 0;
    }
}

li.footer-img-after {
    &:after {
        content: '' !important;
    }
}

.recommended-links {
    text-align: center;
    padding: $default-padding-top-bottom 0;

    .label {
        color: $color14;
        font-weight: 400;
        display: inline;
        font-size: 15px;
        line-height: 16px;
        padding: 0;
    }

    ul {
        display: inline;
        margin: 0;
        padding: 0;
        color: $color17;
        li {
            display: inline;
            position: relative;
            font-size: 15px;
            line-height: 16px;
            margin: 0 5px 0 0;
            a {
                //text-decoration: underline;
                color: inherit
            }
        }
    }
}

.language-option {
    text-align: center;
    padding: 15px 0 0;
    .label {
        color: $color15;
        font-weight: 400;
        display: inline;
        font-size: 14px;
        line-height: 16px;
        padding: 0;
    }
    ul {
        display: inline;
        margin: 0 0 0 5px;
        padding: 0;
        position: relative;
        top: 3px;
        li {
            display: inline;
            list-style: none;
            margin: 0 2px 0 0;
            a {
                display: inline-block;
                width: 23px;
                height: 15px;
                background: red;
            }
        }
    }
}

.payment-info {
    text-align: center;
    padding: $default-padding-top-bottom 0;
    p {
        margin: 0;
        font-size: 12px;
        line-height: 30px;
        img {
            max-height: 30px;
            width: auto;
            margin: 0 3px;
        }
    }
}

.footer-info {
    padding: $default-padding-top-bottom 0 0;
    @media (max-width: $screen-xs-max) {
        padding-top: 0;
    }
    .rwd {
        @include cf;
        width: 520px;
        margin: 0 auto;
        .img-container {
            width: 130px;
            height: 60px;
        }
        p {
            margin: 22px 0 0 140px;
            font-size: 12px;
            line-height: 17px;
        }
    }
    .disclaimer {
        text-align: center;
        margin: 17px 0 0;
        @media (max-width: $screen-xs-max) {
            margin-top: 0;
        }
        p {

            font-size: 12px;
            line-height: 15px;
            @media (max-width: $screen-xs-max) {
                br {
                    display: none;
                }
            }
            margin-bottom: $default-padding-top-bottom;
        }
    }

    .img-container {
        position: relative;

        .fa-desktop {
            font-size: 60px;
            position: relative;
            top: 0px;
            margin: 0 5px 0 0;
        }
        .fa-tablet {
            font-size: 50px;
            position: relative;
            top: 6px;
            margin: 0 5px 0 0;
        }
        .fa-mobile {
            font-size: 30px;
            position: relative;
            top: 7px;
        }
    }
}

.footer-social-media {
    text-align: right;
    padding: 17px 0 0;
    .label {
        font-weight: 400;
        font-size: 18px;
        color: $color13;
    }
    ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        @include cf;
        li {
            list-style: none;
            margin: 0;
            display: inline-block;
            a {
                background: rgba(255, 255, 255, .4);
                margin: 0 0 0px 6px;
                padding-top: 2px;
                display: block;
                width: 42px;
                height: 40px;
                border: 1px solid #ffffff;
                font-size: 30px;
                text-align: center;
                @include respond-to($screen-xs-max) {
                    @include scale(0.7);
                }
                &:hover {
                    background: rgba(255, 255, 255, 1)
                }
                .#{$fa-css-prefix} {
                    transform: translateY(-3px);
                }
            }
        }
    }
    @include respond-to($screen-sm-max) {
        text-align: left;
        padding: 60px 0 0;

        &.smaller-padding {
            padding: 45px 0 0;
        }
    }
    @include respond-to($screen-xs-max) {
        text-align: center;
        padding: 15px 0 0;
    }
}

div.footer-social-without-contact {
    @include respond-from($screen-sm-min) {
        @include respond-to($screen-sm-max) {
            display: table;
            width: 100%;
            .footer-social-media {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
                padding: 20px 0 0;
            }
        }
    }
}

.non-functional-link {
    &:hover {
        color: $color18;
    }
}

.ext-footer {
    padding: 30px 0 20px;

    .content {
        padding: 0 15px;
    }

    .center-footer {
        display: table;
        margin: 0 auto;
        @include respond-to($screen-xs-max) {
            margin: 0;
        }
    }

    .row {
        border: none;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: bold;
    }

    .ext-footer-wrapper {
        position: relative;
        float: left;
        @include respond-to($screen-sm-max) {
            width: 50% !important;
            margin: 20px 0;
        }
        @include respond-to($screen-xs-max) {
            width: 100% !important;
        }
        &::before {
            content: '';
            height: 100%;
            width: 1px;
            background-color: $color15;
            position: absolute;
            left: 0;
            top: 0;
            opacity: .2;
            @include respond-to($screen-sm-max) {
                display: none;
            }
        }
        &:last-child::after {
            content: '';
            height: 100%;
            width: 1px;
            background-color: $color15;
            position: absolute;
            right: 0;
            top: 0;
            opacity: .2;
            @include respond-to($screen-sm-max) {
                display: none;
            }
        }
    }

    .ext-footer-elem-container {
        position: relative;

        .ext-footer-elem {
            width: 100%;
            display: table;
            margin: 0 auto;
            padding-right: 15px;
            .ext-footer-icon {
                font-size: 70px;
                line-height: 70px;
                text-align: center;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
                display: table-cell;
            }
            .ext-footer-text {
                width: calc(100% - 90px);
                display: table-cell;
                vertical-align: middle;
                padding-left: 10px;
                p {
                    font-size: 16px;
                    margin: 0;
                    line-height: 22px;
                    strong {
                        font-size: 24px;
                    }
                    sup {
                        top: -0.8em;
                        font-size: .7em;
                        margin-left: 3px;
                    }
                }
                .non-functional-link {
                    color: $color17;

                    &:hover {
                        color: $color18;
                    }
                }
            }
        }

    }
}
