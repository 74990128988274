.region-group {
	.region {
		&:last-child {
			border-bottom: 1px solid;
		}
		h3 {
			margin: 0;
			padding: 10px 15px;
			font-weight: bold;
			font-size: 18px;
		}
		.img-container {
			margin: 15px 0;
			display: block;
			width: 100%;
			text-align: center;
			height: 300px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			@include respond-to($screen-xs-max) {
				height: 200px;
			}

		}
		.region-pricing-list {
			width: 100%;
			margin: 15px 0;
			.meta-label {
				width: 100%;
			}
			&>tbody>tr>td {
				border-bottom: dotted 1px;
				padding: 5px 0;
			}
			& + .more-regions {
				margin-bottom: 15px;
			}
		}
	}
}