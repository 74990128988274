.simpleexpert-box {

    overflow: hidden;
    //background: white;
    position: relative;

    &.shadow-separators{

        &:before,
        &:after{
            content: "";
            position:absolute;
            z-index: 1;
            width:96%;
            top: -10px;
            height: 10px;
            left: 2%;
            border-radius: 100px / 5px;
            box-shadow:0 0 18px rgba(0,0,0,0.4);
        }
        &:after {
            top: auto;
            bottom: -10px;
        }

        .section-content{
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &.section {        
        margin: 10px 0 15px;

        @include respond-to($screen-xs-max) {
            margin: 0;
            border: none;
        }
    }

    .section-content {
        position: relative;
        padding: 30px 0;

        @include respond-to($screen-xs-max) {
            padding: 15px;
        }
    }

    .left-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 135px;
        background-size: cover;
        background-position: 50% 50%;
        overflow: hidden;
    }

    .simple-container {
        padding: 15px 250px 15px 15px;
        border: 1px solid $color19;
        position: relative;
        overflow: hidden;
        @include respond-to($screen-xs-max) {
            padding: 15px;
        }

        &.simple-container-modern{
            padding-left: 0;

            @include respond-to(991) {
                padding-left: 5px;
            }
            @include respond-to(767) {
                padding-right: 0;
            }
        }
    }

    .action-container {
        position: absolute;
        top: 0;
        right: 15px;
        width: 250px;
        height: auto;
        bottom: 0;
        text-align: center;
        background-size: auto 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        .btn {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            @include translate(-50%, -50%);
        }
    }

    .action-container .btn-wak-swiat,
    .btn-wak-swiat{
        border-style: solid;
        border-width: 2px;
        padding: 7px 10px 7px 15px;
        font-size: 16px;
        top: 50%;
        left: auto;
        right: 0;
        @include translate3d(0, -50%, 0); //chrome blurry border bug workaround
        @include respond-to($screen-xs-max){
            transform: none;
            margin-top: 15px;
        }

        .btn-text{
            display: inline-block;
            margin-right: $margin-padding-xs;
            vertical-align: middle;
        }

        .fa{
            vertical-align: middle;
        }
    }

    .text-container {
        position: relative;
        padding-left: 135px;

        @include respond-to($screen-sm-max) {
            padding-left: 0;
        }

        h2 {
            margin: 0;
            padding: 0;
            font-size: 26px;
            line-height: 32px;

            @include respond-to($screen-sm-max) {
                font-size: 22px;
                line-height: 28px;
            }
        }

        p {
            margin: 10px 0 0;
            padding: 0;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .text-container-absolute{
        padding: 0;
        @include respond-from($screen-md-min) {
            position: absolute;
            top: 0;
            bottom: 0;
            padding-left: 258px;
            padding-right: 235px;
        }
    }

    a.inner {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
