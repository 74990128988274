.occupancy-input {
	display: inline-table;
	overflow: hidden;
	& > span, & > input {
		min-width: 35px;
		display: table-cell;
		border-top: 1px solid;
		border-bottom: 1px solid;
	}
	& > span:first-child {
		border: none;
		width: 25px;
		position: relative;
		.fa-2x {
			position: absolute;
			left: 4px;
			top: 2px;
		}
	}
	.occupancy-control {
		text-align: center;
		border-top: 1px solid;
		border-bottom: 1px solid;
		cursor: pointer;
		&.plus {
			border-bottom-left-radius: 0 !important;
			border-top-left-radius: 0 !important;
			border-right: 1px solid;
		}
		&.minus {
			border-bottom-right-radius: 0 !important;
			border-top-right-radius: 0 !important;
			border-left: 1px solid;
		}
		&.disabled, &.disabled:hover {
			cursor: auto;
			background: $color10 !important;
		}
	}
	.input-container {
		min-width: 100px;
		padding-left: 10px;
	}
	input {
		width: 30px;
		height: 32px;
		padding: 0;
		border: none !important;
		margin: 0;
		background: transparent;
	}
}

.occupancy-editor {
	position: relative;
    &.hide-children-for-7p7 > * {
		opacity: 0;
	}

	.occupancy-input {
		margin: 5px 10px 5px 0;
	}
	@include respond-to($screen-xs-max) {
		.occupancy-input {
			display: table;
		}
		.input-container {
			width: 100%;
		}
	}
	.controls {
		line-height: 30px;
		float: left;
		@include respond-to($screen-xs-max) {
			float: none;
		}
	}
	.birthdate-input {
		float: left;
		margin: 5px 10px 5px 0;
		@include respond-to($screen-xs-max) {
			float: none;
			text-align: right;
		}
	}
	.child-birthdate-input {
		display: inline-block;
		width: 160px;
		margin-left: 5px;
		@include respond-to($screen-xs-max) {
			width: 170px;
		}
	}
	.room-list {
		margin: 0;
		.item {
			padding: 15px;
			border-bottom: 1px solid;
			h4 {
				margin: 0;
				display: inline-block;
			}
		}
	}
	.price-loader {
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		margin-left: 50%;
		font-size: 40px;
		font-weight: 900;
		color: lightgray;
		display: none;
		.loader-component {
			margin: 0 5px;
		}
	}
	.info-label {
		display: inline-block;
		padding: 5px;
		font-size: 0.8em;
	}
	.btn {
		@include respond-to($screen-sm-max) {
			margin-top: 10px;
		}
	}
}
