.easymeet-container
{
  .meet {
    display: flex;
    border-bottom: 1px solid;
    align-items: center;

    @media (max-width: $screen-sm) {
      flex-direction: column;
    }

    .meet-icon {
      flex-basis: 50px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      padding: 10px;
      flex-shrink: 0;
      flex-grow: 0;

      @media (max-width: $screen-sm)
      {
        display: none;
      }

      &.meet-image-icon {
        flex-basis: 150px;
        padding: 0;

        img {
          width: 100%;
        }
      }

      &.with-image {
        align-self: flex-start;
      }
    }

    .meet-content {
      flex-shrink: 1;
      flex-grow: 1;
    }

    .meet-title {
      width: 100%;
      display: inline-block;
      font-size: 1.2em;
      font-weight: bold;
      padding: 10px 10px 5px 10px;
    }

    .meet-description {
      width: 100%;
      display: inline-block;
      padding: 5px 10px 10px 10px;
    }

    .meet-select {
      flex-basis: 100px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 10px;

      .btn {
        min-width: 200px;
      }

      .choosen {
        display: none;
      }
    }

    &.selected .meet-select .choosen {
      display: initial;
    }

    &.selected .meet-select .choose {
      display: none;
    }

    .btn:hover {
      @include lighter-background(.7);
      background-color: $color7;
    }

    &.selected .btn{
      @include lighter-background(.5);
    }
  }

  .term-section {
    .mc-container {
      max-width: 620px;
      left: 50%;
      margin-right: -50%;
      transform: translateX(-50%);
    }

    .mcal-cell.selected {
      @include lighter-background(.5);
    }

    .mcal-cell:hover {
      @include lighter-background(.7);
      background-color: $color7;
    }

    .mcal-btn{
      background-color: $color7;
      &:hover {
        @include lighter-background(.7);
        background-color: $color7;
      }
    }

    .mcal-cell.mcal-disabled{
      background: $color3;
      color: $color9
    }

    #available-terms {
      display: flex;
      justify-content: center;

      .terms-day {
        display: none;

        @media (max-width: $screen-sm) {
          width: 100%;
          padding: 0 10px;
        }

        .periods {
          display: none;
          gap: 1px;

          @media (max-width: $screen-sm) {
            flex-direction: column;
            width: 100%;
          }
        }

        .hour-div {
          display: flex;
          flex-direction: column;
          gap: 1px;

          @media (max-width: $screen-sm) {
            flex-direction: row;
          }
        }

        .time-cell {
          padding: 7px;
        }

        .hour-minutes {
          @include border-radius("$btn-br");
          background-color: $color7;
          color: white;
          cursor: pointer;

          @media (max-width: $screen-sm) {
            flex-grow: 1;
            text-align: center;
          }

          &.selected {
            @include lighter-background(.5);
          }
          &:hover{
            @include lighter-background(.7);
          }
        }
      }
    }

    .easymeet-select-hours-header {
      width: 100%;
      padding: 10px;
      text-align: center;
      font-weight: bold;
      display: none;
      border-top: 1px solid $color19;
      margin-top: 10px;
    }

    .easymeet-select-hours-summary {
      width: 100%;
      padding: 10px;
      padding-left: 150px;
      font-weight: bold;
      display: none;
      border-top: 1px solid $color19;
      margin-top: 10px;
    }

    #no-available-terms-info {
      display: none;
      width: 100%;
      padding: 10px;
      text-align: center;
      font-weight: bold;
      border-top: 1px solid rgba(201, 199, 206, 1);
      margin-top: 10px;
      color: $color21;
    }
  }


  .user-section {
    .user-data-container {
      margin: 0 160px;

      @media (max-width: $screen-md)  {
        margin: 0 5%;
      }

      .user-data-row {
        width: 100%;
        overflow: hidden;
        display: flex;
        padding: 5px;
        flex-wrap: wrap;
      }

      .label-container {
        float: left;
        padding: 6px 0;
        width: 30%;

        @media (max-width: $screen-md) {
          width: 100%;
          padding: 3px 0 0 0;
        }

        label:not(:first-child) {
          display: block;

          @media (max-width: $screen-md) {
            display: inline-block;
            float: right;
          }
        }
      }

      .input-container {
        flex-grow: 1;

        input, textarea {
          width: 100%;
        }
      }

      .conditions-block {
        width: 100%;
        margin-left: 20px;

        label {
          padding: 0;
        }

      }

      .send-button-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $screen-sm) {
          flex-direction: column;
        }

        .send-button {
          max-width: 20%;
          margin: 10px;

          @media (max-width: $screen-sm) {
            max-width: initial;
            width: 100%;
          }

          .spinner {
            display: none;
          }
        }

        .meet-summary {
          font-weight: bold;
          @media (max-width: $screen-sm){
            width: 100%;
          }
        }
      }
    }
  }
}

.easymeet-box {
  &.low {
    height: 102px;

    .header {
      padding: 5px 0 0 0;
    }

    .content {
      padding: 0;
    }

    .icon {
      display: none
    }
  }

  &.medium {
    height: 285px;

    a {
      padding-top: 30px;
    }
  }

  &.high {
    height: 408px;

    a {
      padding-top: 100px;
    }
  }

  .header {
    width: 100%;
    display: inline-block;
    font-size: x-large;
    font-weight: bold;
    padding: 10px;
  }

  .icon {
    width: 100%;
    display: inline-block;
  }

  .content {
    width: 100%;
    display: inline-block;
    font-size: large;
    padding: 10px;
  }

  .link {
    width: 100%;
    display: inline-block;
    font-size: larger;
  }

  .#{$fa-css-prefix}-circle-arrow-right {
    font-size: 26px;
  }

  .section-content {
    padding: 0;
    height: 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;

    a {
      height: 100%;
      width: 100%;
      display: block;
      display: flow-root;
      text-decoration: none;
      text-align: center;
      color: inherit;
    }
  }

  .fa-stack {
    height: 1.8em;
  }
}

