.order-first {
    @include respond-to($screen-sm-max) {
        order: -1;
        flex: 1 0 100%;
    }
}

.qtip-no-min-width {
    min-width: auto !important;

    .qtip-content {
        min-width: auto !important;
    }
}

.hotel-alternatives-header{
    border-top: 1px solid $color19;
    border-bottom: 1px solid $color19;

    .heading{
        display: inline-block;
        padding: $margin-padding-sm 0 $margin-padding-xs;
        white-space: nowrap;
        user-select: none;

        .toggle-caret{
            display: none;
            vertical-align: -21%;
            font-size: 24px;
            line-height: 18px;
        }

        &.active{
            color: $color18;

            .toggle-caret{
                display: inline-block;
            }
        }
    }

    .xs-heading{
        margin: $margin-padding-sm 0;
        font-size: 18px;
    }
}

.hotel-alternatives-list{
    position: relative;

    .alt-list-overlay{
        display: none;

        /*.fa {
            position: absolute;
            top: 50%;
            margin-top: -30px;
            margin-left: -30px;
        }*/
    }

    &.pending {
        min-height: 100px;

        .alt-list-overlay{
            display: block;
        }
    }

    .state{
        display: none;
        padding: $margin-padding-sm 0;
    }

    .btn-not-available{
        display: none;
        text-align: center;
    }

    .overlay-relative{
        position: relative;

        .overlay{
            @include overlay;
            height: auto;
            bottom: -10px;
            display: none;
            background: #fff;
            @include opacity($pending-opacity);
            cursor: auto;
        }
    }

    .alt-item{
        padding: 0 $margin-padding-md $margin-padding-sm;
        border-bottom: 1px solid $color19;


        .after-positive-check{
            display: none;

            td{
                vertical-align: top;
            }
        }

        &.alt-item-checked{
            border: 1px solid;

            .state{
                display: block !important;
            }
        }
        &.alt-item-checked-bold{
            border: 3px solid;

            .state{
                display: block !important;
            }
        }
        &.alt-item-positive{
            border-color: $color20;

            .state-positive{
                background: $color20;
            }

            .after-positive-check{
                display: block;
            }
            tr.after-positive-check{
                display: table-row;
            }
            .before-check{
                display: none;
            }
        }
        &.alt-item-negative{
            border-color: $color21;
            color: $color9 !important;

            .state-negative{
                background: $color21;
            }

            .overlay{
                display: block;
            }

            .alternative-details .btn-check{
                display: none;

            }
            .alternative-details .btn-not-available{
                display: block;
            }
        }

        .btn-link.br-block-bottom {
            background: $color3 !important;
        }

        @include respond-from($screen-md-min) {
            .alt-flights-container, .alt-rooms-container {
                .btn-check {
                    width: 163px;
                    margin: 0 0 0 auto;
                }
            }
        }

        .alt-flights-container {
            .box-container {
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
            }
            .js-stopover,
            .js-depTime,
            .js-flightTime,
            .js-arrTime,
            .js-rStopover,
            .js-rDepTime,
            .js-rFlightTime,
            .js-rArrTime {
                font-weight: bold;
            }

            .flight-time {
                display: flex;
                justify-content: flex-end;
                align-items: baseline;
                padding: 10px 0 0;

                i {
                    transform: translateY(-50%);
                    top: 50%;
                    position: relative;
                    color: $color18;
                }

                span {
                    border-top-width: 2px;
                    border-top-style: dashed;
                    border-top-color: $color18;
                    text-align: center;
                    align-self: flex-end;
                    flex-grow: 1;
                }
            }
        }

        .flight-info {
            i.fa {
                width: 15px;
            }
        }
    }

    .js-alt-item-highlight {
        padding-bottom: 0 !important;
        margin-bottom: 5px;

        &:hover {
            background-color: $color6;
        }

        .box-item {
            align-self: auto !important;
            &>div>div{
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .box-item.item-hotel {
            white-space: nowrap;
        }
        .box-item-center {
            display: flex;
            align-items: center;
            @include respond-to($screen-sm-max) {
                justify-content: center;
            }
        }
    }

    .js-payment-icons {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;

        &:empty {
            @include respond-to($screen-sm-max) {
                display: none;
            }
        }

        & > * {
            margin-left: 5px;
        }

        i {
            font-size: 1.5em;
        }

        .payment-icon {
            border: 1px solid;
            border-radius: 3px;
            padding: 0 3px;
            line-height: normal;
        }
    }

    .js-alternative-selectors {
        .other-active {
            border-bottom: 1px solid $color9;
        }

        .with-accordion {
            &:not(:first-of-type) {
                border-left: 1px solid $color6;
            }
            border-radius: unset;
            background-color: $color5;
            border-top: 3px solid $color5;
        }

        .with-accordion-active {
            &:not(:first-of-type) {
                border-left: 1px solid $color9;
            }
            border-right: 1px solid $color9;
            border-top: 3px solid $color18;

            &.br-block-bottom {
                background-color: $color5 !important;
            }
        }
    }

    .heading-xs{
        color: $color10;
    }


    .box-container {
        @include respond-to($screen-sm-max) {
            display: block;
        }

        .box-item {
            @include respond-from($screen-md-min) {
                padding: 0 $margin-padding-md;
            }
        }

        &.order-first-container {
            @include respond-to($screen-sm-max) {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
            }
        }
    }

    .alimentation{
        font-weight: 700;
    }

    .room, .alimentation, .duration, .candy {
        i {
            width: 20px;
        }
    }

    .price-container{
        text-align: right;
        @include respond-to($screen-sm-max){
            text-align: center;
            margin: 10px auto;
        }
        .main-price{
            @include respond-to($screen-sm-max){
                display: inline-block;
            }
            sup{
                font-size: 11px;
                margin-left: -2px;
            }
            .price,
            .currency {
                font-size: 18px;
            }

        }
        &.fix-price-styles {
            color: $color11;
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            float: right;
            .main-price>span:first-of-type>span:first-of-type{
                float: left;
            }
            sup{
                font-size: 8px;
            }
            .price,
            .currency {
                font-size: 14px;
                font-weight: bold;
            }
            >div:last-of-type {
                font-size: 18px;
                border-top: 1px solid $color11;
                margin-top: 3px;
                sup{
                    font-size: 15px;
                }
                .price,
                .currency {
                    font-size: 22px;
                    font-weight: bold;
                }
            }
        }
        .secondary-price{
            @include respond-to($screen-sm-max){
                display: inline-block;
                margin-left: 5px;
            }
            sup{
                font-size: 8px;
                margin-left: -2px;
            }
        }

    }


    .label-bigger{
        font-size: 18px;
    }
}

.more-offers-container{
    padding: 0;
    text-align: right;

    .btn-load-more{
        display: inline-block;
        padding: $margin-padding-sm $margin-padding-md;
        cursor: pointer;
        user-select: none;
    }
}

.tfg-included, .tfg-not-included{
    display: block;
    @media (min-width: $screen-sm-min) {
        float:right;
        display: inline-flex;
    }
}

.price-block .hidden-md.hidden-lg .btn-reserve{
    &>span:first-of-type{
        &>span:last-of-type {
            font-size: 1.2em;
            font-weight: bold;
        }
    }
    &>span:last-of-type{
        font-size: .9em;
    }
}


.hotel-alternatives-list .alt-item .flight-details-handler {
    i.fa{
        color: $color21;

        &:not(.fa-spinner) {
            margin-left: -17px;
        }
        + span {
            margin-left: 3px;
        }
        &.fa-spinner{
            margin-left: 5px;
            width: auto;
        }
    }

    align-items: center;
    color: $color21;
}

.hotel-alternatives-list .alt-item .alt-flights-container .box-container {
    i.fa{
        &:not(.fa-spinner) {
            margin-left: 0;
        }
    }
    align-items: center;
}

.flight-details {
    padding: 10px;
    width: auto;
    color: $color9;

    &>div {
        display: flex;
        align-items: stretch;
        &:not(.flight-details-info){
            gap: 50px;
        }

        .flight-details-out, .flight-details-ret {
            display: flex;
            align-items: stretch;
            flex-direction: column;
            gap: 10px;

            >span:first-child {
                font-weight: bold;
                margin:auto;

                .fa {
                    color: $color11;
                    margin-right: 5px;
                    font-size: 1.3em;
                    vertical-align: middle;
                }
            }
        }
    }

    .flight-details-part{
        display: flex;
        gap: 10px;
        padding: 5px;

        &:not(.flight-details-stop){
            background: $darkerBackground;
        }

        .flight-details-places div{
            white-space: nowrap;
        }

        .flight-details-times div:first-child,
        .flight-details-places div:first-child {
            font-weight: bold;
        }

         &~.flight-details-part {
            .flight-details-times div:first-child,
            .flight-details-places div:first-child {
                font-weight: normal;
            }
        }

        &:last-child {
            .flight-details-times div:last-child,
            .flight-details-places div:last-child {
                font-weight: bold;
            }
        }

        .flight-details-times div:not(:last-child):not(:first-child),
        .flight-details-places div:not(:last-child):not(:first-child)
        {
            font-size: .9em;
            font-weight: lighter;
            color: $color10;
            margin-bottom: 25px;
            margin-top:5px;
        }

        .flight-details-markers{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;

            i.fa{
                color: $color10
            }
        }
        .flights-connector{
            border-left: 1px dotted $color10;
            flex-grow: 1;
        }
    }

    .flight-details-stop {
        color: $color10;

        div {
            margin: auto;
            .fa {
                margin-right: 5px;
            }
        }


    }

    .flight-details-gaps{
        color: $color10;
    }

    .date-span, .arrival-same-day, .arrival-next-day {
        color: $color10;
        margin: -10px auto auto;
    }

    .flight-details-info {
        margin-top: 20px;
        color: $color10;
        margin-right: 10px;
        div, span {
            margin-left: 10px;
            text-align: justify;
            max-width: 700px;
        }
    }

    .baggage-allowance {
        order: 100;
        margin-top: 20px;
        color: $color10;
        max-width: 300px;
        .baggage-allowance-desc .fa {
            margin-right: 10px;
            color: $color10;
        }
    }

    @media (max-width: $screen-sm-max) {
        & > div:first-child {
            flex-direction: column;
            gap: 0;
        }
        .flight-details .flight-details-info div, .flight-details .flight-details-info span{
            margin: 0;
        }
    }
}




.qtip-flight-details{
    max-width: 1000px;
}
