$width: 110px;
$height: 108px;

.gallery-widget {
  img {
    width: $width;
    height: $height;
  }
  .gallery-row {
    padding: 5px 20px;
    .gallery-col {
      padding: 3px;
      a {
        min-height: 114px;
      }
    }
  }
}

section.gallery-page {
  text-align: center;

  .gallery-row {
    padding: 5px 20px;
  }

  .gallery-col {
    padding: 5px 5px 4px 5px;
    overflow: hidden;
    cursor: pointer;
    &:hover{
      .text-content {
        opacity: 1;
        -moz-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
      }
    }
  }

  .text-content {
    color: #FFFFFF;
    cursor: pointer;
    left: 4px;
    position: absolute;
    bottom: -30px;
    height: 35px;
    width: calc(100% - 8px);
    line-height: 25pt;
    opacity: 0;
    -webkit-transition: opacity 500ms;
    -moz-transition: opacity 500ms;
    -o-transition: opacity 500ms;
    transition: opacity 500ms;
    background: rgba(0,0,0,0.5);
    .text {
      padding-left: 10px;
    }
  }

  .caption {
    visibility: hidden;
    position: relative;
    padding: 0;
    transition: 0s top;
    top:0;

    .caption-background {
      background-color: black;
      opacity: 0.5;
      position: relative;
      top: 0;
      height: 60px;
    }
    .caption-content {
      position: relative;
      color: #FFF;
      top: 0;
      padding: 20px;
    }
  }

  span.text-content span {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
}

.gallery-widget-step2 {
  position: fixed !important;
  bottom: 21px;
}

.modern-free-layout {
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  opacity: 0;
}

.js-modern-free-layout {
  &:hover {
    > .caption {
      transition-delay: 0.2s;
      top: -60px;
      visibility: visible;
    }
  }
}