.information-primary {
	margin-bottom: 15px;

	p {
		padding: 10px 14px;
		margin: 0;
	}
}

.eguide {
	margin: 0;
	overflow: hidden;

	li {
		cursor: pointer;
		line-height: 38px;
		border-top: 1px solid;
		position: relative;

		&:first-child {
			border-top: 0;
		}

		> .section-header {
			padding: 0 0 0 52px;
			background-color: transparent;
			text-transform: uppercase;

			&.alpabet {
				padding-left: 0;
				margin-left: 22px;
				font-weight: bold;
			}

			> .sh-inner {
				&:after {
					//top: 0px;
					right: 15px;
				}
			}
		}

		img {
			position: absolute;
			top: 11px;
			left: 15px;
			width: 25px;
			height: 17px;
		}

		> a {
			padding-left: 52px;
			display: block;
			text-transform: uppercase;
			position: relative;
			z-index: 1;

			&:hover {
				text-decoration: none;
			}
		}

		span {
			margin-right: 19px;

			&.regions {
				margin-top: -38px;
			}
		}

		.list-unstyled {
			border-top: 1px solid $color19;
		}
	}

	.section-header.with-accordion:after {
		display: none;
	}
}

.descriptionImage {
	margin-bottom: 14px;
}

.rowDesc {
	padding: 15px 0 30px 0;
	position: relative;

	table {
		tr {
			td {
				&:first-child {
					width: 97px;
					color: $color10;
				}
			}
		}
	}
}

.section {
	.beforeTab {
		margin: 8px 0;
		padding: 0;

		&:before {
			content: " ";
		}

		.tab {
			padding: 0;
			cursor: pointer;
			overflow: hidden;
			border: 1px solid;

			h3 {
				margin: 0;
				font-size: 14px;
				padding: 0 10px;
				font-weight: bold;
				line-height: 34px;
			}
		}
	}

	#region-info-content {
		.section-header-space {
			p {
				margin-bottom: 0;
			}
		}

		.region-info {
			section.section {
				margin: 0;

				.section-header-space {
					margin-left: 0;
					margin-right: 0;
				}

				&:last-of-type {
					.beforeTab {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.tabContent {
		.text-justify {
			padding-top: 0;
			padding-bottom: 0;

			& * {
				font-size: 14px !important;
			}
		}
	}

	&.tabCont {
		.tabContent {
			@media (min-width: $screen-sm-min) {
				display: block !important;
			}

			.text-justify {
				margin: 0;

				@media (max-width: $screen-xs-max) {
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}

		.text-justify {
			padding-top: 10px;
			padding-bottom: 10px;
		}

		@media (max-width: $screen-xs-max) {
			.tabContent {
				background-color: transparent;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
}

#eguideDetails {
	.description {
		padding-bottom: 1px;
	}
}

#rightColumn {
	.countryActive {
		.section-header {
			color: $color9;

			.with-accordion:after {
				display: block;
			}
		}
	}

	.section-header:first-child:before {
		display: block;
	}
}

.tabShowContainer {
	.description {
		.section {
			margin-bottom: 0;

			.section-header {
				.sh-inner {
					padding-right: 0;
				}
			}
		}

		.hide {
			&.visible-sm {
				border-bottom: 1px solid $color19;
				text-align: right;
				padding: 0 0 14px 17px;
			}
		}
	}

	table {
		font-size: inherit;
		font-family: inherit;
	}

	.addationalSpace {
		padding-right: 15px;

		.text {
			padding: 0;
		}
	}
}

.tableDetailCountry {
	width: 100%;

	td:first-child {
		padding-right: 10px;
		vertical-align: top;
		width: 0;
	}
}


.print {
	overflow: visible;

	body {
		overflow: visible;
	}

	.wrapper {
		max-width: 800px;

		.tabContent {
			display: block !important;
		}
	}
}

@media print {
	.printEguide {
		overflow: visible;

		body {
			overflow: visible;
		}

		.tabContent {
			display: block !important;
		}

		.container-fluid {
			padding: 0;
		}

		.col-lg-2 {
			width: 108px;
			height: 124px;
			display: block !important;
		}

		.col-lg-10 {
			width: 500px;
			float: left;
			padding: 0 !important;
			margin: -120px 0 0 123px;
		}

		.descriptionImage {
			img {
				width: 108px !important;
				height: 110px !important;
			}
		}
	}

	.gallery {
		li {
			width: 170px;
			overflow: hidden;
		}
	}

	section .section-header.with-accordion:after, section .section-header-2.with-accordion:after {
		content: "";
	}

	a[href]:after {
		content: none;
	}
}
