/*
 * general inputs styling
 */

input, select, textarea, .form-control {
    color: $color9;

    @include placeholder {
        color: $color10;
    }

    &.error {
        border-color: $color21 !important;
    }

    &.invalid {
        color: $color21;
    }
}

.select2-container, input, select, textarea, checkbox .form-control {
    &.error {
        background: $input-err-col-transp;
        border-color: $color21 !important;
        outline-color: $color21 !important;
    }
}

label.error {
    color: $color21 !important
}

textarea {
    resize: none;
}

.blended-textarea {
    width: 100%;
    background: none;
    border: none;
    outline: none;
}

.input-row {
    @include cf;
}

.label-container {
    font-size: 14px;
    line-height: 16px;
    //padding: 6px 8px 7px;
    padding: 0;
}

.input-container, .form-group {
    div.input {
        @include border-radius("$input-br");
    }
    input, textarea {
        padding: 6px 8px;
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;
        outline: none;

        &.invalid {
            border-color: $color21 !important;
            background: #e8c3c5 !important;
        }
        & + .invalid {
            color: #c11f27;
            font-size: 12px;
            line-height: 12px;
        }
    }
    input:not([type="submit"]), textarea {
        border: 1px solid $color19;
        &:focus {
            border-color: #5897fb;
        }
    }
    &.file {
        display: table;

        .file-row {
            display: table-row;
        }

        .btn {
            display: table-cell;
        }

        .sep {
            display: table-cell;
            width: 5px;
        }

        input[type="text"] {
            display: table-cell;
        }
    }

    &.map {
        border: 1px solid $color19;
        @include border-radius("$input-br");
        overflow: hidden;
        text-align: center;
        width: 350px !important;
        margin: 0 auto;
        float: none !important;
    }

    .img-preview {
        display: none;
        border: 1px solid $color19;
        @include border-radius("$input-br");
        margin: 0 0 5px;
        padding: 5px 0;
        text-align: center;
        background-color: #ffffff;
        position: relative;
        cursor: pointer;

        img {
            max-width: 100%;
            max-height: 170px;
        }

        &:after {
            display: block;
            font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            content: unquote("\"#{ $fa-var-crop }\"");
            font-weight: var(--#{$fa-css-prefix}-style);
            position: absolute;
            color: rgba(0, 0, 0, 0.5);
            font-size: 20px;
            line-height: 20px;
            top: 10px;
            right: 10px;
            @include opacity(.7);
            @include transition(all ease 0.5s);
        }

        &:hover {
            &:after {
                @include opacity(1);
            }
        }
    }

    &.inline {
        display: inline-block;
    }

    &.smaller {
        input[type='text'], input[type='email'], input[type='password'] {
            padding: 6px 8px 7px;
            font-size: 13px;
            line-height: 13px;
        }
    }
}

input[type='file'] {
    cursor: pointer;
}

/* Select2
 * Version: 3.5.1 Timestamp: Tue Jul 22 18:58:56 EDT 2014
 */

.searchbox-element {
    .select2-choice {
        padding: 7px 0 7px 8px;
        line-height: 18px;
    }
}

.select2-container {
    margin: 0;
    position: relative;
    display: block;
    overflow: hidden;
    border: 1px solid /* $color19*/
;
    background: $fields-background-color;

    /* inline-block for ie7 */
    zoom: 1;
    *display: inline;

    &:not(.select2-not-empty) {
        /* Select2 expand arrow indicator */
        .select2-choice:after, .select2-choices:after {
            content: unquote("\"#{ $fa-var-angle-down }\"");
            font-weight: var(--#{$fa-css-prefix}-style);
            font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
            position: absolute;
            top: calc(50% - 16px);
            right: 0;
            text-align: center;
            width: 32px;
            line-height: 32px;
            font-size: 21px;
            color: $color1;
            cursor: pointer;
            -webkit-font-smoothing: antialiased;
        }

        &.select2-dropdown-open {
            .select2-choice:after, .select2-choices:after {
                content: unquote("\"#{ $fa-var-angle-up }\"");
                font-weight: var(--#{$fa-css-prefix}-style);
            }
        }

        .clear-btn-active:after {
            content: '';
        }

        &.select2-dropdown-open {
            .clear-btn-active:after {
                content: '';
            }
        }
    }

    /* Single select */
    .select2-choice {
        color: $color9;
        display: block;
        min-height: 30px;
        padding: 0 0 0 8px;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        line-height: 0;
        text-decoration: none;
        background-clip: padding-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .select2-chosen {
            line-height: 30px;
        }
    }
}

.subscriptionsbox {
    .select2-container-multi {
        border: 0px !important;
        ul {
            border: 1px solid;
            min-height: 34px;
        }
    }
    .select2-container-active {
        ul {
            border: 1px solid #5897fb;
        }
    }
    input.double-calendar-init {
        &.invalid {
            font-size: inherit;
            line-height: 16px;
        }
    }
}

.select2-container-disabled {
    .select2-choice:after, .select2-choices:after {
        color: $color19;
    }
}

.select2-body-overflow {
    @include respond-to($screen-xs-max) {
        overflow: hidden;
        position: fixed;
    }
}

html[dir="rtl"] .select2-container .select2-choice {
    padding: 0 8px 0 0;
}

.select2-container.select2-drop-above .select2-choice {
    @include border-radius(0 0 "$input-br" "$input-br");
}

.select2-drop.select2-drop-above {
    margin-top: -1px;
    border-top: 1px solid #aaaaaa;
    border-bottom: 0;
    @include border-radius("$input-br" "$input-br" 0 0);
    -webkit-box-shadow: 0 -2px 8px rgba(50, 50, 50, .6);
    box-shadow: 0 -2px 8px rgba(50, 50, 50, .6);
}

.select2-drop.select2-drop-above.select2-drop-active {
    border-top: 1px solid #5897fb;
}

.select2-drop.select2-drop-above .select2-search input {
    margin-top: $eighth-gutter;
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
    margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    float: none;
    width: 100%;
}

html[dir="rtl"] .select2-container .select2-choice > .select2-chosen {
    margin-left: 26px;
    margin-right: 0;
}

.select2-drop-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 999;
    /* styles required for IE to work */
    background-color: #ffffff;
    filter: alpha(opacity=0);
}

.select2-drop {
    @include border-radius("$input-br");
    width: 100%;
    margin-top: 1px;
    position: absolute;
    z-index: 9999;
    top: 100%;
    background: #ffffff;
    color: #000000;
    //border: 1px solid $color19;
    overflow: hidden;
    -webkit-box-shadow: 0 2px 8px rgba(50, 50, 50, .6);
    box-shadow: 0 2px 8px rgba(50, 50, 50, .6);

    &.filter-multiselect .select2-results {
        @include respond-to($screen-xs-max) {
            max-height: calc(100% - 100px);  //screen height - select/cancel buttons height
            position: relative;
            top: 0;
        }
    }

    @include respond-to($screen-xs-max) {
        position: fixed;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100%;
        @include border-radius(0);
        border-width: 0;
        margin-top: 0;
    }

    &.hide-titlebar-sm {
        @include respond-from($screen-sm-min) {
            .select2-titlebar {
                display: none;
            }
        }
    }
    .selection-handler-all{
        padding: 10px;
        background-color: $color4;
        .btn{
            width: 50%;
            border-radius: 0;
        }
    }


    .select2-titlebar {
        position: relative;
        padding: 0 35px 0 10px;
        line-height: 46px;

        .select2-title {
            //font-size: 14px;
            //font-weight: bold;
            //line-height: 18px;

            font-size: 18px;
        }
        .btn-close {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            cursor: pointer;
            padding: 0 5px;
            @include respond-to($screen-xs-max) {
                display: block;
            }
        }
        .fa {
            font-size: 24px;
            position: relative;
            top: 3px;
        }
    }
}

.select-service {
    .select2-titlebar {
        max-width: 320px;
    }

}

.select2-drop-auto-width {
    border-top: 1px solid #aaaaaa;
    width: auto;
}

.select2-drop-auto-width .select2-search {
    padding-top: $eighth-gutter;
    padding-bottom: 9px;
    background: "$bgcolor4";
}

.select2-search {
    display: inline-block;
    width: 100%;
    min-height: 26px;
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    z-index: 10000;
    white-space: nowrap;
}

.select2-search input {
    @include border-radius("$input-br");
    width: 100%;
    height: auto !important;
    min-height: 26px;
    padding: $eighth-gutter 20px $eighth-gutter $quarter-gutter;
    margin: 5px 0 0;
    outline: 0;
    font-family: sans-serif;
    font-size: 1em;
    border: 1px solid #aaaaaa;
    background: #ffffff;

    &.select2-focused {
        color: #000000 !important;
    }
}

html[dir="rtl"] .select2-search input {
    padding: $eighth-gutter 5px $eighth-gutter 20px;
    background: #ffffff;
}

.select2-country-drop {
    .select2-titlebar-mobile {
        display: none !important;

        @include respond-to($screen-xs-max) {
            display: block !important;
        }
    }
}

.select2-country-combobox {
    .select2-choice {
        .select2-chosen {
            line-height: 32px;
        }
        &:after {
            display: none;
        }
    }

    .select2-search {
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 99999;
        line-height: 32px;
        display: block;

        @include respond-to($screen-xs-max) {
            position: fixed;
            top: 32px;
            padding: 8px;
            background: "$bgcolor4";
        }

        input {
            margin: 0;
            padding: 0 8px;
            border: none;
            height: 32px !important;
            font-family: inherit;

            @include respond-to($screen-xs-max) {
                padding: 8px;
                border: 1px solid "$color19";
            }
        }
    }
}

.select2-search input.select2-active {
    background: #ffffff url('select2-spinner.gif') no-repeat 100%;
}

.select2-container-active {
    border: 1px solid #5897fb !important;
    .select2-choice, .select2-container-active .select2-choices, input[type=text]:FOCUS {
        outline: none;
    }
}

.select2-dropdown-open.select2-drop-above .select2-choice, .select2-dropdown-open.select2-drop-above .select2-choices {
    border-top-color: transparent;
}

.select2-dropdown-open .select2-choice .select2-arrow {
    background: transparent;
    border-left: none;
    filter: none;
}

html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow {
    border-right: none;
}

.select2-dropdown-open .select2-choice .select2-arrow .fa {
    &:before {
        content: unquote("\"#{ $fa-var-angle-up }\"");
        font-weight: var(--#{$fa-css-prefix}-style);
    }
}

.select2-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* results */

.select2-with-searchbox {
    .select2-results {
        @include respond-to($screen-xs-max) {
            top: 79px;
        }
    }
}

.select2-results {
    max-height: 500px;
    padding: 0;
    margin: 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    line-height: 26px;

    li {
        cursor: pointer;
        border-top: 1px solid $color19;
        display: block;

        &.group-elem {
            padding-left: 15px;
        }

        &:first-child {
            border: none;
        }
    }

    @include respond-to($screen-xs-max) {
        position: absolute;
        max-height: 100%;
        width: 100%;
        bottom: 0;
        top: 46px;

        li {
            &:last-child {
                border-bottom: 1px solid $color19;
            }
        }
    }

    .select2-result-label {
        padding: $eighth-gutter 10px $eighth-gutter;
        min-height: 34px;
    }

    .select2-subresult-1 {
        padding-left: 15px;
    }
}

html[dir="rtl"] .select2-results {
    padding: 0 $eighth-gutter 0 0;
    margin: $eighth-gutter 0 $eighth-gutter $eighth-gutter;
}

.select2-results .select2-no-results, .select2-results .select2-searching, .select2-results .select2-ajax-error, .select2-results .select2-selection-limit {
    background: #f4f4f4;
    display: list-item;
    padding-left: 5px;
}

/*
 disabled look for disabled choices in the results dropdown
 */
.select2-results .select2-disabled.select2-highlighted {
    color: #666666;
    background: #f4f4f4;
    display: list-item;
    cursor: default;
}

.select2-results .select2-disabled {
    background: #f4f4f4;
    display: list-item;
    cursor: default;
    opacity: 0.5;
}

.select2-results .select2-selected {
    display: none;
}

.select2-more-results.select2-active {
    background: red;
}

.select2-results .select2-ajax-error {
    background: rgba(255, 50, 50, .2);
}

.select2-drop-multi-show-selected {
    .select2-result-selectable {
        display: block;
        position: relative;
        &.checkboxed-element .select2-result-label {
            padding-left: 35px;
        }

        &.checkboxed-element:not(.select2-selected){
            &:before {
                content: unquote("\"#{ $fa-var-square }\"");
                font-weight: var(--#{$fa-css-prefix}-style);
                position: absolute;
                left: 10px;
                top: 50%;
                margin-top: -13px;
                font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
                font-size: 1.3em;
            }
        }
        &.checkboxed-element.select2-selected {
            &:before {
                content: unquote("\"#{ $fa-var-square-check }\"");
                font-weight: var(--#{$fa-css-prefix}-style);
                position: absolute;
                left: 10px;
                top: 50%;
                margin-top: -13px;
                font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
                font-size: 1.3em;
            }
        }
        &.indent-element{
            padding-left: 20px;
            &.checkboxed-element {
                &:before {
                    padding-left: 20px;
                }
            }
        }

        &.indent-element-2{
            padding-left: 40px;
            &.checkboxed-element {
                &:before {
                    padding-left: 40px;
                }
            }
        }

        i.fa.fa-star, i.fa.fa-star-half {
            margin:0 2px;
        }
    }
}

/* multiselect */

.select2-container-multi {
    ul.select2-choices {
        height: 100%;
        min-height: 32px;
        margin: 0;
        padding: $eighth-gutter 35px $eighth-gutter $quarter-gutter;
        position: relative;
        cursor: text;
        overflow: hidden;
    }
    .placeholder {
        padding-left: 3px;
        line-height: 25px;
    }
}

html[dir="rtl"] .select2-container-multi .select2-choices {
    padding: 0 0 0 $quarter-gutter;
}

.select2-locked {
    padding: 3px $quarter-gutter 3px $quarter-gutter !important;
}

.select2-container-multi .select2-choices li {
    float: left;
    list-style: none;
    max-width: 100%;
}

html[dir="rtl"] .select2-container-multi .select2-choices li {
    float: right;
}

.select2-container-multi .select2-choices .select2-search-field input {
    padding: 0;
    margin: $eighth-gutter 0;
    outline: 0;
    border: 0;
    line-height: 18px;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
    background: #ffffff url('img/select2-spinner.gif') no-repeat 100% !important;
}

// placeholder
.select2-default {
    color: $color10 !important;
}

.select2-focused {
    color: #999999 !important;
}

.select2-container-multi {
    .select2-choices {
        .select2-search-choice {
            color: $color9;
        }

        > .select2-search-choice:not(.select2-search-choice-group) {
            position: relative;
            width: 100%;
            border-bottom: 1px dashed $color19;
            padding-right: 18px;

            .select2-search-choice-close {
                font-size: 18px;
                line-height: 18px;
                right: 0;
                top: calc(50% - 9px);
                padding: 0;
            }
        }

        .select2-search-choice:nth-child(1) {
            border-bottom: none;
        }
        .select2-search-choice:nth-child(2) {
            border-top: 1px dashed $color19;
        }

        .select2-search-choice-group {
            border: transparent;
            margin: 0;
            box-shadow: none;
        }
        .select2-choice-counter {
            display: inline-block;
            padding: 0px 3px 0 0;
        }
    }
}

html[dir="rtl"] .select2-container-multi .select2-choices .select2-search-choice {
    margin: 3px $quarter-gutter 3px 0;
    padding: 3px 18px 3px $quarter-gutter;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
    cursor: default;
}

.select2-search-choice-close {
    display: block;
    position: absolute;
    right: 2px;
    top: calc(50% - 14px);
    color: $color16;
    font-size: 25px;
    line-height: 25px;
    padding: 2px;
    cursor: pointer;

    &:hover {
        color: $color18;
    }
}

html[dir="rtl"] .select2-search-choice-close {
    right: auto;
    left: 5px;
}

html[dir="rtl"] .select2-container-multi .select2-search-choice-close {
    left: auto;
    right: 2px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
    background-position: right -11px;
}

.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
    background-position: right -11px;
}

/* disabled styles */
.select2-container-disabled {
    &.select2-container, &.select2-container-multi {
        opacity: .5;
        cursor: default;
    }
}

/*.select2-container, .select2-container-multi.select2-container-disabled .select2-choices {
	background-image: none;
	opacity: .5;
	cursor: default;
	.select2-search-choice {
		padding: 3px 5px 3px 5px;
		border: 1px solid #ddd;
		background-image: none;
		background-color: #f4f4f4;
		.select2-search-choice-close {
			display: none;
			background: none;
		}
	}
}*/
/* end multiselect */

#alternatives-filter {
    .select2-container-multi .select2-choices {
        padding: $eighth-gutter 30px $eighth-gutter $quarter-gutter;

        .select2-search-choice {
            max-width: 100%;
            .text-ellipsis {
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
            }
        }
    }
}

.alternative-filter-dropdown {
    max-width: 350px;
}

.select2-result-selectable .select2-match, .select2-result-unselectable .select2-match {
    text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
}

.select2-display-none {
    display: none;
}

.select2-measure-scrollbar {
    position: absolute;
    top: -10000px;
    left: -10000px;
    width: 100px;
    height: 100px;
    overflow: scroll;
}

/*
.select2-container, .select2-results {
	.layout-icon {
		margin: 0 5px 0 0;
	}
}*/

.on-windows-mobile {

    .select2-titlebar {
        height: 54px;
    }
    .select2-title {
        display: none;
    }
    .select2-results {
        top: 54px;
    }
    .btn-close {
        top: 10px !important;
        z-index: 21472;
    }
    .select2-search {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        padding: 10px 90px 10px 10px;
        input {
            width: 100%;
            height: 34px !important;
            padding-left: 10px;
            margin: 0;

            &.select2-focused {
                color: #000000 !important;
            }
        }
    }
}

.select2-mobile {
    display: none;

    .select2-titlebar {
        .select2-title {
            display: block;
        }

        @include respond-to($screen-xs-max) {
            height: 54px;
            .select2-title {
                display: none;
            }
        }
    }
    .select2-results {
        @include respond-to($screen-xs-max) {
            top: 54px;
        }
    }
    .btn-close {
        top: 10px !important;
        z-index: 21472;
    }
    .filter-hotel-name-mobile-input-container {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        padding: 10px 90px 10px 10px;
        display: none;
        @include respond-to($screen-xs-max) {
            display: block;
        }
        input {
            width: 100%;
            height: 34px;
            padding-left: 10px;
            display: none;
            @include respond-to($screen-xs-max) {
                display: block;
            }
        }

        .clear-search {
            position: absolute;
            right: 90px;
            color: grey;
            margin-right: 0px;
            margin-top: 0;
            padding: 5px;
            font-size: 25px;
            line-height: 25px;
            cursor: pointer;
            display: none;
        }


    }
}

div.input-map-container {
    i {
        right: 75px;
    }
    div.mc-input {
        @include respond-from($screen-lg-min) {
            padding-right: 102px
        }
    }
}

input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
