$mm-base-font-size: $font-size-base;
$mm-titles-font-size: $font-size-large;
$mm-line-height: 1.1;
$mm-spacing: $quarter-gutter;

.main-nav {
    width: 100%;
    position: relative;
    overflow: hidden;

    ul {
        margin: 0;
        padding: 0;

        li {
            display: block;
            float: left;
            list-style: none;
            position: relative;
            @include cf;

            > a {
                color: inherit;
                position: relative;
                display: block;
                font-size: 19px;
                padding: 12px 19px 14px;
                text-decoration: none;
                min-height: 46px;

                @include respond-to($screen-sm-max) {
                    padding: 12px 15px 14px;
                }
                &:hover {
                    text-decoration: none;
                }
            }

            &.current, &.current:hover {
                .arrow {
                    display: block;
                }
            }

            &.editing {
                a {
                    background: #699A21;
                }
            }
        }
    }
    // ul

    .arrow {
        //z-index: 99;
        position: absolute;
        display: none;
        content: '';
        left: 50%;
        bottom: 2px;
        margin: 0 0 0 -8px;
        color: inherit;
        border-bottom-width: 8px;
        border-bottom-style: solid;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }

    .quick-search {
        padding: $quarter-gutter $half-gutter;
    }
    .current-language {
        color: $color3;
        text-decoration: none !important;

        &:hover {
            color: $color3;
        }
    }
}

.main-nav:not(.mega-menu) {

    &:after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        border-bottom: 2px solid;
        pointer-events: none;
    }

    &.main-nav-hide-separator {
        &::after {
            display: none;
        }

        .arrow {
            bottom: 0;
        }
    }

    ul {
        li {
            > a {
                line-height: 20px;
            }
        }
    }

    .menu-search-close {
        display: none;
    }

    .panel-elem {
        margin: 0;
    }
}

.select-curr-se2 {
    cursor: default;
    @include respond-to($screen-xs-max) {
        display: none !important;
    }
}

.currency-select-position {
    border: 1px solid;
    background: rgba(255, 255, 255, .3);

    .select2-choice {
        height: 30px;
        line-height: 29px;
        border: none;
        .select2-chosen, &:after {
            color: $color15;
        }
    }
}

.elem-in-nav {
    font-size: $mm-titles-font-size;
    padding: 8px 20px 3px 20px;

    &:hover {
        color: $color3;
        text-decoration: none;

    }

    a {
        color: $color3;

        &:hover {
            color: $color3;
            text-decoration: none;
        }
    }

    .curr-elem-qtip {
        position: relative;
        top: -8px;
        left: 0;
    }

    .currency-select-styles {
        position: relative;
        top: -2px;

        @include respond-from($screen-sm-min) {
            width: auto;
        }
    }
}

.background-hover-1 {
    .select-curr-in-nav {
        padding: 1px 20px 4px 4px;
    }
}

.curr-in-hamburger {
    .currency-select-position {
        background: transparent !important;
        color: $color15;
        padding: $quarter-gutter $half-gutter $quarter-gutter 12px;
        border: 0;
    }

    .fa-money {
        margin-top: 1px;
    }
}

.currency-in-hamburer-show-span {
    display: none;
}

.lang-in-hamburger {
    display: none;

    .currency-select-position {
        background: transparent !important;
        color: $color15;
        padding: $quarter-gutter $half-gutter $quarter-gutter 12px;
        border: 0;
    }
}

.link-select-dropdown-width {
    @include respond-from($screen-sm-min) {
        width: auto !important;
    }
    .select2-titlebar {
        padding-right: 15px;
    }
}

.mega-menu {
    min-height: 54px;
    overflow: visible;
    background-color: transparent;
    z-index: 99;
    position: relative;

    &:hover {
        z-index: 100;
    }

    &.sticking-menu:hover {
        z-index: 1010;
    }

    .menu-logo-container {
        transition: height 0.1s ease-in-out, transform 0.3s ease-in-out;

        img {
            transition: all 0.1s ease-in-out;
        }
    }

    &.mega-menu-hide-separator {
        .header-container:after {
            display: none;
        }
    }

    &.mega-menu-high:not(.stuck-menu) {

        .menu-logo-container {
            height: 90px;

            img {
                max-height: 90px;
                padding: 10px 0;
            }
        }

        .row > ul > li {
            & > a {
                padding: 32px 19px 31px;
            }

            &.with-additional-description .sub-menu ~ .elem-in-nav {
                padding-right: 38px;
            }

            &.with-additional-description > .elem-in-nav {
                padding: 26px 19px 25px;
            }

            .sub-menu {
                top: 90px;
            }
        }

        .quick-search-container {
            padding: 25px 7px;
        }

        .basket-container {
            padding: 25px 7px;
        }

        .lng, .curr_menu {
            padding: 25px 7px;
        }
    }

    .header-container {
        min-height: 54px;
        width: 100%;

        &:after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            border-bottom: 2px solid;
            pointer-events: none;
        }

        &.main-nav-fixed {
            position: fixed;
            top: 0;
            z-index: 120;
        }
        &.animated {
            -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
        }
    }

    .sub-menu-bg-image {
        background-position: center;
        background-size: cover;
        height: 90px;
        max-width: 100%;

        &.no-link-image {
            margin: $mm-spacing;
        }
    }

    .sub-menu-subposition {
        .link-with-image {
            padding: $mm-spacing !important;
            float: left;
            display: table;

            .sub-menu-bg-image {
                display: block;
                width: 51px;
                height: 37px;
                float: left;
                margin-right: $mm-spacing;
            }

            .text-container {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    .sub-menu-image {
        .text-container {
            display: none;
        }
    }

    .text-container {
        display: inline;
    }

    .scale-down {
        transform: scale(0);
    }

    .additional-separator {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        height: 1px;
        border-bottom: 2px solid;
    }

    ul {
        li {
            position: static;
            transition: transform .3s ease;
            transform-origin: 50% 0;

            & > a {
                min-height: 54px;
                padding: 13px 19px 14px;
                transition: padding 0.1s ease-in;
            }

            .submenu-icon {
                transition: transform .3s;
            }

            .sub-menu {
                border-top: 3px solid;
                -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .7);
                -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, .7);
                box-shadow: 0 5px 15px rgba(0, 0, 0, .7);
                visibility: hidden;
                opacity: 0;
                position: absolute;
                transform: translateY(-5px);
                transition: all 0.3s ease-in-out, top 0.1s ease-in-out;
                z-index: 1;
                top: 54px;
                max-height: 80vh;
                overflow: auto;

                li:hover {
                    .elem-in-nav, a, .price-details span {
                        color: $color18;
                    }
                }

                li.background-hover-nav-elem:hover {
                    .sub-menu-bg-image {
                        opacity: .7;
                    }
                }

                &:not(.dropdown-sub-menu) {
                    .sub-menu-inner {
                        @include display-flex(flex);
                        min-height: min-content;
                        width: 100%;
                    }
                }

                &.full-width-sub-menu {
                    padding: 20px 0;
                    width: 100%;
                    left: 0;
                    right: 0;
                    @include display-flex(flex);

                    ul {
                        @include align-items(stretch);
                        position: relative;
                        min-height: 0.1px;

                        &.sub-menu-with-separator::after {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            content: '';
                            width: 1px;
                            height: 100%;
                            border: 0;
                            border-right: 1px;
                            border-color: inherit;
                        }

                        &.sub-menu-with-separator.separator-dashed::after {
                            border-style: dashed;
                        }

                        &.sub-menu-with-separator.separator-dotted::after {
                            border-style: dotted;
                        }

                        &.sub-menu-with-separator.separator-solid::after {
                            border-style: solid;
                        }

                        &:first-child::after {
                            display: none;
                        }
                    }
                }

                &.dropdown-sub-menu {
                    padding: 0;
                    max-width: 25%;
                    min-width: 170px;

                    ul {
                        float: none;
                        padding: 0;
                        width: 100% !important;

                        li {
                            a {
                                padding: $mm-spacing;
                            }

                            &.sub-menu-caption {
                                padding: 10px !important;
                            }
                        }

                        .sub-menu-expert, .sub-menu-eguide, .sub-menu-offer_l {
                            padding: 0;
                        }

                        .sub-menu-eguide_s, .sub-menu-eguide_m {
                            .sub-menu-bg-image, .sub-menu-eguide-short-content {
                                float: none !important;
                                width: 100% !important;
                            }

                            .sub-menu-eguide-short-content {
                                padding: 5px 0 0 0 !important;
                            }
                        }
                    }
                }

                ul {
                    float: left;
                    padding: 0 20px;

                    li {
                        float: none;
                        position: static;
                        transform: translateY(-5px);

                        a {
                            font-size: 16px;
                            line-height: $mm-line-height;
                            min-height: initial;
                            padding: $mm-spacing;

                            .link-image {
                                margin: $mm-spacing 0;
                            }

                            &:after {
                                display: none;
                            }

                            i.fa {
                                padding-right: 3px;
                            }

                            img {
                                padding: 0;
                                //border: 2px solid;
                            }
                        }

                        img {
                            height: auto;
                            max-width: 100%;
                            padding: $mm-spacing;
                        }
                    }

                    .sub-menu-offer, .sub-menu-eguide {
                        padding: $mm-spacing;
                        line-height: $mm-line-height;

                        a {
                            padding: 0;

                            h2 {
                                font-size: $mm-titles-font-size;
                                font-weight: bold;
                                margin: 1.5*$mm-spacing 0 $mm-spacing 0;
                                line-height: $mm-line-height;

                                sup {
                                    font-size: 8px;

                                    i.fa {
                                        padding-right: 0;
                                    }
                                }
                            }

                            p {
                                margin: 0;
                                font-size: 14px;
                            }

                            .short-text, .long-text {
                                font-size: 14px;
                            }

                            .price-details {
                                margin-top: 10px;

                                span {
                                    font-size: $mm-titles-font-size;
                                    font-weight: bold;
                                }
                            }
                        }

                        .region-name {
                            font-size: $mm-titles-font-size;
                            text-align: left;
                        }

                        &.narrow-column {
                            text-align: left;

                            .sub-menu-eguide-short-content {
                                min-height: inherit !important;
                            }
                        }

                        &.sub-menu-offer_s, &.sub-menu-offer_m {
                            p:not(.price-details) {
                                display: none;
                            }

                            .price-details {
                                margin: 0;
                            }

                            a {
                                padding: 0;

                                h2 {
                                    margin-bottom: 0;
                                }
                            }
                        }

                        &.sub-menu-offer_s {

                            h2 {
                                margin: 0;
                            }

                            .sub-menu-bg-image {
                                display: none;
                            }

                            &.column-size-small {
                                .sub-menu-bg-image {
                                    display: block;
                                    height: 60px;
                                }

                                h2 {
                                    margin-top: 12px;
                                }
                            }

                            &.column-size-large {
                                .sub-menu-bg-image {
                                    display: block;
                                    width: 51px;
                                    height: 37px;
                                    float: left;
                                    margin-right: 12px;
                                }

                            }
                        }

                        &.sub-menu-eguide_s, &.sub-menu-eguide_m {

                            &.narrow-column {
                                .sub-menu-bg-image, .sub-menu-eguide-short-content {
                                    float: none !important;
                                    width: 100% !important;
                                }

                                .sub-menu-eguide-short-content {
                                    padding: 0 !important;
                                }
                            }

                            .sub-menu-eguide-short-content {
                                float: left;
                                width: 66.6%;
                                padding-left: 12px;
                                min-height: 90px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                            }

                            .region-name {
                                margin: 1.5*$mm-spacing 0 $mm-spacing 0;
                            }

                            .sub-menu-bg-image {
                                width: 33.3%;
                                height: 90px;
                                float: left;
                            }
                        }

                        &.sub-menu-eguide_m {
                            &:not(.narrow-column) {
                                text-align: justify;
                            }
                            .sub-menu-bg-image {
                                height: 90px;
                            }
                        }

                        &.sub-menu-eguide_l {
                            &:not(.narrow-column) {
                                text-align: justify;
                            }
                            .region-name {
                                font-size: $mm-titles-font-size;
                                margin-bottom: 2*$mm-spacing;
                            }

                            .sub-menu-bg-image {
                                margin: 10px 0;
                                height: 90px;
                            }
                        }
                    }

                    .sub-menu-expert {
                        padding: $mm-spacing;

                        p {
                            padding: 0;
                            margin: 0;
                            font-size: $mm-base-font-size;
                            line-height: $mm-line-height;
                        }

                        .expert-title {
                            font-size: $mm-base-font-size;
                        }

                        .expert-phone, .expert-mail {
                            font-size: $mm-base-font-size;

                            .fa {
                                font-size: $mm-base-font-size;
                            }
                        }

                        a {
                            padding: 0;

                            .link-image {
                                margin: 12px 0;
                            }
                        }
                    }

                    .sub-menu-empty-line {
                        height: 2*$mm-spacing;
                        display: block;

                        &.sub-menu-empty-line-with-separator {
                            position: relative;
                            height: 0;

                            &.separator-dashed::after {
                                border-style: dashed;
                            }

                            &.separator-dotted::after {
                                border-style: dotted;
                            }

                            &.separator-solid::after {
                                border-style: solid;
                            }

                            &::after {
                                position: absolute;
                                content: '';
                                width: calc(100% - 24px);
                                left: 12px;
                                top: 50%;
                                height: 1px;
                                border: 0;
                                border-bottom: 1px;
                            }
                        }
                    }

                    .sub-menu-text {
                        padding: $mm-spacing 2*$mm-spacing;
                        font-size: $mm-base-font-size;
                        line-height: $mm-line-height;
                        text-align: justify;

                        &.narrow-column {
                            text-align: left;
                        }

                        p {
                            margin: 0;
                        }
                    }

                    .sub-menu-caption {
                        font-size: $mm-titles-font-size;
                        padding: $mm-spacing;
                        font-weight: bold;
                        line-height: $mm-line-height;

                        .description {
                            font-size: $mm-base-font-size;
                            font-weight: normal;
                        }
                    }
                }
            }

            &:hover {
                .submenu-icon {
                    transform: rotate(-180deg);
                }

                .sub-menu {
                    visibility: visible;
                    opacity: 1;
                    transition-delay: 0s, 0s;
                    transform: translateY(-2px);
                    z-index: 11;

                    li {
                        transform: translateY(0%);
                        transition-delay: 0s, 0s;
                    }
                }
            }
        }
    }

    .lng, .curr_menu {
        font-size: $mm-titles-font-size;
        padding: $mm-spacing;
        transition: padding 0.1s ease-in-out;

        &:hover {
            color: inherit;
        }

        .select2-container {
            color: inherit !important;
            border: 0;
            @include border-radius("$btn-br");
            padding: 0;
            width: 40px;
            height: 40px;

            &.select2-container-active {
                border: 0 !important;
            }
        }
    }

    .currency-select-styles {
        top: 0;
    }

    .currency-select-position {
        background: rgba(255, 255, 255, 0.3);

        &:hover {
            background-color: rgba(255, 255, 255, .2);
            cursor: pointer;
        }

        .select2-choice {
            color: inherit !important;
            padding: 0;
            text-align: center;
            font-size: $mm-titles-font-size;

            .select2-chosen {
                margin: 0;
                line-height: 40px;
                color: inherit !important;

                img {
                    margin-top: -3px;
                }
            }
            &:after {
                display: none;
            }
        }
    }

    .background-hover-nav-elem {
        &:hover {
            background-color: rgba(230, 230, 230, .2);
        }
    }

    .active-menu-elem, .active-submenu-container {
        background-color: rgba(230, 230, 230, .3);
    }



    .sub-menu {
        .active-menu-elem {
            color: $color18 !important;

            .elem-in-nav {
                color: $color18;
            }

            .text-color-11 {
                color: inherit;
            }
        }
    }

    .quick-search-container {
        padding: $mm-spacing;
        transition: padding 0.1s ease-in-out;

        .panel-elem {
            visibility: hidden;
            opacity: 0;
        }

        &.visible-panel {
            .quick-search {
                position: absolute;
                left: 2*$mm-spacing;
                width: calc(100% - 30px);
            }
            .panel-elem {
                visibility: visible;
                opacity: 1;

                & + .fa-menu-search {
                    opacity: 1;
                }
            }
            .menu-search-close {
                display: block;
            }
        }
    }

    .basket-container {
        padding: $mm-spacing;
        transition: padding 0.1s ease-in-out;

        .basket-icon {
            background-color: rgba(255, 255, 255, .3);
            @include border-radius("$btn-br");
            width: 40px;
            height: 40px;
            padding: $mm-spacing;
            text-align: center;
            position: relative;
            color: inherit !important;

            &:hover {
                background-color: rgba(255, 255, 255, .2);
                cursor: pointer;
            }

            .countCart {
                position: absolute;
                top: 2px;
                right: 2px;
                background-color: rgba(0, 0, 0, .4);
                border-radius: 50%;
                width: 16px;
                font-size: 12px;
                height: 16px;
                color: #fff;
            }
        }
    }

    .quick-search {
        background-color: rgba(255, 255, 255, .3);
        @include border-radius("$btn-br");
        padding: 0 10px;
        width: 40px;
        height: 40px;

        &:hover {
            background-color: rgba(255, 255, 255, .2);
            cursor: pointer;
        }

        .fa-menu-search {
            line-height: 38px;
            margin-left: 1px;
            display: block;
            font-size: 20px;
            opacity: .7;
            cursor: pointer;
            transition: opacity 0.3s ease-in-out, line-height 0.1s ease-in-out;;
        }

        .panel-elem {
            position: absolute;
            width: calc(100% - 50px);
            top: 0;
            z-index: 2;
            margin-left: 30px;

            .input-container {
                .quick-search-input {
                    padding: 10px 20px 10px 5px;
                    border: 0;
                    background: transparent;
                    height: 40px;

                    @include placeholder {
                        color: #ddd;
                        padding-left: 1px;
                    }
                }
            }

            .fa-search {
                display: none;
            }
        }

        .menu-search-close {
            top: 5px;
            right: 5px;
            font-size: 24px;
            cursor: pointer;
            z-index: 2;
            display: none;
        }
    }

    .current-menu-item {
        background-color: rgba(230, 230, 230, .3);
    }

    .description {
        font-size: $mm-base-font-size;
        display: block;
    }
}

.logo-justified {
    .menu-logo-container img {
        position: static;
    }
}

.with-additional-description {
    text-align: center;

    > .elem-in-nav {
        padding: 7px 19px 8px;

        .description {
            font-size: 12px;
            margin-top: -5px;
        }
    }

    > .sub-menu {
        text-align: left;

        & ~ .elem-in-nav {
            padding-right: 38px;
        }
    }

    > .has-submenu {
        .submenu-icon {
            position: absolute;
            right: 19px;
            top: 50%;
            margin-top: -10px;
        }
    }
}

.menu-logo-container {
    padding: 0 4*$mm-spacing 0 2*$mm-spacing !important;
    @include display-flex(flex);
    @include flex-direction(column);
    @include justify-content(center);
    height: 54px;

    img {
        max-height: 54px;
        display: block;
        max-width: 100%;
        height: auto;
        padding: $mm-spacing 0;
    }
}

@include respond-from($screen-md-min) {
    .select-tip {
        margin-top: 10px;
        overflow: visible;

        .select2-titlebar::after {
            content: '';
            position: absolute;
            top: -10px;
            left: 50%;
            margin-left: -15px;
            width: 0;
            height: 0;
            z-index: 9;
            border-style: solid;
            border-width: 0 2*$mm-spacing 2*$mm-spacing 2*$mm-spacing;
            border-color: transparent transparent $color1 transparent;
        }
    }
}

//menu alignment

.align-left, .align-right, .align-center {
    .menu-logo-container {
        float: left;
    }
}

.align-right {
    .mega-menu-content {
        padding-right: 2*$mm-spacing;
        @include display-flex(flex);
        @include flex-direction(row);
        @include justify-content(flex-end);
        @include flex-wrap(wrap);

        & > li.background-hover-nav-elem:last-child {
            padding-right: 0 !important;
        }
    }
}

.align-center {
    .mega-menu-content {
        margin: 0 auto;
        display: table;
    }
}

.align-justify {
    .mega-menu-content {
        @include display-flex(flex);
        @include justify-content(space-between);
        @include flex-wrap(wrap);
        padding: 0 $mm-spacing;
    }
}

//Mobile Menu
@media print {
    .mobile-nav {
        display: none !important;
    }
}

.mobile-nav-container {
    position: absolute;
    top: 0;
    height: 50px;
    background-color: $color2;
    width: 100%;

    .mobile-logo {
        position: relative;
        display: block;
        height: 100%;
        z-index: 11;
        margin: 0 66px;
        text-align: center;

        > img {
            height: 100%;
        }
    }

    &.mobile-nav-fixed {
        position: fixed;
        z-index: 10;
    }
}

.sticking-menu {
    z-index: 1020;
}

@include respond-to($screen-sm-max) {
    .sticking-menu + .container-fluid {
        z-index: 9;

        .logo-section {
            position: fixed;
            max-height: 50px;
            line-height: 50px;
        }

        #logo-image-container {
            img {
                width: auto;
                margin: 0 auto;
                top: 0;
                padding: 5px 0;
            }
        }
    }
}

.mobile-nav {
    position: fixed;
    top: 50px;
    left: 100%;
    width: 100%;
    bottom: 0;
    z-index: 100;
    border: 1px solid $color19;
    border-top: 5px solid #fff;

    ul {
        position: relative;
        padding: 0;
        margin: 0;
        height: 100%;
        overflow: auto;

        li {
            list-style: none;
            border-bottom: 1px solid $color15;
            margin: 0;
            transition: all .3s;

            a, .caption-in-mobile, .offer-in-mobile, .eguide-in-mobile, .expert-in-mobile {
                display: block;
                font-size: $mm-titles-font-size;
                line-height: 31px;
                padding: $mm-spacing 2*$mm-spacing $mm-spacing 20px;
                text-transform: uppercase;
                text-decoration: none;
                position: relative;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: $color15;

                .submenu-icon {
                    display: none;
                }

                &.has-submenu {
                    position: relative;
                    padding-right: 47px + 2*$mm-spacing;

                    .expand-menu {
                        position: absolute;
                        text-align: center;
                        width: 47px;
                        height: 47px;
                        line-height: 47px;
                        margin: -$mm-spacing -2*$mm-spacing;
                        right: 2*$mm-spacing;
                    }
                }
            }

            .caption-in-mobile {
                padding-left: 5px;
                font-weight: bold;
                opacity: .5;
            }

            .offer-in-mobile, .eguide-in-mobile, .expert-in-mobile {
                padding: 0;
                font-weight: normal;
            }

            .offer-in-mobile {
                a {
                    padding-right: 95px;
                }

                h2 {
                    font-size: $mm-titles-font-size;
                    line-height: 31px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                }

                p {
                    display: none;
                }

                .price-details {
                    display: block;
                    margin: 0;
                    position: absolute;
                    right: 2*$mm-spacing;
                    top: $mm-spacing;

                    .price-label {
                        display: none;
                    }
                    span {
                        color: inherit;
                    }
                }
            }

            .expert-in-mobile {
                .sub-menu-bg-image, .expert-phone, .expert-mail {
                    display: none;
                }
                p {
                    display: inline-block;
                    margin: 0;
                }
            }

            .eguide-in-mobile {
                .short-text, .long-text, .sub-menu-bg-image {
                    display: none;
                }

                .region-name {
                    margin: 0;

                    strong {
                        font-weight: normal;
                    }
                }
            }
        }
    }

    .description {
        display: none;
    }

    .text-container {
        display: inline;
    }

    .mobile-submenu {
        height: 100%;
        width: 100%;
        left: 100%;
        display: none;
        position: absolute;
        top: 0;
        z-index: 1;

        li {
            padding-left: 4*$mm-spacing;

            &:first-child {
                padding: 10px 20px;
                font-weight: bold;
                text-transform: uppercase;

                .fa-chevron-left {
                    vertical-align: -10%;
                    margin-right: 10px;
                }
            }
        }
    }

    .link-separation {
        &:after {
            position: absolute;
            right: 47px;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 1px;
            content: '';
            background: $color15;
        }
    }

    .functional-link-in-mobile {
        background-color: rgba(230, 230, 230, .2);
        position: relative;

        .fa {
            font-size: 24px;
            top: 11px;
            right: 8px;
            position: absolute;
        }
    }

    .currency-select-position {
        .select2-choice {
            &:after {
                display: none;
            }
        }
    }
}

.js-new-menu, .js-old-menu {
    display: none;
}

.js-old-menu {
    &.mobile-nav {
        ul {
            position: static;
            padding: 0 $half-gutter $half-gutter;

            li {
                border-bottom: 0;
                margin: $half-gutter 0 0;

                a {
                    padding: 0 $half-gutter;
                    color: $color15;
                    @include border-radius(6px);

                    .fa {
                        display: inline-block;
                        min-width: 1.5em;
                        border-right: 1px solid white;
                        line-height: inherit;
                        position: relative;
                        margin-right: .3em;
                    }
                }
            }
        }
    }
}
