.quick-search-popup {
	@media screen and (min-width: $screen-md-min) {
		.qtip-content {
			padding: 0;
		}
	}
	.text-center {
		margin-top: 10px;
	}
}
.quick-search-elem {
	position: relative;
	.fa-search {
		position: absolute;
		top: 50%;
		font-size: 14px;
		margin-top: -7px;
		right: 5px;
		color: inherit;
		@include opacity(0.5);
	}
}

.fa-menu-search {
	display: none;
}

.placeholder {
	padding: 10px 10px 0 50px;
	position: relative;
	min-height: 28px;
	line-height: 16px;
	font-size: 12px;
	.fa {
		position: absolute;
		top: 50%;
		left: 12px;
		font-size: 29px;
		margin-top: -10px;
	}
}
.quick-search-results {
	max-height: 500px;
	overflow: auto;
	h1 {
		margin: 0;
		padding: 5px 10px;
		font-size: 21px;
		border-bottom: 1px solid;
	}
	.offer, .region {
		position:relative;
		display: block;
		padding: 7px 15px;
		text-decoration: none;
		border-bottom: 1px solid;
		h3 {
			margin: 0;
			padding: 0;
			font-size: 15px;
			font-weight: 400;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		h4 {
			margin: 0;
			font-size: 12px;
		}
		.price {
			text-align: right;
			position: absolute;
			bottom: 5px;
			right: 15px;
			font-size: 15px;
			font-weight: 600;
		}

		&.background-color-18 {
			.text-color-1, .text-color-9, .text-color-11 {
				color: inherit;
			}
		}
	}
}
