.calendar {
	position: relative;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	width: 100%;

	.days-of-the-week {
		height: 20px;
		text-align: center;
	}

	.btn-calendar {
		display: block;
		width: 100%;
		padding: 6px 12px;
		line-height: 1.42857;
		font-size: 14px;
		white-space: nowrap;
		text-align: center;
		vertical-align: middle;
		background-clip: padding-box;
		border: 1px solid rgba(255,255,255,.3);
		cursor: pointer;
	}

	.calendar-single {
		width: 100%;
		margin-bottom: 20px;

		tr {
			text-align: center;
		}

		td {
			display: table-cell;
			width: (100% / 7);
		}
	}

	.calendar-double-table {
		@media (max-width: 599px) {
			width: 100%;
			margin-bottom: 20px;
		}

		@media (min-width: 600px) and (max-height: 519px) {
			width: 48%;
			display: inline-table;
			margin: 5px;
		}

		@media (min-width: 520px) and (min-height:520px) and (max-width: $screen-sm-max) {
			width: 100%;
			margin-bottom: 20px;
		}

		@media (min-width: $screen-md-min) and (min-height: 520px) {
			width: 48%;
			display: inline-table;
		}

		tr {
			text-align: center;
		}

		td {
			display: table-cell;
			width: (100% / 7);
		}
	}

	.calendar-top {
		display: table;
		width: 100%;
		@media (max-width: $screen-sm-max) {
			margin-top: 10px;
		}

		@media (max-height:519px) and (min-width:410px) and (max-width: $screen-sm-max) {
			position: absolute;
		}

		@media (min-height:520px), (max-width:409px) {
			position: relative;
		}

		&.header-inline{
			position: absolute;
			left: 0;
			right: 0;
		}

		.calendar-top-btn {
			width: 80px;
			display: inline-block;

			&.year-range {
				width: 120px;
			}
		}

		.fa {
			display: inline-block;
			font-size: 14px;
			padding-top: 4px;
		}
	}

	.calendar-content {
		display: table;
		width: 100%;
	}

	th {
		h4 {
			text-align: center;
			font-size: 1.3em;
			font-weight: bold;
		}
	}
}

.double-calendar-init, .child-date-input,
.double-calendar-init, .youth-date-input {
	outline: none;
	padding: 8px;
	width: 100%;
	font-size: 14px;
	line-height: 16px;
	font-weight: normal;
	background-color: $fields-background-color;
	cursor: pointer;

	&.disabled {
		cursor: default;
		opacity: .5;

		.fa {
			color: $color19;
		}
	}
}

.calendar, .double-calendar-init, .child-date-input,
.calendar, .double-calendar-init, .youth-date-input {
	@include no-selection;
}
