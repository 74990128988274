.checkbox-stack, .radio-stack{
    display: inline;
    position: relative;
    top: -18px;
    right: 1px;
    font-size: 26px;
    line-height: 26px;
    font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');

    label{
        margin: 0;
        font-weight: 500;
    }

    input[type=radio], input[type=checkbox] {
        display:none;
    }
    input[type=radio] + label.last-plan:before {
        position: absolute;
        top: 0;
        left: -22px;
        content: unquote("\"#{ $fa-var-circle }\"");
        font-weight: 900;
        display: inline-block;
        vertical-align: middle;
        color: $color15;
        cursor: pointer;
        z-index: 1;
    }
    input[type=radio] ~ label.first-plan:before{
        position: absolute;
        top: 0;
        left: -22px;
        content: unquote("\"#{ $fa-var-circle }\"");
        font-weight: 400;
        display: inline-block;
        vertical-align: middle;
        color: $color7;
        cursor: pointer;
        z-index: 2;
    }
    input[type=checkbox] + label.last-plan:before {
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 0;
        line-height: 23px;
        font-size: 23px;
        content: unquote("\"#{ $fa-var-square }\"");
        font-weight: 900;
        color: $color15;
        cursor: pointer;
        z-index: 1;
    }
    input[type=checkbox] ~ label.first-plan:before{
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 0;
        line-height: 23px;
        font-size: 23px;
        content: unquote("\"#{ $fa-var-square }\"");
        font-weight: 400;
        color: $color7;
        cursor: pointer;
        z-index: 2;
    }
    input[type=radio]:checked + label.last-plan:before {
        color: $color7;
    }
    input[type=radio]:checked ~ label.first-plan:before {
        font-size: 0.8em;
        left: -19.5px;
        top: 1px;
        color: $color15;
    }
    input[type=checkbox]:checked + label.last-plan:before {
        content: unquote("\"#{ $fa-var-square }\"");
        font-weight: 900;
        color: $color7;
    }
    input[type=checkbox]:checked ~ label.first-plan:before {
        content: unquote("\"#{ $fa-var-check }\"");
        font-weight: 900;
        font-size: 0.6em;
        left: 2px;
        color: $color15;
    }
    input[type=radio]:disabled + label.last-plan:before,
    input[type=radio]:disabled ~ label.first-plan:before,
    input[type=checkbox]:disabled + label.last-plan:before,
    input[type=checkbox]:disabled ~ label.first-plan:before {
        @include opacity(0.25);
    }
    input[type=checkbox]:not(:checked).error + label.last-plan:before{
        color: $color21;
    }
    input[type=radio]:not(:checked).error + label.last-plan:before{
        color: $color21;
    }
}

.checkbox-stack2{
    display: inline;
    position: relative;
    top: 0;
    left: -23px;
    font-size: 23px;
    line-height: 20px;
    vertical-align: middle;
    font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');

    label,
    .stack-label,
    & + .stack-label{
        margin: 0;
        font-weight: 500;
        vertical-align: baseline;
    }

    input[type=radio], input[type=checkbox] {
        display:none;
    }
    input[type=radio] + label.last-plan:before {
        position: absolute;
        top: 0;
        left: -22px;
        content: unquote("\"#{ $fa-var-circle }\"");
        font-weight: 900;
        display: inline-block;
        vertical-align: middle;
        color: $color15;
        cursor: pointer;
        z-index: 1;
    }
    input[type=radio] ~ label.first-plan:before{
        position: absolute;
        top: 0;
        left: -22px;
        content: unquote("\"#{ $fa-var-circle }\"");
        font-weight: 400;
        display: inline-block;
        vertical-align: middle;
        color: $color7;
        cursor: pointer;
        z-index: 2;
    }
    input[type=checkbox] + label.last-plan:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 23px;
        content: unquote("\"#{ $fa-var-square }\"");
        font-weight: 900;
        color: $color15;
        cursor: pointer;
        z-index: 1;
    }
    input[type=checkbox] ~ label.first-plan:before{
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 23px;
        content: unquote("\"#{ $fa-var-square }\"");
        font-weight: 400;
        color: $color7;
        cursor: pointer;
        z-index: 2;
    }
    input[type=radio]:checked + label.last-plan:before {
        color: $color7;
    }
    input[type=radio]:checked ~ label.first-plan:before {
        font-size: 0.8em;
        left: -19.5px;
        top: 1px;
        color: $color15;
    }
    input[type=checkbox]:checked + label.last-plan:before {
        content: unquote("\"#{ $fa-var-square }\"");
        font-weight: 900;
        color: $color7;
    }
    input[type=checkbox]:checked ~ label.first-plan:before {
        content: unquote("\"#{ $fa-var-check }\"");
        font-weight: 900;
        font-size: 0.6em;
        left: 3px;
        color: $color15;
    }
    input[type=radio]:disabled + label.last-plan:before,
    input[type=radio]:disabled ~ label.first-plan:before,
    input[type=checkbox]:disabled + label.last-plan:before,
    input[type=checkbox]:disabled ~ label.first-plan:before {
        @include opacity(0.25);
    }
    input[type=checkbox]:not(:checked).error + label.last-plan:before{
        color: $color21;
    }
}
