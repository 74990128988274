/*
	common styles _common.scss
*/
@font-face {
    font-family: 'Slabo 27px';
    font-style: normal;
    font-weight: 400;
    src: url($fonts-dir + "Slabo27px-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 400;
    src: url($fonts-dir + "Dosis-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url($fonts-dir + "Inconsolata-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url($fonts-dir + "OpenSans-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/ggl/Exo-Regular.ttf") format('truetype');
}


html, body {
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: subpixel-antialiased;
    -ms-touch-action: pan-y;
    font-family: "$font-family", sans-serif;
    color: $color9;
}

body {
    overflow-x: hidden;
}

.with-wrapper {
    overflow-x: hidden;

    &.fixed {
        position: fixed;
        width: 100%;
    }
}

#body-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    position: relative;
    -webkit-overflow-scrolling: touch;
}

header{
    z-index: 3
}

main {
    position: relative;
    margin: 0;
    margin-top: 15px;
    z-index: 2;

    @include respond-to($screen-sm-max) {
        margin-top: 5px;
        margin-bottom: 0;
    }

    &.push-mobile-footer {
        @include respond-to($screen-sm-max) {
             margin-bottom: 56px;
        }
    }
}

footer{
    z-index:1;
}

a {
    &:active, &:focus {
        outline: none;
    }
}

img.scalable-x {
    max-width: 100%;
    min-width: 100%;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.fixed-pos {
    position: fixed;
    overflow: hidden;
    width: 100%;
}

.font-weight-lighter {
    font-weight: lighter;
}

.font-size-xs {
    font-size: 0.625em;
}

.font-size-sm {
    font-size: 0.85em;
}

.font-size-lg {
    font-size: 1.25em;
}

.font-size-2x {
    font-size: 2em;
}

.line-height-xs {
    line-height: 0.625em;
}

.line-height-sm {
    line-height: 0.8em;
}

.line-height-lg {
    line-height: 1.3em;
}

.line-height-2x {
    line-height: 2em;
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0 !important;
}

// Paddings
.padding-5 {
    padding: 5px;
}

.padding-10 {
    padding: 10px;
}

.padding-10-horizontal {
    padding-left: 10px;
    padding-right: 10px;
}

.padding-10-vertical {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-15 {
    padding: 15px;
}

.padding-15-horizontal {
    padding-left: 15px;
    padding-right: 15px;
}

.padding-15-vertical {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-20-horizontal {
    padding-left: 20px;
    padding-right: 20px;
}

.no-padding {
    padding: 0;
}

.no-padding-xs {
    @include respond-to($screen-xs-max) {
        padding: 0;

        > .row {
            margin: 0;
        }
    }
}

// No spacing after row setting
@include respond-from($screen-md-min) {
    .row[data-no-spacing-top="1"] {
        margin-top: -15px;
    }
}

@include respond-from($screen-sm-min) {
    .row[data-no-spacing="1"] {
        section {
            margin-bottom: 0;
        }
        .row-bg {
            height: 100%;
        }
    }

    .row[data-no-spacing="1"] + .row[data-no-spacing-top="1"] {
        margin-top: 0px;
    }

    .row[data-col-no-spacing="1"] {
        padding-right: $margin-padding-md;
        padding-left: $margin-padding-md;

        [data-widgetname] {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-padding-right-sm-lg {
    @include respond-from($screen-sm-min)  {
        padding-right: 0 !important;
    }
}

// Margins
.margin-10 {
    margin: 10px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}


.margin-15 {
    margin: 15px;
}

.margin-10-vertical {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-15-horizontal {
    margin-left: 15px;
    margin-right: 15px;
}

.margin-bottom-15-xs {
    @include respond-to($screen-xs-max) {
        margin-bottom: 15px;
    }
}

.no-margin {
    margin: 0;
}

.no-margin-xs {
    @include respond-to($screen-xs-max) {
        margin-left: 0;
        margin-right: 0;
    }
}

// Borders
.border-top {
    border-top-width: 1px;
    border-top-style: solid;
}

.border-bottom {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.border-right {
    border-right-width: 1px;
    border-right-style: solid;
}

.border-left {
    border-left-width: 1px;
    border-left-style: solid;
}

.simple-border {
    border: 1px solid;
}

.border-transparent {
    border-color: transparent !important;
}

.separator-b-dotted {
    border-bottom: 2px dotted $color19;
}

.wrapper {
    max-width: 1200px;
    position: relative;
}

.col-label {
    margin: 0 0 15px;

    &.pull-left {
        @include respond-to($screen-xs-max) {
            margin-left: 15px;
        }
    }
}

.stars {
    font-size: .6em;
    opacity: .7;
}

.opacity-7 {
    opacity: .7;
}

.shadow-block{
    box-shadow: "$shadow-block";
    @include respond-to($screen-xs-max){
        box-shadow: none;
    }
}

.shadow-block-verical{
    box-shadow: "$shadow-block-vertical";
    @include respond-to($screen-xs-max){
        box-shadow: none;
    }
}

.shadow-block-horizontal{
    box-shadow: "$shadow-block-horizontal";
    @include respond-to($screen-xs-max){
        box-shadow: none;
    }
}

.freebies {
    @include box-shadow(2px 3px 2px rgba(0, 0, 0, 0.4));
    cursor: pointer;
    padding: 3px 2px 2px 10px;
    margin: 10px 0;

    .icon {
        width: 18px;
        height: 18px;
        background: #fff;
        margin: 10px auto;
        @include border-radius(50%);
    }

    p {
        margin: 0;
        font-size: 14px;
        line-height: 2em;
        text-transform: uppercase;
    }

    .freebie-shown {
        text-overflow: ellipsis;
        max-width: 80px;
        font-size: 0.7em;
        white-space: nowrap;
        overflow: hidden;
    }

    .fa-stack {
        position: relative;
        margin: 0 0 0 3px;
        top: 2px;

        .fa-circle {
            font-size: 24px;
            position: absolute;
        }

        .fa-star {
            font-size: 16px;
            position: absolute;
            top: 3px;
            left: 3px;
        }
    }

    &:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        bottom: 5px;
        left: 0;
        border-style: solid;
        border-width: 5px 0 0 7px;
        border-color: rgba(0, 0, 0, .6) transparent transparent transparent;
    }

    .content {
        display: none;
    }
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// freebies
.text-lighter {
    font-weight: lighter;
}

.text-normal {
    font-weight: normal;
}

.fixed-table {
    width: 100%;
    table-layout: fixed;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    td {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.alert {
    margin: 0 auto 15px;
    max-width: 1170px;

    @include respond-to($screen-md-max) {
        margin: 0 15px 15px;
    }
}

.section-separator {
    color: #B7B8B8;
    text-align: center;
    overflow: hidden;
    margin: 5px 0 8px;
    width: 100%;

    .sep-title {
        overflow: hidden;
        text-align: center;
        font-size: 12px;
        line-height: 12px;

        &:before,
        &:after {
            background: #B7B8B8;
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
        }

        &:before {
            right: 0.5em;
            margin-left: -50%;
        }

        &:after {
            left: 0.5em;
            margin-right: -50%;
        }
    }
}

hr {
    font-size: 0;
    line-height: 0;
    border: none;
    border-top: 1px solid;
    margin-top: 11px;
    margin-bottom: 11px;
}

@media (max-width: $screen-sm-max) {
    .fullscreen-popup {
        overflow: hidden;
        position: fixed;
        width: 100%;
        &.mobile-scroll {
            overflow: visible;
            overflow-x: hidden;
            position: fixed;
            #qtip-overlay {
                display: block;
                opacity: 1;
                background-color: $color3;
            }
            .qtip-fullscreen {
                position: fixed;
            }
            .qtip-content {
                overflow: scroll;
                -ms-overflow-style: none !important;
            }
            .mc-searchbar {
                position: fixed;
                top: 0;
                z-index: 5;
                width: 100%;
            }
            .mc-footer {
                position: fixed;
                bottom: 0;
            }
        }
    }
}

.mc-mobile-overflow {
    @include respond-to($screen-sm-max) {
        height: 100%;
        overflow-y: auto !important;
    }
}

// Extended variables based on bootstrap for different screen sizes
@each $alias, $screen-size in ('xs': $screen-xs, 'sm': $screen-sm, 'md': $screen-md, 'lg': $screen-lg) {
    @media (max-width: ($screen-size - 1)) {
        .clearfix-#{$alias} {
            &:before,
            &:after {
                content: " "; // 1
                display: table; // 2
            }
            &:after {
                clear: both;
            }
        }
        .center-block-#{$alias} {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .pull-right-#{$alias} {
            float: right !important;
        }
        .pull-left-#{$alias} {
            float: left !important;
        }
    }
}

@media (max-width: $screen-xs-max) {

    .hide_xs_position {
        position: absolute;
        left: -99999999em;
    }

    .hide_xs {
        display: none;
    }

    .show_xs {
        display: none;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .hide_sm {
        display: none;
    }

    .padding-left-sm {
        padding-left: 0;
    }
}

@media (min-width: $screen-md-min) and (max-width: 1999px) {
    .hide_md {
        display: none;
    }
}

@media (min-width: $screen-lg-min) {
    .hide_lg {
        display: none;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .show_sm {
        display: none;
    }
}

@media (min-width: $screen-md-min) and (max-width: 1999px) {
    .show_md {
        display: none;
    }
}

@media (min-width: $screen-lg-min) {
    .show_lg {
        display: none;
    }
}

.hideBodyOverflow {
    overflow: hidden !important;
}

.hideOverflow {
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.gallery {
    list-style-type: none;
    padding: 0;
    margin-bottom: 15px;
    text-align: center;

    li {
        display: inline-block;
        margin: 4px;

        a {
            img {
                width: 170px;
            }
        }
    }
}

.quicksearch-box.section {
    margin-bottom: 1px;
}

.m-table {
    width: 100%;
    display: table;
}

.m-cell {
    display: table-cell;
}

.direct-communicators {

    ul {
        margin: 0;
        padding: 0;

        li {
            display: inline;
            margin: 0 25px 0 0;
            font-size: 14px;
            line-height: 20px;
            list-style: none;

            a {
                span {
                }
            }
        }
    }
}

.layout-col {
    &:first-child {
        padding-left: 0;

        @include respond-to($screen-md-max) {
            padding: 0;
        }

        @include respond-to($screen-md-max) {
            padding: 0 15px;
        }
    }

    &:last-child {
        padding-right: 0;

        @include respond-to($screen-md-max) {
            padding: 0;
        }

        @include respond-to($screen-md-max) {
            padding: 0 15px;
        }
    }
}

.carousel-control {
    z-index: 11;
    margin: 36px 0 59px 0;
    padding: 0 15px;
    width: $carousel-control-width-sm;
    font-size: $carousel-control-width-sm;
    min-height: 36px + $carousel-control-width-sm / 2;
    @include opacity(.6);

    > .carousel-control-inner {
        position: absolute;
        top: 50%;
        margin-top: -$carousel-control-width-sm;
    }

    &.left {
        > .carousel-control-inner {
            left: 0;
            padding-left: inherit;
            text-align: left;
        }
    }

    &.right {
        > .carousel-control-inner {
            right: 0;
            padding-right: inherit;
            text-align: right;
        }
    }
}

.merlinsplash {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    z-index: 100000;

    img {
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.carousel-inner {
    -webkit-transform-style: preserve-3d;
}

.top-bottom-btns {
    display: none;
    position: fixed;
    top: 50%;
    margin-top: -40px;
    right: 15px;
    z-index: 1000;
    transform: translateZ(0);
    a {
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        z-index: 998;
        display: block;
        margin: 2px 0;
    }

    #btn-back-to-top {
        @include opacity(.7);
        &:hover {
            @include opacity(1);
        }
    }

    #btn-go-to-bottom {
        @include opacity(.7);
        &:hover {
            @include opacity(1);
        }
    }
}

.pos-rel {
    position: relative;
}

.special-label {
    position: absolute;
    font-size: 14px;
    line-height: 16px;
    pointer-events: none;
    z-index: 9;
    text-align: center;
    .sl-wrap {
        display: table;
        width: 100%;
        height: 100%;
    }

    .sl-content {
        display: table-cell;
        vertical-align: middle;
        overflow: hidden;
    }

    &.style-ribbon, &.style-rectangle {
        padding: 8px 12px 8px 14px;

        &.pos-cm {
            top: 50%;
            left: 50%;
            @include translate(-50%, -50%);
            text-align: center;
        }

        &.pos-ct {
            top: 10px;
            left: 50%;
            @include translateX(-50%);
            text-align: center;
        }

        &.pos-cb {
            bottom: 10px;
            left: 50%;
            @include translateX(-50%);
            text-align: center;
        }

        &.pos-rt {
            right: 0;
            top: 10px;
            text-align: right;
        }

        &.pos-rc {
            right: 0;
            top: 50%;
            @include translateY(-50%);
            text-align: right;
        }

        &.pos-rb {
            right: 0;
            bottom: 10px;
            text-align: right;
        }

        &.pos-lt {
            left: 0;
            top: 10px;
            text-align: left;
        }

        &.pos-lm {
            left: 0;
            top: 50%;
            @include translateY(-50%);
            text-align: left;
        }

        &.pos-lb {
            left: 0;
            bottom: 10px;
            text-align: left;
        }

        &.pos-cts {
            top: -10px;
            left: 50%;
            @include translateX(-50%);
        }

        &.pos-cbs {
            bottom: -10px;
            left: 50%;
            @include translateX(-50%);
        }
    }

    &.style-ribbon {
        &.pos-rt, &.pos-rc, &.pos-rb {
            right: -5px;

            &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 5px 0 0;
                border-color: rgba(0, 0, 0, .7) transparent transparent transparent;
                bottom: -4px;
                right: 0;
            }
        }

        &.pos-lt, &.pos-lm, &.pos-lb {
            left: -5px;

            &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 5px 4px 0;
                border-color: transparent rgba(0, 0, 0, .7) transparent transparent;
                bottom: -4px;
                left: 0;
            }
        }
    }

    &.style-ellipse {

        &.pos-cm {
            top: 50px;
            left: 50%;
            @include translateX(-50%);
        }

        &.pos-ct {
            top: 20px;
            left: 50%;
            @include translateX(-50%);
        }

        &.pos-cb {
            bottom: 10px;
            left: 50%;
            @include translateX(-50%);
        }

        &.pos-rt {
            right: 20px;
            top: 20px;
        }

        &.pos-rc {
            right: 20px;
            top: 50px;
        }

        &.pos-rb {
            right: 20px;
            bottom: 10px;
        }

        &.pos-lt {
            left: 20px;
            top: 20px;
        }

        &.pos-lm {
            left: 20px;
            top: 50px;
        }

        &.pos-lb {
            left: 20px;
            bottom: 10px;
        }

        &.pos-cts {
            top: 20px;
            left: 50%;
            @include translate(-50%, -50%);
        }

        &.pos-cbs {
            bottom: 20px;
            left: 50%;
            @include translate(-50%, 50%);
        }

        padding: 5px;
        width: 70px;
        height: 70px;
        text-align: center;
        @include border-radius(50%);
    }

    &.style-sheep {
        width: 75px;
        height: 50px;
        text-align: center;

        &.pos-cm {
            top: 55px;
            left: 50%;
            @include translateX(-50%);
        }

        &.pos-ct {
            top: 25px;
            left: 50%;
            @include translateX(-50%);
        }

        &.pos-cb {
            top: 95px;
            left: 50%;
            @include translateX(-50%);
        }

        &.pos-rt {
            right: 45px;
            top: 25px;
        }

        &.pos-rc {
            right: 10px;
            top: 65px;
        }

        &.pos-rb {
            right: 45px;
            top: 95px;
        }

        &.pos-lt {
            left: 45px;
            top: 25px;
        }

        &.pos-lm {
            left: 45px;
            top: 64px;
        }

        &.pos-lb {
            left: 45px;
            top: 95px;
        }

        &.pos-cts {
            top: 13px;
            left: 50%;
            @include translate(-50%, -50%);
        }

        &.pos-cbs {
            bottom: 15px;
            left: 50%;
            @include translate(-50%, 50%);
        }

        .fa {
            position: absolute;
            top: -7px;
            left: 0;
            font-size: 70px;
            z-index: 1;
            @include scale(1.5, 1);
        }

        .sl-wrap {
            position: relative;
            z-index: 2;
            top: 5px;
        }

        .sl-content {
            max-height: 50px;
        }
    }
}

.pageFormQtip2 {
    padding: 5px;
}

.full-width {
    width: 100%;
}

#allhtlContent {
    box-sizing: content-box;
}

.mapWindow-header {
    box-sizing: content-box;
    .map-location-input {
        display: initial;
        font-weight: normal;
    }
}

.cursor-default {
    cursor: default;
}

.cursor-wait {
    cursor: wait;
}

.cursor-pointer {
    cursor: pointer;
}

// For smoother animation during loading
.fa {
    &.bright, &.semi, &.semi2, &.semi3, &.semi4, &.semi5 {
        @include transition(opacity 0.15s ease-in-out);
    }
}

// Vertical align for .row and others, ie 10+
.vertical-align {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
}

// Background widget style
.sortable-col[data-bg-widget="1"] {
    z-index: 0;
    margin-right: -100%;

    @media (max-width: $screen-sm) {
        display: none;
    }
}

// Background widget style
.sortable-col[data-bg-widget="1"] {
    z-index: 0;
    margin-right: -100%;

    @media (max-width: $screen-sm) {
        display: none;
    }

    + div.VCMSComponent {
        .section, section {
            box-shadow: none !important;
        }

        + div.VCMSComponent {
            .section, section {
                box-shadow: none !important;
            }

            + div.VCMSComponent {
                .section, section {
                    box-shadow: none !important;
                }
            }
        }
    }
}

.sortable-col[data-bg-widget="1"][data-margin-l="F"][data-margin-r="F"] {

    .promo-box{
        box-shadow: none;
        @include border-radius(0);
        .carousel-inner {
            @include border-radius(0);
        }
    }

    @media(min-width: $screen-sm) {
        width: calc(100vw + #{$half-gutter});
        margin-left: calc(-1 * (#{$half-gutter}));
        margin-right: calc(-1 * (100% + 2 * #{$half-gutter}));
    }
    @media(min-width: $screen-lg) {
        width: calc(100vw + #{$half-gutter});
        margin-left: calc(-1 * ((100vw - 100% + #{$half-gutter}) / 2));
        margin-right: calc(-1 * (100% + (100vw - 1200px + 4 * #{$half-gutter}) / 2));
    }
}

.layout-col:first-of-type {
    .sortable-col[data-bg-widget="1"][data-margin-l="F"][data-margin-r="F"] {
        @media(min-width: $screen-md) {
            margin-right: calc(-1 * (100% + (100vw - 1200px + #{$half-gutter}) / 2 + (1200px - 100%)));
        }
        @media(min-width: $screen-lg) {
            margin-left: calc(-1 * ((100vw - 1200px + #{$half-gutter}) / 2));
        }
    }
}

.layout-col + .layout-col {
    .sortable-col[data-bg-widget="1"][data-margin-l="F"][data-margin-r="F"] {
        @media(min-width: $screen-md) {
            margin-left: calc(-1 * (100vw - 100%));
        }
        @media(min-width: $screen-lg) {
            margin-left: calc(-1 * ((100vw - 1200px + #{$half-gutter}) / 2 + (1200px - 100%)));
        }
    }
}

// Row background style
.row-bg {
    position: absolute;
    display: block;
    height: calc(100% - 15px);
    top: 0px;
    z-index: -2;
    left: 0;
    right: 0;
    @media(min-width: $screen-md) {
        width: 100vw;
        right: inherit;
    }
    @media(min-width: $screen-lg) {
        left: calc((100vw - 1200px) / -2);
    }
}

.layout-col + .layout-col {
    .row-bg {
        @media(min-width: $screen-md) {
            width: 100vw;
            left: inherit;
            right: 0;
        }
        @media(min-width: $screen-lg) {
            right: calc((100vw - 1200px) / -2);
        }
    }
}

// Widget visibility setting from admin

@media (min-width: $screen-sm-min) {
    [data-display-desktop="hidden"] {
        display: none;
        .body-wrapper & {
            display: block;
        }
    }
}

@media (max-width: $screen-xs-max) {
    [data-display-mobile="hidden"] {
        display: none;
        .body-wrapper & {
            display: block;
        }
    }
}

// Animate vertical position of widgets
.sortable-col[data-vertical-align].animateVerticalPosition {
    @include transition(margin-top 0.4s ease-out);
}

// Make sortable-row relative
.sortable-row {
    position: relative;

    @media (max-width: $screen-sm-max) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

@media (max-width: $screen-xs-max) {
    .captchaField {
        .captcha {
            min-width: 150px;
        }
    }
}

.captchaField {
    .codeCaptcha {

        .captchaImage {
            position: relative;
            padding: 0 18px 0 0;

            .reloadCaptcha {
                position: absolute;
                top: calc(50% - 9px);
                right: 0;
                text-align: center;
                cursor: pointer;
                z-index: 10;
                font-size: $font-size-large;
            }
        }
    }
}

.quickcontact-box {
    .captcha {
        padding-left: 3px;
        padding-right: 3px;
    }
}

ul.list-style-none{
    list-style:none;
}
.log-track-handler{
    background-color: black;
    padding:3px 5px;position: fixed; bottom: 0;
    right: 0;
    z-index: 1000000000;
    border-top: solid 1px white;
    border-left: solid 1px white;
    display: inline-flex;
    transition: height 0.2s;
    height: 24px;
    .log-track-handler-container{
        padding:0 5px;
        color: white;
        a{
            cursor: pointer;
            text-decoration: none;
            color: white;
        }
    }
    &.has-admin{
        right: 130px;
    }
    &:hover{
        height: 100px;
    }
}

details
{
    max-height: 50px;
    overflow: hidden;
    transition: max-height .5s linear;
    .fa-chevron-down{
        transition: transform .2s linear;
    }
}

details[open]
{
    max-height: 1000px;
    .fa-chevron-down{
        transform:rotate(180deg);
    }
}

summary {
    display: block;
    outline:none;
    &::-webkit-details-marker {
        display: none;
    }
}

.anchor-display {cursor: pointer}
.anchor-display:hover, details:hover .anchor-display span{text-decoration: underline;}

.strip-table {
    tr:nth-child(even){
        background-color: $darkerBackground;
    }
}

.beach-d-tootlip .fa-attr_beach {
    vertical-align: -.2em;
    height: .6em;
}

.history-price{
    font-size: 0.8em;
    .amount{
        text-decoration: line-through;
        vertical-align: middle;
        position: relative;
        top: 1px;
    }
    .diff{
      //  padding: 0.1em;
        @include border-radius("$btn-br");
        color: $color12;
        background-color: "$bgcolor20";
        min-width: 40px;
        display: inline-block;
        text-align: center;
        line-height: 1.5;
        vertical-align: middle;
        height: 1.6em;
        padding: 0.1em;

    }
}

.hide-with-fade {
    animation: hide-with-fade 1.5s ease-in 3s forwards;
}
@keyframes hide-with-fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}


.omnibus {
  color: gray;
  position: absolute;
  right: 10px;
  line-height: 1;
  bottom: 3px;
  font-size: 0.5em;
  .omnibus-loader:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis-s steps(4,end) 900ms infinite;
    animation: ellipsis-s steps(4,end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0;
    position: absolute;
  }
}

@keyframes ellipsis-s {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis-s {
  to {
    width: 1.25em;
  }
}

@keyframes ellipsis-l {
  to {
    width: 0.7em;
  }
}

@-webkit-keyframes ellipsis-l {
  to {
    width: 0.7em;
  }
}
