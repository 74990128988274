.slider-offer-gallery {
  width: 640px;
  height: 500px;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  visibility: visible;
}

.carousel-arrow {
  display: block;
  position: absolute;
  width: 55px;
  height: 55px;
  cursor: pointer;
  background: url('/public/img/slider-arrow.png') no-repeat;
  overflow: hidden;
}

.gallery-mobile-content {
  cursor: default;
  position: relative;
  top: 0;
  left: 0;
  width: 640px;
  height: 400px;
  overflow: hidden;
}

.carousel-arrow-left {
  top:0;
  left:8px;
  width:55px;
  height:55px;
  background-position: -3px -33px;
  &:hover{
    background-position: -123px -33px;
  }
}

.carousel-arrow-right {
  top:0;
  right:8px;
  width:55px;
  height:55px;
  background-position: -63px -33px;
  &:hover{
    background-position: -183px -33px;
  }
}
.slider-bullet-navigation {
  position: absolute;
  right: 0;
  bottom: 16px;
  left: 0;
  margin: 0 auto;
  .av {
    background-color: $color3;
  }
  div{
    bottom:16px;
    right:16px;
    position: absolute;
    width: 12px;
    height: 12px;
    filter: alpha(opacity=90);
    opacity: .9;
    overflow: hidden;
    cursor: pointer;
    border: #000 1px solid;
    background-color: $color2;
  }
}
